import { useEffect } from 'react';
import { useHowlerStore } from 'services/HowlerService';

function composedPath(el) {
  const path = [];

  let safeCounter = 0;
  while (el) {
    safeCounter++;
    if (safeCounter === 20) {
      // eslint-disable-next-line no-console
      console.warn('could not terminate loop');
      return path;
    }
    path.push(el);
    el = el.parentElement;
  }
  return path;
}

export default function() {
  const playPrimaryClickUiSound = useHowlerStore(state => state.playPrimaryClickUiSound);
  const playClickUiSound = useHowlerStore(state => state.playClickUiSound);
  const playHotspotSfx = useHowlerStore(state => state.playHotspotSfx);
  useEffect(() => {
    const handleClick = event => {
      let path = event.path;
      if (!path) {
        path = composedPath(event.target);
      }
      for (let i = 0; i < path.length; i++) {
        const element = path[i];
        if (element.dataset) {
          const { soundAction } = element.dataset;

          if (soundAction && soundAction === 'primaryClick') {
            playPrimaryClickUiSound();
            break;
          } else if (soundAction && soundAction === 'click') {
            playClickUiSound();
            break;
          } else if (soundAction && soundAction === 'hotspotSfx') {
            playPrimaryClickUiSound();
            playHotspotSfx();
            break;
          }
        }
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);
  return null;
}
