import Countdown from './Countdown';
import Timetable from 'common/components/Timetable';
import TimetableTeaser from 'common/components/Timetable/TimetableTeaser';
import { BackButton, BackgroundContainer, CenterContainer, ScrollContainer, TimetableContainer } from './styles';
import { useWindowStore } from 'services/WindowService';
import { useHistory } from 'react-router-dom';
import { useTranslate } from 'services/TranslationService/hooks';

export default function ConfirmationPage() {
  const breakpoint = useWindowStore(state => state.breakpoint);
  const history = useHistory();
  const translate = useTranslate();

  return (
    <ScrollContainer>
      <BackgroundContainer>
        <CenterContainer>
          <Countdown />
        </CenterContainer>
      </BackgroundContainer>
      <TimetableContainer>
        <TimetableTeaser />
        <Timetable columns={breakpoint === 'medium' ? 2 : 4} preventScroll={true} />
      </TimetableContainer>
      <BackButton accent onClick={() => history.push(`/profile`)}>
        {translate('confirmation.avatar.backbutton') || 'Go back to my avatar'}
      </BackButton>
    </ScrollContainer>
  );
}
