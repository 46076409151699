import styled, { css } from 'styled-components';
import { mediaMax, mediaMin } from 'common/layout';
import { colors, lgBaseColors } from '../../../styles';
import { iconHoverStyle, iconInactiveStyle, iconStyle } from 'common/icon';
import { smallTextStyle } from 'common/text';
import { hexToRgba } from 'utilities/color';

export const ActiveIndicator = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 4px;
  background: linear-gradient(90deg, ${hexToRgba(colors.accent.base, 1)}, ${hexToRgba(colors.accent.highlight, 1)});
  background: -webkit-linear-gradient(
    90deg,
    ${hexToRgba(colors.accent.base, 1)},
    ${hexToRgba(colors.accent.highlight, 1)}
  );
`;

export const IconContainer = styled.div`
  ${props => (props.active ? iconStyle : iconInactiveStyle)};
  position: relative;
  height: 20px;
  width: 20px;
  margin: 0 auto;
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: calc(50% - 10px);
  }

  ${mediaMax(
    'small',
    css`
      ${iconStyle};
      width: 40px;
      height: 40px;
      svg {
        top: calc(50% - 20px);
        left: calc(50% - 20px);
      }
    `
  )};
`;

export const Item = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: relative;
  font-weight: bold;
  text-align: center;
  padding: 15px 10px;
  white-space: nowrap;
  ${mediaMax(
    'small',
    css`
      padding: 14px;
      display: flex;
      flex-direction: row;
      align-self: flex-start;
    `
  )};
  background-color: #000;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  ${ActiveIndicator} {
    display: ${props => (props.active ? 'block' : 'none')};
    ${mediaMax(
      'small',
      css`
        display: none;
      `
    )};
  }
  ${mediaMax(
    'small',
    css`
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    `
  )};
  ${mediaMin(
    'medium',
    css`
      &:first-of-type {
        padding-top: 14px;
      }
      &:hover {
        background-color: #222;
        ${IconContainer} {
          ${props => (props.active ? iconStyle : iconHoverStyle)};
        }
      }
    `
  )}
`;

export const TextContainer = styled.div`
  ${smallTextStyle};
  margin-top: 4px;
  color: ${props => (props.active ? 'white' : lgBaseColors.grey.medium)};
  flex-basis: content;
  ${mediaMax(
    'small',
    css`
      font-size: 18px;
      color: ${colors.font.strong};
      margin-left: 20px;
      line-height: 40px;
      align-self: center;
    `
  )};
`;
