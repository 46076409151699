import { DivideLine, Padder, Footer, Row1, Row2 } from '../styles';
import { useCallback } from 'react';
import ContactUsButton from '../icons/contact-us-button.svg';
import ContactUsButtonSlim from '../icons/contact-us-button-slim.svg';
import SystemsBrochureButton from '../icons/systems-brochure-button-dark.svg';
import SystemsBrochureButton2 from '../icons/systems-brochure-button-dark-copy.svg';
import LogoText from '../icons/logo-text.svg';
import useContainerWidth from '../useContainerWidth';
import { SvgButtonHoverEffect } from '../../../../../uiLivi/styles';

const totalButtonWidth = 180 + 200 + 200 + 2 * 16;

export default function FooterComponent({ downloadBrochure = null, contactForm = false }) {
  const openWindow = useCallback(url => {
    return () => {
      window.open(url);
    };
  }, []);

  const [row2, row2Width] = useContainerWidth();
  const buttonsFitInRow2 = totalButtonWidth < row2Width;

  return (
    <Footer>
      <Row1 $buttonsVisible={!buttonsFitInRow2}>
        <Padder style={{ textAlign: 'right', lineHeight: 0 }}>
          {downloadBrochure && (
            <SvgButtonHoverEffect className="button">
              <SystemsBrochureButton onClick={openWindow(downloadBrochure)} />
            </SvgButtonHoverEffect>
          )}
          {contactForm && (
            <SvgButtonHoverEffect className="button">
              <ContactUsButtonSlim onClick={openWindow('https://www.schletter-group.com/metaverse/')} />
            </SvgButtonHoverEffect>
          )}
        </Padder>
      </Row1>
      <Padder>
        <DivideLine />
        <Row2 ref={row2} $buttonsVisible={buttonsFitInRow2}>
          <LogoText className="logotext" />
          {downloadBrochure && (
            <SvgButtonHoverEffect className="button">
              <SystemsBrochureButton2 onClick={openWindow(downloadBrochure)} />
            </SvgButtonHoverEffect>
          )}
          {contactForm && (
            <SvgButtonHoverEffect className="button">
              <ContactUsButton onClick={openWindow('https://www.schletter-group.com/metaverse/')} />
            </SvgButtonHoverEffect>
          )}
        </Row2>
      </Padder>
    </Footer>
  );
}
