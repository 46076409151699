import { useEffect, useRef } from 'react';
import { MAX_SCROLL, useScrollStore } from '../../../../services/ScrollService';
import { MathUtils } from 'three';
import { gsap, Power2, Sine } from 'gsap';
import { useNavigationStore } from '../../../../services/NavigationService';
import { useModalService } from '../../../../services/ModalService';

export default function ScrollHandler() {
  const requestRef = useRef(null);
  const deltaY = useRef(0);
  const scrollY = useRef(0);
  const smoothY = useRef(0);
  const touchStartY = useRef(0);
  const tTransition = useRef({ progress: 0 });
  const setProgress = useScrollStore(state => state.setProgress);
  const setNormalizedProgress = useScrollStore(state => state.setNormalizedProgress);
  const transition = useScrollStore(state => state.transition);
  const setTransition = useScrollStore(state => state.setTransition);
  const transitionTarget = useScrollStore(state => state.transitionTarget);
  const setDidScrollForward = useScrollStore(state => state.setDidScrollForward);

  const update = () => {
    const navigationMapOpen = useNavigationStore.getState().showNavigationOverlay;
    const modalOpen = useModalService.getState().modals.length > 0;
    const showProductInfoLayer = useNavigationStore.getState().showProductInfoLayer;
    const didScrollForward = useScrollStore.getState().didScrollForward;
    const isForward = deltaY.current < 0;
    const updateScroll =
      !navigationMapOpen &&
      !modalOpen &&
      !showProductInfoLayer &&
      (isForward || didScrollForward || transitionTarget !== 0);

    if (updateScroll) {
      const progress = useScrollStore.getState().progress;

      scrollY.current += deltaY.current;
      smoothY.current = MathUtils.lerp(smoothY.current, -scrollY.current, 0.07);

      setProgress(smoothY.current / MAX_SCROLL);
      setNormalizedProgress(progress >= 0 ? progress % 1 : 1 - ((progress * -1) % 1));

      deltaY.current = 0;
      if (!didScrollForward) setDidScrollForward(true);
    }
    requestRef.current = requestAnimationFrame(update);
  };

  const onWheel = event => {
    deltaY.current = event.wheelDeltaY || event.deltaY * -1;
  };

  const onTouchStart = event => {
    const t = event.targetTouches ? event.targetTouches[0] : event;
    touchStartY.current = t.pageY;
  };

  const onTouchMove = event => {
    const t = event.targetTouches ? event.targetTouches[0] : event;
    deltaY.current = (t.pageY - touchStartY.current) * 10;
    touchStartY.current = t.pageY;
  };

  const createBlendShape = () => {
    const blendShape = document.createElement('div');
    blendShape.style.position = 'absolute';
    blendShape.style.left = '0';
    blendShape.style.top = '0';
    blendShape.style.zIndex = '1';
    blendShape.style.width = '100%';
    blendShape.style.height = '100vh';
    blendShape.style.opacity = '0';
    blendShape.style.background = '#ffffff';
    return blendShape;
  };

  useEffect(() => {
    const progress = useScrollStore.getState().progress;

    if (transition === 'blend') {
      cancelAnimationFrame(requestRef.current);
      tTransition.current.progress = progress;

      const application = document.getElementsByClassName('application')[0];
      const blendShape = createBlendShape();
      application.appendChild(blendShape);

      tTransition.current.progress = progress;
      const t = Math.min(progress + 0.1, 1);

      gsap.to(tTransition.current, {
        duration: 1,
        ease: Sine.easeIn,
        progress: t,
        onUpdate: () => {
          setProgress(tTransition.current.progress);
        },
      });

      gsap.to(blendShape, {
        duration: 0.5,
        opacity: 1,
        ease: Sine.easeIn,
        onComplete: () => {
          gsap.killTweensOf(tTransition.current);
          tTransition.current.progress = transitionTarget - 0.025;
          gsap.to(tTransition.current, {
            duration: 0.5,
            delay: 0.1,
            ease: Sine.easeOut,
            progress: transitionTarget,
            onUpdate: () => {
              setProgress(tTransition.current.progress);
            },
          });

          gsap.to(blendShape, {
            duration: 0.5,
            delay: 0.1,
            opacity: 0,
            ease: Sine.easeOut,
            onComplete: () => {
              setProgress(tTransition.current.progress);
              smoothY.current = tTransition.current.progress * MAX_SCROLL;
              scrollY.current = -tTransition.current.progress * MAX_SCROLL;
              application.removeChild(blendShape);
              setTransition('none');
              requestRef.current = requestAnimationFrame(update);
            },
          });
        },
      });
    } else if (transition === 'scroll') {
      cancelAnimationFrame(requestRef.current);
      tTransition.current.progress = progress % 1;
      const duration = Math.abs(tTransition.current.progress - transitionTarget) * 40;

      gsap.to(tTransition.current, {
        duration: duration,
        progress: transitionTarget,
        delay: 0,
        ease: Power2.easeInOut,
        onUpdate: () => {
          setProgress(tTransition.current.progress);
        },
        onComplete: () => {
          setProgress(tTransition.current.progress);
          smoothY.current = tTransition.current.progress * MAX_SCROLL;
          scrollY.current = -tTransition.current.progress * MAX_SCROLL;
          setTransition('none');
          requestRef.current = requestAnimationFrame(update);
        },
      });
    }
  }, [transition, transitionTarget]);

  useEffect(() => {
    window.addEventListener('wheel', onWheel, { passive: false });
    window.addEventListener('touchstart', onTouchStart);
    window.addEventListener('touchmove', onTouchMove);
    requestRef.current = requestAnimationFrame(update);

    return () => {
      window.removeEventListener('wheel', onWheel);
      window.removeEventListener('touchstart', onTouchStart);
      window.removeEventListener('touchmove', onTouchMove);
      cancelAnimationFrame(requestRef.current);
    };
  }, []);
}
