import React, { useEffect } from 'react';
import { useUserStore } from 'services/UserService';
import { NoCards } from '../styles';
import { CardsContainer, GroupContainer, OuterContainer } from './styles';
import TimetableCard from './TimetableCard';

export default function TimetableGrid({ entryGroups, updateSize, noEventText, columns }) {
  const userIsExpert = useUserStore(state => state.user.role.type === 'expert');

  useEffect(updateSize, [entryGroups, updateSize]);

  return (
    <OuterContainer>
      {entryGroups.length === 0 && <NoCards>{noEventText}</NoCards>}

      {Object.values(entryGroups).map((group, gIndex) => {
        return (
          <GroupContainer key={gIndex}>
            <CardsContainer userIsExpert={userIsExpert}>
              {group.map((entry, rIndex) => {
                return (
                  <TimetableCard
                    entry={entry}
                    key={rIndex}
                    index={rIndex}
                    columns={columns}
                    row={Math.floor(rIndex / columns)}
                    column={rIndex % columns}
                  />
                );
              })}
            </CardsContainer>
          </GroupContainer>
        );
      })}
    </OuterContainer>
  );
}
