import React from 'react';
import { useAvatarStore } from 'services/AvatarService';
import { ArrowWrapper, Button, NextStep, NextStepTitle } from './styles';
import ArrowIcon from 'assets/icons/Icon_Arrow_Down.svg';

const NextStepButton = () => {
  const nextCategory = useAvatarStore(state => state.getNextCategory());
  return (
    <Button onClick={useAvatarStore.getState().goToNextCategory}>
      <NextStep>next step</NextStep>
      <NextStepTitle>{nextCategory.name}</NextStepTitle>
      <ArrowWrapper>
        <ArrowIcon />
      </ArrowWrapper>
    </Button>
  );
};

export default NextStepButton;
