import styled from 'styled-components';
import { useDebugStore } from 'storage/debug';
import { DEVELOPMENT, LOCAL, PRODUCTION } from 'services/BuildService';
import { Button } from 'common/components/Button';
import ToggleButton from 'common/components/ToggleButton';

export const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  color: white;
`;

export default function Config() {
  const debugStore = useDebugStore();

  return (
    <Container>
      <h3>
        <a href="/">Start</a>
      </h3>
      <h3>Show Developer Content</h3>
      <ToggleButton
        active={debugStore.getDeveloperContentEnabled()}
        onToggle={value => {
          debugStore.setDeveloperContentEnabled(value);
        }}
      />

      <h3>Your backend environment</h3>
      <p>Select LOCAL if you want to connect to your local hub/strapi. (Default: Auto)</p>
      {['AUTO', LOCAL, DEVELOPMENT, PRODUCTION].map(target => {
        const selected = debugStore.getSocketTarget() === target;
        return (
          <Button
            key={target}
            onClick={() => {
              debugStore.setSocketTarget(target);
            }}
          >
            {selected ? '*' : ''}
            {target}
          </Button>
        );
      })}

      <h3>Local Storage</h3>
      <Button
        onClick={() => {
          window.sessionStorage.clear();
          window.localStorage.clear();
          useDebugStore.setState({});
        }}
      >
        Clear Session & Local Storage
      </Button>
    </Container>
  );
}
