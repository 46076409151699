import { Vector3 } from 'three';
import { Raycaster } from 'utilities/octree/Raycaster';
import { Spherecaster } from 'utilities/octree/Spherecaster';

const wallSpherecaster = new Spherecaster();
const groundRaycaster = new Raycaster();
const EPSILON = 0.0001;
const ELEVATION = 0.25;
const BODYRADIUS = 0.25; //radius of the body for collisions. Should ideally be the same as ELEVATION for best performance

const DOWN_VEC = new Vector3(0, -1, 0);

export const interpolatePosRot = (position, rotation, targetPosition, targetRotation, velocity, delta) => {
  const newPosition = position;
  let newRotation = rotation;
  const newVelocity = velocity;
  const delta2 = delta * 60;
  //xyz
  const dx = targetPosition[0] - newPosition[0];
  const dy = targetPosition[1] - newPosition[1];
  const dz = targetPosition[2] - newPosition[2];
  const force = 0.05;
  newVelocity[0] += dx * force;
  newVelocity[1] += dy * force;
  newVelocity[2] += dz * force;

  const maxV = 0.083;
  const currentV = Math.sqrt(newVelocity[0] * newVelocity[0] + newVelocity[2] * newVelocity[2]);
  if (currentV > maxV) {
    newVelocity[0] *= maxV / currentV;
    newVelocity[2] *= maxV / currentV;
  }

  newPosition[0] += newVelocity[0] * delta2;
  newPosition[1] += newVelocity[1] * delta2;
  newPosition[2] += newVelocity[2] * delta2;
  newVelocity[0] *= 0.7;
  newVelocity[1] *= 0.7;
  newVelocity[2] *= 0.7;

  //rotation
  let dr = targetRotation - newRotation;
  while (dr < -Math.PI) dr += Math.PI * 2;
  while (dr > Math.PI) dr -= Math.PI * 2;
  const rotForce = 0.1;
  newVelocity[3] += dr * rotForce;
  newRotation = newRotation + newVelocity[3] * delta2;
  newRotation = Math.atan2(Math.sin(newRotation), Math.cos(newRotation));
  newVelocity[3] *= 0.5;
  return {
    position: newPosition,
    rotation: newRotation,
    velocity: newVelocity,
  };
};

export const simulatePlayerMovement = (collisionMesh, playerData) => {
  const { position, rotation, input } = playerData;
  return simulateMovement(collisionMesh, position, rotation, input, input[3]);
};

export const simulateMovement = (collisionMesh, position, rotation, deltaPosition, deltaRotation) => {
  const { walls, ground } = collisionMesh || {};

  let rotationChanged = false;
  let positionChanged = false;

  if (deltaRotation !== 0) {
    rotationChanged = true;
    rotation += deltaRotation;
    rotation = Math.atan2(Math.sin(rotation), Math.cos(rotation));
  }
  if (deltaPosition[0] !== 0 || deltaPosition[2] !== 0) {
    positionChanged = true;
    if (walls && ground) {
      const delta = new Vector3(deltaPosition[0], deltaPosition[1], deltaPosition[2]);
      // if movement is too far (higher than ELEVATION or BODYRADIUS), for example because of low fps, perform multiple steps of collision checking.
      const steps = Math.ceil(delta.length() / Math.min(ELEVATION, BODYRADIUS));

      for (let step = 1; step <= steps; step++) {
        const newPosition = [
          position[0] + deltaPosition[0] / steps,
          position[1],
          position[2] + deltaPosition[2] / steps,
        ];

        let newPos = new Vector3(newPosition[0], newPosition[1] + ELEVATION, newPosition[2]);
        const oldPos = new Vector3(position[0], position[1] + ELEVATION, position[2]);
        newPos = wallCollision(walls, oldPos, newPos);
        groundRaycaster.set(newPos, DOWN_VEC);
        const gravityIntersections = groundRaycaster.intersectObject(ground, true);
        if (gravityIntersections.length > 0) {
          newPos.y = gravityIntersections[0].point.y;
          position = [newPos.x, newPos.y, newPos.z];
        }
      }
    } else {
      position = [position[0] + deltaPosition[0], position[1], position[2] + deltaPosition[2]];
    }
  }
  return {
    rotation: rotationChanged ? rotation : null,
    position: positionChanged ? position : null,
  };
};

function wallCollision(walls, oldPos, newPos) {
  let intersections;
  let isHit = false;
  for (let i = 0; i < 3; i++) {
    wallSpherecaster.set(oldPos, newPos, BODYRADIUS);
    intersections = wallSpherecaster.intersectObject(walls, true);
    isHit = intersections.length > 0;
    if (isHit) {
      const normal = intersections[0].face.normal;
      newPos.copy(intersections[0].correctedPoint);
      newPos.x += normal.x * EPSILON;
      newPos.z += normal.z * EPSILON;
    } else {
      return newPos;
    }
  }
  return oldPos;
}

export const rayCastHeight = (collisionMesh, pos) => {
  const ground = collisionMesh?.ground;
  if (ground) {
    groundRaycaster.set(pos, DOWN_VEC);
    const gravityIntersections = groundRaycaster.intersectObject(ground, true);
    if (gravityIntersections.length > 0) {
      return gravityIntersections[0].point.y;
    }
  }
  return pos.y;
};
