import styled from 'styled-components';
import WeatherSlider from './WeatherSlider';
import WindDisplay from './WindDisplay';
import TextDisplay from './TextDisplay';
import { useDebugStore } from '../../../../storage/debug';
import React, { useEffect } from 'react';
import { useWeatherStore } from '../store';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const GradientBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 220px;
  left: 0;
  bottom: 0;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 3.95%, rgba(0, 0, 0, 0.3) 120%);
`;

export default function WeatherUI() {
  const hideUi = useDebugStore(state => state.getHideUi());
  const setIsWeather = useWeatherStore(state => state.setIsWeather);

  useEffect(() => {
    return () => {
      setIsWeather(true);
    };
  }, []);

  return (
    <Container>
      <GradientBackground />
      <WeatherSlider />
      <WindDisplay />
      {!hideUi && <TextDisplay />}
    </Container>
  );
}
