import { Container, OptionPanelColumn } from './styles';
import React, { useEffect, useCallback } from 'react';
import CategoryPanel from './CategoryPanel';
import OptionsPanel from './OptionsPanel';
import { useAvatarStore } from 'services/AvatarService';
import { useWindowStore } from 'services/WindowService';
import { useDeviceStore } from 'services/DeviceService';
import { useUserStore } from '../../../services/UserService';
import { useGesture } from '@use-gesture/react';
import ColorPicker from './ColorPicker';

const dragThreshold = 100;

export function CustomizationUiGen2({ onDone }) {
  const customizationConfiguration = useAvatarStore(state => state.customizationConfiguration);
  const selectedCategory = customizationConfiguration.find(ao => ao.selected);
  const isMobileBreakpoint = useWindowStore(state => state.isMobile);
  const isMobileOnly = useDeviceStore(state => state.device.isMobileOnly);

  useEffect(() => {
    return () => {
      useAvatarStore.getState().deselectCustomizationCategories();
    };
  }, []);

  const handleDrag = useCallback(
    state => {
      const { down, axis, movement } = state;
      if (!down) {
        if (axis === 'x') {
          if (movement[0] < -dragThreshold)
            useAvatarStore.getState().selectNextOrPreviousOption(selectedCategory.id, 1);
          if (movement[0] > dragThreshold)
            useAvatarStore.getState().selectNextOrPreviousOption(selectedCategory.id, -1);
        } else if (axis === 'y') {
          if (movement[1] < -dragThreshold) useAvatarStore.getState().goToNextCategory();
          if (movement[1] > dragThreshold) useAvatarStore.getState().goToPreviousCategory();
        }
      }
    },
    [selectedCategory]
  );

  const bind = useGesture(
    {
      onDrag: handleDrag,
    },
    { target: window }
  );

  return (
    <Container {...bind}>
      <CategoryPanel
        customizationConfiguration={customizationConfiguration}
        onDone={onDone}
        randomize={() => {
          useAvatarStore.getState().randomize();
          useUserStore.getState().previewAppearance(useAvatarStore.getState().getAppearance());
        }}
      />
      {/* <OptionPanelColumn>
        {selectedCategory &&
          selectedCategory.optionPanels.map((op, opIndex) => {
            return (
              <OptionsPanel
                key={selectedCategory.id + opIndex}
                categoryId={selectedCategory.id}
                panelIndex={opIndex}
                panel={op}
              />
            );
          })}
      </OptionPanelColumn> */}
      <ColorPicker />
    </Container>
  );
}
