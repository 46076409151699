import styled, { css } from 'styled-components';
import { lgBaseColors, zIndex } from 'uiLivi/styles';
import { breakPoints, styledScrollbar, vp } from 'common/layout';

const chatBarWidth = 56;
const chatWidth = 320;
const videoChatWidth = 300;
const itemTransitionDuration = 0.15;
const easing = `cubic-bezier(0.17, 0.84, 0.44, 1)`;

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: ${props => props.vh * 100 - 56}px;
  pointer-events: all;
  color: white;
  ${styledScrollbar};
  overflow-y: auto;
`;

export const FullscreenContentPadding = css`
  padding: 15px 86px 0;
  @media only screen and (max-width: ${breakPoints.small.max}px) {
    padding: 15px 24px 0;
  }
  ${vp.medium(css`
    padding: 15px 48px 0;
  `)}
`;

export const CloseFullscreen = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: all 0.15s ease;
  transition-delay: ${props => (props.show ? 0.1 : 0)}s;
  cursor: pointer;
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

export const Background = styled.div`
  z-index: ${zIndex.overlayContent};
  position: absolute;
  left: 0px;
  top: 0px;
  width: ${props => {
    if (!props.hasChat || props.isMobile) {
      return '100%';
    }
    return props.isChatOpen || props.isShiftedByVideoChat
      ? `calc(100% - ${(props.isChatOpen ? chatWidth : 0) +
          (props.isShiftedByVideoChat ? videoChatWidth : 0) +
          chatBarWidth}px)`
      : `calc(100% - ${chatBarWidth}px)`;
  }};
  height: 100%;
  background-color: ${lgBaseColors.grey.dark};
  pointer-events: none;
  transform: scale(${props => (props.show ? 1 : 1.3)});
  transform-origin: 100% 50%;
  transition: opacity ${props => (props.show ? 0.5 : itemTransitionDuration)}s ${easing}
      ${props => (props.show ? itemTransitionDuration * 4 : 0)}s,
    transform ${props => (props.show ? 1 : 2)}s ${easing} ${props => (props.show ? itemTransitionDuration * 2 : 0)}s,
    width 0.15s ease;
  opacity: ${props => (props.show ? 1 : 0)};
`;

export const StyledTransitionBarContainer = styled.div`
  z-index: ${zIndex.overlayContent};
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  top: 0;
  left: 0;
  pointer-events: none;
  height: ${props => props.vh * 100}px;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  width: ${props =>
    props.isChatOpen || props.isShiftedByVideoChat
      ? `calc(100vw - ${(props.isChatOpen ? chatWidth : 0) + (props.isShiftedByVideoChat ? videoChatWidth : 0)}px)`
      : `100vw`};
  transition: visibility 0.35s linear ${props => (props.show ? 0 : itemTransitionDuration * 4)}s;
`;

export const StyledCoverBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${props => props.vh * 100}px;
  background: black;
  opacity: ${props => (props.show ? 1 : 0)};
  transition-delay: ${props => (props.show ? itemTransitionDuration * 4 : 0)}s;
`;

export const StyledTransitionBar = styled.div`
  width: calc(100% / 3);
  height: 100%;
  background: black;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.35s cubic-bezier(0.17, 0.84, 0.44, 1);
  transition-delay: ${props => (props.show ? props.index / 10 + itemTransitionDuration : 0)}s;
`;

export const StyledFullScreenTitleBar = styled.div`
  position: relative;
  top: 0;
  left: 0;
  z-index: ${zIndex.overlayContent + 1};
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${lgBaseColors.white};
  font-weight: bold;
  background: ${lgBaseColors.grey.dark};
  transform: translateY(${props => (props.show ? 0 : -56)}px);
  transition-delay: ${props => (props.show ? itemTransitionDuration * 4 : 0)}s;
`;
