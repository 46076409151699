import create from 'utilities/zustand/create';
import debugStorage from 'storage/debug';

import AuditoriumMaterials from './AuditoriumMaterials';
import LobbyMaterials from './LobbyMaterials';

const materialsOptions = [];
materialsOptions.push({ name: 'AuditoriumMaterials', materials: <AuditoriumMaterials /> });
materialsOptions.push({ name: 'LobbyMaterials', materials: <LobbyMaterials /> });

export const useMaterialStore = create((set, get) => ({
  materialsOptions: materialsOptions,
  materials: materialsOptions[0],

  setMaterialsIndex: index => {
    debugStorage.setMaterialsIndex(index);
    if (index === -1) {
      set({ materials: { name: 'null', materials: <></> } });
    } else {
      set({ materials: materialsOptions[index % materialsOptions.length] });
    }
  },

  setMaterialsOption: materialsOption => {
    if (debugStorage.getMaterialsIndex() === -1) {
      set({ materials: materialsOption });
    } else {
      get().setMaterialsIndex(debugStorage.getMaterialsIndex());
    }
  },
}));
