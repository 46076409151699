import styled from 'styled-components';
import { colors } from 'uiLivi/styles';

const sizes = { s: 26, m: 42, l: 56, xl: 95 };
const margins = { s: 12, m: 15, l: 18 };

export const ImageContainer = styled.div`
  position: relative;
  min-width: ${props => sizes[props.size]}px;
  height: ${props => sizes[props.size]}px;
  margin-right: ${props => margins[props.size]}px;
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-size: cover;
`;

const badgePositions = { s: -3, m: 0, l: 2 };

export const StarBadge = styled.div`
  position: absolute;
  right: ${props => badgePositions[props.size]}px;
  bottom: ${props => badgePositions[props.size]}px;
  width: 14px;
  height: 14px;
  background-color: ${colors.secondary.base};
  border-radius: 50%;
  svg {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 10px;
    height: 10px;
  }
`;

export const ChatUnseenNotification = styled.div`
  position: absolute;
  right: -2px;
  top: -2px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${colors.secondary.base};
`;
