import styled from 'styled-components';
import SendIcon from 'uiLivi/components/Chat/Icons/Send.svg';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { H4, smallTextStyle, textOverflowEllipsis } from 'common/text';
import { menuPaddingPx } from '../../styles';

export const ListContainer = styled.div`
  margin: 20px ${menuPaddingPx};
`;

export const ListHeadline = styled(H4)`
  margin: 30px 0;
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const FullName = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  display: inline-block;
  cursor: pointer;
  ${textOverflowEllipsis};
  max-width: 145px;
`;

export const Title = styled.div`
  ${smallTextStyle};
  line-height: 15px;
  color: ${lgBaseColors.grey.light};
`;

export const LiveSessionIndicator = styled.div`
  font-weight: bold;
  ${smallTextStyle};
  line-height: 15px;
  color: ${colors.accent.base};
  margin-bottom: 2px;
`;

export const GreySendIcon = styled(SendIcon).attrs(props => ({
  'data-sound-action': 'click',
}))`
  cursor: pointer;
  margin: 0 0 0 10px;
  path {
    transition: stroke 0.1s;
    stroke: ${lgBaseColors.grey.light};
    stroke-width: 1.1;
  }
  &:hover {
    path {
      stroke: ${lgBaseColors.green.dark};
    }
  }
`;
