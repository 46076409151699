import create from 'utilities/zustand/create';

export const MAX_SCROLL = 45000;

export const SCROLL_RANGES = [
  [0.05, 0.3],
  [0.3, 0.47],
  [0.47, 0.66],
  [0.66, 0.83],
];

export const PRODUCT_NAMES = ['Tracking System 1P', 'FixGrid Pro', 'Proline Roof Hooks', 'SUPPORT DESK'];

export const useScrollStore = create((set, get) => ({
  progress: 0,
  setProgress: newProgress => {
    set({ progress: newProgress });
  },
  normalizedProgress: 0,
  setNormalizedProgress: newProgress => {
    set({ normalizedProgress: newProgress });
  },
  transition: 'none',
  setTransition: type => {
    set({ transition: type });
  },
  transitionTarget: 0,
  setTransitionTarget: progress => {
    set({ transitionTarget: progress });
  },
  didScrollForward: false,
  setDidScrollForward: didScrollForward => {
    set({ didScrollForward: didScrollForward });
  },
}));
