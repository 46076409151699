import React, { useMemo, useRef } from 'react';
import { BufferGeometry, BufferAttribute } from 'three';
import { useFrame } from '@react-three/fiber';

import { useWeatherStore } from '../store';

import vertex from './shaders/index.vert';
import fragment from './shaders/index.frag';

import renderOrder from '../config/renderOrder';

export default React.memo(() => {
  const ref = useRef(null);

  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const isAnimating = useWeatherStore(state => state.isAnimating);
  const { env } = smoothedValues;

  const geometry = useMemo(() => {
    const g = new BufferGeometry();
    const vertices = new Float32Array([-1.0, -1.0, 3.0, -1.0, -1.0, 3.0]);

    const uvs = new Float32Array([0, 0, 2, 0, 0, 2]);

    g.setAttribute('uv', new BufferAttribute(uvs, 2));
    g.setAttribute('position', new BufferAttribute(vertices, 2));

    return g;
  }, []);

  const shaderArgs = useMemo(() => {
    return {
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        uDarkness: { value: 0 },
        uOffset: { value: 0 },
      },
      depthTest: false,
      depthWrite: false,
      transparent: true,
    };
  }, []);

  useFrame(() => {
    if (!isAnimating) return;

    ref.current.visible = !(env.vignette.darkness < 0.01 && env.vignette.offset < 0.01);

    shaderArgs.uniforms.uDarkness.value = env.vignette.darkness;
    shaderArgs.uniforms.uOffset.value = env.vignette.offset;
  });

  return (
    <mesh ref={ref} renderOrder={renderOrder.VIGNETTE} frustumCulled={false}>
      <primitive object={geometry} />
      <shaderMaterial args={[shaderArgs]} />
    </mesh>
  );
});
