import ScrollCamera from './ScrollCamera';
import React from 'react';
import LobbyTree from './LobbyTree';
import { useDebugStore } from '../../../storage/debug';
import DustParticles from './DustParticles';
import Rays from './Rays';
import InfoButton from './InfoButton';
import Hotspot from './Hotspot';
import {
  FixGridPro,
  ProlineRoofHooks,
  SUPPORTDESK,
  TrackingSystem1P,
} from './ProductInfoLayer/productInfoIDToComponent';
import NavigationSpot from './NavigationSpot';

export default function ScrollScene() {
  const hideUi = useDebugStore(state => state.getHideUi());

  return (
    <>
      <ScrollCamera />
      {/*<CameraControls />*/}
      <LobbyTree />
      <Rays />
      <DustParticles count={225} />
      {!hideUi && (
        <>
          <InfoButton position={[-9, 1.33, -11.49]} id={TrackingSystem1P} visibleRange={[0.05, 0.24]} />
          <InfoButton position={[-0.05, 1, -17.49]} id={FixGridPro} visibleRange={[0.28, 0.43]} />
          <InfoButton position={[7.1, 2.44, -19.75]} id={ProlineRoofHooks} visibleRange={[0.28, 0.625]} />
          {/*<InfoButton position={[-4.0, 1.4, -24.5]} id={SUPPORTDESK} visibleRange={[0.76, 0.83]} />*/}
          <NavigationSpot position={[-4.4, 1.4, -26]} type={'contact'} id={SUPPORTDESK} visibleRange={[0.77, 0.83]} />

          <Hotspot position={[-9, 2.5, -7]} transitionTarget={0.06} />
          <Hotspot position={[1.75, 2, -15]} transitionTarget={0.301} />

          {/*<NavigationSpot type={'contact'} isMobile={isMobile} position={[-6.6, 1.4, -25.2]} visibility={0.93} />*/}
        </>
      )}
    </>
  );
}
