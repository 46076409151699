import * as PARTICLE_FX from '../ParticleFXConstants';
import * as THREE from 'three';
import gsap, { Elastic, Power2 } from 'gsap';

export function getDataForPreset(animationPreset) {
  if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_EMOJI) {
    return emojiAnimationData;
  } else if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_SPARKLE) {
    return sparkleAnimationData;
  } else if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_TELEPORT) {
    return teleportAnimationData;
  } else {
    return null;
  }
}

export function getTimelineForPreset(animationPreset) {
  if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_EMOJI) {
    return emojiAnimationTimeline;
  } else if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_SPARKLE) {
    return sparkleAnimationTimeline;
  } else if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_TELEPORT) {
    return teleportAnimationTimeline;
  } else {
    return null;
  }
}

export function getSpawnPositionForPreset(animationPreset) {
  if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_EMOJI) {
    return emojiSpawnPosition();
  } else if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_SPARKLE) {
    return sparkleSpawnPosition();
  } else if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_TELEPORT) {
    return teleportSpawnPosition();
  } else {
    return null;
  }
}

export function getParticleAmountForPreset(animationPreset) {
  if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_EMOJI) {
    return 10;
  } else if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_SPARKLE) {
    return 25;
  } else if (animationPreset === PARTICLE_FX.ANIMATION_PRESET_TELEPORT) {
    return 30;
  } else {
    return 2;
  }
}

// Emojis ------------------------------------------------------------------
const emojiAnimationData = { size: 0, y: 0 };
const emojiAnimationTimeline = gsap
  .timeline()
  .set(emojiAnimationData, { size: 0, y: 0 })
  .to(emojiAnimationData, {
    size: 1,
    duration: 0.85,
    ease: Elastic.easeOut.config(1.33, 0.35),
  })
  .to(emojiAnimationData, {
    size: 0,
    duration: 0.15,
    ease: Power2.easeIn,
  })
  .to(
    emojiAnimationData,
    {
      y: 0.2,
      duration: 1,
    },
    '-=1'
  );

function emojiSpawnPosition() {
  const angle = Math.random() * Math.PI * 2;
  const distance = 0.45 + Math.random() * 0.1;
  const x = Math.cos(angle) * distance;
  const y = 0.03 + Math.random() * 1.5;
  const z = Math.sin(angle) * distance;
  return new THREE.Vector3(x, y, z);
}

// Sparkle ------------------------------------------------------------------
const sparkleAnimationData = { size: 0, y: 0 };
const sparkleAnimationTimeline = gsap
  .timeline()
  .set(sparkleAnimationData, { size: 0 })
  .to(sparkleAnimationData, {
    size: 1.3,
    duration: 0.7,
    ease: Elastic.easeOut.config(1.33, 0.35),
  })
  .to(sparkleAnimationData, {
    size: 0,
    duration: 0.3,
    ease: Power2.easeIn,
  });

function sparkleSpawnPosition() {
  const angle = Math.random() * Math.PI * 2;
  const distance = 0.45 + Math.random() * 0.15;
  const x = Math.cos(angle) * distance;
  const y = 0.03 + Math.random() * 1.5;
  const z = Math.sin(angle) * distance;
  return new THREE.Vector3(x, y, z);
}

// Teleport ------------------------------------------------------------------
const teleportAnimationData = { size: 0, y: 0 };
const teleportAnimationTimeline = gsap
  .timeline()
  .delay(1.0)
  .set(teleportAnimationData, { size: 0.3 })
  .to(teleportAnimationData, {
    size: 0.5 + Math.random() * 0.5,
    duration: 0.5,
    ease: Power2.easeOut,
  })
  .to(teleportAnimationData, {
    size: 0,
    duration: 0.5,
    ease: Power2.easeIn,
  })
  .to(
    teleportAnimationData,
    {
      y: -(1.0 + Math.random()),
      duration: 1,
      ease: Power2.easeInOut,
    },
    '-=1'
  );

function teleportSpawnPosition() {
  const angle = Math.random() * Math.PI * 2;
  const distance = 0.6 + Math.random() * 0.3;
  const x = Math.cos(angle) * distance;
  const y = 1.5 + Math.random() * 0.5;
  const z = Math.sin(angle) * distance;
  return new THREE.Vector3(x, y, z);
}
