import { ShaderLib, ShaderMaterial, UniformsUtils, Vector2 } from 'three';
import spriteVert from './sprite.vert';
import spriteFrag from './sprite.frag';

class HUDSpriteMaterialNoScale extends ShaderMaterial {
  constructor(parameters) {
    const internalParameters = {
      vertexShader: spriteVert,
      fragmentShader: spriteFrag,
      uniforms: UniformsUtils.merge([
        ShaderLib.sprite.uniforms,
        { opacity: { value: 1 }, resolution: { value: new Vector2(256, 256) } },
      ]),
      defines: { USE_UV: '', USE_MAP: '' },
    };
    super({ ...internalParameters, ...parameters });
  }

  set map(map) {
    this.uniforms.map.value = map;
  }
  get map() {
    return this.uniforms.map.value;
  }
}

export default HUDSpriteMaterialNoScale;
