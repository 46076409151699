import { useMemo, useRef } from 'react';
import { useTexture } from '@react-three/drei';
import { createNoise2D } from 'simplex-noise';
import { AdditiveBlending, BufferAttribute } from 'three';
import { useFrame } from '@react-three/fiber';
import particle_texture_1 from './assets/particles-single.png';
import { getRandomBetween } from '../../../../utilities/math';

export default function DustParticles({ count = 0 }) {
  const pointsRef = useRef();
  const t = useRef(0);
  const [texture_1] = useTexture([particle_texture_1]);

  const noise = useMemo(() => {
    return createNoise2D();
  }, []);

  const points = useMemo(() => {
    const a = new Array(count * 3).fill(0).map(v => 0);
    return new BufferAttribute(new Float32Array(a), 3);
  }, [count]);

  const origins = useMemo(() => {
    const a = new Array(count).fill(0).map(() => {
      const x = getRandomBetween(-15, 15);
      const y = getRandomBetween(2, 7);
      const z = getRandomBetween(-30, 10);
      return [x, y, z];
    });
    return new BufferAttribute(new Float32Array(a.flat(1)), 3);
  }, [count]);

  useFrame((_, delta) => {
    t.current += delta * 0.025;

    for (let i = 0; i < count; i++) {
      const i3 = i * 3;
      const offsetX = noise(t.current, i + 0) * 5;
      const offsetY = noise(t.current, i + 1) * 5;
      const offsetZ = noise(t.current, i + 2) * 5;

      pointsRef.current.geometry.attributes.position.array[i3 + 0] =
        pointsRef.current.geometry.attributes.origin.array[i3 + 0] + offsetX;
      pointsRef.current.geometry.attributes.position.array[i3 + 1] =
        pointsRef.current.geometry.attributes.origin.array[i3 + 1] + offsetY;
      pointsRef.current.geometry.attributes.position.array[i3 + 2] =
        pointsRef.current.geometry.attributes.origin.array[i3 + 2] + offsetZ;
    }

    pointsRef.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <points ref={pointsRef}>
      <bufferGeometry>
        <bufferAttribute attach={'attributes-position'} {...points} />
        <bufferAttribute attach={'attributes-origin'} {...origins} />
      </bufferGeometry>
      <pointsMaterial
        size={3}
        map={texture_1}
        sizeAttenuation={false}
        transparent={true}
        opacity={0.5}
        renderOrder={-1}
        depthTest={false}
        depthWrite={false}
        blending={AdditiveBlending}
        // alphaTest={0.5}
        // color={'#ff0000'}
      />
    </points>
  );
}
