import { CheckboxContainer, Container, DropdownContainer, FilterRow } from './styles';
import DatePicker from 'common/components/DatePicker';
import Checkbox from 'common/components/Checkbox';

export default function TimetableFilter({ checkboxOptions, dateOptions }) {
  const checkboxes = checkboxOptions
    ? checkboxOptions.map((co, i) => {
        return (
          <CheckboxContainer key={i}>
            <Checkbox value={co.value} label={co.label} handleAccept={co.toggle} />
          </CheckboxContainer>
        );
      })
    : null;

  const datePicker = (
    <DatePicker date={dateOptions.value} onChange={dateOptions.set} clearable allowedDates={dateOptions.allowedDates} />
  );

  return (
    <Container>
      <FilterRow>{checkboxes}</FilterRow>
      <FilterRow wrapOnMobile>
        <DropdownContainer>{datePicker}</DropdownContainer>
      </FilterRow>
    </Container>
  );
}
