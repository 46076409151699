import { Container, Headline, Image, ImageContainer, Subline } from './styles';

export default function SmallTeaser({ content, index, columns, clickHandler = () => {} }) {
  return (
    <Container columns={columns} onClick={clickHandler} index={index}>
      <ImageContainer>
        <Image src={content.teaser ? content.teaser.url : null} />
      </ImageContainer>
      <Headline>{content.headline}</Headline>
      <Subline>{content.subline}</Subline>
    </Container>
  );
}
