import { CubicInterpolant } from 'three';
import get from 'lodash-es/get';

const glsl = x => x;

export const windNoiseGlsl = glsl`
  float noisyTime(float t){
    float n = cos(0.3 * t + sin(cos(0.2 * t) + cos(0.6 * t) + sin(0.2 * t + 0.2)  *  cos(0.95 * t)));
    return (n + 1.0) * 0.5;
  }
`;

export const windNoiseJs = t => {
  var n = Math.cos(
    0.3 * t + Math.sin(Math.cos(0.2 * t) + Math.cos(0.6 * t) + Math.sin(0.2 * t + 0.2) * Math.cos(0.95 * t))
  );
  return (n + 1.0) * 0.5;
};

export const createInterpolator = (arr, key) => {
  const positions = arr.map(el => el.position);
  const values = arr.map(el => get(el, key));
  const instance = new CubicInterpolant(new Float32Array(positions), new Float32Array(values), 1, new Float32Array(1));

  return { key, getPoint: v => instance.evaluate(v)[0], instance };
};
