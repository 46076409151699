import { css } from 'styled-components';
import { colors, easings, lgBaseColors } from 'uiLivi/styles';

export const iconFillAndStroke = (accentColor, baseColor) => css`
  path,
  rect,
  circle,
  ellipse,
  line,
  polyline,
  polygon {
    transition: fill 0.25s, stroke 0.25s;
    transition-timing-function: ${easings.easeOutStrong};
    &[fill] {
      fill: ${baseColor};
      &.accent {
        fill: ${accentColor};
      }
      &:not(.accent) {
        transition-duration: 0.5s;
      }
    }
    &[stroke] {
      stroke: ${baseColor};
      &.accent {
        stroke: ${accentColor};
      }
      &:not(.accent) {
        transition-duration: 0.5s;
      }
    }
  }
`;

export const iconHideFill = css`
  path,
  rect,
  circle,
  ellipse,
  line,
  polyline,
  polygon {
    &[fill] {
      fill: none;
    }
  }
`;

export const iconStyle = css`
  ${iconFillAndStroke(colors.accent.base, 'white')}
`;

export const iconHoverStyle = css`
  ${iconFillAndStroke(lgBaseColors.grey.light, lgBaseColors.grey.light)}
`;

export const iconInactiveStyle = css`
  ${iconFillAndStroke(lgBaseColors.grey.light, lgBaseColors.grey.light)}
`;
