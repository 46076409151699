import React, { useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { MathUtils } from 'three';

import { useWeatherStore } from '../../store';
import { windNoiseJs, createInterpolator } from '../../helpers';

const noisyIntensity = windNoiseJs;

const interpolateValue = (weather, sun, t) => {
  return MathUtils.lerp(weather, sun, t);
};

const createInterpolators = obj => {
  const arr = Object.keys(obj).map(key => obj[key]);
  const keys = Object.keys(arr[0]).filter(el => el !== 'position');
  const instances = keys.map(key => createInterpolator(arr, key));
  return instances;
};

export default function WindStoreInterpolator() {
  const weatherPresets = useWeatherStore(state => state.windPresets).weather;
  const sunTrackerPresets = useWeatherStore(state => state.windPresets).sunTracker;
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const isAnimating = useWeatherStore(state => state.isAnimating);

  const weatherInterpolators = useMemo(() => {
    return createInterpolators(weatherPresets);
  }, []);

  const sunTrackerInterpolators = useMemo(() => {
    return createInterpolators(sunTrackerPresets);
  }, []);

  useFrame((state, delta) => {
    smoothedValues.time += 0.5 * delta * smoothedValues.wind.speedFactor;
    smoothedValues.noisyIntensity = noisyIntensity(smoothedValues.time);

    if (!isAnimating) return;
    weatherInterpolators.forEach((el, i) => {
      smoothedValues.wind[el.key] = interpolateValue(
        weatherInterpolators[i].getPoint(smoothedValues.dampedSliderProgress),
        sunTrackerInterpolators[i].getPoint(smoothedValues.dampedSliderProgress),
        smoothedValues.scenesInterpolator
      );
    });
  });

  return null;
}
