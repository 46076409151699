export default /* glsl */ `
#define USE_UV
#define DISTANCE 

#include <common>
#include <uv_pars_vertex>
#include <envmap_pars_vertex>

varying vec3 vWorldPosition;

void main() {

    #include <uv_vertex>

    #include <begin_vertex>
    #include <project_vertex>

    #include <worldpos_vertex>
    
    vWorldPosition = worldPosition.xyz;

}
`;
