import React, { useEffect, useState } from 'react';
import { StyledAddOrRemoveToChat, StyledChatCreator, StyledFullName, StyledLoadMore } from './styles';
import { usePeopleStore } from 'services/PeopleService';
import OnlineIndicator from 'common/components/OnlineIndicator';
import Person from 'common/components/Person';
import { useUserStore } from 'services/UserService';
import { useTranslate } from 'services/TranslationService/hooks';

const ChatCreator = ({ updateChatList = null }) => {
  const [chatList, setChatList] = useState([]);
  const user = useUserStore(state => state.user);
  const people = usePeopleStore(state => state.people).filter(p => p.id !== user.id);
  const hasMore = usePeopleStore(state => state.hasMore);
  const translate = useTranslate();
  useEffect(() => {
    usePeopleStore.getState().requestPeople();
  }, []);

  useEffect(() => {
    if (updateChatList) updateChatList(chatList);
  }, [chatList]);

  const isInList = id => !!chatList.find(c => c.id === id);

  const updateList = person => {
    const { id } = person;

    let newList;
    if (!isInList(id)) {
      newList = [...chatList, person];
    } else {
      newList = chatList.filter(c => c.id !== id);
    }
    setChatList(newList);
  };

  return (
    <StyledChatCreator>
      {people.map((user, index) => {
        const isExpert = user.role.type === 'expert';
        const isAdded = isInList(user.id);
        return (
          <Person
            verticalCenter
            margin={'15px 0 0 0 '}
            key={index}
            isExpert={isExpert}
            image={{ src: user.profilePicture, size: 'm' }}
            infoComponents={[
              <StyledFullName key="name">
                {user.forename} {user.surname}
              </StyledFullName>,
              <OnlineIndicator key="online" active={user.isOnline} />,
            ]}
            actionComponents={[
              <StyledAddOrRemoveToChat key="addRemove" onClick={() => updateList(user)}>
                {isAdded ? '-' : '+'}
              </StyledAddOrRemoveToChat>,
            ]}
          />
        );
      })}
      {hasMore && (
        <StyledLoadMore
          onClick={() => {
            usePeopleStore.getState().loadMore();
          }}
        >
          {translate('chat.group.loadmore') || 'Load More'}
        </StyledLoadMore>
      )}
    </StyledChatCreator>
  );
};

export default ChatCreator;
