import styled from 'styled-components';
import React from 'react';
import { lgBaseColors } from 'uiLivi/styles';
const TouchArea = styled.div`
  cursor: pointer;
`;

const ProgressBar = styled.div`
  position: relative;
  --bar-height: 6px;
  cursor: ${props => (!props.selected ? 'pointer' : 'default')};
  width: ${props => (props.selected ? 25 : 6)}px;
  height: var(--bar-height);
  background-color: rgba(255, 255, 255, 0.5);
  clip-path: ${props =>
    props.selected ? 'polygon(0px 0px, 23px 0px, 20px 6px, 3px 6px)' : 'polygon(0px 0px, 6px 0px, 3px 6px, 3px 6px)'};
  margin: 0 2px;
  transition: 0.3s ease-in-out;
`;

const Bar = styled.div`
  position: absolute;
  width: 100%;
  height: 6px;
  transform: ${props => (props.selected ? 'translateX(0)' : 'translateX(100%)')};
  background-color: ${lgBaseColors.white};
  clip-path: polygon(0px 0px, 23px 0px, 20px 6px, 3px 6px);
`;

const PaginationContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 25px;
`;

export default function Pagination({ slideCount, slideIndex, gotoSlide }) {
  const bullets = Array.from(Array(slideCount).keys());
  return (
    <PaginationContainer>
      {bullets.map((_, index) => {
        return (
          <TouchArea
            key={index}
            onClick={() => {
              if (index !== slideIndex) gotoSlide(index);
            }}
          >
            <ProgressBar selected={index === slideIndex}>
              <Bar selected={index === slideIndex} />
            </ProgressBar>
          </TouchArea>
        );
      })}
    </PaginationContainer>
  );
}
