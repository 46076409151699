import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import { useDistrictWarmupStore, WarmupPhase } from '../../../services/DistrictWarmupService';
import { Vector3 } from 'three';
import MeshReflectorMaterial from '../../../utilities/canvas/MeshReflectorMaterial';
import { useReflectorMaterialStore } from './store';
import OrbitCameraControls from './OrbitCameraControls';
import TrackingSystem from './ExplodedModels/TrackingSystem';
import RoofHooks from './ExplodedModels/RoofHooks';
import FixGridPro from './ExplodedModels/FixGridPro';
import RaysConstruction from './RaysConstruction';

const tweakedMeshToMaterialConfigs = {
  ground_LM01: { roughness: 0.728, metalness: 0.5, envMapIntensity: 0.053, lightMapIntensity: 1.82 },
  rooftri_LM03_3: { roughness: 0.628, metalness: 0.632, envMapIntensity: 0, lightMapIntensity: 1.82 },
};

export default function ConstructionScene() {
  const { camera, scene, gl } = useThree();
  const meshReflectorMaterials = useRef([]);
  const meshReflectorMeshes = useRef([]);
  const phase = useDistrictWarmupStore(state => state.phase);
  const isLoaded = phase === WarmupPhase.DONE;

  useEffect(
    () =>
      useReflectorMaterialStore.subscribe(state => {
        meshReflectorMaterials.current = [];
        meshReflectorMeshes.current.forEach(el => {
          el.material = new MeshReflectorMaterial(gl, camera, scene, el, {
            planeNormal: new Vector3(0, 1, 0),
            ...state.params,
          });
          el.material.name = el.orgMaterial.name;
          meshReflectorMaterials.current.push(el.material);

          el.material.setValues({
            color: el.orgMaterial.color || null,
            // colorMap: el.orgMaterial.colorMap || null,

            emissive: el.orgMaterial.emissive || null,
            emissiveIntensity: el.orgMaterial.emissiveIntensity || null,
            emissiveMap: el.orgMaterial.emissiveMap || null,

            envMap: el.orgMaterial.envMap || null,
            envMapIntensity: el.orgMaterial.envMapIntensity || null,

            lightMap: el.orgMaterial.lightMap || null,
            lightMapIntensity: el.orgMaterial.lightMapIntensity || null,

            metalness: el.orgMaterial.metalness || null,
            roughness: el.orgMaterial.roughness || null,
          });

          const tweakedValues = tweakedMeshToMaterialConfigs[el.name];
          if (tweakedValues) {
            el.material.setValues(tweakedValues);
          }
        });
      }),
    []
  );

  useEffect(() => {
    if (!isLoaded) return;

    scene.traverse(el => {
      if (el.material?.name === 'aifloor' || el.name === 'test_level_LM01') {
        el.orgMaterial = el.material;
        el.material = new MeshReflectorMaterial(gl, camera, scene, el, {
          planeNormal: new Vector3(0, 1, 0),
          ...useReflectorMaterialStore.getState().params,
        });
        el.material.name = el.orgMaterial.name;
        meshReflectorMaterials.current.push(el.material);
        meshReflectorMeshes.current.push(el);

        el.material.setValues({
          color: el.orgMaterial.color || null,
          // colorMap: el.orgMaterial.colorMap || null,

          emissive: el.orgMaterial.emissive || null,
          emissiveIntensity: el.orgMaterial.emissiveIntensity || null,
          emissiveMap: el.orgMaterial.emissiveMap || null,

          envMap: el.orgMaterial.envMap || null,
          envMapIntensity: el.orgMaterial.envMapIntensity || null,

          lightMap: el.orgMaterial.lightMap || null,
          lightMapIntensity: el.orgMaterial.lightMapIntensity || null,

          metalness: el.orgMaterial.metalness || null,
          roughness: el.orgMaterial.roughness || null,
        });
      }

      const tweakedValues = tweakedMeshToMaterialConfigs[el.name];
      if (tweakedValues) {
        el.material.setValues(tweakedValues);
      }
    }, true);
  }, [isLoaded]);

  useFrame(() => {
    meshReflectorMaterials.current.forEach(mat => mat.update());
  });

  return (
    <>
      <OrbitCameraControls />
      <RaysConstruction />
      <TrackingSystem />
      <RoofHooks />
      <FixGridPro />
    </>
  );
}
