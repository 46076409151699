import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { useAnimations } from '@react-three/drei';
import { gsap } from 'gsap';
import { damp } from 'maath/easing';
import { Color, MathUtils, Vector3 } from 'three';

import { useWeatherStore } from '../store';

import renderOrder from '../config/renderOrder';

// TEMPORARY IF WE NEED MORE ANIMS
//   useEffect(() => {
//     clips.forEach(el => {
//       lazyActionsRef.current[el.name] = mixer.clipAction(el, additionalMesh.current);
//     });
//     lazyActionsRef.current['All Animations'].reset().play().paused = true;
//   }, []);

/**
 * THIS IS PROBABLY OVER-ENGINEERED, UPS.
 */

const V3 = new Vector3();
const COLOR = new Color();

export default function useModelAnimation({
  weatherRanges,
  sunTrackerRanges,
  animations,
  rotatingMesh,
  additionalMesh,
  additionalMaterials,
  onMovementStart = () => {},
  onMovementEnd = () => {},
  onSupportOut = () => {},
  onSupportIn = () => {},
}) {
  const tlWeatherRef = useRef(null);
  const tlSunTrackerRef = useRef(null);
  const weatherDampRef = useRef(0);
  const sunTrackerDampRef = useRef(0);

  const weatherDestinationRef = useRef(0);
  const sunTrackerDestinationRef = useRef(0);
  const lazyActionsRef = useRef({});
  const opacityTweenRef = useRef(null);

  const weatherRotation = useRef(V3.clone());
  const sunTrackerRotation = useRef(V3.clone());
  const finalRotation = useRef(V3.clone());

  const actionTime = useRef(0);

  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const isAnimating = useWeatherStore(state => state.isAnimating);
  const isWeather = useWeatherStore(state => state.isWeather);
  const panelIsMoving = useRef(false);
  const prevLegsVisible = useRef(false);
  const prevBuildTime = useRef(0);

  const weatherEmissiveColor = useMemo(() => COLOR.clone(), []);
  const sunTrackedEmissiveColor = useMemo(() => COLOR.clone().set(0x000000), []);
  const emissiveColor = useMemo(() => COLOR.clone(), []);

  const legsMaterials = useMemo(() => Object.keys(additionalMaterials).map(el => additionalMaterials[el]), []);
  const opacityLegsMaterial = useMemo(() => legsMaterials.find(el => el.name === 'aimetal1'), []);

  const { mixer, clips } = useAnimations(animations); // actions are undefined, maybe some name issue since it's "All Animations"?

  const [currentWeatherStep, setCurrentWeatherStep] = useState(0);
  const [currentSunTrackerStep, setCurrentSunTrackerStep] = useState(0);

  const onTrack = () => {
    const { sliderProgress } = useWeatherStore.getState();
    // WEATHER
    for (let i = 0; i < weatherRanges.length; i++) {
      if (sliderProgress <= weatherRanges[i].position) {
        setCurrentWeatherStep(i);
        break;
      }
    }

    // SUNTRACKER
    for (let i = 0; i < sunTrackerRanges.length; i++) {
      if (sliderProgress <= sunTrackerRanges[i].position) {
        setCurrentSunTrackerStep(i);
        break;
      }
    }
  };

  const createLegsTimeline = () => {
    // ADD LEGS ANIMATION
    const tlLegs = gsap.timeline();
    tlLegs.to(actionTime, { duration: 1, current: lazyActionsRef.current['build'].getClip().duration }, 0);

    legsMaterials.forEach(el => {
      tlLegs.fromTo(
        weatherEmissiveColor,
        { r: 0, g: 0, b: 0 },
        { duration: 0.15, r: 1, g: 1, b: 1, ease: 'none' },
        0.0
      );
      tlLegs.to(weatherEmissiveColor, { duration: 0.15, r: 0, g: 0, b: 0, ease: 'none' }, 0.8);
    });

    return tlLegs;
  };

  const createWeatherTimeline = () => {
    const onUpdate = () => rotatingMesh.current.updateMatrix();
    weatherRotation.current.z = weatherRanges[0].angle;

    tlWeatherRef.current = gsap.timeline({ paused: true, onUpdate });

    weatherRanges.forEach((el, i) => {
      tlWeatherRef.current.to(weatherRotation.current, { duration: 1, z: el.angle, ease: 'none' }).addLabel('step' + i);
    });

    // ADD LEGS ANIMATION
    tlWeatherRef.current.add(createLegsTimeline(), `step${weatherRanges.length - 2}`);
  };

  const createSunTrackerTimeline = () => {
    const onUpdate = () => rotatingMesh.current.updateMatrix();
    sunTrackerRotation.current.z = sunTrackerRanges[0].angle;

    tlSunTrackerRef.current = gsap.timeline({ paused: true, onUpdate });

    sunTrackerRanges.forEach((el, i) => {
      tlSunTrackerRef.current
        .to(sunTrackerRotation.current, { duration: 1, z: el.angle, ease: 'none' })
        .addLabel('step' + i);
    });
  };

  const animate = () => {
    finalRotation.current.lerpVectors(
      weatherRotation.current,
      sunTrackerRotation.current,
      smoothedValues.scenesInterpolator
    );

    rotatingMesh.current.rotation.z = finalRotation.current.z;
    rotatingMesh.current.updateMatrix();

    // COLORS
    emissiveColor.lerpColors(weatherEmissiveColor, sunTrackedEmissiveColor, smoothedValues.scenesInterpolator);

    // LEGS ANIMATION
    lazyActionsRef.current['build'].time = MathUtils.lerp(actionTime.current, 0, smoothedValues.scenesInterpolator);
  };

  useEffect(() => {
    // PREPARE MATERIALS
    opacityLegsMaterial.transparent = true;
    additionalMesh.current.traverse(el => {
      if (el.isMesh) {
        // UPDATE RO to prevent artifacts
        if (el.material.name === opacityLegsMaterial.name) {
          el.renderOrder = renderOrder.KARNOBAT;
        }

        // clone the battery to keep in the scene during opacity tween
        if (el.name === 'batterymat_geo') {
          el.material = el.material.clone();
          el.material.name = el.material.name + '__batterymat_geo';
          el.material.emissive = emissiveColor; // set shared emissive
        }
      }
    });

    // set shared emissive
    legsMaterials.forEach(el => (el.emissive = emissiveColor));

    // CLIP ACTIONS
    lazyActionsRef.current['build'] = mixer.clipAction(clips[0], additionalMesh.current);
    lazyActionsRef.current['build'].reset().play().paused = true;

    // CREATE TIMELINE
    createWeatherTimeline();
    createSunTrackerTimeline();

    // SUB TO TRACK SLIDER
    const unsub = useWeatherStore.subscribe(() => onTrack());

    animate();

    return () => {
      tlWeatherRef.current?.kill();
      tlSunTrackerRef.current?.kill();
      unsub();
    };
  }, []);

  useEffect(() => {
    const opacity = isWeather ? 1 : 0;
    const delay = isWeather ? sunTrackerRanges[currentSunTrackerStep].delayLegs : 0;

    opacityTweenRef.current?.kill();
    opacityTweenRef.current = gsap.to(opacityLegsMaterial, { duration: 0.8, opacity, delay, ease: 'power1.inOut' });
  }, [isWeather]);

  useEffect(() => {
    weatherDestinationRef.current = tlWeatherRef.current.labels[`step${currentWeatherStep}`];
  }, [currentWeatherStep]);

  useEffect(() => {
    weatherDampRef.current = weatherDestinationRef.current;
  }, []);

  useEffect(() => {
    sunTrackerDestinationRef.current = tlSunTrackerRef.current.labels[`step${currentSunTrackerStep}`];
  }, [currentSunTrackerStep]);

  useFrame((state, delta) => {
    // WEATHER
    if (Math.abs(weatherDampRef.current - weatherDestinationRef.current) > 0.01) {
      damp(weatherDampRef, 'current', weatherDestinationRef.current, 0.99, delta * 0.6);
      tlWeatherRef.current.time(weatherDampRef.current);
    }

    // SUN-TRACKER
    if (Math.abs(sunTrackerDampRef.current - sunTrackerDestinationRef.current) > 0.01) {
      damp(sunTrackerDampRef, 'current', sunTrackerDestinationRef.current, 0.99, delta * 0.6);
      tlSunTrackerRef.current.time(sunTrackerDampRef.current);
    }

    // SOUNDS
    const movementSpeedInWindScene = Math.abs(weatherDampRef.current - weatherDestinationRef.current);
    const movementSpeedInSunScene = Math.abs(sunTrackerDampRef.current - sunTrackerDestinationRef.current);
    const interpolationActive = smoothedValues.scenesInterpolator !== 0 && smoothedValues.scenesInterpolator !== 1;
    const hasDiffInPosition = Math.abs(weatherRotation.current.z - sunTrackerRotation.current.z) > 0.1;
    const panelHasMovement =
      (isWeather ? movementSpeedInWindScene > 0.1 : movementSpeedInSunScene > 0.1) ||
      (hasDiffInPosition && interpolationActive);
    const legsVisible = weatherDestinationRef.current >= 6 && weatherDampRef.current > 5 && isWeather;
    if (panelHasMovement !== panelIsMoving.current) {
      if (panelHasMovement) onMovementStart();
      else onMovementEnd();
      panelIsMoving.current = panelHasMovement;
    }
    if (legsVisible !== prevLegsVisible.current) {
      if (legsVisible) onSupportIn();
      else onSupportOut();
    }
    prevLegsVisible.current = legsVisible;
    prevBuildTime.current = lazyActionsRef.current['build']?.time;

    if (!isAnimating) return;
    animate();
  });
}
