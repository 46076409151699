import styled, { keyframes } from 'styled-components';
import map from './assets/map-image.png';
import { useWindowStore } from '../../../services/WindowService';
import { useEffect, useMemo, useState } from 'react';
import LocationPin from './LocationPin';
import { useDistrictStore } from '../../../services/DistrictService';
import { useDeviceStore } from '../../../services/DeviceService';
import { useNavigationStore } from '../../../services/NavigationService';

const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    linear-gradient(180deg, rgba(214, 234, 222, 0.8) 0%, rgba(0, 94, 97, 0.8) 100%);
  backdrop-filter: blur(4px);
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  opacity: ${props => (props.show ? 1 : 0)};
  transform: scale(${props => (props.show ? 1 : 1.2)});
  transition: all ${props => (props.show ? '.35s ease-out' : '.2s ease-in')};
`;

const MapImage = styled.div`
  position: absolute;
  width: ${props => props.size.w}px;
  height: ${props => props.size.h}px;
  background-image: url(${map});
  background-repeat: no-repeat;
  background-size: ${props => props.size.w}px ${props => props.size.h}px;
`;

const PINS = [
  { id: 'house', title: 'The Hub', position: { left: 50, top: 40 }, enabled: true },
  { id: 'weather', title: 'The Weather Lab', position: { left: 60, top: 49 }, enabled: true },
  { id: 'construction', title: 'The Workshop', position: { left: 37, top: 46 }, enabled: true },
];

export default function MapOverlay() {
  const windowWidth = useWindowStore(state => state.width);
  const windowHeight = useWindowStore(state => state.height);
  const showNavigationOverlay = useNavigationStore(state => state.showNavigationOverlay);
  const isPhone = useDeviceStore(state => state.device.isMobileOnly);
  const district = useDistrictStore(state => state.district);
  const [size, setSize] = useState({ w: 0, h: 0 });
  const ratio = useMemo(() => {
    return 1920 / 1080;
  }, []);

  useEffect(() => {
    const scale = isPhone ? 0.6 : 1;
    setSize({ w: windowHeight * ratio * scale, h: windowHeight * scale });
  }, [windowWidth, windowHeight, isPhone]);

  return (
    <Overlay show={showNavigationOverlay}>
      <MapImage size={size}>
        {showNavigationOverlay &&
          PINS.map((pin, index) => {
            return (
              <LocationPin
                key={index}
                id={pin.id}
                title={pin.title}
                selected={district?.room === pin.id}
                position={pin.position}
                enabled={pin.enabled}
                index={index}
              />
            );
          })}
      </MapImage>
    </Overlay>
  );
}
