import { PRODUCT_NAMES, SCROLL_RANGES, useScrollStore } from '../../../../services/ScrollService';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import PartialProgressBar from './PartialProgressBar';
import DebugProgressBar from '../DebugProgressBar';
import IconMouse from './assets/icon-mouse.svg';
import IconSwipe from './assets/icon-swipe.svg';
import { useDeviceStore } from '../../../../services/DeviceService';
import { useDebugStore } from '../../../../storage/debug';
import { useNavigationStore } from '../../../../services/NavigationService';
import SignPostOverlay from './SignPostOverlay';

const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  bottom: 55px;
  color: black;
  z-index: 1;

  pointer-events: ${props => (props.show ? 'all' : 'none')};
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const WrapperUI = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity ${props => (props.show ? 0.5 : 0.25)}s ease-in-out;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 20px;
`;

const CtaWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.25s ease-in;
  pointer-events: none;
`;

const CtaText = styled.div`
  margin-top: 12px;
  font-family: 'AzoSans', sans-serif;
  color: #ffffff;
  font-size: 16px;
  text-shadow: 0 0 4px #ffffff;
`;

const ProductName = styled.div`
  color: #fff;
  font-family: 'AzoSans', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  text-shadow: 0 0 4px #ffffff;
  padding-bottom: 10px;
`;

const GradientBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 150px;
  left: 0;
  bottom: 0;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 3.95%, rgba(0, 0, 0, 0.3) 120%);
`;

const hover = keyframes`
  0% {transform: translateY(0px);}
  40% {transform: translateY(-10px);}
  80% {transform: translateY(0px);}
`;

const AnimationWrapper = styled.div`
  animation: ${hover} 2s infinite ease-in-out;
`;

export default function ScrollUI() {
  const requestRef = useRef(null);
  const isMobile = useDeviceStore(state => state.device.isMobile);
  const progressEnabled = useDebugStore(state => state.getLobbyProgressEnabled());
  const [showNavigation, setShowNavigation] = useState(false);
  const [showCta, setShowCta] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const visibleRange = useMemo(() => {
    return SCROLL_RANGES[0][0];
  }, []);
  const hideUi = useDebugStore(state => state.getHideUi());

  const showProductInfoLayer = useNavigationStore(state => state.showProductInfoLayer);

  const update = () => {
    const progress = useScrollStore.getState().normalizedProgress;
    if (progress >= SCROLL_RANGES[0][0] && progress <= SCROLL_RANGES[0][1]) {
      setSelectedIndex(0);
    } else if (progress >= SCROLL_RANGES[1][0] && progress <= SCROLL_RANGES[1][1]) {
      setSelectedIndex(1);
    } else if (progress >= SCROLL_RANGES[2][0] && progress <= SCROLL_RANGES[2][1]) {
      setSelectedIndex(2);
    } else if (progress >= SCROLL_RANGES[3][0] && progress <= SCROLL_RANGES[3][1]) {
      setSelectedIndex(3);
    } else {
      setSelectedIndex(-1);
    }

    setShowCta(progress < 0.05);
    setShowNavigation(progress > visibleRange);
    requestRef.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    !hideUi && (
      <>
        <GradientBackground />
        <Container show={!showProductInfoLayer}>
          <WrapperUI show={showNavigation}>
            <ProductName>{PRODUCT_NAMES[selectedIndex] || ' '}</ProductName>
            <Pagination>
              {SCROLL_RANGES.map((range, index) => {
                return <PartialProgressBar key={index} range={range} index={index} selectedIndex={selectedIndex} />;
              })}
            </Pagination>
          </WrapperUI>
          <CtaWrapper show={showCta}>
            <AnimationWrapper>{!isMobile ? <IconMouse /> : <IconSwipe />}</AnimationWrapper>
            <CtaText>{'SCROLL TO DISCOVER'}</CtaText>
          </CtaWrapper>

          {progressEnabled && <DebugProgressBar />}
        </Container>
        <SignPostOverlay />
      </>
    )
  );
}
