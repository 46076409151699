import { useTraverse } from '../hooks';
import { useEnvironmentStore } from '../../../components/Play/Environment/store';

export default function useConfigureEnvMap() {
  const environmentConfiguration = useEnvironmentStore(state => state.environmentConfiguration);

  useTraverse(() => {
    return o => {
      if (o.material) {
        if (o.material.lightMap) {
          o.material.envMapIntensity = environmentConfiguration.envMap.intensityLightMaps;
        } else {
          o.material.envMapIntensity = environmentConfiguration.envMap.intensity;
        }
      }
    };
  }, [environmentConfiguration]);
}
