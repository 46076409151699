/**
 * @see https://threejs.org/examples/#webgl_shaders_ocean
 */
import * as THREE from 'three';
import { Color, Mesh, ShaderChunk } from 'three';
import waterVert from './water.vert';
import waterFrag from './water.frag';

ShaderChunk.commons = ShaderChunk.common;

class Water extends Mesh {
  constructor(geometry, options) {
    super(geometry);

    this.name = 'Water';

    options = options || {};

    var alpha = options.alpha !== undefined ? options.alpha : 1.0;
    var time = options.time !== undefined ? options.time : 0.0;
    var normalSampler = options.waterNormals !== undefined ? options.waterNormals : null;
    var sunDirection = new THREE.Vector3(0.70707, 0.70707, 0.0);
    var sunColor = new THREE.Color(options.sunColor !== undefined ? options.sunColor : 0xffffff);
    var eye = options.eye !== undefined ? options.eye : new THREE.Vector3(0, 0, 0);
    var distortionScale = options.distortionScale !== undefined ? options.distortionScale : 20.0;
    var side = options.side !== undefined ? options.side : THREE.FrontSide;
    var fog = options.fog !== undefined ? options.fog : false;

    var mirrorShader = {
      vertexShader: waterVert,
      fragmentShader: waterFrag,
      uniforms: THREE.UniformsUtils.merge([
        THREE.UniformsLib['fog'],
        THREE.UniformsLib['lights'],
        {
          map: { value: null },
          normalSampler: { value: null },
          alpha: { value: 1.0 },
          time: { value: 0.0 },
          size: { value: 1.0 },
          distortionScale: { value: 20.0 },
          textureMatrix: { value: new THREE.Matrix4() },
          sunColor: { value: new THREE.Color(0xfcecbc) },
          sunDirection: { value: new THREE.Vector3(0.70707, 0.70707, 0) },
          eye: { value: new THREE.Vector3() },
          waterColor: { value: new THREE.Color(0x0a5268) },
          waterColor2: { value: new THREE.Color(0x01080b) },
          color1: { value: new Color(0xf9eee1) },
          color2: { value: new Color(0xa8bccd) },
          color3: { value: new Color(0x6681b9) },
        },
      ]),
    };
    var material = new THREE.ShaderMaterial({
      name: 'Water',
      defines: options.reflections ? { USE_MIRROR: '' } : {},
      fragmentShader: mirrorShader.fragmentShader,
      vertexShader: mirrorShader.vertexShader,
      uniforms: THREE.UniformsUtils.clone(mirrorShader.uniforms),
      lights: true,
      side: side,
      fog: fog,
      depthTest: true,
      depthWrite: false,
      transparent: false,
    });

    material.uniforms['alpha'].value = alpha;
    material.uniforms['time'].value = time;
    material.uniforms['normalSampler'].value = normalSampler;
    material.uniforms['sunColor'].value = sunColor;
    material.uniforms['sunDirection'].value = sunDirection;
    material.uniforms['distortionScale'].value = distortionScale;
    material.uniforms['eye'].value = eye;

    this.material = material;

    const scope = this;
    scope.onBeforeRender = function(renderer, scene, camera) {
      eye.setFromMatrixPosition(camera.matrixWorld);
    };
  }
}

export default Water;
