import create from 'utilities/zustand/create';
import config from './config';

export const useReactionStore = create((set, get) => ({
  reactions: config,
  getReactionById: id => {
    const { reactions } = get();
    const reaction = reactions.find(r => r.id === id);
    if (!reaction) {
      return reactions.find(r => r.id === 0);
    }
    return reaction;
  },
  isTeleport: id => {
    const { reactions } = get();
    const teleportId = reactions.find(r => r.name === 'Teleport').id;
    return id === teleportId;
  },
}));
