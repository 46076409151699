import styled, { css } from 'styled-components';
import LgFontRegularTtf from 'assets/fonts/LGSmartRegular.ttf';
import LgFontBoldTtf from 'assets/fonts/LGSmartBold.ttf';
import LgFontRegularWoff from 'assets/fonts/LGSmartRegular.woff';
import LgFontBoldWoff from 'assets/fonts/LGSmartBold.woff';

import veBoldWoff from 'assets/fonts/demodern-bold-webfont.woff';
import veBoldWoff2 from 'assets/fonts/demodern-bold-webfont.woff2';

import veLightWoff from 'assets/fonts/demodern-light-webfont.woff';
import veLightWoff2 from 'assets/fonts/demodern-light-webfont.woff2';

import veMediumWoff from 'assets/fonts/demodern-medium-webfont.woff';
import veMediumWoff2 from 'assets/fonts/demodern-medium-webfont.woff2';

import veSuperWoff from 'assets/fonts/demodern-super-webfont.woff';
import veSuperWoff2 from 'assets/fonts/demodern-super-webfont.woff2';

import veThinWoff from 'assets/fonts/demodern-thin-webfont.woff';
import veThinWoff2 from 'assets/fonts/demodern-thin-webfont.woff2';

import AzoSansBlack from 'assets/fonts/AzoSans-Black.ttf';
import AzoSansRegular from 'assets/fonts/AzoSans-Regular.ttf';
import AzoSansLight from 'assets/fonts/AzoSans-Light.ttf';
import AzoSansThin from 'assets/fonts/AzoSans-Thin.ttf';
import AzoSansBold from 'assets/fonts/AzoSans-Bold.woff';

export const fontFamily = ['LgFont', 'sans-serif'];

export const fontStyle = family => css`
  font-family: ${family.map((ff, i) => `'${ff}'${i < family.length - 1 ? ',' : ''}`)};
`;

export const lgFontStyle = () => css`
  ${fontStyle(fontFamily)}
`;

export const lgFontFace = () => css`
  @font-face {
    font-family: 'LgFont';
    src: url(${LgFontBoldTtf}) format('truetype'), url(${LgFontBoldWoff}) format('woff');
    font-weight: bold;
  }
  @font-face {
    font-family: 'LgFont';
    src: url(${LgFontRegularTtf}) format('truetype'), url(${LgFontRegularWoff}) format('woff');
    font-weight: normal;
  }
`;

export const veFontFamily = ['demodernlight', 'sans-serif'];
export const veFontStyle = () => css`
  ${fontStyle(veFontFamily)}
`;

export const veFontFace = () => css`
  @font-face {
    font-family: 'demodernbold';
    src: url(${veBoldWoff}) format('woff2'), url(${veBoldWoff2}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'demodernlight';
    src: url(${veLightWoff}) format('woff2'), url(${veLightWoff2}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'demodernmedium';
    src: url(${veMediumWoff}) format('woff2'), url(${veMediumWoff2}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'demodernsuper';
    src: url(${veSuperWoff}) format('woff2'), url(${veSuperWoff2}) format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'demodernthin';
    src: url(${veThinWoff}) format('woff2'), url(${veThinWoff2}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;

export const azoFontFace = () => css`
  @font-face {
    font-family: 'AzoSans';
    src: url('${AzoSansRegular}')
  }

  @font-face {
    font-family: 'AzoSansBlack';
    src: url('${AzoSansBlack}')
  }

  @font-face {
    font-family: 'AzoSansLight';
    src: url('${AzoSansLight}')
  }

  @font-face {
    font-family: 'AzoSansThin';
    src: url('${AzoSansThin}')
  }
  @font-face {
    font-family: 'AzoSansBold';
    src: url('${AzoSansBold}')
  }
`;
export const H1 = styled.h1``;

export const H2 = styled.h2``;

export const H3 = styled.h3``;

export const H4 = styled.h4``;

export const H5 = styled.h5``;

export const fontSize = `13px`;
export const smallFontSize = `10px`;

export const textStyle = css`
  font-size: ${fontSize};
  line-height: 20px;
  letter-spacing: 0.05em;
`;

export const smallTextStyle = css`
  font-size: ${smallFontSize};
  line-height: 14px;
  letter-spacing: 0.05em;
`;

export const textOverflowEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
