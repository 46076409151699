import React, { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import Interpolators from './Interpolators';
import WeatherCamera from './WeatherCamera';
import Trees from '../Common/Trees';
import Sun from './Sun';
import Karnobat from './Karnobat';
import Dom from './Dom';
import Vignette from './Vignette';
import Particles from './Particles';
import treesConfig from './config/treesConfig';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';
import useSfx from './useSfx';

export default function WeatherScene() {
  const { scene } = useThree();
  const phase = useDistrictWarmupStore(state => state.phase);
  const isLoaded = phase === WarmupPhase.DONE;

  const { setPanelMovementSoundActive, playSupportOutSound, playSupportInSound } = useSfx();

  useEffect(() => {
    if (!isLoaded) return;
    scene.traverse(el => {
      if (el.name === 'StandPanel') {
        el.visible = false;
      }
      if (el.name === 'triangle_wall_LM01') {
        el.castShadow = true;
      }
    }, true);
  }, [isLoaded]);

  return (
    <>
      <Interpolators />
      <Dom />
      <WeatherCamera />
      <Trees
        data={treesConfig.palm.data}
        leavesPivot={treesConfig.palm.leavesPivot}
        assets={treesConfig.palm.assets}
        castShadow={true}
      />
      <Trees
        data={treesConfig.thiccPalm.data}
        leavesPivot={treesConfig.thiccPalm.leavesPivot}
        assets={treesConfig.thiccPalm.assets}
        castShadow={true}
      />
      <Karnobat
        onMovementStart={() => setPanelMovementSoundActive(true)}
        onMovementEnd={() => setPanelMovementSoundActive(false)}
        onSupportOut={playSupportOutSound}
        onSupportIn={playSupportInSound}
      />
      <Particles />
      <Vignette />
      <Sun />
    </>
  );
}
