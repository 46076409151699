import hoverFullscreen from './assets/hover/fullscreen.png';
import hoverPlay from './assets/hover/play.png';

import pinPlay from './assets/pin/pin_play.svg';
import pinLive from './assets/pin/pin_live.svg';
import pinResource from './assets/pin/pin_ressource.svg';
import pinCalendar from 'assets/icons/Icon_Calender.svg';

function createContentType(params) {
  const defaultParams = {
    isFullscreen: false,
    contentChatRoomEnabled: false,
    pinIcon: pinPlay,
    hoverImage: null,
    hideMenu: true,
  };
  return { ...defaultParams, ...params };
}

export const CONTENT_TYPE = {
  VIDEO: createContentType({ id: 'VIDEO', isFullscreen: true, hoverImage: hoverPlay }),
  SINGLE_VIDEO: createContentType({ id: 'SINGLE_VIDEO', isFullscreen: true, hoverImage: hoverPlay }),
  PDF: createContentType({ id: 'PDF', pinIcon: pinResource, isFullscreen: true, hoverImage: hoverFullscreen }),
  IFRAME: createContentType({
    id: 'IFRAME',
    pinIcon: pinResource,
    isFullscreen: true,
    hoverImage: hoverFullscreen,
  }),
  THEATER: createContentType({
    id: 'THEATER',
    isFullscreen: true,
    hoverImage: hoverFullscreen,
  }),
  LIVESTREAM: createContentType({
    id: 'LIVESTREAM',
    pinIcon: pinLive,
    isFullscreen: true,
    hoverImage: hoverFullscreen,
  }),
  IMAGE: createContentType({ id: 'IMAGE', pinIcon: pinPlay, isFullscreen: true, hoverImage: hoverFullscreen }),
  LINK: createContentType({ id: 'LINK', pinIcon: pinPlay, isFullscreen: true, hoverImage: hoverFullscreen }),
  RESOURCE: createContentType({
    id: 'RESOURCE',
    pinIcon: pinResource,
    isFullscreen: true,
    hoverImage: hoverFullscreen,
  }),
  PORTAL: createContentType({
    id: 'PORTAL',
    pinIcon: pinPlay,
    isFullscreen: true,
    hoverImage: hoverFullscreen,
  }),
  SCHEDULE: createContentType({
    id: 'SCHEDULE',
    pinIcon: pinCalendar,
    isFullscreen: true,
    hoverImage: hoverFullscreen,
  }),
  PRODUCT: createContentType({
    id: 'PRODUCT',
    pinIcon: pinResource,
    isFullscreen: true,
    hoverImage: hoverFullscreen,
  }),
};
