import styled from 'styled-components';
import { colors } from 'uiLivi/styles';
import { buttonTransition, getHoverStyle } from 'common/components/Button';

const verticalTextAreaPadding = `10px`;
const containerPadding = `16px`;

export const Container = styled.div`
  padding: 16px;
  position: relative;
  bottom: 0;
  width: 100%;
`;

export const TextArea = styled.textarea`
  width: inherit;
  height: 100%;
  border: 1px solid black;
  outline: none;
  padding: ${verticalTextAreaPadding};
  line-height: 22px;
  resize: none;
  color: ${colors.font.light};
  overflow: hidden;
  background: black;
`;

export const SendButton = styled.button.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: absolute;
  right: ${containerPadding};
  width: 44px;
  height: 44px;
  border: none;
  outline: none;
  background-color: ${colors.accent.base};
  cursor: pointer;
  ${props => (props.expanded ? `bottom: 18px` : `top: ${containerPadding}`)};
  ${buttonTransition}
  &:hover {
    ${getHoverStyle(true)};
  }
`;
