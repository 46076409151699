import constructionAssemblyBg from '../images/construction-assembly-bg.png';
import testCenterBg from '../images/test-center-bg.png';
import ConstructionAssemblyIcon from '../icons/construction-assembly-icon.svg';
import TestCenterIcon from '../icons/test-center-icon.svg';
import {
  BgImage,
  Headline_22_900,
  minContainerWidth,
  Padder,
  RoomBannerBg,
  RoomBannerDescription,
  RoomBanners,
  RoomBannersScrollContainer,
  RoomBannerText,
} from '../styles';
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigationStore } from '../../../../../services/NavigationService';
import { useDistrictStore } from '../../../../../services/DistrictService';
import { useConstructionAssemblyService } from '../../../../../services/ConstructionAssemblyService';

export default function Banners({ containerWidth, constructionAssemblyId = null, bannerAnimation = null }) {
  const history = useHistory();
  const setShowProductInfoLayer = useNavigationStore(state => state.setShowProductInfoLayer);
  const productInfoID = useNavigationStore(state => state.productInfoID);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const isScrollable = containerWidth < minContainerWidth;
  const setSelectedModelId = useConstructionAssemblyService(state => state.setSelectedModelId);

  const transitionTo = useCallback(
    districtId => () => {
      setShowProductInfoLayer(false);
      if (constructionAssemblyId) setSelectedModelId(constructionAssemblyId);
      useDistrictStore.getState().navigateToDistrict(history, districtId);
    },
    [history, constructionAssemblyId]
  );

  const mouseHandlers = index => ({
    onMouseOver: () => setHoverIndex(index),
    onMouseOut: () => setHoverIndex(-1),
  });

  return (
    <RoomBannersScrollContainer $isScrollable={isScrollable}>
      <Padder>
        <RoomBanners>
          <RoomBannerBg
            style={{ gridArea: 'tl' }}
            $containerWidth={containerWidth}
            {...(bannerAnimation ? bannerAnimation(-1) : null)}
            {...mouseHandlers(0)}
            $hasHover={hoverIndex === 0}
            onClick={transitionTo('construction')}
          >
            <BgImage src={constructionAssemblyBg} className="image" />
          </RoomBannerBg>
          <RoomBannerText
            style={{ gridArea: 'bl' }}
            {...(bannerAnimation ? bannerAnimation(-1) : null)}
            {...mouseHandlers(0)}
            $hasHover={hoverIndex === 0}
            onClick={transitionTo('construction')}
          >
            <ConstructionAssemblyIcon className="icon" />
            <Headline_22_900 className="headline">THE WORKSHOP</Headline_22_900>
            <RoomBannerDescription>
              Learn about our newest products, as well as how to construct and assemble them.
            </RoomBannerDescription>
          </RoomBannerText>
          <RoomBannerBg
            style={{ gridArea: 'tr' }}
            $containerWidth={containerWidth}
            {...(bannerAnimation ? bannerAnimation(1) : null)}
            {...mouseHandlers(1)}
            $hasHover={hoverIndex === 1}
            onClick={transitionTo('weather')}
          >
            <BgImage src={testCenterBg} className="image" />
          </RoomBannerBg>
          <RoomBannerText
            style={{ gridArea: 'br' }}
            {...(bannerAnimation ? bannerAnimation(1) : null)}
            {...mouseHandlers(1)}
            $hasHover={hoverIndex === 1}
            onClick={transitionTo('weather')}
          >
            <TestCenterIcon className="icon" />
            <Headline_22_900 className="headline">WEATHER LAB</Headline_22_900>
            <RoomBannerDescription>
              Take control of the elements, and put our Tracking System to the ultimate test.
            </RoomBannerDescription>
          </RoomBannerText>
        </RoomBanners>
      </Padder>
    </RoomBannersScrollContainer>
  );
}
