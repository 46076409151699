import styled, { css } from 'styled-components';

// const transition = 'cubic-bezier(0.68, -0.55, 0.265, 1.55)';
const transition = 'ease-in-out';
const duration = '0.2s';

const EffectWrapper = styled.div`
  padding: 0 0.5em;
  margin: 0 -0.5em;
  overflow: hidden;
  position: relative;

  > span {
    display: block;
    transform: translateY(calc(var(--hover) * -100%));
    transition: transform ${duration} ${transition};
  }

  :after {
    content: attr(data-link-alt);
    display: inline;
    position: absolute;
    transition: top ${duration} ${transition};
    top: calc((1 - var(--hover)) * 100%);
    left: 0;
    right: 0;
    text-align: center;
  }
`;

export const parentElementHoverCSS = css`
  --hover: 0;
  &:hover {
    --hover: 1;
  }
`;

export default function SliceHoverEffect({ children, alt = null }) {
  return (
    <EffectWrapper data-link-alt={alt || children}>
      <span>{children}</span>
    </EffectWrapper>
  );
}
