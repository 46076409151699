import IconReminder from 'assets/icons/Icon_Bell.svg';
import IconDelete from 'assets/icons/Icon_Delete.svg';
import { Button } from 'common/components/Button';

import Notification from './index';
import IconCalendarAdd from 'assets/icons/Icon_Calender_Add.svg';
import IconCalendarStar from 'assets/icons/Icon_Calendar_Star.svg';
import { useTranslationStore } from 'services/TranslationService';

// @deprecated - we should create a factory that is feed with props or a payload object
export default function createTimetableNotification(
  type,
  reservation = null,
  handlers = null,
  text = null,
  ButtonComponent = null
) {
  const translate = useTranslationStore.getState().translate;
  const accept = handlers && handlers.accept ? handlers.accept : null;
  const decline = handlers && handlers.decline ? handlers.decline : null;
  let notificationProps = null;

  switch (type) {
    /*case 'lessThen10Minutes':
      notificationProps = {
        icon: <IconReminder width={32} height={32} />,
        headline: translate('notification.meeting.ten.title') || 'Reminder',
        text: translate('notification.meeting.ten.copy') || `A talk starts in 10 minutes.`,
        stayOpen: true,
      };
      break;*/
    case 'lessThenOneMinute':
      notificationProps = {
        icon: <IconReminder width={32} height={32} />,
        buttons: [
          <ButtonComponent small accent key={1} accept={accept}>
            {translate('notification.talk.one.join') || 'Join'}
          </ButtonComponent>,
        ],
        closeHandler: decline,
        headline: translate('notification.talk.one.title') || 'A talk is beginning...',
        text: ''.concat(
          translate('notification.talk.one.copy.hi') || 'Hey',
          ' ',
          text,
          translate('notification.talk.one.copy') ||
            ', a talk is beginning now in the auditorium. Navigate to the auditorium or click the button below to join!'
        ),
      };
      break;
    case 'deleteAppointment':
      notificationProps = {
        icon: <IconDelete width={32} height={32} />,
        buttons: [
          <Button key={'yes'} small accent onClick={accept}>
            {translate('schedule.meeting.cancel.confirm') || 'Yes, cancel it'}
          </Button>,
          <Button key={'no'} small accent onClick={decline}>
            {translate('schedule.meeting.cancel.abort') || 'No, keep it'}
          </Button>,
        ],
        headline: translate('schedule.meeting.cancel.title') || 'Cancel 1:1 Meeting',
        text: translate('schedule.meeting.cancel.copy') || `Are you sure that you want to cancel your appointment?`,
      };
      break;
    case 'deleteSuccess':
      notificationProps = {
        icon: <IconDelete width={32} height={32} />,
        headline: translate('schedule.meeting.canceled.title') || 'Meeting Canceled',
        text: translate('schedule.meeting.canceled.copy') || `Your appointment has been successfully canceled.`,
        closeHandler: decline,
      };
      break;
    case 'new1on1Booking':
      notificationProps = {
        icon: <IconCalendarStar width={32} height={32} />,
        headline: translate('schedule.meeting.new.title') || 'New 1:1 Booking',
        text,
        buttons: [
          <Button key={'claim'} data-id={reservation.id} onClick={accept} small accent>
            <IconCalendarAdd />
            {translate('schedule.meeting.new.claim') || 'Claim'}
          </Button>,
        ],
        stayOpen: true,
      };
      break;
    case 'deleteTalk':
      notificationProps = {
        icon: <IconDelete width={16} height={16} />,
        buttons: [
          <Button key={'yes'} small accent onClick={accept}>
            {translate('demo.stop.confirm') || 'Yes, delete it'}
          </Button>,
          <Button key={'no'} small accent onClick={decline}>
            {translate('demo.stop.cancel') || 'No, keep it'}
          </Button>,
        ],
        headline: translate('demo.stop.title') || 'Stop Live Demo',
        text: translate('demo.stop.copy') || 'Are you sure you want to cancel your demo?',
      };
      break;
  }
  return notificationProps ? <Notification {...notificationProps} /> : notificationProps;
}
