import ProductVariations from './ProductVariations';
import About from './About';
import groundMountedHeaderBg from './images/TrackingSystemBG.png';

import clampFit from './images/ClampFit.png';
import fixGridPro from './images/FixGridPro.png';
import fixZ from './images/Fixz.png';
import flatGrid from './images/FlatGrid.png';
import flush from './images/Flush.png';
import fsUno from './images/fs_uno.png';
import fsDuo from './images/fs_duo.png';
import solfarm from './images/solfarm.png';
import carport from './images/carport.png';

import dachhakenRapid2Pro from './images/Dachhacken_Rapid2-Pro.png';
import rundfalzklemme from './images/Rundfalzklemme.png';
import stockschraube from './images/Stockschraube.png';

export const TrackingSystem1P = 'TrackingSystem1P';
export const FixGridPro = 'FixGridPro';
export const ProlineRoofHooks = 'ProlineRoofHooks';
export const SUPPORTDESK = 'SUPPORTDESK';

const productInfoIDToComponent = {
  [TrackingSystem1P]: {
    component: (
      <ProductVariations
        headline="GROUND-MOUNTED SYSTEMS"
        description="Solar farms are investments in a fair, and sustainable future that runs on cheap, clean energy. A smart investment delivers maximal yields with minimal risks—to this end, we’ve developed our own range of ground-mounted systems."
        bgSrc={groundMountedHeaderBg}
        video="https://www.youtube.com/watch?v=7wUZzHvdOKM"
        isCarousel={false}
        constructionAssemblyId="TrackingSystem"
        variations={[
          {
            name: 'TRACKING SYSTEM 1P & 2P',
            url: 'https://www.schletter-group.com/mounting-systems/tracking-systems/',
            rendering: groundMountedHeaderBg,
            description: [
              '✔ As stable as a fixed tilt\n',
              '✔ Zero galloping effect\n',
              '✔ Low installation and maintenance costs\n',
              '✔ 120° pivot range\n',
              '✔ Minimal module loads',
            ],
          },
          {
            name: 'FS UNO',
            url: 'https://www.schletter-group.com/fixed-tilt-systems/',
            rendering: fsUno,
            description: [
              '✔ Easy installation\n',
              '✔ Low weight for reduced shipping costs and LCOE\n',
              '✔ Custom corrosion-proof coating\n',
              '✔ Cost-efficient with rammed steel foundations and only a few posts\n',
              '✔ Available pre- or unassembled\n',
              '✔ No ground sealing',
            ],
          },
          {
            name: 'FS DUO',
            url: 'https://www.schletter-group.com/fixed-tilt-systems/',
            rendering: fsDuo,
            description: [
              '✔ High strength and durability\n',
              '✔ Pile-driven and concrete foundations available\n',
              '✔ Superior material efficiency\n',
              '✔ Fully optimized connections\n',
              '✔ No ground sealing\n',
              '✔ ≤ 25° inclination\n',
            ],
          },
          {
            name: 'SOLFARM',
            url: 'https://www.schletter-group.com/mounting-systems/agri-pv-systems/',
            rendering: solfarm,
            description: [
              '✔ Maximizes farmland utilization\n',
              '✔ Can be installed in flat or hilly terrain\n',
              '✔ Is equipped to compensate for height variations\n',
              '✔ Supports monofacial, bifacial, and XXL modules\n',
              '✔ Is quick and easy to install and connect\n',
              '✔ Allows adjustments to distances between rows',
            ],
          },
          {
            name: 'SUNRIDE CARPORT',
            url: 'https://www.schletter-group.com/mounting-systems/sunride-carports/',
            rendering: carport,
            description: [
              '✔ Optimal use of parking space\n',
              '✔ Suitable for all module types and sizes\n',
              '✔ Made of high-quality, galvanized steel\n',
              '✔ Stand-alone columns for straight or angled parking\n',
              '✔ Expert support in project planning',
            ],
          },
        ]}
        ctas={{
          downloadBrochure: 'https://www.schletter-group.com/downloads/',
          constructionAndAssembly: true,
          testCenter: false,
          signupNewsletter: true,
          contactForm: true,
        }}
      />
    ),
  },
  [FixGridPro]: {
    component: (
      <ProductVariations
        headline="FLAT ROOF SYSTEMS"
        description="We’ve designed our flat roof mounts to suit almost all structural conditions and make installations quick and easy. In addition to wind speed and snow load limits, our designs comply with all relevant building regulations."
        video="https://www.youtube.com/watch?v=K8YrX15e31s"
        isCarousel={true}
        constructionAssemblyId="FixGridPro"
        variations={[
          {
            name: 'FIXGRID PRO',
            url: 'https://www.schletter-group.com/mounting-systems/proline/',
            rendering: fixGridPro,
            description: [
              '✔ Improved aerodynamics for ballast optimization\n',
              '✔ Flexible, time-saving planning\n',
              '✔ Tool-free assembly of the heightening elements\n',
              '✔ Supports portrait and landscape module mounting\n',
              '✔ Enables 5°, 10°, and 15° inclinations irrespective of module clamping, orientation, and size',
            ],
          },
          {
            name: 'FLATGRID PRO',
            url: 'https://www.schletter-group.com/mounting-systems/flat-roof/',
            rendering: flatGrid,
            description: [
              '✔ Very few superimposed loads required\n',
              '✔ Integrated surface protection mat\n',
              '✔ Fully optimized for material and cost efficiency\n',
              '✔ Zero roof penetration\n',
              '✔ Improved ballasts',
            ],
          },
        ]}
        ctas={{
          downloadBrochure: 'https://www.schletter-group.com/downloads/',
          constructionAndAssembly: true,
          testCenter: false,
          signupNewsletter: true,
          contactForm: true,
        }}
      />
    ),
  },
  [ProlineRoofHooks]: {
    component: (
      <ProductVariations
        headline="FLUSH MOUNTING"
        description="Slate or ceramic, flat or pitched, corrugated or standing seam—we’ve designed our mounting systems to make installing them safe, fast, and easy on any roof."
        isCarousel={true}
        constructionAssemblyId="RoofHooks"
        variations={[
          {
            name: 'ROOF HOOKS',
            url: 'https://www.schletter-group.com/mounting-systems/proline/',
            rendering: dachhakenRapid2Pro,
            description: [
              '✔ Improved adjustability, stability, and weight\n',
              '✔ Higher load capacity due to new profile geometries\n',
              '✔ Fast and flexible click-in mounting\n',
              '✔ Zero pressure on the roof tiles\n',
              '✔ Compatible with all Schletter Classic and ProLine systems\n',
            ],
          },
          {
            name: 'HANGER BOLTS',
            url: 'https://www.schletter-group.com/mounting-systems/pitched-roof/',
            rendering: stockschraube,
            description: [
              '✔ Made for fiber cement, corrugated, and trapezoidal sheet roofs\n',
              '✔ Provides superior stability on sloped and flat surfaces\n',
              '✔ Compatible with all Schletter Classic systems',
            ],
          },
          {
            name: 'FLUSH MOUNTING SOLUTIONS',
            url: 'https://www.schletter-group.com/mounting-systems/pitched-roof/',
            rendering: flush,
            description: [
              '✔ Suitable for steep roofs with bitumen cladding\n',
              '✔ Easily adaptable for fastening from above\n',
              '✔ No need to remove bitumen shingles\n',
              '✔ Universal fastening option for all sorts of substructures (wood, concrete, steel, etc.)',
            ],
          },
          {
            name: 'SEAM CLAMPS',
            url: 'https://www.schletter-group.com/mounting-systems/pitched-roof/',
            rendering: rundfalzklemme,
            description: [
              '✔ Quick assembly\n',
              '✔ Excellent value\n',
              '✔ Variable use\n',
              '✔ Easy to store\n',
              '✔ Compatible with all Schletter Classic systems',
            ],
          },
          {
            name: 'TRAPEZOIDAL SHEET CONNECTORS',
            url: 'https://www.schletter-group.com/mounting-systems/pitched-roof/',
            rendering: clampFit,
            description: [
              '✔ Modern system to connect to the substructure\n',
              '✔ Screws onto the side of the trapezoidal sheets for optimal load distribution\n',
              '✔ Compatible with all Schletter Classic systems',
            ],
          },
          {
            name: 'FIX-Z SHEET CONNECTOR',
            url: 'https://www.schletter-group.com/mounting-systems/pitched-roof/',
            rendering: fixZ,
            description: [
              '✔ Quick and easy to mount\n',
              '✔ Improved rear ventilation\n',
              '✔ Enhanced self-cleaning\n',
              '✔ Optimal load transfer through roof cladding\n',
              '✔ Greater yield-to-price ratio',
            ],
          },
        ]}
        ctas={{
          downloadBrochure: 'https://www.schletter-group.com/downloads/',
          constructionAndAssembly: true,
          testCenter: false,
          signupNewsletter: true,
          contactForm: true,
        }}
      />
    ),
  },
  [SUPPORTDESK]: {
    component: (
      <About
        ctas={{
          map: true,
          downloadBrochure: 'https://www.schletter-group.com/downloads/',
          contactForm: true,
        }}
      />
    ),
  },
};

export default productInfoIDToComponent;
