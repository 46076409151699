import styled, { css } from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { H3, H5, smallTextStyle, textOverflowEllipsis } from 'common/text';
import { menuPaddingPx } from '../../styles';

export const Container = styled.div`
  height: 100%;
`;

const centered = css`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const ProfileBlock = styled.div`
  background-color: ${props => (props.highlighted ? 'black' : 'transparent')};
  padding: ${menuPaddingPx};
  ${props => (props.centered ? centered : null)}
`;

export const ProfileInfoSection = styled.section`
  &:not(:last-of-type) {
    margin-bottom: 25px;
  }
`;

export const ProfileInfoTitle = styled.div`
  color: ${colors.font.light};
  ${smallTextStyle};
  opacity: 0.75;
  margin-top: 5px;
`;

export const ProfileInfo = styled.div`
  color: ${props => (props.light ? colors.font.light : colors.font.strong)};
  font-weight: ${props => (props.bold ? 'bold' : 'regular')};
  word-break: break-word;

  ::selection {
    background: ${lgBaseColors.green.light};
  }
`;

export const FullName = styled(H3)`
  margin: 0 0 5px 0;
  ${textOverflowEllipsis}
`;

export const BlockTitle = styled(H5)`
  margin: 16px 0;
`;

export const LightSwitchOptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const HelpText = styled.div`
  margin-top: 4px;
  ${smallTextStyle};
  max-width: 80%;
  color: ${colors.font.light};
  opacity: 0.75;
`;

export const LightSwitchOptionWithHelpTextOuterContainer = styled.div`
  ${LightSwitchOptionContainer} {
    margin-bottom: 0;
  }
  margin-bottom: 16px;
`;
