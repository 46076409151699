import create from 'utilities/zustand/create';
import { useUserStore } from '../UserService';

let socket = null;
export const useExpertStore = create((set, get) => ({
  experts: [],

  init(managedSocket) {
    socket = managedSocket;
  },

  requestExperts: () => {
    socket.emit('expert/list', {}, experts => {
      set({ experts });
    });
  },

  logout() {
    set({ experts: [] });
  },

  findAvailableExpert: () => {
    const user = useUserStore.getState().user;
    const userLanguage = user && user.language ? user.language : 'en_US';
    //TODO: add fix for older users having only countrycode (en instead of en_US) as language; remove next line after this has been done
    const userLangFix = userLanguage.length < 2 ? 'en_US' : userLanguage;
    const { experts } = get();
    const onlineExperts = experts.filter(e => e.isOnline);
    const sameLanguageOnlineExperts = onlineExperts.filter(e => e.language === userLangFix && e.id !== user.id);
    const englishOnlineExperts = onlineExperts.filter(e => e.language === 'en_US' && e.id !== user.id);
    const sameLanguageExperts = experts.filter(e => e.language === userLangFix && e.id !== user.id);
    const englishExperts = experts.filter(e => e.language === 'en_US' && e.id !== user.id);
    const expertSelection =
      sameLanguageOnlineExperts.length > 0
        ? sameLanguageOnlineExperts
        : englishOnlineExperts.length > 0
        ? englishOnlineExperts
        : sameLanguageExperts.length > 0
        ? sameLanguageExperts
        : englishExperts;
    const getRandomId = Math.floor(Math.random() * expertSelection.length);
    return expertSelection[getRandomId];
  },
}));
