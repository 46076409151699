import CloseButton from './images/close-button.inline.svg';
import { productsData, useConstructionAssemblyService } from '../../../../services/ConstructionAssemblyService';
import { useEffect, useRef, useState } from 'react';
import { Details } from './config';
import { useWindowStore } from '../../../../services/WindowService';
import {
  Title,
  Close,
  Content,
  Copy,
  ExpandButton,
  Header,
  Headline,
  MOBILE_BREAKPOINT,
  Overlay,
  Wrapper,
  copyPaddingBottom,
  copyPaddingTop,
} from './styles';
import { SvgButtonHoverEffect } from '../../../../uiLivi/styles';
import Triangle from '../../ScrollScene/ProductInfoLayer/icons/triangle.svg';

export default function DetailInfoLayer() {
  const detailID = useConstructionAssemblyService(state => state.detailID);
  const setDetailID = useConstructionAssemblyService(state => state.setDetailID);
  const modelData = useConstructionAssemblyService(state => productsData.find(p => p.id === state.selectedModelId));
  const showDetail = detailID !== null;
  const [content, setContent] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const vh = useWindowStore(state => state.vh);
  const width = useWindowStore(state => state.width);
  const isMobile = width <= MOBILE_BREAKPOINT;
  const contentRef = useRef(null);
  const [hasFade, setHasFade] = useState(false);

  const handleClose = () => {
    setDetailID(null);
    setExpanded(false);
  };

  useEffect(() => {
    if (showDetail) {
      setContent(Details[detailID]);
      setExpanded(false);
    }
  }, [detailID, showDetail]);

  const updateFade = () => {
    const willHaveFade =
      expanded &&
      contentRef.current.clientHeight < contentRef.current.scrollHeight &&
      contentRef.current.scrollTop + 2 < contentRef.current.scrollHeight - contentRef.current.clientHeight;

    if (willHaveFade !== hasFade) setHasFade(willHaveFade);
  };

  useEffect(updateFade, [vh, hasFade]);

  return (
    <>
      <Wrapper>
        <Overlay show={showDetail} vh={vh} isMobile={isMobile}>
          <Header>
            <Title>{modelData.name || '???'}</Title>
            <Headline>{content?.headline || '???'}</Headline>
            <SvgButtonHoverEffect>
              <Close onClick={handleClose} data-sound-action="click">
                <CloseButton />
              </Close>
            </SvgButtonHoverEffect>
          </Header>
          <Content
            $expanded={expanded}
            $hasFade={hasFade}
            animate={{ height: expanded ? 'auto' : `calc(2em + ${copyPaddingBottom + copyPaddingTop}px)` }}
            ref={contentRef}
            onAnimationComplete={updateFade}
            onScroll={updateFade}
          >
            <Copy>{content?.copy || '???'} </Copy>
          </Content>
          <ExpandButton onClick={() => setExpanded(!expanded)} $expanded={expanded}>
            more information <Triangle />
          </ExpandButton>
        </Overlay>
      </Wrapper>
    </>
  );
}
