import { BookmarkButtonContainer } from './styles';
import IconBookmark from 'assets/icons/Icon_Bookmark.svg';

export default function BookmarkButtonSmall({ active, clickHandler, style = {}, position = {} }) {
  return (
    <BookmarkButtonContainer onClick={clickHandler} active={active} style={style} position={position}>
      <IconBookmark />
    </BookmarkButtonContainer>
  );
}
