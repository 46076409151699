import { useWeatherStore } from '../../store';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import IconDanger from './icons/icon-danger.svg';
import { mediaMax } from '../../../../../common/layout';

import windConfig from '../../config/windConfig';
import { MathUtils } from 'three';

const Wrapper = styled.div`
  position: absolute;
  top: 140px;
  right: 50px;
  display: flex;
  height: 120px;
  opacity: ${props => (props.isWeather ? 1 : 0.25)};
  transition: opacity 0.5s ease-in-out;

  ${mediaMax(
    'small',
    css`
      flex-direction: column;
      top: 120px;
      right: 24px;
      transform-origin: top right;
      transform: scale(0.9);
    `
  )};
`;

const Wind = styled.div`
  display: flex;
  align-items: flex-end;
  text-align: right;
`;

const Digit = styled.div`
  height: 100%;
  font-size: 160px;
  line-height: 150px;
  width: 110px;
  font-family: 'AzoSansBlack', sans-serif;
  //border: 1px solid red;

  ${mediaMax(
    'small',
    css`
      width: 55px;
      line-height: 65px;
      font-size: 80px;
    `
  )};
`;

const Unit = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'AzoSansLight', sans-serif;
  font-size: 22px;
  //border: 1px solid red;

  ${mediaMax(
    'small',
    css`
      font-size: 20px;
    `
  )};
`;

const BeaufortScale = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: 'AzoSansLight', sans-serif;
  font-size: 22px;
  padding-bottom: 16px;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s ${props => (props.visible ? 'ease-in' : 'ease-out')};
  //border: 1px solid green;

  ${mediaMax(
    'small',
    css`
      font-size: 20px;
      padding-right: 16px;
      padding-bottom: 0;
    `
  )};
`;

const Units = styled.div`
  width: 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  //border: 1px solid yellow;

  ${mediaMax(
    'small',
    css`
      flex-direction: row;
      width: 100%;
    `
  )};
`;

export default function WindDisplay() {
  const requestRef = useRef(null);
  const digit_1 = useRef(null);
  const digit_2 = useRef(null);
  const digit_3 = useRef(null);
  const [bftValue, setBftValue] = useState(0);
  const [isHurricane, setIsHurricane] = useState(false);
  const isWeather = useWeatherStore(state => state.isWeather);

  const update = () => {
    const smoothedValues = useWeatherStore.getState().smoothedValues;
    const windRange = MathUtils.lerp(
      (smoothedValues.sliderProgress * 0.8 + 0.2) * windConfig.kmVariation,
      0.3 * windConfig.kmVariation,
      smoothedValues.scenesInterpolator
    );
    const v = (smoothedValues.wind.kmSpeed + windRange * smoothedValues.noisyIntensity + 0.5) | 0;

    const a = v.toString().split('');
    if (a.length === 1) {
      digit_1.current.innerText = '';
      digit_2.current.innerText = '';
      digit_3.current.innerText = a[0];
    } else if (a.length === 2) {
      digit_1.current.innerText = '';
      digit_2.current.innerText = a[0];
      digit_3.current.innerText = a[1];
    } else if (a.length === 3) {
      digit_1.current.innerText = a[0];
      digit_2.current.innerText = a[1];
      digit_3.current.innerText = a[2];
    }

    const sliderProgress = useWeatherStore.getState().sliderProgress;

    // TODO: STURMIE
    const bftValue = -20.5792 * sliderProgress * sliderProgress + 32.2901 * sliderProgress + 0.455475;
    setBftValue(Math.min(Math.round(bftValue), 12));

    requestRef.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    setIsHurricane(bftValue >= 12);
  }, [bftValue]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <Wrapper isWeather={isWeather}>
      <Wind>
        <Digit ref={digit_1}>1</Digit>
        <Digit ref={digit_2}>2</Digit>
        <Digit ref={digit_3}>3</Digit>
      </Wind>
      <Units>
        <BeaufortScale visible={isWeather}>
          {!isHurricane ? bftValue : <IconDanger style={{ marginBottom: '4px' }} />}
          <span style={{ paddingLeft: '5px' }}>{'Bft'}</span>
        </BeaufortScale>
        <Unit>{'km/h'}</Unit>
      </Units>
    </Wrapper>
  );
}
