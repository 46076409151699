import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { lgBaseColors } from 'uiLivi/styles';
import { useWindowStore } from 'services/WindowService';
import { useDeviceStore } from 'services/DeviceService';
import Icon_RotateScreen from './assets/Icon_RotateScreen.svg';
import { useContentStore } from 'services/ContentService';
import { useChatStore } from 'services/ChatService';
import { iconStyle } from 'common/icon';
import { H3 } from 'common/text';

const HintWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20005;
  background-color: ${lgBaseColors.grey.dark};
`;

const FlexCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
`;

export const Info = styled(H3)`
  font-family: 'AzoSansLight', serif;
  padding: 15px;
`;

const DeviceIconAnimation = keyframes`
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(45deg);
  }
  75% {
    transform: rotate(0deg);
  }
`;

const IconWrapper = styled.div`
  animation: ${DeviceIconAnimation} 2s ease-in-out infinite;
`;

export default function DeviceHint() {
  const [showHint, setShowHint] = useState(false);
  const windowHeight = useWindowStore(state => state.height);
  const isLandscape = useWindowStore(state => state.landscape);
  const isMobileOnly = useDeviceStore.getState().device.isMobileOnly;
  const keyboard = useDeviceStore(state => state.keyboard);
  const activeContent = useContentStore(state => state.activeContent);
  const isChatOpen = useChatStore(state => state.isActive);

  useEffect(() => {
    const keyboardActive = keyboard.isActive;
    const isFullscreenContent = activeContent && activeContent.type.isFullscreen;
    const showHint = isMobileOnly && !keyboardActive && isLandscape && !isFullscreenContent && windowHeight < 500;
    setShowHint(showHint);
  }, [isLandscape, windowHeight, activeContent, keyboard, isChatOpen]);

  return showHint ? (
    <HintWrapper>
      <FlexCenter>
        <IconWrapper>
          <Icon_RotateScreen />
        </IconWrapper>
        <Info>Please rotate your device</Info>
      </FlexCenter>
    </HintWrapper>
  ) : null;
}
