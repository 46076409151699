import create from 'utilities/zustand/create';
import { useTalkStore } from 'services/TalkService';
import { useModalService } from 'services/ModalService';
import { useUserStore } from 'services/UserService';
import moment from 'moment';
import createTimetableNotification from 'components/Notification/createTimetableNotification';
import JoinTalkButton from 'components/Notification/JoinTalkButton';

const POLL_INTERVAL = 1000;
const lessThen10Minutes = {};
const lessThenOneMinute = {};

/*const willStartInLessThanTenMinutes = startTime =>
  moment(startTime)
    .subtract(10, 'minutes')
    .isBefore(new Date());*/

const willStartInLessThanOneMinute = startTime =>
  moment(startTime)
    .subtract(1, 'minutes')
    .isBefore(new Date());

const isAlreadyLiveSinceOneMinute = startTime =>
  moment(startTime)
    .add(1, 'minutes')
    .isBefore(new Date());

/* eslint-disable no-unused-vars */
let socket = null;

export const useCronService = create((set, get) => ({
  jobs: [],
  selfId: null,

  init: managedSocket => {
    socket = managedSocket;
  },

  addJob: job => {
    const stateJobs = get().jobs;

    const newJob = {
      type: job.type,
      interval: setInterval(() => {
        get().createReservationReminder();
      }, POLL_INTERVAL),
    };

    set({ jobs: [...stateJobs, newJob] });
  },

  removeJob: type => {
    const stateJobs = get().jobs;
    const jobToBeRemoved = stateJobs.find(j => j.type === type);
    if (jobToBeRemoved && jobToBeRemoved.interval) {
      clearInterval(jobToBeRemoved.interval);
      set({ jobs: stateJobs.filter(sj => sj.type !== type) });
    }
  },

  createReservationReminder: () => {
    const selfId = get().selfId;

    if (!selfId) {
      const id = useUserStore.getState().user.id;
      set({ selfId: id });
      return;
    }

    const user = useUserStore.getState().user;
    const userForename = user ? user.forename : '';
    const talks = useTalkStore.getState().talks;

    for (let t = 0; t < talks.length; t++) {
      const { startTime, id } = talks[t];

      if (isAlreadyLiveSinceOneMinute(startTime)) {
        lessThenOneMinute[id] = true;
        lessThen10Minutes[id] = true;
      } else if (willStartInLessThanOneMinute(startTime)) {
        if (!lessThenOneMinute[id]) {
          const handlers = {
            accept: useModalService.getState().closeModal,
            decline: useModalService.getState().closeModal,
          };
          const notification = createTimetableNotification(
            'lessThenOneMinute',
            null,
            handlers,
            userForename,
            JoinTalkButton
          );
          useModalService.getState().addModal(notification);
          lessThenOneMinute[id] = true;
        }
      } /*else if (willStartInLessThanTenMinutes(startTime)) {
        if (!lessThen10Minutes[id]) {
          const notification = createTimetableNotification('lessThen10Minutes');
          useNotificationStore.getState().addNotification(notification);
          lessThen10Minutes[id] = true;
        }
      } */
    }
  },
}));
