import React from 'react';
import styled, { css } from 'styled-components';
import { center } from '../../../../uiLivi/styles';
import { mediaMax } from '../../../../common/layout';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  ${center.horizontal()};
  width: 200px;
  height: 1px;
  bottom: 90px;
`;

const Track = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 6px;
`;

const GradientMask = styled.div`
  position: absolute;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 0 7px #ffffff;
`;

export const Gradient = styled.div`
  width: 200px;
  height: 100%;
  background: linear-gradient(270deg, #f9b102 0%, #00ffc2 100%);
`;

const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  opacity: 1;
  background-color: white;
`;

const EnteringText = styled.div`
  position: absolute;
  font-family: 'AzoSans', serif;
  font-size: 16px;
  ${center.horizontal()};
  bottom: 90px;
  padding-bottom: 15px;
  text-transform: uppercase;
  text-shadow: 0 0 4px #ffffff;

  ${mediaMax(
    'small',
    css`
      font-size: 14px;
    `
  )};
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${center.absolute()};
  width: 100px;
  font-family: 'AzoSans', serif;
  font-weight: bold;
  font-size: 12px;
  margin-top: 20px;
`;

export default function LinearProgress({ nextDistrict, progress }) {
  const getProgress = () => {
    return (
      (progress * 100)
        .toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .toString() + '%'
    );
  };
  return (
    <>
      <EnteringText>{'ENTERING ' + nextDistrict}</EnteringText>
      <Wrapper>
        <Track>
          <Icon style={{ left: `${progress * 100}%` }}>{getProgress()}</Icon>
          <Line />
          <GradientMask style={{ width: `${progress * 100}%` }}>
            <Gradient />
          </GradientMask>
        </Track>
      </Wrapper>
    </>
  );
}
