import styled, { css } from 'styled-components';

export const InfoContainer = styled.div`
  flex-grow: 1;

  ${props =>
    props.verticalCenter &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `};
`;
