import styled from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { H3, textOverflowEllipsis } from '../../../../common/text';
import { smallTextStyle } from 'common/text';

export const StyledAddOrRemoveToChat = styled(H3)`
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  color: ${colors.font.light};
  margin: 0;
  cursor: pointer;

  &:hover {
    color: ${colors.secondary.base};
  }
`;

export const StyledLoadMore = styled.div`
  ${smallTextStyle};
  font-weight: bold;
  cursor: pointer;
  color: ${lgBaseColors.grey.light};
  text-align: center;
  padding-top: 10px;
`;

export const StyledChatCreator = styled.div`
  padding: 0 0 20px 0;

  form {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    height: 55px;

    button {
      margin-left: 5px;
      flex-grow: 1;
    }
  }
`;

export const StyledFullName = styled.div`
  max-width: 180px;
  ${textOverflowEllipsis};
  cursor: pointer;
  color: white;
`;
