import styled, { css } from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { iconFillAndStroke } from 'common/icon';
import { styledScrollbar } from 'common/layout';

export const DropdownContainer = styled.div`
  position: relative;
`;
export const IconContainer = styled.div`
  position: absolute;
  width: 8px;
  height: 4px;
`;
export const DeleteIconContainer = styled(IconContainer)`
  top: 16px;
  right: 48px;
  svg {
    width: 16px;
    height: 16px;
  }
`;
export const ArrowIconContainer = styled(IconContainer)`
  top: 12px;
  right: 20px;
`;
export const DropdownButton = styled.div`
  border: 1px solid #4e4e4e;
  padding: 16px 13px;
  cursor: pointer;
  background-color: ${props => (props.open ? colors.background.base : 'black')};
  color: ${props => (props.open ? 'white' : props.greyedOut ? '#c8c8c8' : colors.font.strong)};
  font-size: 11px;
  font-weight: 600;
  ${IconContainer} {
    svg {
      ${props =>
        props.open
          ? iconFillAndStroke(lgBaseColors.white, lgBaseColors.white)
          : iconFillAndStroke(lgBaseColors.grey.medium, lgBaseColors.grey.medium)};
    }
  }
  ${ArrowIconContainer} {
    svg {
      transform: rotate(${props => (props.open ? 180 : 0)}deg);
    }
  }
`;
const scrollable = css`
  max-height: 235px;
  overflow-y: scroll;
  ${styledScrollbar}
`;
export const DropdownOptionList = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: auto;
  ${props => (props.numItems > 5 ? scrollable : null)};
  background-color: black;
`;
export const DropdownOption = styled.div`
  padding: 14px;
  color: ${props => (props.selected ? colors.secondary.base : colors.font.strong)};
  display: ${props => (props.disabled ? 'none' : ' block')};
  background-color: black;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${lgBaseColors.grey.veryLight};
  }
  &:hover {
    background-color: ${colors.accent.base};
    color: ${colors.font.strong};
  }
`;
