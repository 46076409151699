import { Object3D } from 'three';
import create from 'utilities/zustand/create';

/**
 * @typedef CollisionMesh
 * @type {object}
 * @property {Object3D} walls
 * @property {Object3D} ground
 */

/**
 * @typedef DistrictScene
 * @type {object}
 * @property {Object3D} renderable
 * @property {Object3D} contentGroup
 * @property {Object3D} zoneGroup
 * @property {CollisionMesh} collisionMesh
 */

export const useSceneStore = create(set => ({
  /**@type ?DistrictScene*/
  scene: null,
  isLoaded: false,
  isShown: false,

  setScene: scene => {
    set({ scene });
  },

  setEmptyScene: () => {
    set({
      scene: {
        collisionMesh: { walls: null, ground: null },
        contentGroup: null,
        zoneGroup: null,
        renderable: new Object3D(),
      },
    });
  },
}));
