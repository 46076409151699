import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { useDistrictStore } from 'services/DistrictService';
import { useOnboardingStore } from 'services/OnboardingService';
import { useUserStore } from 'services/UserService';
import { useDebugStore } from 'storage/debug';
import Notifications from '../Notifications';
import Twilio from 'components/Twilio';
import { useEventStore } from 'services/EventService';
import useDebugHotkeys from 'components/Play/useDebugHotkeys';
import PlayDistrict from 'components/Play/PlayDistrict';

// mounted & unmounted by Routes
// has event & login
export default function Play() {
  const { mapId } = useParams();

  const districtIdList = useDistrictStore(state => state.getSimpleDistrictList().map(d => d.id));
  const showUI = useDebugStore(state => !state.getUiDisabled());
  const userId = useUserStore(state => state.user.id);
  const loadedDistrict = useDistrictStore(state => state.districts.find(d => d.room === mapId && d.isLoaded));

  const userInitials = useUserStore(
    state => state.user.forename[0].toUpperCase() + state.user.surname[0].toUpperCase()
  );

  useDebugHotkeys();
  useEffect(() => useOnboardingStore.getState().startOnboarding(), []);
  const { uiLivi } = useDebugStore(state => state.getUi());

  if (!districtIdList.includes(mapId)) {
    // eslint-disable-next-line no-console
    console.warn('redirect in play', districtIdList, mapId);
    const startDistrict = useEventStore.getState().event.districts?.[0]?.room;
    return <Redirect to={`/play/${startDistrict}`} />;
  }

  return (
    <>
      <PlayDistrict mapId={mapId} />
      {showUI && <Notifications />}
      {uiLivi && loadedDistrict?.entered && (
        <Twilio key={mapId} mapId={mapId} identity={userId} userInitials={userInitials} />
      )}
    </>
  );
}
