import styled, { css } from 'styled-components';
import { colors } from 'uiLivi/styles';

export const panelStyle = css`
  background-color: ${colors.background.base};
  pointer-events: all;
  box-shadow: 0px 2px 5px rgba(44, 52, 73, 0.1);
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //pointer-events: none;
`;

export const OptionPanelColumn = styled.div`
  width: 200px;
  padding-left: 1px;
  position: absolute;
  top: 16px;
  bottom: 16px;
  left: 238px;
`;
