import React, { useEffect, useRef } from 'react';
import { createImageMaterial, createVideoMaterial } from 'utilities/content';
import { sharedTextures } from 'services/MaterialService/AuditoriumMaterials';
import fromCdn from 'utilities/cdn';
import { useDebugStore } from 'storage/debug';

export default function Teaser({ object, content, createSharedTexture = false }) {
  const debugMinimal3d = useDebugStore(state => state.getMinimal3d());
  const mediaRef = useRef();
  const meshRef = useRef();
  if (!object) {
    return null;
  }

  useEffect(() => {
    const materialFactory = {
      video: () => {
        const isAuditorium = content.location === 'stage01';
        return createVideoMaterial(fromCdn(content.teaser), object.position, isAuditorium);
      },
      image: () => {
        return createImageMaterial(fromCdn(content.teaser), object.position);
      },
    };

    const media = materialFactory[content.teaserType]();
    mediaRef.current = media;

    if (createSharedTexture) sharedTextures.videoTexture = media.material.map;
    meshRef.current.material = media.material;
    return () => {
      media.material.dispose();
    };
  }, [debugMinimal3d, content.teaser, content.teaserType]);

  return <mesh name={'Teaser'} geometry={object.geometry} ref={meshRef} position={[0, 0, 0]} />;
}
