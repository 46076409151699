import { Billboard, Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useRef } from 'react';
import { usePlayerStore } from 'services/PlayerService';
import fromCdn from 'utilities/cdn';

const boldProps = {
  outlineWidth: '2.5%',
  outlineColor: '#ffffff',
  outlineOpacity: 0.7,
};

const LINE_HEIGHT = 0.13;

const Label = ({ label, globalPosition }) => {
  const bilRef = useRef();
  useFrame((_, delta) => {
    if (bilRef.current) {
      const position = usePlayerStore.getState().position;
      const dx = position[0] - globalPosition[0];
      const dz = position[2] - globalPosition[2];
      const dist = dx * dx + dz * dz;
      bilRef.current.visible = dist < 100;
    }
  }, 1000);

  const position = label.position.split(',');
  const textArray = label.text.split('\n');
  return (
    <Billboard ref={bilRef} name={'billdboard'} position={position}>
      {textArray.map((t, i) => {
        const length = textArray.length;
        const y = 0 + ((length - 1) / 2) * LINE_HEIGHT - i * LINE_HEIGHT;
        return (
          <Text
            key={i}
            name={t}
            position={[0, y, 0]}
            fillOpacity={0.7}
            color={'#ffffff'}
            fontSize={0.1}
            maxWidth={200}
            lineHeight={1}
            letterSpacing={0.02}
            font={fromCdn('/shared/fonts/demodern-medium-webfont.woff')}
            anchorX="center"
            anchorY="middle"
            {...(i === 0 && boldProps)}
          >
            {t}
          </Text>
        );
      })}
    </Billboard>
  );
};

export default Label;
