import styled, { css } from 'styled-components';
import { zIndex } from '../../../../uiLivi/styles';
import { mediaMaxPx } from '../../../../common/layout';
import { motion } from 'framer-motion';

export const MOBILE_BREAKPOINT = 992;

export const Wrapper = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  pointer-events: none;

  ${mediaMaxPx(
    MOBILE_BREAKPOINT,
    css`
      justify-content: center;
      align-items: flex-end;
    `
  )};
`;

export const Header = styled.div`
  padding: 24px;
  padding-top: 29px;

  align-self: stretch;

  background: #00676b;
  position: relative;
`;

export const Overlay = styled.div.attrs(props => ({
  style: {
    maxHeight: `${100 * props.vh - (props.isMobile ? 0 : 40)}px`,
  },
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(180deg, #fff 0%, #f3f6f6 100%);
  color: #000000;
  white-space: pre-line;
  text-align: center;

  margin-right: 40px;
  margin-bottom: 40px;

  opacity: ${props => (props.show ? 1 : 0)};
  transform: scale(${props => (props.show ? 1 : 0.75)});
  transition: all ${props => (props.show ? '.35s ease-out' : '.1s ease-in')};
  pointer-events: ${props => (props.show ? 'all' : 'none')};

  overflow: auto;
  width: 100%;
  max-width: 461px;

  position: relative;

  ${mediaMaxPx(
    MOBILE_BREAKPOINT,
    css`
      margin-right: 0;
      margin-bottom: 0;
    `
  )};
`;

export const ExpandButton = styled.div`
  color: #00676b7f;
  margin: 24px 0;
  cursor: pointer;
  font-size: 16px;

  svg {
    transform: rotate(${props => (props.$expanded ? '180deg' : '0deg')});
    transition: transform 0.2s;
  }

  svg path {
    fill: #00676b;
  }
`;

export const copyPaddingTop = 21;
export const copyPaddingBottom = 24;

const gradientRightPosition = 95;
const gradientBottomPosition = 95;

export const Content = styled(motion.div)`
  overflow: ${props => (props.$expanded ? 'auto' : 'hidden')};

  font-family: AzoSans;
  font-size: 16px;

  ${props =>
    props.$hasFade &&
    css`
      mask-image: linear-gradient(to bottom, black, black ${gradientBottomPosition}%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0) ${gradientRightPosition}%,
          black ${gradientRightPosition}%,
          black 100%
        );
    `};
`;

export const Title = styled.div`
  color: #fff;
  font-family: AzoSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  opacity: 0.8;
  text-align: left;
`;

export const Headline = styled.div`
  color: #fff;
  font-family: AzoSansBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;

  margin-top: 12px;
  max-width: 290px;
  text-align: left;
`;

export const Copy = styled.div`
  color: #00676b;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6);
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 27.2px */

  padding: ${copyPaddingTop}px;
  padding-top: ${copyPaddingBottom}px;
  text-align: left;
`;

export const Close = styled.div`
  cursor: pointer;

  justify-self: flex-start;

  svg {
    width: 42px;
    display: block;
  }

  position: absolute;
  top: 16px;
  right: 16px;
`;
