import create from 'utilities/zustand/create';

let socket = null;
const limit = 50;
export const usePeopleStore = create((set, get) => ({
  //updated only on result
  people: [],
  hasMore: false,

  //cleared if input (start or search) changes
  internalPeople: [],
  search: '',
  start: 0,

  init(managedSocket) {
    socket = managedSocket;
  },

  requestPeople: () => {
    set({ start: 0, internalPeople: [] });
    get().update();
  },

  setSearchValue(search) {
    set({ search, start: 0, internalPeople: [] });
    get().update();
  },

  loadMore: () => {
    const { start } = get();
    set({ start: start + limit });
    get().update();
  },

  update: () => {
    const { search, start } = get();
    socket.emit('user/people', { search, start, limit }, newPeople => {
      const { search: newSearch } = get();
      if (search === newSearch) {
        const hasMore = newPeople.length === limit;
        const { internalPeople } = get();
        const mergedPeople = [...internalPeople, ...newPeople];
        set({ people: mergedPeople, internalPeople: mergedPeople, hasMore });
      }
    });
  },

  logout() {
    socket = null;
    set({ people: [], internalPeople: [], search: '', start: 0, hasMore: false });
  },
}));
