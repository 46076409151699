import styled from 'styled-components';
import React, { useState } from 'react';

const SwipeArea = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export default function SwipeDetect({ onSwipeDetect }) {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const onTouchStart = e => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = e => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    let swipeDirection = 0;
    const distance = touchStart - touchEnd;
    if (distance > minSwipeDistance) {
      swipeDirection = -1;
    } else if (distance < -minSwipeDistance) {
      swipeDirection = 1;
    }

    if (Math.abs(swipeDirection) > 0) {
      onSwipeDetect(swipeDirection);
    }
  };

  return <SwipeArea onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} />;
}
