import styled, { css, keyframes } from 'styled-components';
import { colors, easings } from 'uiLivi/styles';
import { animated } from 'react-spring';
import { marginTopAndButton } from '../styles';
import { mediaMax } from 'common/layout';

export const closeButtonSize = 32;
export const menuPadding = 24;
export const menuPaddingPx = `${menuPadding}px`;

const appear = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

export const SubMenuContainer = animated(styled.div`
  position: relative;
  float: left;
  background-color: ${colors.background.base};
  left: 8px;
  //top: ${marginTopAndButton}px;
  top: 100px; //tbr => schletter
  max-height: calc(100% - ${marginTopAndButton * 2}px);
  pointer-events: all;
  transition: opacity 0.5s, transform 0.5s ${easings.easeOutStrong};
  transition-delay: ${props => (props.show ? 0 : 0.25)}s;
  opacity: ${props => (props.show ? 1 : 0)};
  overflow: hidden;
  color: ${colors.font.strong};
  ${mediaMax(
    'small',
    css`
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      max-height: none;
      background-color: transparent;
      pointer-events: none;
      transform: translateX(${props => (props.show ? 0 : -100)}%);
    `
  )}
`);

export const ContentContainer = styled.div`
  position: absolute;
  left: 0;
  width: ${props => (props.open ? (props.wide ? 610 : 300) : 0)}px;
  opacity: 0;
  background-color: ${colors.background.base};
  animation: ${appear} 0.5s linear forwards;
  animation-delay: 0.25s;
  overflow: hidden;
  ${mediaMax(
    'small',
    css`
      width: ${props => (props.open ? 100 : 0)}vw;
      height: ${props => (props.open ? props.vh * 100 - closeButtonSize : 0)}px;
      max-height: ${props => props.vh * 100 - closeButtonSize}px;
      pointer-events: all;
      background-color: ${colors.background.base};
      animation-duration: 0.05s;
    `
  )}
`;

export const CloseButtonContainer = styled.div`
  height: 32px;
  transition: background-color 0.3s ${easings.easeOutStrong};
  background-color: black;
  ${mediaMax(
    'small',
    css`
      margin: 0 32px;
    `
  )}
`;
