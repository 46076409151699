// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import uniqBy from 'lodash-es/uniqBy';
import { gsap } from 'gsap';
import { damp } from 'maath/easing';
import { AdditiveBlending } from 'three';

import fromCdn from 'utilities/cdn';

import { useWeatherStore } from '../store';
import EnvironmentChoreography from './EnvChoreography';
import CloudsStage from './CloudsStage';
import Fog from './Fog';

import renderOrder from '../config/renderOrder';

export default React.memo(() => {
  const isReady = useRef(false);

  const sun = useRef({});
  const mountains = useRef({});

  const model = useGLTF(fromCdn(`/shared/districts/intersolar/weather/dom/index.glb`));
  const envConfig = useWeatherStore(state => state.envConfig);
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const isAnimating = useWeatherStore(state => state.isAnimating);
  const { env } = smoothedValues;
  const cloudsDisposition = envConfig.weather.clouds;

  const updateOrder = () => {
    mountains.current.renderOrder = renderOrder.MOUNTAINS;
    sun.current.renderOrder = renderOrder.SUN;
  };

  useEffect(() => {
    model.scene.visible = true; // force visibility. Somehow DOM disappears if coming from another page.

    sun.current = model.scene.getObjectByName('Sun');
    mountains.current = model.scene.getObjectByName('Mountains');
    mountains.current.side = 1;
    mountains.current.depthWrite = false;

    // TEMPORARY WAITING FOR NEW MODEL
    model.scene.traverse(el => {
      if (el.name.includes('Cloud')) {
        el.visible = false;
      }
    });

    updateOrder();

    isReady.current = true;
  }, []);

  useFrame((state, delta) => {
    if (!isReady.current) return;

    if (isAnimating) {
      sun.current.material.opacity = env.sun.opacity;
    }
  });

  return (
    <>
      <EnvironmentChoreography />
      <CloudsStage data={cloudsDisposition[0]} />
      <CloudsStage data={cloudsDisposition[1]} />
      <CloudsStage data={cloudsDisposition[2]} />
      <CloudsStage data={cloudsDisposition[3]} />
      <CloudsStage data={cloudsDisposition[4]} />
      <Fog model={model} />
      <primitive object={model.scene} position-y={0} rotation-y={0} />
    </>
  );
});
