import styled, { css } from 'styled-components';
import { easings, lgBaseColors } from 'uiLivi/styles';

const DOT_SIZE = 10;

const selectedDotStyle = css`
  transform: scale(1);
  border-color: white;
  background-color: transparent;
`;

export const Dot = styled.div`
  height: ${DOT_SIZE}px;
  width: ${DOT_SIZE}px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid transparent;
  transform: scale(0.5);
  border-radius: ${DOT_SIZE / 2}px;
  transition: all 0.15s ease-out;
  ${({ selected }) => selected && selectedDotStyle};
`;

const PADDING = 5;

export const DotContainer = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  padding: ${PADDING}px;
  width: ${DOT_SIZE + 2 * PADDING}px;
  cursor: pointer;
  pointer-events: all;
  &:hover {
    ${Dot} {
      ${selectedDotStyle};
    }
  }
`;

export const OptionalButton = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: absolute;
  pointer-events: all;
  cursor: pointer;
  height: 40px;
  width: 40px;
  left: 0;
  background-color: ${lgBaseColors.red.base};
  border-radius: 20px;
`;

export const OptionalButtonText = styled.div`
  color: #fff;
  position: absolute;
  line-height: 40px;
  left: 50px;
  width: 140px;
`;
