import { StyledChatButton } from './styles';
import ChatToggleIcon from 'uiLivi/components/Chat/Icons/Icon_Chat.svg';
import ChatCollapseIcon from 'uiLivi/components/Chat/Icons/Icon_Arrow_Collapse.svg';
import { useChatStore } from 'services/ChatService';
import { useWindowStore } from 'services/WindowService';
import { useTranslate } from 'services/TranslationService/hooks';

const ChatButton = ({ isContentChat }) => {
  const isChatOpen = useChatStore(state => state.isActive);
  const contentChatIsOpen = isContentChat && isChatOpen;
  const isMobile = useWindowStore(state => state.isMobile);
  const setIsActive = useChatStore.getState().setIsActive;
  const translate = useTranslate();

  const toggleActive = () => {
    setIsActive(!isChatOpen);
  };

  const show = !isChatOpen || isContentChat;

  return (
    <StyledChatButton
      contentChatIsOpen={contentChatIsOpen}
      isContentChat={isContentChat}
      isMobile={isMobile}
      onClick={toggleActive}
      show={show}
    >
      {contentChatIsOpen ? (
        <>
          <ChatCollapseIcon /> {translate('chat.minimize') || 'Minimize'}
        </>
      ) : (
        <>
          <ChatToggleIcon /> {translate('chat.cta') || 'Chat'}
        </>
      )}
    </StyledChatButton>
  );
};

export default ChatButton;
