import create from 'utilities/zustand/create';
import { subscribeWithSelector } from 'zustand/middleware';
import { gsap } from 'gsap';

import throttle from 'lodash-es/throttle';

import envConfig from './config/envConfig';
import windConfig from './config/windConfig';

let dc = undefined;
let scenesInterpolatorTween = undefined;

const useWeatherStore = create(
  subscribeWithSelector(set => {
    const debouncedSetSliderProgress = throttle(v => {
      set({ sliderProgress: v });
    }, 200);

    return {
      windPresets: { weather: windConfig.weather.stages, sunTracker: windConfig.sunTracker.stages },
      envConfig,

      weatherConfiguration: {
        wind: { ...windConfig.weather.stages.start },
      },
      set: weatherConfiguration => {
        set(state => {
          state.smoothedValues.wind = { ...state.smoothedValues.wind, ...weatherConfiguration.wind };
          return { weatherConfiguration: JSON.parse(JSON.stringify(weatherConfiguration)) };
        });
      },

      // ANIMATE AND AUTO-CALL FALSE
      isAnimating: false,
      setIsAnimating: v => {
        if (v) {
          set({ isAnimating: v });
          dc && dc?.kill();
          dc = gsap.delayedCall(8, () => set({ isAnimating: false }));
        } else {
          dc && dc?.kill();
          set({ isAnimating: v });
        }
      },

      sliderProgress: 0,
      setSliderProgress: v => debouncedSetSliderProgress(v),

      // SMOOTHED VALUES ARE CALCULATED IN INTERPOLATORS (and children)
      smoothedValues: {
        time: 0,
        sliderProgress: 0,
        dampedSliderProgress: 0,
        scenesInterpolator: 0,
        noisyIntensity: 0,
        wind: { ...windConfig.weather.stages.start },
        env: {}, // created on load in envStoreInterpolator
      },

      setWindPresets: values => {
        set(state => {
          if (values) {
            state.smoothedValues.wind = { ...state.smoothedValues.wind, ...values };
            return { weatherConfiguration: { ...state.weatherConfiguration, wind: { ...values } } };
          } else {
            return { weatherConfiguration: { ...state.weatherConfiguration } };
          }
        });
      },

      sliderActive: false,
      setSliderActive: bool => {
        set({ sliderActive: bool });
      },

      isWeather: true,
      setIsWeather: value => {
        set(state => {
          scenesInterpolatorTween?.kill();
          scenesInterpolatorTween = gsap.to(state.smoothedValues, {
            duration: 3,
            scenesInterpolator: value ? 0 : 1, // WEATHER 0, SUN TRACKER 1
            ease: 'power1.inOut',
          });

          state.setIsAnimating(true);
          return { isWeather: value };
        });
      },
    };
  })
);

export { useWeatherStore };
