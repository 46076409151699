import { InstructionHeadline, InstructionIconContainer, InstructionText, InstructionWrapper } from '../../../../styles';
import IconTurn from '../../../assets/turnToLearn.png';
import React from 'react';
import styled from 'styled-components';

const InstructionIcon = styled.div`
  width: 110px;
  height: 81px;
  background-image: url(${IconTurn});
  background-size: cover;
`;

export default function OnboardingMove() {
  return (
    <InstructionWrapper>
      <InstructionIconContainer>
        <InstructionIcon />
      </InstructionIconContainer>
      <InstructionHeadline>TURN TO LEARN</InstructionHeadline>
      <InstructionText>Don`t just read about our products. Interact with them.</InstructionText>
    </InstructionWrapper>
  );
}
