import create from 'zustand';
import ErrorStackParser from 'error-stack-parser';

// don't recreate zustand stores if hot inits them a second time
const cache = {};
export default function(fn) {
  if (module.hot) {
    try {
      // console.log(new Error());
      const fileName = ErrorStackParser.parse(new Error())[1].fileName;
      // console.log('Create store for', fileName);
      // const fileName = Math.random();
      if (!fileName) throw 'Cannot create ErrorStack';
      if (cache[fileName]) {
        return cache[fileName];
      } else {
        const store = create(fn);
        cache[fileName] = store;
        return store;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      // console.warn(e);
      return create(fn);
    }
  } else {
    return create(fn);
  }
}
