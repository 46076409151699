import { createDebug, updateDebug } from './debug';
import { prefixEventListener } from 'utilities/prefixed-events';
import debounce from 'utilities/debounce';

const emitInterval = 500;
let idleTimeoutInSeconds = 10;
let timeout = null;
let idle = false;
let focus = true;
let showDebugInfo = false;
let callback = () => {};

const resetIdle = () => {
  idle = false;
  emitUpdate();
  if (timeout) clearInterval(timeout);
  timeout = setInterval(() => {
    if (!idle || !focus) {
      idle = true;
      emitUpdate();
    }
  }, idleTimeoutInSeconds * 1000);
};

const handleInteraction = e => {
  if (e && e.isTrusted) {
    const isBlur = e.type === 'blur';
    const focusIsBlurred = isBlur && focus;
    focus = !focusIsBlurred;
    emitUpdate();
    if (!focusIsBlurred) resetIdle();
  }
};

const emitUpdate = () => {
  if (showDebugInfo) updateDebug(focus, idle);
  callback({ focus, idle });
};

const addInteractionListener = () => {
  prefixEventListener(window, 'focus', debounce(handleInteraction, emitInterval, false));
  prefixEventListener(window, 'blur', debounce(handleInteraction, emitInterval, false));
  prefixEventListener(window, 'mousemove', debounce(handleInteraction, emitInterval, false));
  prefixEventListener(window, 'keyup', debounce(handleInteraction, emitInterval, false));
  prefixEventListener(window, 'touchstart', debounce(handleInteraction, emitInterval, false));
  prefixEventListener(window, 'scroll', debounce(handleInteraction, emitInterval, false));
};

const configureSession = properties => {
  const { update, idleTimeout = idleTimeoutInSeconds, debug = showDebugInfo } = properties;
  if (update) callback = update;
  idleTimeoutInSeconds = idleTimeout;
  showDebugInfo = debug;
  if (showDebugInfo) createDebug();
};

resetIdle();
addInteractionListener();

export { configureSession };
