import React, { useEffect, useRef, useState } from 'react';
import { Container, ErrorIcon, EyeToggle, StyledIconEye, StyledPasswordInput } from './styles';
import IconEye from 'assets/icons/Icon_Eye.svg';
import { useDeviceStore } from 'services/DeviceService';

function PasswordInput({ placeholder, password, setPassword, invalid = false }) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setKeyboardActive } = useDeviceStore.getState();
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.type = passwordVisible ? 'text' : 'password';
  }, [passwordVisible]);

  const handlePasswordChange = e => {
    const value = e.nativeEvent.target.value;
    setPassword(value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleOnFocus = () => {
    setKeyboardActive(true);
  };
  const handleOnBlur = () => {
    setKeyboardActive(false);
  };

  return (
    <Container>
      {invalid && <ErrorIcon />}
      <StyledPasswordInput
        ref={inputRef}
        border
        placeholder={placeholder}
        type="text"
        value={password}
        onChange={handlePasswordChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        disabled={false}
      ></StyledPasswordInput>
      <EyeToggle onClick={togglePasswordVisibility}>
        <StyledIconEye passwordVisible={passwordVisible}>
          <IconEye />
        </StyledIconEye>
      </EyeToggle>
    </Container>
  );
}

export default PasswordInput;
