// import { useSoundStore } from 'services/SoundService';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebugAssetVersion } from 'services/DebugAssetVersion';
import { Howl } from 'howler';
import fromCdn from 'utilities/cdn';
// import { useFrameAfterMount } from 'utilities/hooks';
// import { usePlayerStore } from 'services/PlayerService';
import { AdditiveBlending, DoubleSide } from 'three';
// import { useContentStore } from 'services/ContentService';
import { useProductAudioStore } from '../../../../services/ProductAudioService';

// const defaultPannerAttributes = {
//   distanceModel: 'exponential',
//   maxDistance: 10000,
//   panningModel: 'HRTF',
//   coneInnerAngle: 360,
//   coneOuterAngle: 360,
//   coneOuterGain: 1,
//   refDistance: 1,
//   rolloffFactor: 1,
// };

export default function ProductAudio({ audio, name, groupRef }) {
  // const content = useContentStore(state => state.activeContent);
  // const enabledByUser = useSoundStore(state => state.enabled);
  // const enabledBySystem = useSoundStore(state => state.isPlayAllowed);
  // const enabled = useMemo(() => enabledByUser && enabledBySystem, [enabledByUser, enabledBySystem]);
  const { registerProductAudio, unregisterProductAudio } = useProductAudioStore();
  const howl = useRef(null);
  const debugAssetVersion = useDebugAssetVersion(state => state.version);
  const versionParam = useMemo(() => `?v=${debugAssetVersion}`, [debugAssetVersion]);

  const [debugAudio, setDebugAudio] = useState([]);

  const getSources = () => {
    const sources = [];
    if (audio.webm) sources.push(fromCdn(audio.webm + versionParam));
    if (audio.mp3) sources.push(fromCdn(audio.mp3 + versionParam));
    return sources;
  };

  useEffect(() => {
    howl.current = new Howl({
      src: getSources(),
      loop: true,
    });
    howl.current.name = name;
    console.log('Loading ambient sound', howl.current.name);
    registerProductAudio(howl.current);
    return () => {
      unregisterProductAudio(howl.current);
      if (howl.current.playing()) {
        howl.current.off('pause');
        howl.current.once('pause', () => {
          console.log('Unloading ambient sound', howl.current.name);
          howl.current.unload();
        });
        return;
      }

      console.log('Unloading ambient sound', howl.current.name);
      howl.current.unload();
    };
  }, []);

  // useEffect(() => {
  //   console.log('content', content);
  //   if (content) {
  //     howl.current.stop();
  //   } else if (enabled) {
  //     howl.current.play();
  //   }
  // }, [content]);

  /*useFrameAfterMount(() => {
    howl.current.mute(!groupRef.current.visible);

    // turn panner on/off
    if (audio.directional) {
      howl.current.pannerAttr({
        ...defaultPannerAttributes,
        refDistance: audio.distance,
        rolloffFactor: audio.rolloff,
      });
      howl.current.hasPanner = true;

      howl.current.pos(groupRef.current.position.x, groupRef.current.position.y, groupRef.current.position.z);
      howl.current.volume(audio.volume);
    } else {
      if (howl.current.hasPanner) {
        howl.current.unload();
        howl.current = new Howl({
          src: getSources(),
          loop: true,
          // autoplay: true,
        });
      }
      howl.current.hasPanner = false;

      const position = usePlayerStore.getState().position;
      const dx = position[0] - groupRef.current.position.x;
      const dy = position[1] - groupRef.current.position.y;
      const dz = position[2] - groupRef.current.position.z;
      const d = Math.sqrt(dx * dx + dy * dy + dz * dz);

      // manual exponential volume
      const volume = Math.min(1, Math.pow(d / audio.distance, -audio.rolloff));
      howl.current.volume(volume * audio.volume);
    }

    // debug
    if (audio.showDebug) {
      const minVolume = 0.005;
      const distanceAtMinVolume = audio.distance * Math.pow(minVolume, -1 / audio.rolloff);
      const totalDistance = distanceAtMinVolume - audio.distance;
      const segments = 6;
      const debugDistances = [];
      for (let i = 0; i < segments; i++) {
        const d = audio.distance + (totalDistance / (segments - 1)) * i;
        debugDistances.push(d);
      }
      setDebugAudio(debugDistances);
    } else {
      if (debugAudio.length !== 0) {
        setDebugAudio([]);
      }
    }
  });*/

  // useEffect(() => {
  //   if (enabled) {
  //     howl.current.play();
  //   } else {
  //     howl.current.stop();
  //   }
  // }, [enabled]);

  if (!audio.showDebug) return null;

  return (
    <group userData={{ hideInDebug: true }}>
      {debugAudio.map((r, i) => {
        const colors = ['red', 'red', 'green', 'green', 'blue', 'blue'];
        return (
          <mesh key={i} rotation={[0, 0, 0]} position={[0, 0, 0]} scale={[r, r, r]}>
            <icosahedronGeometry args={[1, 30]} />
            <meshBasicMaterial
              blending={AdditiveBlending}
              side={DoubleSide}
              color={colors[i]}
              opacity={0.15}
              transparent={true}
              depthWrite={false}
              depthTest={true}
            />
          </mesh>
        );
      })}
      <mesh position={[0, 0, 0]} scale={[0.2, 0.2, 0.2]}>
        <icosahedronGeometry args={[1, 30]} />
        <meshBasicMaterial
          blending={AdditiveBlending}
          side={DoubleSide}
          color={'white'}
          opacity={0.1}
          transparent={true}
          depthWrite={false}
        />
      </mesh>
    </group>
  );
}
