import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { ImageLoader, MathUtils, NormalBlending, Sprite, Texture, Vector3 } from 'three';
import background_about from './back_about.png';
import front_about from './front_about.png';
import back from './back.png';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import HUDSpriteMaterial from '../../../../utilities/canvas/HUDSpriteMaterial';
import { useScrollStore } from '../../../../services/ScrollService';
import { useWindowStore } from '../../../../services/WindowService';
import { gsap, Power2, Sine } from 'gsap';
import { mapLinear } from 'three/src/math/MathUtils';
import { useDeviceStore } from '../../../../services/DeviceService';
import { useNavigationStore } from '../../../../services/NavigationService';
import { useHowlerStore } from '../../../../services/HowlerService';

const getSprite = srcTexture => {
  const canvas = document.createElement('canvas');
  canvas.width = 256;
  canvas.height = 256;

  const context = canvas.getContext('2d');
  context.drawImage(srcTexture, 0, 0, 256, 256);

  const texture = new Texture(canvas);
  texture.needsUpdate = true;

  const material = new HUDSpriteMaterial();
  material.map = texture;
  material.transparent = true;
  material.depthTest = true;
  material.depthWrite = false;
  material.blending = NormalBlending;

  const sprite = new Sprite(material);
  const scale = 0.5;
  sprite.scale.set(scale, scale, scale);
  sprite.material.uniforms.opacity.value = 1;

  return sprite;
};

export default function NavigationSpot({ type, id, position, visibleRange, disabled = false }) {
  const { camera } = useThree();
  const isMobile = useDeviceStore(state => state.device.isMobileOnly);
  const setShowProductInfoLayer = useNavigationStore(state => state.setShowProductInfoLayer);
  const showProductInfoLayer = useNavigationStore(state => state.showProductInfoLayer);
  const playPrimaryClickUiSound = useHowlerStore(state => state.playPrimaryClickUiSound);
  const setProductInfoID = useNavigationStore(state => state.setProductInfoID);
  const group = useRef(null);
  const [active, setActive] = useState(true);
  const vPos = useMemo(() => {
    return new Vector3().fromArray(position);
  }, []);
  const textures = useMemo(() => {
    return [background_about, back, front_about];
    // switch (type) {
    //   case 'weather':
    //     return [background_weather, back, front_weather];
    //   case 'construction':
    //     return [background_construction, back, front_construction];
    //   case 'contact':
    //     return [background_contact, back, front_contact];
    // }
  }, [type]);

  const [backgroundShape, shapeBack, shapeFront] = useLoader(ImageLoader, textures);
  const setHover = useWindowStore(state => state.setHover);
  const spriteBackground = useRef(null);
  // const spriteBack = useRef(null);
  const spriteFront = useRef(null);
  const { mouse } = useThree();
  const shapeOffsetX = useRef(0);
  const shapeOffsetY = useRef(0);

  useEffect(() => {
    spriteBackground.current = getSprite(backgroundShape);
    spriteBackground.current.scale.set(1, 1, 1);
    // spriteBack.current = getSprite(shapeBack);
    spriteFront.current = getSprite(shapeFront);
    spriteBackground.current.renderOrder = -3;
    // spriteBack.current.renderOrder = -2;
    spriteFront.current.renderOrder = -1;
    if (group.current) {
      group.current.add(spriteBackground.current);
      // group.current.add(spriteBack.current);
      group.current.add(spriteFront.current);
    }

    return () => {
      setHover(false);

      if (group.current) {
        group.current.remove(spriteBackground.current);
        // group.current.remove(spriteBack.current);
        group.current.remove(spriteFront.current);
      }
    };
  }, []);

  useFrame((_, delta) => {
    const progress = useScrollStore.getState().normalizedProgress;
    if (progress >= visibleRange[0] && progress < visibleRange[1]) {
      setActive(true);
    } else {
      // setActive(false);
    }

    if (active) {
      const limit = 0.05;
      if (spriteBackground.current) spriteBackground.current.material.uniforms.rotation.value += delta * 0.2;
      if (!disabled && !isMobile) {
        const distToCam = vPos.distanceTo(camera.position);
        let m = 0;
        if (distToCam < 12) {
          m = Math.min(mapLinear(distToCam, 12, 5, 0, 1), 1);
        }
        shapeOffsetX.current = MathUtils.lerp(shapeOffsetX.current, -mouse.x * limit * m, 0.1);
        shapeOffsetY.current = MathUtils.lerp(shapeOffsetY.current, -mouse.y * limit * m, 0.1);
        try {
          spriteFront.current.position.x = shapeOffsetX.current;
          spriteFront.current.position.y = shapeOffsetY.current;
        } catch (e) {
          // nothing
        }
      }
    }
  });

  useEffect(() => {
    gsap.killTweensOf(group.current);
    if (!active) {
      gsap.to(group.current.scale, {
        duration: 0.3,
        ease: Power2.easeIn,
        x: 0,
        y: 0,
        z: 0,
        onComplete: () => {
          if (group.current) group.current.visible = false;
        },
      });
    } else {
      if (group.current) group.current.visible = true;
      gsap.to(group.current.scale, { duration: 0.4, ease: Sine.easeOut, x: 1, y: 1, z: 1 });
    }
  }, [active]);

  useEffect(() => {
    if (showProductInfoLayer) {
      setHover(false);
      gsap.to(spriteFront.current.material.uniforms.opacity, { duration: 0.2, easing: Power2.easeIn, value: 0 });
      // gsap.to(spriteBack.current.material.uniforms.opacity, { duration: 0.2, easing: Power2.easeIn, value: 0 });
      gsap.to(spriteBackground.current.material.uniforms.opacity, { duration: 0.2, easing: Power2.easeIn, value: 0 });
    } else {
      gsap.to(spriteFront.current.material.uniforms.opacity, { duration: 0.5, easing: Power2.easeOut, value: 1 });
      // gsap.to(spriteBack.current.material.uniforms.opacity, { duration: 0.5, easing: Power2.easeOut, value: 1 });
      gsap.to(spriteBackground.current.material.uniforms.opacity, { duration: 0.5, easing: Power2.easeOut, value: 1 });
    }
  }, [showProductInfoLayer]);

  const toggleInfoLayer = () => {
    if (active) {
      setShowProductInfoLayer(true);
      setProductInfoID(id);
      playPrimaryClickUiSound();
    }
  };

  return (
    <group name="HotspotLobby" ref={group} position={position}>
      <mesh
        onPointerOver={() => {
          !showProductInfoLayer && setHover(true);
        }}
        onPointerOut={() => {
          !showProductInfoLayer && setHover(false);
        }}
        onClick={toggleInfoLayer}
      >
        <sphereGeometry args={[0.4, 24, 24]} />
        <meshBasicMaterial color={'#ff7200'} opacity={0} transparent={true} depthTest={false} deptWrite={false} />
      </mesh>
    </group>
  );
}
