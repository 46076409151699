import { useSpring } from 'react-spring';
import { useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';

import circle from './assets/circle.png';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

export default function PlayerIndicator({ visible }) {
  const planeMesh = useRef();
  const planeMaterial = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [color] = useState(new THREE.Color('white'));
  const texture = useLoader(TextureLoader, circle);

  const onStartSpring = () => {
    if (isVisible && planeMesh.current != null) planeMesh.current.visible = true;
  };

  const onEndSpring = () => {
    if (!isVisible && planeMesh.current) planeMesh.current.visible = false;
  };

  const { progress } = useSpring({
    from: { progress: 0 },
    progress: isVisible ? 1 : 0,
    delay: isVisible ? 500 : 0,
    onStart: onStartSpring,
    onRest: onEndSpring,
    config: {
      tension: 280,
      friction: 40,
    },
  });

  useFrame(() => {
    if (planeMesh.current && planeMesh.current.visible) {
      // MathUtils.mapLinear(progress.value, 0, 1, 1, 0.15);
      planeMaterial.current.color.lerp(color, 0.1);
      planeMaterial.current.opacity = progress.value;
      planeMesh.current.rotation.z += 0.01;
    }
  });

  useEffect(() => {}, []);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <mesh name="Player Indicator" ref={planeMesh} rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.05, 0]}>
      <planeGeometry attach="geometry" args={[1.3, 1.3, 1, 1]} />
      <meshBasicMaterial
        ref={planeMaterial}
        attach="material"
        map={texture}
        side={THREE.DoubleSide}
        depthTest={true}
        depthWrite={false}
        transparent={true}
      />
    </mesh>
  );
}
