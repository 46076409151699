import styled, { css, keyframes } from 'styled-components';
import { center, easings } from '../../../../../uiLivi/styles';
import { mediaMax, mediaMaxPx } from '../../../../../common/layout';

export const HANDLE_SIZE = 48;

export const SliderWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 680px;
  height: 1px;
  ${center.horizontal()};
  bottom: ${props => (!props.isMobile ? 90 : 55)}px;

  ${mediaMax(
    'small',
    css`
      width: 80%;
    `
  )};
`;

export const Track = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 8px;
`;

export const GradientMask = styled.div`
  height: 100%;
  width: 0%;
  overflow: hidden;
`;

export const GradientMaskSun = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const GradientWeather = styled.div`
  width: ${props => props.width}px;
  max-width: 680px;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(89.99deg, #fce301 -2.83%, #ffc601 13.85%, #ff3801 49.9%, #5301bd 78.42%, #250154 100.48%);
`;

export const GradientSun = styled.div`
  width: ${props => props.width}px;
  max-width: 680px;
  height: 100%;
  border-radius: 10px;
  //background: linear-gradient(89.99deg, #ff3801 0%, #ffc601 25%, #ffc601 50%, #ffc601 75%, #ff3801 100%);
  background: #f9b102;
`;

export const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  opacity: 0.3;
  background-color: white;
`;

export const DragHandle = styled.div`
  position: absolute;
  left: -24px;
  top: -24px;
  width: ${HANDLE_SIZE}px;
  height: ${HANDLE_SIZE}px;
  border-radius: 50%;
  background: rgba(72, 72, 72, 0.3);
  cursor: pointer;
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.35);

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.75);
  }
`;

export const Icons = styled.div`
  position: absolute;
  width: 100%;
  bottom: 80px;
`;

export const IconsSuntracker = styled.div`
  position: absolute;
  width: 100%;
  bottom: 20px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${center.absolute()};
  left: ${props => props.xp}%;
`;

export const IconSuntracker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  left: ${props => props.xp}%;
`;

export const IconImage = styled.img`
  transform: scale(0.5);

  ${mediaMax(
    'small',
    css`
      transform: scale(0.4);
    `
  )};
`;

export const IconImageSuntracker = styled.img`
  transform: scale(0.5);

  ${mediaMax(
    'small',
    css`
      transform: scale(0.5);
    `
  )};
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  ${center.absolute()};
`;

export const DotScala = styled.div`
  position: absolute;
  left: ${props => (props.last ? 100 : 0)}%;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
`;

export const Markers = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const MarkerPosition = styled.div`
  position: absolute;
  bottom: 0;
  left: ${props => props.xp}%;
`;

export const Values = styled.div`
  position: absolute;
  width: 100%;
  top: 50px;
  font-size: 12px;
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${center.vertical()};
  left: ${props => props.xp}%;

  text-transform: uppercase;
  color: ${props => props.color};
  font-family: 'AzoSans', serif;
  font-weight: 700;
  opacity: 0.5;
`;

export const ValueScale = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${center.vertical()};
  left: ${props => props.xp}%;

  text-transform: uppercase;
  color: ${props => props.color};
  font-family: 'AzoSans', serif;
  font-weight: 700;
  opacity: 0.5;

  ${mediaMax(
    'small',
    css`
      left: ${props => (props.last ? '54%' : '2%')};
      width: 50%;
      font-size: 11px;
    `
  )};
`;

export const DebugProgress = styled.div`
  position: absolute;
  font-size: 20px;
  color: red;
  left: -100px;
`;

export const PeakIndicator = styled.div`
  position: absolute;
  top: -43px;
  width: 12px;
  height: 9px;
  transition: transform 0.3s;
  pointer-events: none;

  & svg {
    width: 12px;
    height: 9px;
  }
`;

const ping = keyframes`
  0% {
    opacity: 1;
    transform: scale(0);
  }
  60% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
`;

export const PingContainer = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  left: -50px;
  animation: ${ping} 2s ${easings.easeOutStrong} forwards infinite;
`;

export const Ping = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #00686b;
  opacity: 0.5;
`;

export const VisibilityWrapper = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s ${props => (props.visible ? 'ease-in' : 'ease-out')};
`;

// suntrack

export const WrapperSuntrack = styled.div`
  position: absolute;
  bottom: -50px;
  width: ${props => props.width}px;
  max-width: 680px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

export const SunIconPath = styled.div`
  width: 50px;
  height: 50px;
  background-image: url(${props => props.url});
  background-size: contain;
  background-repeat: no-repeat;
`;

// toggle

export const ToggleWrapperDesktop = styled.div`
  position: absolute;
  left: 80px;
  bottom: 85px;
  transform-origin: bottom left;

  ${mediaMaxPx(
    1350,
    css`
      transform: scale(0.75);
      left: 40px;
    `
  )};
`;

export const ToggleWrapperMobile = styled.div`
  position: absolute;
  right: -5px;
  bottom: 120px;
  transform-origin: bottom right;
  transform: scale(0.6);
`;

export const WrapperToggleText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-bottom: 15px;

  ${mediaMaxPx(
    1350,
    css`
      transform: scale(1.25);
    `
  )};
`;

export const ToggleText = styled.div`
  font-family: 'AzoSansBold', sans-serif;
  color: #00676b;
  font-size: 14px;
  letter-spacing: 1.26px;
  text-transform: uppercase;
  margin-left: 7px;
  margin-top: 3px;
`;
