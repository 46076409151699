import { useChatStore } from 'services/ChatService';
import { useWindowStore } from 'services/WindowService';
import CloseButton from 'common/components/CloseButton';
import IconChatOverview from 'uiLivi/components/Chat/Icons/Icon_Chat_Overview.svg';
import IconFullScreenOpen from 'uiLivi/components/Chat/Icons/Icon_Chat_Fullscreen_Open.svg';
import IconFullScreenClose from 'uiLivi/components/Chat/Icons/Icon_Chat_Fullscreen_Close.svg';
import { StyledButtonItem, StyledChatTitleBar, StyledNewChatTitle, StyledTitle } from './styles';
import ChatFilter from 'uiLivi/components/Chat/ChatFilter';
import { useTranslate } from 'services/TranslationService/hooks';

const ChatTitle = ({ isContent, canGoBack = true, createNewChat, toggleNewChat }) => {
  const activeChat = useChatStore(state => state.activeChat);
  const isFullHeight = useChatStore(state => state.isFullHeight);
  const isMobile = useWindowStore(state => state.isMobile);
  const translate = useTranslate();

  const onTitleClick = () => {
    !canGoBack && toggleNewChat();
  };

  const onBackClick = () => {
    createNewChat ? toggleNewChat() : useChatStore.getState().leave();
  };

  const closeChat = () => {
    useChatStore.getState().setIsActive(false);
  };

  const toggleFullHeight = () => {
    useChatStore.getState().setFullHeight(!isFullHeight);
  };

  const getChatTitle = () => {
    if (activeChat) return activeChat.name;
    return (
      <StyledNewChatTitle>
        {createNewChat ? translate('chat.newmessage') || `New message` : translate('chat.newchat') || `New Chat`}
        {!createNewChat && <span>+</span>}
      </StyledNewChatTitle>
    );
  };

  return (
    <>
      <StyledChatTitleBar isContent={isContent} hasShadow={!createNewChat}>
        <StyledButtonItem hide={!canGoBack} alignLeft={!createNewChat} onClick={onBackClick}>
          <IconChatOverview width={22} height={22} />
        </StyledButtonItem>
        <StyledTitle alignLeft={!canGoBack} onClick={onTitleClick} clickable={!activeChat && !createNewChat}>
          {getChatTitle()}
        </StyledTitle>
        {!isContent ? (
          <>
            {!isMobile && (
              <StyledButtonItem onClick={toggleFullHeight}>
                {isFullHeight ? (
                  <IconFullScreenClose width={14} height={14} />
                ) : (
                  <IconFullScreenOpen width={12} height={12} />
                )}
              </StyledButtonItem>
            )}
            <StyledButtonItem>
              <CloseButton size={48} handler={closeChat} position={{ top: 0, right: 0 }} />
            </StyledButtonItem>
          </>
        ) : null}
        {isContent && isMobile && (
          <StyledButtonItem>
            <CloseButton noBackground size={48} handler={closeChat} position={{ top: 0, right: 0 }} />
          </StyledButtonItem>
        )}
      </StyledChatTitleBar>
      {createNewChat ? <ChatFilter /> : null}
    </>
  );
};

export default ChatTitle;
