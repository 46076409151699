import { useLoader } from '@react-three/fiber';
import { LinearFilter, LinearMipMapLinearFilter, MeshBasicMaterial, sRGBEncoding, TextureLoader } from 'three';
import { useEventStore } from 'services/EventService';
import { useTraverse } from '../hooks';
import fromCdn from 'utilities/cdn';

export default function useBranding() {
  const branding = useEventStore(state => state.event.branding);

  const defaultLogo = fromCdn('/shared/textures/testimage.png');

  const bigLogoTexture = useLoader(TextureLoader, fromCdn(branding?.logoBig ? branding?.logoBig : defaultLogo));
  const smallLogoTexture = useLoader(TextureLoader, fromCdn(branding?.logoSmall ? branding?.logoSmall : defaultLogo));

  const createBrandingMaterial = texture => {
    const material = new MeshBasicMaterial({ color: 0xffffff });
    texture.flipY = false;
    texture.anisotropy = 16;
    texture.generateMipmaps = true;
    texture.minFilter = LinearMipMapLinearFilter;
    texture.magFilter = LinearFilter;
    texture.encoding = sRGBEncoding;
    texture.needsUpdate = true;
    material.map = texture;
    material.transparent = true;
    return material;
  };

  useTraverse(() => {
    return o => {
      if (o.material) {
        if (o.material.name === 'logo_big_mat') {
          o.material = createBrandingMaterial(bigLogoTexture);
        }
        if (o.material.name === 'logo_small_mat') {
          o.material = createBrandingMaterial(smallLogoTexture);
        }
      }
    };
  });
}
