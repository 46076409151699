import { useLayoutEffect, useMemo, useRef } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import noiseTexture from './assets/noise-fbm.png';
import { AdditiveBlending, Color, DoubleSide, RepeatWrapping, TextureLoader } from 'three';
import { DEG2RAD } from 'three/src/math/MathUtils';
import vertexShader from './glsl/fakeRaysVert.glsl';
import fragmentShader from './glsl/fakeRaysFrag.glsl';

export default function RaysConstruction() {
  const mesh = useRef(null);
  const map = useLoader(TextureLoader, noiseTexture);

  const uniforms = useMemo(
    () => ({
      uTime: { value: 0 },
      uBaseAlpha: { value: 0.61 },
      uGlowAlpha: { value: 0.18 },
      uTopFade: { value: 0.13 },
      uRaysColor: { value: new Color('#868686') },
      uGlowColor: { value: new Color('#ffea9e') },
      uRaysStepMin: { value: 0.21 },
      uRaysStepMax: { value: 0.54 },
      uGlowStepMin: { value: 0.38 },
      uTexture: { value: map },
      m_uv_x: { value: 2.0 },
    }),
    []
  );

  useLayoutEffect(() => {
    map.wrapS = RepeatWrapping;
  }, []);

  useFrame((state, delta, frame) => {
    mesh.current.material.uniforms.uTime.value -= delta * 0.5;
  });

  return (
    <mesh ref={mesh} position={[3, 12.5, 0]} scale={[1, 1, 1]} rotation={[15 * DEG2RAD, 0, -30 * DEG2RAD]}>
      <cylinderGeometry args={[8, 8, 25, 32, 1, true, 0, 360 * DEG2RAD]} />
      {/*<meshBasicMaterial color={'#00ff00'} side={DoubleSide} />*/}

      <shaderMaterial
        uniforms={uniforms}
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        transparent={true}
        blending={AdditiveBlending}
        depthWrite={false}
        depthTest={false}
        side={DoubleSide}
      />
    </mesh>
  );
}
