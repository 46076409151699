import styled, { css } from 'styled-components';
import { chat } from 'uiLivi/styles';
import { styledScrollbar } from 'common/layout';

export const StyledChatContent = styled.div`
  position: relative;
  height: ${props => (props.activeChat ? chat.defaultHeight : chat.defaultHeight + chat.textInputHeight)}px;
  width: 100%;
  flex-grow: ${props => (props.isFullHeight ? 1 : 0)};
  overflow-y: scroll;
  padding: 5px 10px;
  ${styledScrollbar};
  ${props =>
    props.isMobile &&
    css`
      height: ${props.vh * 100 - chat.textInputHeight - chat.titleHeight + 1}px;
    `};
`;
