import styled from 'styled-components';

const StyledFullScreenContentImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${props => props.src}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export default function FullScreenImage({ imgSrc }) {
  return <StyledFullScreenContentImage src={imgSrc} />;
}
