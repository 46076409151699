import React from 'react';
import Trees from '../../Common/Trees';

const CONFIG = {
  assets: {
    model: `/shared/districts/intersolar/common/trees/lobbyTree/1.glb`,
    dataTexture: `/shared/districts/intersolar/common/trees/lobbyTree/data.jpg`,
  },
  config: {
    windStrength: 0.05,
    baseWind: 0.1,
    speedFactor: 2.15,
    heightFactor: 0.35,
    blueFactor: 0.05,
    redFactor: 0.4,
    greenFactor: 1,
    noiseScale: 2,
  },
  leavesPivot: [-0.3, 4.8, 0.0],
  data: [
    {
      position: [-11.313, -0.537, -25.85],
      rotation: [0, 0.85, 0],
      scale: 0.025,
      timeShift: 1.6,
      overAllFactor: 1.4,
    },
  ],
};

export default React.memo(() => {
  return (
    <Trees
      data={CONFIG.data}
      leavesPivot={CONFIG.leavesPivot}
      assets={CONFIG.assets}
      config={CONFIG.config}
      renderOrder={-1}
    />
  );
});
