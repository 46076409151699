import styled, { css } from 'styled-components';
import { lgBaseColors } from 'uiLivi/styles';
import { textOverflowEllipsis } from 'common/text';
import { mediaMaxPx } from 'common/layout';

export const PersonContainer = styled.div`
  margin-top: 30px;
  cursor: pointer;
`;

export const SpeakerRole = styled.div`
  color: ${lgBaseColors.red.base};
  font-weight: bold;
`;

export const SpeakerName = styled.div`
  max-width: 400px;
  ${mediaMaxPx(
    600,
    css`
      max-width: 150px;
    `
  )}
  ${textOverflowEllipsis};
  font-weight: bold;
`;

export const SpeakerCompany = styled.div`
  color: ${lgBaseColors.grey.light};
`;
