import create from 'utilities/zustand/create';

export const useModalService = create((set, get) => ({
  modals: [],
  level: 'default',

  addModal: modal => {
    const { props } = modal;
    const stateModals = get().modals;
    const level = props && props.level ? props.level : 'default';
    const position = props && props.position ? props.position : 'default';
    set({ modals: [...stateModals, modal], level, position });
  },
  closeModal: () => {
    const { modals } = get();
    modals.shift();
    set({ modals: [...modals] });
  },
}));
