import { useInterval } from 'utilities/hooks';
import { useFrame, useThree } from '@react-three/fiber';
import { useStatsStore } from './store';

let dt = 0.0016;
export default function Measurement() {
  const three = useThree();

  useFrame((_, _dt) => {
    dt += (_dt - dt) * 0.1;
  });

  useInterval(() => {
    const fps = Math.round(1 / dt);
    const drawCalls = three.gl.info.render.calls;
    const triangles = three.gl.info.render.triangles;
    const partial = {
      fps,
      drawCalls,
      triangles,
      ...three.gl.info.memory,
      programs: three.gl.info.programs.length,
      renderMs: three.gl.info.renderMs?.toLocaleString('en-US', {
        minimumFractionDigits: 1,
      }),
    };
    useStatsStore.setState(partial);
  }, 250);

  return null;
}
