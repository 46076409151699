import styled from 'styled-components';
import { hexToRgba } from '../../../utilities/color';
import bigCircle from './assets/bigCircle.png';
import { colors } from '../../../uiLivi/styles';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: ${props => (props.dragging ? 'all' : 'none')};
  cursor: move;
  touch-action: none;
`;

export const CircleContainer = styled.div`
  position: absolute;
  bottom: 22px;
  right: ${props => (props.isChatOpen ? 380 : 75) + (props.isShiftedByVideoChat ? 300 : 0)}px;
  width: 30vw;
  height: 30vw;
  max-width: 200px;
  max-height: 200px;
  transition: right 0.2s ease;
`;

export const BigCircle = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: all;
  cursor: move;
`;

export const BigCircleImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: ${props => (props.dragging ? 1 : 0.8)};
  transition: opacity 0.3s;
  pointer-events: none;
  background-image: url(${bigCircle});
  background-size: contain;
  user-select: none;
`;

export const SmallCircle = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 45%;
  top: 27.5%;
  left: 27.5%;
  pointer-events: none;
  transition: transform 0s ease-out;
  transition-duration: ${props => (props.dragging ? 0 : 0.2)}s;

  border-radius: 50%;
  background: linear-gradient(140deg, rgb(196 119 232), ${hexToRgba(colors.accent.base, 1)} 50%);
  background: linear-gradient(140deg, rgb(196 119 232), ${hexToRgba(colors.accent.base, 1)} 50%);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2), inset -1px 1px 1px rgba(255, 206, 206, 0.2);
`;

export const InnerSmallCircle = styled.div`
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: linear-gradient(39.24deg, #7c73f3 31.19%, #3a3676 118.2%);
  box-shadow: 0 5px 27px -10px rgb(0 0 0);
`;
