const glsl = x => x;

export const vertexBody = glsl`
    vUvWind = uvWind;
    ////////////////////////////////////////////////////////////////
    // Extract the translation component
    mat4 translationMatrix = getTranslationMatrix(instanceMatrix);

    // Extract the rotation component
    mat4 rotationMatrix = getRotationMatrix(instanceMatrix);

    // Extract the scale component
    mat4 scaleMatrix = getScaleMatrix(instanceMatrix);

    ////////////////////////////////////////////////////////////////
    vec3 dataValues = texture2D(uDataTxt, uvWind).rgb;

    ///// UPDATE TRANSFORM
    vec4 customTransformed = rotationMatrix * vec4(transformed, 1.0);

    //// TIME
    float baseTime = uTime + aTimeShift;
    float asyncDelay = dataValues.g * uGreenFactor;
    float regTime = baseTime;
    float slowTime = baseTime + asyncDelay;

    float noise = texture2D(uNoiseTxt, vec2(vUv.x * uNoiseScale + slowTime * 0.5, vUv.y * uNoiseScale + slowTime * 0.2)).r;
    float time = noisyTime(slowTime);

    //// INTENSITY
    float intensity = uBaseWind + (1.0 - uBaseWind) * time;
    float time2 = noisyTime(regTime);
    float intensity2 = uBaseWind + (1.0 - uBaseWind) * time2;

    //// WIND
    vec3 windDirection = normalize(vec3(-0.8 + (time2 - 0.5) * 0.4, time2 * 0.0, (time - 0.5) * 0.2));

    //// HEIGHT BEND
    float heightFactor = smoothstep(0.0, uModelHeight, customTransformed.y);
    heightFactor = pow(heightFactor, 2.4) * uHeightFactor * 0.1; // vertical bend if needed

    //// FIX WIND FACTOR ON OPPOSITE DIRECTION WIND
    vec3 center = uLeavesPivot;
    vec3 rotatedCenter = (rotationMatrix * vec4(center, 1.0)).xyz;
    float fixStretchFactor = dot(
        normalize(vec3(customTransformed.x, 0.0, customTransformed.z) - vec3(rotatedCenter.x, 0.0, rotatedCenter.z)),
        normalize(vec3(windDirection.x, 0.0, windDirection.z))
    );

    fixStretchFactor = 1.0 - smoothstep(-0.55, 0.7, fixStretchFactor);
    vHeight = fixStretchFactor;
    fixStretchFactor = 0.35 + 0.65 * fixStretchFactor;

    float bendFactor = uStrength * dataValues.b * uBlueFactor;
    bendFactor *= 0.1 * fixStretchFactor;

    float wiggleFactor = dataValues.r * noise * uRedFactor;
    wiggleFactor *= 0.1;

    // ORDER MATTERS
    vec3 force = (windDirection * bendFactor) * intensity;
    force += (windDirection * heightFactor) * intensity2;
    force += (windDirection * wiggleFactor) * intensity2;
    force *= uNormalizedHeightFactor;
    force *= aOverAllFactor; // more dynamism between trees

    customTransformed.xyz += force;
    customTransformed.y -= length(force) * 0.20; // just bend it a little down to mimic IK

    mat4 finalMatrix = translationMatrix * scaleMatrix;
    vec4 mvPosition = modelViewMatrix  * finalMatrix * customTransformed;  // rotation already applied
    gl_Position = projectionMatrix * mvPosition;
`;
