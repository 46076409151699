import IconSound from './icons/icon-sound.svg';
import styled from 'styled-components';
import { useSoundStore } from '../../../../services/SoundService';
import { useTrackingStore } from '../../../../services/TrackingService';
import { useHowlerStore } from '../../../../services/HowlerService';
import SliceHoverEffect, { parentElementHoverCSS } from '../SliceHoverEffect';
import { useEffect, useRef } from 'react';
import { IconShadow } from '../index';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
  opacity: ${props => (props.enabled ? 1 : 0.5)};
  cursor: pointer;
  pointer-events: all;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.35);

  ${parentElementHoverCSS}
`;

export default function ButtonSound() {
  const enabledByUser = useSoundStore(state => state.enabled);
  const enabledBySystem = useSoundStore(state => state.isPlayAllowed);
  const playClickUiSound = useHowlerStore(state => state.playClickUiSound);
  const isHowlerEnabled = useHowlerStore(state => state.isHowlerEnabled);
  const enabled = enabledByUser && enabledBySystem;
  const didClick = useRef(false);

  useEffect(() => {
    if (isHowlerEnabled && enabled && didClick.current) {
      playClickUiSound();
    }
  }, [isHowlerEnabled, enabled]);

  return (
    <ButtonWrapper
      enabled={enabled}
      onClick={() => {
        didClick.current = true;
        if (enabledBySystem) {
          useTrackingStore.getState().trackEvent({ category: 'Sound', action: enabled ? 'Mute' : 'Unmute' });
          useSoundStore.getState().toggle();
        }
      }}
    >
      <IconShadow style={{ height: 16 }}>
        <IconSound style={{ marginRight: '8px' }} />
      </IconShadow>
      <SliceHoverEffect alt={!enabled ? 'SOUND ON' : 'SOUND OFF'}>
        {enabled ? 'SOUND ON' : 'SOUND OFF'}
      </SliceHoverEffect>
    </ButtonWrapper>
  );
}
