import { InstructionHeadline, InstructionIconContainer, InstructionText, InstructionWrapper } from '../../../../styles';
import IconOrientate from '../../../assets/orientate.png';
import React from 'react';
import styled from 'styled-components';

const InstructionIcon = styled.div`
  width: 71px;
  height: 71px;
  background-image: url(${IconOrientate});
  background-size: cover;
`;

export default function OnboardingOrientate() {
  return (
    <InstructionWrapper>
      <InstructionIconContainer>
        <InstructionIcon />
      </InstructionIconContainer>
      <InstructionHeadline>Tap the map.</InstructionHeadline>
      <InstructionText>To switch rooms and locations, just open the menu and click the map icon.</InstructionText>
    </InstructionWrapper>
  );
}
