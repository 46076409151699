import create from 'utilities/zustand/create';

export const useMenuStore = create((set, get) => ({
  items: [],
  burgerOpen: false,
  highlightedMenuItem: null,
  setMenuItemActive: (id, active) => {
    const { items } = get();
    const item = items.find(i => i.id === id);
    item.active = active;
    const changes = { items: [...items] };
    if (active) {
      changes.burgerOpen = true;
    }
    set(changes);
  },
  highlightMenuItem: id => {
    set({ highlightedMenuItem: id });
  },
  removeMenuItemHighlights: () => {
    set({ highlightedMenuItem: null });
  },
  setBurgerOpen: value => {
    set({ burgerOpen: value });
  },
}));
