import React, { useEffect, useState } from 'react';
import { useChatStore } from 'services/ChatService';
import { useWindowStore } from 'services/WindowService';
import ChatTitle from 'uiLivi/components/Chat/ChatTitle';
import ChatMessageContainer from 'uiLivi/components/Chat/ChatMessageContainer';
import ChatOverview from 'uiLivi/components/Chat/ChatOverview';
import ChatInput from 'uiLivi/components/Chat/ChatInput';
import ChatCreator from 'uiLivi/components/Chat/ChatCreator';
import ChatContent from 'uiLivi/components/Chat/ChatContent';
import ChatUserList from 'uiLivi/components/Chat/ChatUserList';
import ChatMessageHint from 'uiLivi/components/Chat/ChatMessageHint';
import ChatPendingOverlay from 'uiLivi/components/Chat/ChatPendingOverlay';
import { StyledChatContainer, StyledChatContent, StyledInputContainer } from './styles';

const ChatContainer = ({ chatWidth }) => {
  const activeChat = useChatStore(state => state.activeChat);
  const chatsAsc = useChatStore(state => state.chats);
  const chats = [...chatsAsc].reverse();
  const isChatOpen = useChatStore(state => state.isActive);
  const isFullHeight = useChatStore(state => state.isFullHeight);
  const isMobile = useWindowStore(state => state.isMobile);
  const isContent = useChatStore(state => state.chatType === 'content');
  const vh = useWindowStore(state => state.vh);
  const useFullHeight = isFullHeight || isMobile;

  const [createNewChat, setCreateNewChat] = useState(false);
  const [chatList, setChatList] = useState([]);

  const updateChatList = users => {
    setChatList(users);
  };

  const toggleNewChat = () => {
    setCreateNewChat(!createNewChat);
  };

  const startNewChat = () => {
    if (chatList.length === 0) return;
    const userIds = chatList.reduce((acc, curr) => {
      acc.push(curr.id);
      return acc;
    }, []);
    useChatStore.getState().start(userIds);
  };

  const content = () => {
    return createNewChat ? (
      <ChatCreator key={'creator'} updateChatList={updateChatList} />
    ) : activeChat ? (
      <ChatMessageContainer key={'messages'} chat={activeChat} isFullHeight={useFullHeight || isContent} />
    ) : (
      <ChatOverview key={'overview'} chats={chats} />
    );
  };

  useEffect(() => {
    if (activeChat && createNewChat) toggleNewChat();
  }, [activeChat]);

  return (
    <StyledChatContainer vh={vh} chatWidth={chatWidth} isContent={isContent} show={isChatOpen}>
      <StyledChatContent isFullHeight={useFullHeight || isContent} isContent={isContent}>
        <ChatTitle
          isContent={isContent}
          canGoBack={activeChat || createNewChat}
          createNewChat={createNewChat}
          toggleNewChat={toggleNewChat}
        />
        <ChatContent isContent={isContent} isFullHeight={useFullHeight || isContent}>
          {content()}
        </ChatContent>
        <ChatPendingOverlay isContent={isContent} />
        <StyledInputContainer activeChat={activeChat} isContent={isContent}>
          {activeChat && (
            <>
              <ChatMessageHint />
              <ChatInput maxCharacters={90} expandAfterCharacters={20} isContent={isContent} />
            </>
          )}
          {createNewChat && <ChatUserList chatList={chatList} startNewChat={startNewChat} />}
        </StyledInputContainer>
      </StyledChatContent>
    </StyledChatContainer>
  );
};

export default ChatContainer;
