import React, { useEffect, useMemo, useState } from 'react';
import TalkDetail from './Detail/TalkDetail';
import TimetableGrid from './TimetableGrid';
import TabNavigation from 'common/components/TabNavigation';
import HBFLayout from 'common/components/HBFLayout';
import useOpenCloseTracking from 'services/TrackingService/useOpenCloseTracking';
import TimetableFilter from './TimetableFilter';
import IconCalender from 'assets/icons/Icon_Calender.svg';

import { filterTalks, getAllowedDatesFromTalks, mapTimetableEntryGroupsFromTalks } from './helper';
import { BodyContainer } from './styles';
import { useTheaterStore } from 'services/TheaterService';
import moment from 'moment/moment';
import { marginTopAndButton } from '../../../uiLivi/components/Menu/styles';
import { closeButtonSize } from '../../../uiLivi/components/Menu/SubMenu/styles';
import { useTranslate } from 'services/TranslationService/hooks';

function Timetable({ updateSize = () => {}, columns = 2, preventScroll = false }) {
  const theaterTalks = useTheaterStore(state => state.theaterTalks);
  const translate = useTranslate();

  const [activeTab, setActiveTab] = useState('talks');
  const [activeTalkId, setActiveTalkId] = useState(null);
  const [onlyBookmarks, setOnlyBookmarks] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [allowedDates, setAllowedDates] = useState([]);

  useEffect(updateSize, [activeTab, activeTalkId, updateSize]);
  useOpenCloseTracking('Schedule');

  const filteredTalks = useMemo(() => {
    const context = { selectedDate, onlyBookmarks };
    return filterTalks(theaterTalks, context);
  }, [theaterTalks, selectedDate, onlyBookmarks]);

  const timetableEntryGroups = useMemo(() => {
    return activeTab === 'talks' ? mapTimetableEntryGroupsFromTalks(filteredTalks, setActiveTalkId) : null;
  }, [activeTab, filteredTalks]);

  useEffect(() => {
    return () => {
      setActiveTalkId(null);
    };
  }, []);

  useEffect(() => {
    let allowedDates = [];
    if (activeTab === 'talks' && theaterTalks.length > 0) {
      allowedDates = getAllowedDatesFromTalks(theaterTalks);
    }

    // pick today or the closest possible date in the future
    const isSameOrAfterToday = date => moment(new Date()).isSameOrBefore(date, 'day');
    const todayOrFutureDates = [...allowedDates].filter(isSameOrAfterToday);
    let initialDate = todayOrFutureDates.length > 0 ? todayOrFutureDates[0] : null;

    if (initialDate == null && allowedDates.length > 0) {
      // pick the last possible date after the event
      initialDate = allowedDates[allowedDates.length - 1];
    }

    setSelectedDate(initialDate);
    setAllowedDates(allowedDates.length === 0 ? null : allowedDates);
  }, [activeTab]);

  const isInDetailView = activeTalkId !== null;
  const showFilter = activeTab === 'talks';
  const header = (
    <>
      <TabNavigation
        tabs={[{ id: 'talks', title: translate('schedule.talks.title') || 'Talks', icon: IconCalender }]}
        activeTab={activeTab}
        setActiveTab={tab => {
          setActiveTalkId(null);
          setActiveTab(tab);
        }}
      />
      {!isInDetailView && showFilter && (
        <TimetableFilter
          dateOptions={{ value: selectedDate, set: setSelectedDate, allowedDates }}
          checkboxOptions={
            activeTab === 'talks'
              ? [
                  {
                    label: translate('schedule.filter.talks') || 'All Talks',
                    value: !onlyBookmarks,
                    toggle: () => {
                      setOnlyBookmarks(false);
                    },
                  },
                  {
                    label: translate('schedule.filter.bookmarks') || 'Only Bookmarks',
                    value: onlyBookmarks,
                    toggle: () => {
                      setOnlyBookmarks(true);
                    },
                  },
                ]
              : null
          }
        />
      )}
    </>
  );

  const talk = useMemo(() => (activeTalkId != null ? theaterTalks.find(m => m.id === activeTalkId) : null), [
    activeTalkId,
    theaterTalks,
  ]);

  const body = (
    <BodyContainer>
      {talk != null ? (
        <TalkDetail talk={talk} setActiveTalkId={setActiveTalkId} />
      ) : (
        <TimetableGrid
          columns={columns}
          entryGroups={timetableEntryGroups}
          noEventText={
            activeTab === 'talks'
              ? translate('schedule.talks.notalks') || 'There are no sessions available for your selected date.'
              : ''
          }
          updateSize={updateSize}
        />
      )}
    </BodyContainer>
  );

  return (
    <HBFLayout
      header={header}
      body={body}
      fullHeightMinus={marginTopAndButton * 2 + closeButtonSize}
      preventScroll={preventScroll}
    />
  );
}

export default Timetable;
