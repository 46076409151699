import create from 'utilities/zustand/create';
import { getGPUTier } from 'detect-gpu';

export const useGpuDetect = create(set => ({
  tier: 2,
  isMobile: true,

  async init() {
    const gpu = await getGPUTier();
    set({ tier: gpu.tier, isMobile: gpu.isMobile });
  },
}));
