import IconCheck from './Icons/icon_check.svg';
import styled from 'styled-components';
import { easings, lgBaseColors } from 'uiLivi/styles';

export const IndicatorContainer = styled.div`
  position: absolute;
  bottom: 2px;
  right: -3px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: ${lgBaseColors.red.base};
  transition: transform 0.2s ${easings.easeOutStrong};
  transform: scale(${props => (props.visible ? 1 : 0)});
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  box-shadow: 0px 2px 5px rgba(44, 52, 73, 0.1);
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 1px;
  left: 3.5px;
`;

export default function Indicator({ showCheckIcon }) {
  return (
    <IndicatorContainer visible={showCheckIcon}>
      {showCheckIcon && (
        <IconContainer>
          <IconCheck />
        </IconContainer>
      )}
    </IndicatorContainer>
  );
}
