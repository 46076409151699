import useBranding from '../features/useBranding';
import usePatchFoliage from '../features/usePatchFoliage';
import useCloneOrg from '../features/useCloneOrg';
import useMakeInvisible from '../features/useMakeInvisible';
import useConfigureShadows from '../features/useConfigureShadows';
import useConfigureLightmaps from '../features/useConfigureLightmaps';
import usePatchPortal from '../features/usePatchPortal';
import useConfigureEnvMap from '../features/useConfigureEnvMap';

export default function LobbyMaterials() {
  useCloneOrg();
  useMakeInvisible();
  useConfigureShadows();
  useBranding();
  useConfigureLightmaps();
  useConfigureEnvMap();
  usePatchFoliage();
  usePatchPortal();
}
