export const headerPaddingTopDesktop = 118;
export const headerPaddingTopMobile = 80;

export const aboutHeightDesktop = 916;
export const aboutHeightMobile = 674;

export const VIEWPORT_S_MAX = 750;

export const VIEWPORT_M_MIN = 800;

export const VIEWPORT_L_MIN = 1200;
export const VIEWPORT_L_MAX = 1800;
