import styled, { css } from 'styled-components';
import { mediaMin } from 'common/layout';
import { lgBaseColors } from 'uiLivi/styles';

export const StyledBouncePage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  color: ${lgBaseColors.grey.light};

  *:not(img) {
    padding: 0 10vw;
    font-size: 14px;
    line-height: 1.15;
    ${mediaMin(
      'medium',
      css`
        padding: 0;
        font-size: 20px;
        line-height: 1.5;
      `
    )}
  }
`;

export const StyledLogo = styled.img`
  width: 90%;
  ${mediaMin(
    'medium',
    css`
      width: 50%;
    `
  )}
`;

export const StyledCopy = styled.p`
  margin-top: 20px;
`;
