import { StyledBar, StyledChatBar } from './styles';
import ChatButton from 'uiLivi/components/Chat/ChatButton';
import ChatEmotes from 'uiLivi/components/Chat/ChatEmotes';
import { useWindowStore } from 'services/WindowService';
import { useEventStore } from 'services/EventService';

const ChatBar = ({ visible, chatWidth, isActive, isContentChat }) => {
  const vh = useWindowStore(state => state.vh);
  const useAvatars = useEventStore(state => state.event.useAvatars);

  return (
    <StyledChatBar
      vh={vh}
      chatWidth={chatWidth}
      isContentChat={isContentChat}
      useOffsetLeft={visible && isActive}
      showChatToggle={!isActive || isContentChat}
    >
      <StyledBar>{useAvatars ? <ChatEmotes show={true} /> : null}</StyledBar>
      <ChatButton isContentChat={isContentChat} />
    </StyledChatBar>
  );
};

export default ChatBar;
