import styled from 'styled-components';

export const OptionIndicatorWrapper = styled.div``;

export const Bar = styled.div`
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  background-color: rgba(255, 255, 255, 0.4);
  overflow: hidden;
`;

export const BarProgress = styled.div`
  background-color: white;
  height: 100%;
  border-radius: 2.5px;
  width: ${({ progress }) => progress * 100}%;
`;
