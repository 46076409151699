import styled, { css, keyframes } from 'styled-components';
import { colors } from 'uiLivi/styles';
import { mediaMin } from 'common/layout';
import { smallTextStyle, textOverflowEllipsis } from 'common/text';
import { cardMarginPx } from '../../styles';

const appear = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;

export const CardContainer = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: relative;
  animation: ${appear} 0.4s linear forwards;
  animation-delay: ${props => props.index * 0.1}s;
  opacity: 0;
  background: ${colors.background.highlight};
  margin: ${cardMarginPx};
  width: calc(100% - 16px);
  display: flex;
  flex-direction: column;
  ${mediaMin(
    'medium',
    css`
      width: calc(${props => 100 / props.columns}% - 16px);
      animation-delay: ${props => props.row * 0.1 + props.column * 0.1}s;
    `
  )};
  min-height: 146px;
  cursor: pointer;
`;

export const CardTimeOverOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;
  pointer-events: none;
`;

export const Card = styled.div`
  padding: 16px;
  flex-grow: 1;
`;

export const Headline = styled.div`
  font-weight: bold;
  line-height: 25px;
`;

export const Topline = styled.div`
  ${textOverflowEllipsis}
  ${smallTextStyle};
  line-height: 16px;
  min-height: 16px;
  margin-top: 10px;
  max-width: 90%;
`;

export const Footer = styled.div`
  ${smallTextStyle};
  text-align: center;
  color: ${colors.accent.base};
  margin-bottom: 10px;
`;

export const LowerContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

export const LiveContainer = styled.div`
  position: absolute;
  top: -1px;
  right: 0;
`;
