import { useAvatarStore } from 'services/AvatarService';
import { DotContainer, Dot } from './styles';

export default function CategoryDot({ category }) {
  const selectCustomizationCategory = useAvatarStore(state => state.selectCustomizationCategory);
  return (
    <DotContainer
      onClick={() => {
        selectCustomizationCategory(category.id, !category.selected);
      }}
    >
      <Dot selected={category.selected} />
    </DotContainer>
  );
}
