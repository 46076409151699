import create from 'utilities/zustand/create';
import { breakPoints } from 'common/layout';
import debounce from 'utilities/debounce';
import { prefixEventListener } from 'utilities/prefixed-events';

export const useWindowStore = create(set => ({
  width: 0,
  height: 0,
  vh: 0,
  hover: false,
  breakpoint: '',
  //TODO isMobile should be isMobileBreakpoint
  isMobile: false,
  landscape: true,
  supportsTouch: 'ontouchstart' in window || navigator.msMaxTouchPoints,
  setHover: hover => {
    set({ hover });
  },

  init: () => {
    const onResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const landscape = width > height;
      let breakpoint = '';
      for (const bpKey in breakPoints) {
        const bp = breakPoints[bpKey];
        if (bp.min <= width && bp.max >= width) breakpoint = bpKey;
      }
      const isMobile = breakpoint === 'small' || breakpoint === 'extraSmall';
      const vh = window.innerHeight * 0.01;
      set({ width, height, breakpoint, landscape, isMobile, vh });
    };

    prefixEventListener(window, 'resize', debounce(onResize, 250));
    onResize();
  },
}));
