import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider, DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import format, { FORMAT_DATE } from 'utilities/date/format';
import {
  ArrowIconContainer,
  CalendarIconContainer,
  DeleteIconContainer,
  DropdownButton,
  DropdownContainer,
  DropdownOptionList,
} from './styles';
import { useDocumentClickListener } from 'utilities/hooks';
import CalendarIcon from 'assets/icons/Icon_Calender.svg';
import ArrowIcon from 'assets/icons/Icon_Arrow_Down.svg';
import DeleteIcon from 'assets/icons/Icon_Delete.svg';
import moment from 'moment';
import { useTranslate } from 'services/TranslationService/hooks';
import MomentUtils from '@date-io/moment';
import { datepickerTheme } from './datepickerTheme';

function YearScroll({ date, handleChange, allowedDates = null }) {
  const shouldDisableDate = date => {
    const foundDate = allowedDates.find(d => moment(d).isSame(date, 'day'));
    return !foundDate;
  };
  const allowAllDates = () => false;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={datepickerTheme}>
        <MaterialDatePicker
          variant="static"
          value={date}
          onChange={handleChange}
          animateYearScrolling
          disableToolbar
          shouldDisableDate={allowedDates === null ? allowAllDates : shouldDisableDate}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

function AbsoluteYearScroll(props) {
  return (
    <DropdownOptionList open={true} numItems={1}>
      <YearScroll {...props} />
    </DropdownOptionList>
  );
}

function DatePicker(props) {
  const { date, onChange, hasStaticPosition, onOpenClose, clearable } = props;
  const fDate = date ? format(date, FORMAT_DATE) : null;
  const outerContainerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    if (onOpenClose) onOpenClose();
  }, [open]);

  const handleChange = newDate => {
    if (date) setOpen(false);
    if (newDate !== date) {
      onChange(newDate);
    }
  };

  const handleClear = event => {
    event.stopPropagation();
    onChange(null);
  };

  useDocumentClickListener(
    open,
    () => {
      setOpen(false);
    },
    outerContainerRef
  );

  return (
    <DropdownContainer ref={outerContainerRef}>
      <DropdownButton open={open} onClick={() => setOpen(!open)}>
        <CalendarIconContainer open={open}>
          <CalendarIcon />
        </CalendarIconContainer>
        {fDate ? fDate : translate('datepicker.cta') || 'Select date'}
        {clearable && fDate && (
          <DeleteIconContainer onClick={handleClear}>
            <DeleteIcon />
          </DeleteIconContainer>
        )}
        <ArrowIconContainer>
          <ArrowIcon />
        </ArrowIconContainer>
      </DropdownButton>
      {open && (
        <>
          {hasStaticPosition ? (
            <YearScroll {...props} handleChange={handleChange} />
          ) : (
            <AbsoluteYearScroll {...props} handleChange={handleChange} />
          )}
        </>
      )}
    </DropdownContainer>
  );
}

export default DatePicker;
