import { Container, Date, Time } from './styles';
import format, { FORMAT_DATE, FORMAT_TIME, FORMAT_TIME_AND_ZONE } from 'utilities/date/format';

export default function DateAndTime({ startDate, endDate, align = 'left' }) {
  const fDate = format(startDate, FORMAT_DATE);
  const fStartTime = format(startDate, FORMAT_TIME);
  const fEndTime = format(endDate, FORMAT_TIME_AND_ZONE);
  return (
    <Container align={align}>
      <Date>{fDate}</Date>
      <Time>
        {fStartTime} &ndash; {fEndTime}
      </Time>
    </Container>
  );
}
