import React, { Suspense, useRef } from 'react';
import AnimationController from 'components/Play/Character/AnimationController';
import { globalPlayers, useGlobalHubStore } from 'services/GlobalHubService';
import { interpolatePosRot } from 'utilities/physics';
import { useUserStore } from 'services/UserService';
import ParticleFX from '../ParticleFX';
import PlayerEmojiFX from '../PlayerEmojiFX';
import TeleportFX from '../TeleportFX';
import CharacterHitVolume from '../CharacterHitVolume';
import GlobalPlayerNamePlate from './GlobalPlayerNamePlate';

const GlobalPlayer = ({ isFitting, userId }) => {
  const containerRef = useRef();
  const group = useRef();
  const namePlateRef = useRef();

  const scale = 1.0;

  const user = useUserStore(state => state.user);
  const isSelf = user.id === userId;
  const isSelfAndFitting = !isSelf && isFitting;

  const appearance = useGlobalHubStore(state => state.users[userId].appearance);
  const userAppearance = useUserStore(state => state.player.user.appearance);
  const reaction = useGlobalHubStore(state => state.users[userId].reaction);

  const pos = useRef(null);
  const vel = useRef(null);

  const onPlayerClick = e => {
    e.stopPropagation();
    useUserStore.getState().selectUserById(userId);
  };

  if (isSelfAndFitting) return null;

  return (
    <group ref={containerRef} name={'GlobalPlayer_' + userId} visible={false}>
      <group ref={group} name="Avatar">
        <Suspense fallback={null}>
          <ParticleFX reactionId={reaction} />
          <PlayerEmojiFX userId={userId} />
          <TeleportFX reactionId={reaction} />
          <AnimationController
            group={group}
            isSelf={isSelf}
            getPlayerData={dt => {
              containerRef.current.visible = true;
              const playerData = globalPlayers[userId];
              if (!playerData) {
                // eslint-disable-next-line no-console
                console.warn('unhandled error, playerData is null', userId);
                return null;
              }

              if (pos.current === null) pos.current = [...playerData.position];
              if (vel.current === null) vel.current = [...playerData.velocity];

              const instantUpdate = reaction === 10 || dt > 0.5;

              if (instantUpdate) {
                pos.current = playerData.position;
                return playerData;
              }

              if (!isSelf) {
                const { position, rotation } = playerData;
                const res = interpolatePosRot(pos.current, rotation, position, rotation, vel.current, dt);

                if (namePlateRef.current) {
                  namePlateRef.current.position.set(res.position[0], res.position[1] + 2.0, res.position[2]);
                }

                return {
                  ...playerData,
                  position: res.position,
                  rotation: res.rotation,
                  velocity: res.velocity,
                };
              } else {
                return playerData;
              }
            }}
            scale={[scale, scale, scale]}
            reactionId={reaction}
            appearance={isSelf ? userAppearance : appearance}
          />
          {!isSelf && <CharacterHitVolume handleClick={onPlayerClick} name={'expert'} />}
        </Suspense>
      </group>
      {!isSelf && <GlobalPlayerNamePlate userId={userId} internalRef={namePlateRef} />}
    </group>
  );
};

export default function GlobalPlayers({ isFitting }) {
  const userIds = useGlobalHubStore(state => state.userIds);
  if (userIds.length === null) return null;
  return (
    <group name="Global Players">
      {userIds.map((userId, i) => (
        <GlobalPlayer isFitting={isFitting} userId={userId} key={userId} />
      ))}
    </group>
  );
}
