import { useLayoutEffect, useMemo, useRef } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import noiseTexture from './assets/noise-fbm.png';
import { AdditiveBlending, Color, DoubleSide, RepeatWrapping, TextureLoader } from 'three';
import vertexShader from './glsl/fakeRaysVert.glsl';
import fragmentShader from './glsl/fakeRaysFrag.glsl';
import { DEG2RAD } from 'three/src/math/MathUtils';
// import { useControls } from 'leva';

export default function Rays() {
  const mesh = useRef(null);
  const map = useLoader(TextureLoader, noiseTexture);

  const uniforms = useMemo(
    () => ({
      uTime: { value: 0 },
      uBaseAlpha: { value: 0.61 },
      uGlowAlpha: { value: 0.18 },
      uTopFade: { value: 0.13 },
      uRaysColor: { value: new Color('#868686') },
      uGlowColor: { value: new Color('#ffea9e') },
      uRaysStepMin: { value: 0.21 },
      uRaysStepMax: { value: 0.54 },
      uGlowStepMin: { value: 0.38 },
      uTexture: { value: map },
      m_uv_x: { value: 3.0 },
    }),
    []
  );

  useLayoutEffect(() => {
    map.wrapS = RepeatWrapping;
  }, []);

  // const {
  //   uTime,
  //   uRaysColor,
  //   uGlowColor,
  //   uBaseAlpha,
  //   uGlowAlpha,
  //   uTopFade,
  //   uRaysStepMin,
  //   uRaysStepMax,
  //   uGlowStepMin,
  //   m_uv_x,
  //   cylinder_pos,
  // } = useControls({
  //   // uTime: { value: 0, min: -50, max: 50, step: 0.01 },
  //   uRaysColor: { value: '#626262' },
  //   uGlowColor: { value: '#cbac3f' },
  //   uBaseAlpha: { value: 1, min: 0, max: 1, step: 0.01 },
  //   uGlowAlpha: { value: 1, min: 0, max: 1, step: 0.01 },
  //   uTopFade: { value: 0.3, min: 0, max: 1, step: 0.01 },
  //   uRaysStepMin: { value: 0.4, min: 0, max: 1, step: 0.01 },
  //   uRaysStepMax: { value: 0.7, min: 0, max: 1, step: 0.01 },
  //   uGlowStepMin: { value: 0.4, min: 0, max: 1, step: 0.01 },
  //   m_uv_x: { value: 3, min: 0, max: 5, step: 0.01 },
  //   cylinder_pos: {
  //     value: [0.6, 7.0, -12],
  //     step: 0.1,
  //   },
  // });

  useFrame((state, delta, frame) => {
    mesh.current.material.uniforms.uTime.value -= delta * 0.5;
    // mesh.current.material.uniforms.uTime.value = uTime;
    // mesh.current.material.uniforms.uRaysColor.value = new Color(uRaysColor);
    // mesh.current.material.uniforms.uGlowColor.value = new Color(uGlowColor);
    // mesh.current.material.uniforms.uBaseAlpha.value = uBaseAlpha;
    // mesh.current.material.uniforms.uGlowAlpha.value = uGlowAlpha;
    // mesh.current.material.uniforms.uTopFade.value = uTopFade;
    //
    // mesh.current.material.uniforms.uRaysStepMin.value = uRaysStepMin;
    // mesh.current.material.uniforms.uRaysStepMax.value = uRaysStepMax;
    // mesh.current.material.uniforms.uGlowStepMin.value = uGlowStepMin;
    //
    // mesh.current.material.uniforms.m_uv_x.value = m_uv_x;
  });

  return (
    <mesh ref={mesh} position={[0.6, 7.0, -12.0]} scale={[1, 1, 1]} rotation={[15 * DEG2RAD, 0, -30 * DEG2RAD]}>
      <cylinderGeometry args={[15, 15, 25, 32, 1, true, 0, 360 * DEG2RAD]} />

      {/*<meshBasicMaterial color={'#00ff00'} side={DoubleSide} />*/}

      <shaderMaterial
        uniforms={uniforms}
        fragmentShader={fragmentShader}
        vertexShader={vertexShader}
        transparent={true}
        blending={AdditiveBlending}
        depthWrite={false}
        depthTest={false}
        side={DoubleSide}
      />
    </mesh>
  );
}
