import { BodyContainer, FooterContainer, HeaderContainer, OuterContainer } from './styles';
import { useWindowStore } from 'services/WindowService';

export default function HBFLayout({
  header,
  body,
  footer,
  maxHeight = false,
  fullHeightMinus = 0,
  takeFullHeight = false,
  preventScroll = false,
  bodyShadow = false,
  positionedBottom = false,
}) {
  const vh = useWindowStore(state => state.vh);
  return (
    <OuterContainer
      takeFullHeight={takeFullHeight}
      positionedBottom={positionedBottom}
      maxHeight={maxHeight ? maxHeight : preventScroll ? `none` : `${vh * 100 - fullHeightMinus}px`}
    >
      {header && <HeaderContainer>{header}</HeaderContainer>}
      {body && (
        <BodyContainer showShadow={bodyShadow} preventScroll={preventScroll}>
          {body}
        </BodyContainer>
      )}
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </OuterContainer>
  );
}
