import { useContentStore } from '../../services/ContentService';
import { useSoundStore } from '../../services/SoundService';
import { useDistrictWarmupStore, WarmupPhase } from '../../services/DistrictWarmupService';
import { useProductAudioStore } from '../../services/ProductAudioService';
import { useEffect } from 'react';
import { useNavigationStore } from '../../services/NavigationService';

export function useAudioEnabledState() {
  const content = useContentStore(state => state.activeContent);
  const enabledByUser = useSoundStore(state => state.enabled);
  const enabledBySystem = useSoundStore(state => state.isPlayAllowed);
  const isLoaded = useDistrictWarmupStore(state => state.phase === WarmupPhase.DONE);
  const showProductInfoLayer = useNavigationStore(state => state.showProductInfoLayer);

  return isLoaded && enabledByUser && enabledBySystem && !content && !showProductInfoLayer;
}

export function usePlayAmbientHowls(howlNames = null, { fadeInDuration = 2, fadeOutDuration = 2 } = {}) {
  const howls = useProductAudioStore(state => state.howls);
  const enabled = useAudioEnabledState();

  useEffect(() => {
    if (!enabled) return;

    const howlsToPlay = howlNames === null ? howls : howls.filter(h => howlNames.includes(h.name));

    howlsToPlay.forEach(howl => {
      console.log('Playing ambient sound: ' + howl.name);
      howl.off('fade');
      if (!howl.playing()) {
        howl.volume(0);
        howl.play();
      }
      howl.fade(howl.volume(), 1, fadeInDuration * 1000);
    });
    return () => {
      howlsToPlay.forEach(howl => {
        console.log('Pausing ambient sound: ' + howl.name);
        howl.off('fade');
        howl.fade(howl.volume(), 0, fadeOutDuration * 1000);
        howl.once('fade', () => howl.pause());
      });
    };
  }, [howls, enabled, howlNames]);
}
