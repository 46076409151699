import styled, { css } from 'styled-components';
import { colors } from 'uiLivi/styles';
import { Button } from 'common/components/Button';
import { NavLink } from 'react-router-dom';
import { menuPaddingPx } from '../styles';

export const Container = styled.div`
  position: relative;
  padding: 0 ${menuPaddingPx} 20px;
`;

export const Item = styled.div`
  cursor: pointer;
  font-size: 10px;
  user-select: none;
  padding: 4px;
  margin: 5px 0;
  color: #000000;
  background-color: ${props => (props.selected ? colors.accent.base : 'white')};
`;

export const ItemSmall = styled.div`
  display: inline-table;
  cursor: pointer;
  font-size: 10px;
  user-select: none;
  padding: 4px;
  margin: 5px 0px;
  text-align: center;
  color: #000000;
  background-color: ${props => (props.selected ? colors.accent.base : 'white')};
`;

export const DevButton = styled(Button)`
  display: inline;
  margin: 5px;
`;

const secondLevelItemStyle = css`
  padding: 0 20px;
  display: block;
  position: relative;
  line-height: 30px;
  color: ${props => (props.main === 1 ? colors.font.strong : colors.font.light)};
  font-weight: bold;
  font-size: 14px;
`;

const activeClassName = 'you-are-here';
export const SecondLevelLinkItem = styled(NavLink).attrs({ activeClassName })`
  ${secondLevelItemStyle};
  cursor: pointer;
  &.${activeClassName} {
    background-color: ${colors.background.highlight};
  }
`;
