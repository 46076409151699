import create from 'utilities/zustand/create';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';

export function createContentFromTalk(talk) {
  const { providerType } = talk;
  const type = providerType === 'HLS_STREAM' ? CONTENT_TYPE.LIVESTREAM : CONTENT_TYPE.ZOOM;
  return { type, talk, headline: talk.title };
}

export const useContentStore = create((set, get) => ({
  activeContent: null,
  focusContent: null,
  contents: [],
  screens: [],

  setContents: contents => {
    set({ contents: contents });
  },

  setActiveContent: content => {
    set({ activeContent: content });
  },

  clearActiveContent: () => {
    set({ activeContent: null, focusContent: null });
  },

  setFocusContent: focusContent => {
    set({ focusContent: focusContent });
  },

  logout: () => {
    get().clearActiveContent();
  },
}));
