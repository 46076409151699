import create from 'utilities/zustand/create';
import axios from 'axios';
import { useTalkStore } from 'services/TalkService';

const CancelToken = axios.CancelToken;

const getUrlsFromPlaylistUrl = async url => {
  const source = CancelToken.source();
  let result = false;
  try {
    await axios.get(url, {
      cancelToken: source.token,
      onDownloadProgress: e => {
        if (e.target.status === 200) {
          source.cancel('Operation canceled by the user.');
          result = true;
        }
      },
    });
  } catch (e) {
    //no op
  }
  if (!result) throw 'no valid stream';
  return true;
};

export const useLiveStreamStore = create((set, get) => ({
  liveStreams: {},
  override: false,
  init: () => {
    const { talks } = useTalkStore.getState();
    const { updateLiveStream, liveStreams: oldLiveStreams } = get();
    Object.values(oldLiveStreams).forEach(liveStream => {
      liveStream.canceled = true;
    });

    const liveStreams = {};
    talks.forEach(talk => {
      if (!talk.providerPayload || talk.providerType !== 'HLS_STREAM') {
        return;
      }
      const url = talk.providerPayload.liveStream.url;
      const valid = oldLiveStreams[url] ? oldLiveStreams[url].valid : true;
      if (!liveStreams[url]) {
        liveStreams[url] = { valid, url };
      }
    });
    set({ liveStreams });

    Object.values(liveStreams).forEach(liveStream => {
      updateLiveStream(liveStream);
    });
  },

  updateLiveStream: async liveStream => {
    const { updateLiveStream, override } = get();
    if (override) {
      liveStream.valid = false;
    } else {
      try {
        await getUrlsFromPlaylistUrl(liveStream.url);
        liveStream.valid = true;
      } catch (error) {
        liveStream.valid = false;
      }
    }

    if (!liveStream.canceled) {
      liveStream.updater = setTimeout(() => {
        updateLiveStream(liveStream);
      }, 10000);
      set({ liveStreams: { ...get().liveStreams } });
    }
  },
  getStreamValid: url => {
    const { liveStreams } = get();
    return liveStreams[url] && liveStreams[url].valid;
  },
}));
