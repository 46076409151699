import create from 'utilities/zustand/create';

import prolineRoofHooks_CA from './images/ProlineRoofHooks_CA.png';
import trackingSystem1p_CA from './images/TrackingSystem1p_CA.png';
import image3 from './images/FixGrid_CA.png';

export const productsData = [
  { name: 'Tracking System 1P', type: 'Ground-Mounted', id: 'TrackingSystem', image: trackingSystem1p_CA },
  { name: 'ProLine Residential', type: 'Pitched Roof', id: 'RoofHooks', image: prolineRoofHooks_CA },
  { name: 'ProLine Commercial & Industrial', type: 'Flat Roof', id: 'FixGridPro', image: image3 },
];

export const Models = {
  TrackingSystem: {
    targets: [
      {
        id: 'TrackingSystem_Girders',
        text: 'Girders',
        hotspotPosition: [-0.054, 2.051, 3.911],
        targetPosition: [-0.042, 1.995, 3.849],
        // cameraPosition: [0.871, 2.139, 2.553],
        cameraPosition: [0.74, 2.374, 2.864],
      },
      {
        id: 'TrackingSystem_Posts',
        text: 'Posts',
        hotspotPosition: [-0.048, 1.327, 3.176],
        targetPosition: [-0.041, 1.399, 3.21],
        cameraPosition: [0.369, 1.598, 4.417],
      },
      {
        id: 'TrackingSystem_Purlins',
        text: 'Purlins',
        hotspotPosition: [-1.537, 2.278, 4.697],
        targetPosition: [-1.537, 2.278, 4.697],
        cameraPosition: [-1.189, 2.369, 5.788],
      },
      {
        id: 'TrackingSystem_Drive units',
        text: 'Drive units',
        hotspotPosition: [-0.054, 3.04, 3.777],
        targetPosition: [-0.058, 2.906, 3.822],
        cameraPosition: [0.518, 2.981, 2.981],
      },
      {
        id: 'TrackingSystem_PV modules',
        text: 'PV modules',
        hotspotPosition: [0.421, 3.92, 1.473],
        targetPosition: [0.0, 3.819, 0.0],
        cameraPosition: [2.588, 3.561, -2.236],
      },
      {
        id: 'TrackingSystem_Tracking Control Unit',
        text: 'Tracking Control Unit',
        hotspotPosition: [0.365, 2.242, 2.693],
        targetPosition: [0.365, 2.169, 2.348],
        cameraPosition: [1.567, 2.339, 2.925],
      },
    ],
    buildInstructions: [
      // {
      //   headline: 'The Posts',
      //   copy:
      //     'Anchor the posts at an angle perpendicular to the ground and at the depth detailed in the structural calculations. ',
      // },
      {
        headline: 'Step 1:\nThe Girders & TCU',
        copy:
          'Secure the girder assembly to the lower slotted hole of the ram support so that the holes of the post extension, post, and locking plate are aligned, with the locking plate in the middle and the cam on the closed side of the ramming pile. Then set the girders to 0°.\n' +
          '\n' +
          "Fix the bracket to the girder. Don't forget to install the nuts on the side of the TCU (the bottom side), taking special care to not damage its plastic housing.\n" +
          '\n' +
          'Once mounted, tighten all fasteners. ',
      },
      {
        headline: 'Step 2:\nThe Purlins',
        copy:
          "Assemble the individual parts of the purlins with the girder group (including the C-shaped struts, which you'll need to mount on the ends of each purlin), ensuring you're doing so at a level parallel to the ground and in alignment with the mounting holes for the PV modules.",
      },
      {
        headline: 'Step 3:\nThe Drive Unit & Drive Shaft',
        copy:
          'Fix the drive unit to the girder group. Then insert the drive shaft into the drive unit, and connect it with the girder group on the opposite side of the tracker table.',
      },
      {
        headline: 'Step 4:\nThe Modules',
        copy: 'Finally, mount the modules by simply screwing them in from below. No clamps or ladder required.',
      },
    ],
  },
  RoofHooks: {
    targets: [
      {
        id: 'RoofHooks_Roof hooks',
        text: 'Roof hooks',
        hotspotPosition: [1.545, 1.463, 1.541],
        targetPosition: [1.8, 1.35, 1.314],
        cameraPosition: [0.992, 1.192, 1.513],
      },
      {
        id: 'RoofHooks_Module support',
        text: 'Module support',
        hotspotPosition: [1.61, 2.072, 1.877],
        targetPosition: [1.61, 2.072, 1.877],
        cameraPosition: [2.55, 2.311, 1.632],
      },
      {
        id: 'RoofHooks_Clamps',
        text: 'Clamps',
        hotspotPosition: [1.446, 2.417, 2.032],
        targetPosition: [1.446, 2.417, 2.032],
        cameraPosition: [1.033, 2.346, 2.305],
      },
    ],
    buildInstructions: [
      {
        headline: 'Step 1:\nThe Hooks & Tiles',
        copy:
          'Remove the tiles, and screw in the base plate using a single M8 wood screw. Fix the ProLine roof hooks to the base plate by simply clicking them in. Then grind the tiles so that 5 mm of space remains around each hook before putting them back.',
      },
      {
        headline: 'Step 2:\nThe Rails',
        copy:
          'Fix the rail to the roof hooks, and, to compensate for roof unevenness, adjust it to the required height. Then tighten the roof hook screws.',
      },
      {
        headline: 'Step 3:\nThe Modules & Clamps',
        copy:
          "Put the module on the rail, leaving a minimum of 10 mm at the end. Fix the module by clicking and screwing in two end clamps. Before you put on the next module, click in two center clamps between each module, ensuring you leave 10 mm of space. Then screw in the center clamps. Continue until you reach the last module of the row, which you'll need to fix with two end clamps.",
      },
    ],
  },
  FixGridPro: {
    targets: [
      {
        id: 'FixGridPro_Protection mats',
        text: 'Protection mats',
        hotspotPosition: [-0.796, 0.315, 1.51],
        targetPosition: [-0.796, 0.315, 1.51],
        cameraPosition: [-1.428, 0.746, 2.539],
      },
      {
        id: 'FixGridPro_Floor profile',
        text: 'Base Rail',
        hotspotPosition: [-1.745, 0.454, 1.51],
        targetPosition: [-1.5, 0.454, 1.51],
        cameraPosition: [-2.173, 0.751, 2.437],
      },
      {
        id: 'FixGridPro_Module support',
        text: 'Module support',
        hotspotPosition: [-1.422, 0.93, 1.51],
        targetPosition: [-1.422, 0.93, 1.51],
        cameraPosition: [-1.743, 0.892, 2.332],
      },
      {
        id: 'FixGridPro_Heightening elements',
        text: 'Heightening elements',
        hotspotPosition: [-1.234, 0.719, 1.51],
        targetPosition: [-1.234, 0.719, 1.51],
        cameraPosition: [-0.926, 0.819, 2.341],
      },
      {
        id: 'FixGridPro_Module clamps',
        text: 'Module clamps',
        hotspotPosition: [-2.03, 0.992, 1.513],
        targetPosition: [-2.03, 0.992, 1.513],
        cameraPosition: [-1.797, 1.052, 1.892],
      },
    ],
    buildInstructions: [
      {
        headline: 'Step 1:\nThe Roof Protection Mats',
        copy:
          'Fix the rail to the roof protection mat by peeling off the adhesive foil on the upper side before pressing it to the bottom at the desired position, maintaining a projection of 30 mm at both ends. Ensure you place the surface protection mat under each module support.',
      },
      {
        headline: 'Step 2:\nThe Heightening Elements',
        copy:
          'Insert the Pro screw-in heightening elements and screw-in module supports to the rail before turning each element 90° clockwise using the screw-in aid. \n\n' +
          "Extend the heightening element until you've reached the required height. You'll need one additional heightening element for 10° inclinations, or two for 15°.",
      },
      {
        headline: 'Step 3:\nThe Module Support & Ballasts',
        copy:
          "Click in the final module support. Before mounting a module, ballast your system as per your mounting instructions. You'll probably only need blocks, but we also offer rails and trays for more bespoke ballasting.",
      },
      {
        headline: 'Step 4:\nThe Modules & Clamps',
        copy:
          "Place your module onto the module support, and fix it by clicking and screwing in two end clamps. Before you put on the next module, click in two center clamps between each module. Then screw in the center clamps. Continue until you reach the last module of the row, which you'll need to fix with two end clamps.",
      },
    ],
  },
};

export const useConstructionAssemblyService = create((set, get) => ({
  selectedModelId: 'TrackingSystem',
  setSelectedModelId: id => {
    set({ selectedModelId: id });
  },
  detailID: null,
  setDetailID: id => {
    set({ detailID: id });
  },

  progress: 0,
  targetProgress: 0,
  setProgress: progress => set({ progress }),
  setTargetProgress: targetProgress => set({ targetProgress }),
  resetSlider: () => set({ progress: 0, targetProgress: 0 }),

  numSteps: 5,
  setNumSteps: numSteps => set({ numSteps }),
}));
