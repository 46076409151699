import styled from 'styled-components';
import { zIndex } from 'uiLivi/styles';

const barWidth = 56;

export const StyledChatBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  z-index: ${zIndex.chat};
  top: 0;
  right: 0;
  width: ${barWidth}px;
  height: ${props => (props.showChatToggle ? props.vh * 100 : props.vh * 100 + barWidth)}px;
  transform: translateX(${props => (props.useOffsetLeft ? -props.chatWidth : 0)}px);
  transition: transform 0.15s ease;
  pointer-events: none;
`;

export const StyledBar = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  top: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
`;
