import React, { useEffect, useState } from 'react';
import { useConstructionAssemblyService } from '../../../../services/ConstructionAssemblyService';
import ProductSelection from '../ProductSelection';
import { SvgButtonHoverEffect } from '../../../../uiLivi/styles';
import OtherProductsButton from './other-products-button.svg';
import OtherProductsButtonMobile from './other-products-button-mobile.svg';
import ConstructionSlider from './ConstructionSlider';
import BuildInstructions from './BuildInstructions';
import { Container, GradientBackground, ModelSelection, ToggleSelection } from './styles';

export default function ConstructionUI() {
  const selectedModelId = useConstructionAssemblyService(state => state.selectedModelId);
  const setSelectedModelId = useConstructionAssemblyService(state => state.setSelectedModelId);
  const detailID = useConstructionAssemblyService(state => state.detailID);
  const setDetailID = useConstructionAssemblyService(state => state.setDetailID);
  const resetSlider = useConstructionAssemblyService(state => state.resetSlider);
  const showDetail = detailID !== null;
  const [showSelection, setShowSelection] = useState(true);

  useEffect(() => {
    return () => {
      resetSlider();
    };
  }, [selectedModelId]);

  useEffect(() => {
    return () => {
      setSelectedModelId('TrackingSystem');
    };
  }, []);

  useEffect(() => {
    return () => setDetailID(null);
  }, []);

  return (
    <Container>
      <GradientBackground />
      <ToggleSelection visible={!showDetail} onClick={() => setShowSelection(true)} data-sound-action="click">
        <SvgButtonHoverEffect>
          <OtherProductsButton className="desktop" />
          <OtherProductsButtonMobile className="mobile" />
        </SvgButtonHoverEffect>
      </ToggleSelection>
      <ModelSelection visible={showSelection}>
        <ProductSelection onClose={() => setShowSelection(false)} visible={showSelection} />
      </ModelSelection>
      <ConstructionSlider />
      <BuildInstructions />
    </Container>
  );
}
