import { Headline, Paragraph } from '../../styles';
import React from 'react';

const MessageSent = ({ open }) => {
  const { name } = open;
  return (
    <>
      <Headline>Thank you for your message!</Headline>
      <Paragraph>{name} received your message and will contact you personally.</Paragraph>
    </>
  );
};

export default MessageSent;
