import { useMemo } from 'react';
import { useFBO } from '@react-three/drei';
import { OrthographicCamera, RGBAFormat, LinearMipmapLinearFilter } from 'three';
import { useThree } from '@react-three/fiber';

export default function useFakeShadows() {
  const { scene, camera } = useThree();
  const cameraSize = { width: 80, height: 60 };
  const fbo = useFBO(1024, 1024, {
    format: RGBAFormat,
    generateMipmaps: true,
    minFilter: LinearMipmapLinearFilter,
  });

  const quadCamera = useMemo(() => {
    const { width, height } = cameraSize;
    const c = new OrthographicCamera(-width * 0.5, width * 0.5, height * 0.5, -height * 0.5, 1, 50);
    c.position.y = 30;
    c.lookAt(0, 0, 0);
    c.position.z = 35;

    return c;
  }, []);

  return { cameraSize, fbo, quadCamera };
}
