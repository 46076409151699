import localStorage from 'utilities/storage/safe-local-storage';
import sessionStorage from 'utilities/storage/safe-session-storage';

class Storage {
  constructor(options = { type: 'local' }) {
    this.definitions = {};
    switch (options.type) {
      case 'local':
        this.storage = localStorage;
        break;
      case 'session':
        this.storage = sessionStorage;
        break;
    }
  }

  getDefinition(key) {
    const def = this.definitions[key];
    if (!def) throw 'No entry definition for ' + key;
    return def;
  }

  defineEntry(key, defaultValue, expiration) {
    this.definitions[key] = { defaultValue: JSON.stringify(defaultValue), expiration };
  }

  init(prefix) {
    this.prefix = prefix;
  }

  setItem(key, value) {
    const def = this.getDefinition(key);
    this.storage.setItem(this.prefix + '_' + key, JSON.stringify(value));
    if (def.expiration) {
      this.storage.setItem(this.prefix + '_' + key + '_write', JSON.stringify(Date.now()));
    }
    try {
      this.onSetItem && this.onSetItem();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }
  getItem(key) {
    const def = this.getDefinition(key);
    let isExpired = false;
    if (def.expiration) {
      const writeAccess = JSON.parse(this.storage.getItem(this.prefix + '_' + key + '_write'));
      if (Number.isInteger(writeAccess)) {
        const now = Date.now();
        const dt = now - writeAccess;
        isExpired = dt > def.expiration;
      }
    }
    try {
      const value = isExpired ? null : JSON.parse(this.storage.getItem(this.prefix + '_' + key));
      if (value == null) {
        return def.defaultValue == null ? null : JSON.parse(def.defaultValue);
      }
      return value;
    } catch (e) {
      return def.defaultValue == null ? null : JSON.parse(def.defaultValue);
    }
  }

  reset() {
    this.clear();
    this.definitions = {};
  }

  clear() {
    Object.keys(this.definitions).forEach(key => {
      this.storage.removeItem(this.prefix + '_' + key);
      this.storage.removeItem(this.prefix + '_' + key + '_write');
    });
  }
}

export default Storage;
