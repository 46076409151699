import styled, { css } from 'styled-components';
import { animations, colors, lgBaseColors } from 'uiLivi/styles';
import { H5 } from 'common/text';
import useViewTracking from 'services/TrackingService/useViewTracking';

const chatBarPadding = '15px';

const infoStyle = css`
  color: ${colors.font.strong};
`;

const warnStyle = css`
  color: ${colors.warn};
  border: 1px solid ${colors.warn};
`;

const errorStyle = css`
  color: ${colors.error};
  border: 1px solid ${colors.error};
`;

const successStyle = css`
  color: ${colors.success};
  border: 1px solid ${colors.success};
`;

const StyledToast = styled.div`
  width: calc(320px - ${chatBarPadding});
  padding: ${props => (props.isLarge ? '17px 40px' : '14px 32px')};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: ${lgBaseColors.grey.dark};
  color: white;
  border: 1px solid transparent;
  ${props => props.level === 'info' && infoStyle}
  ${props => props.level === 'warn' && warnStyle}
  ${props => props.level === 'error' && errorStyle}
  ${props => props.level === 'success' && successStyle}
  ${props => (props.position ? (props.position === 'bottom' ? animations.showFromBottom : null) : null)}
`;

const Text = styled.div`
  font-weight: normal;
  line-height: 22px;
`;

const Headline = styled(H5)`
  margin: 5px 0 5px 0;
`;

export default function Toast({ headline, text, buttons, level = 'info', large = false, position = null }) {
  useViewTracking('Notification', 'Show', level + '/' + headline + '/' + text);
  return (
    <StyledToast key={Date.now()} level={level} isLarge={large} position={position}>
      <Headline>{headline}</Headline>
      <Text>{text}</Text>
      {buttons}
    </StyledToast>
  );
}
