import styled, { css } from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { H3 } from '../../../../common/text';

const shadowBottomStyle = css`
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
`;

export const StyledChatTitleBar = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 48px;
  background: ${props => (props.isContent ? lgBaseColors.grey.dark : colors.background.base)};
  color: ${props => (props.isContent ? colors.background.base : lgBaseColors.grey.dark)};
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  overflow: hidden;
  border-bottom: 1px solid ${props => (props.isContent ? 'black' : 'transparent')};
  ${props => props.hasShadow && shadowBottomStyle}
`;

export const StyledButtonItem = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  cursor: pointer;
  transform: translateX(${props => (props.hide ? -32 : 0)}px);
  transition: all 0.15s ease;
`;

export const StyledTitle = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  margin-right: auto;
  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
  align-self: center;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateX(${props => (props.alignLeft ? -32 : 0)}px);
  transition: all 0.15s ease;
  color: ${colors.font.light};
  text-transform: capitalize;
`;

export const StyledNewChatTitle = styled(H3)`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0;
  span {
    margin: 0 5px;
  }
`;
