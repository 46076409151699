import { FlexContainer, MessageContainer, MessageHeadline, MessageSubline } from './styles';
import { lgBaseColors } from 'uiLivi/styles';
import { useEventStore } from 'services/EventService';
import { useTranslate } from 'services/TranslationService/hooks';

export default function StartMessage() {
  const eventState = useEventStore(state => state.eventState);
  const translate = useTranslate();

  let components;

  switch (eventState) {
    case 'NONE_AVAILABLE':
      components = (
        <>
          <MessageHeadline color={lgBaseColors.green.dark}>
            {translate('error.noevent.headline') || '👋 Welcome!'}
          </MessageHeadline>
          <MessageSubline color={lgBaseColors.white}>
            {translate('error.noevent.body') || 'No events seem to be configured or the event has no districts.'}
          </MessageSubline>
        </>
      );
      break;
    case 'OVERLOADED':
      components = (
        <>
          <MessageHeadline>{'Im Moment ist hier zu viel los.'}</MessageHeadline>
          <MessageSubline>
            {'Die Virtual Experience ist voll. Versuch es einfach in ein paar Minuten wieder.'}
          </MessageSubline>
        </>
      );
      break;
    default:
      components = (
        <>
          <MessageHeadline>{translate('error.generic.headline') || '😭 Aw, Snap!'}</MessageHeadline>
          <MessageSubline>
            {translate('error.generic.body') ||
              'Sorry, something went wrong. Please try again in a short while – we’re working on it.'}
          </MessageSubline>
        </>
      );
      break;
  }

  return (
    <FlexContainer>
      <MessageContainer>
        <div>{components}</div>
      </MessageContainer>
    </FlexContainer>
  );
}
