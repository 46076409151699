import { useFrame, useLoader } from '@react-three/fiber';
import { ImageLoader, NormalBlending, Sprite, Texture } from 'three';
import HotspotTexture from './hotspot-texture.png';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import HUDSpriteMaterial from '../../../../utilities/canvas/HUDSpriteMaterial';
import { SCROLL_RANGES, useScrollStore } from '../../../../services/ScrollService';
import { useWindowStore } from '../../../../services/WindowService';
import { Circ, gsap, Power2, Sine } from 'gsap';
import { useDeviceStore } from '../../../../services/DeviceService';
import { useDistrictWarmupStore, WarmupPhase } from '../../../../services/DistrictWarmupService';
import { useHowlerStore } from '../../../../services/HowlerService';

const getSprite = (srcTexture, isPhone) => {
  const canvas = document.createElement('canvas');
  canvas.width = 256;
  canvas.height = 256;

  const context = canvas.getContext('2d');
  context.drawImage(srcTexture, 0, 0, 256, 256);

  const texture = new Texture(canvas);
  texture.needsUpdate = true;

  const material = new HUDSpriteMaterial();
  material.map = texture;
  material.transparent = true;
  material.depthTest = true;
  material.depthWrite = false;
  material.blending = NormalBlending;

  const sprite = new Sprite(material);
  const scale = !isPhone ? 0.5 : 1.0;
  sprite.renderOrder = -1;
  sprite.scale.set(scale, scale, scale);
  sprite.material.uniforms.opacity.value = 1;

  return sprite;
};

export default function Hotspot({ position, transitionTarget }) {
  const group = useRef(null);
  const [active, setActive] = useState(false);
  const [initial, setInitial] = useState(true);
  const [hotspotTexture] = useLoader(ImageLoader, [HotspotTexture]);
  const setTransition = useScrollStore(state => state.setTransition);
  const setTransitionTarget = useScrollStore(state => state.setTransitionTarget);
  const transition = useScrollStore(state => state.transition);
  const progress = useScrollStore(state => state.progress);
  const setHover = useWindowStore(state => state.setHover);
  const isPhone = useDeviceStore(state => state.device.isMobileOnly);
  const playPrimaryClickUiSound = useHowlerStore(state => state.playPrimaryClickUiSound);
  const playHotspotSfx = useHowlerStore(state => state.playHotspotSfx);

  const phase = useDistrictWarmupStore(state => state.phase);
  const isLoaded = phase === WarmupPhase.DONE;
  const timeout = useRef(null);

  const indicator = useRef(null);
  const visibleRange = useMemo(() => {
    return SCROLL_RANGES[0][0];
  }, []);

  useEffect(() => {
    const sprite = getSprite(hotspotTexture, isPhone);
    if (group.current) {
      group.current.add(sprite);

      gsap.to(sprite.position, {
        duration: 1,
        ease: Sine.easeInOut,
        yoyo: true,
        repeat: -1,
        y: 0.3,
      });

      gsap.to(indicator.current.position, {
        duration: 1,
        ease: Sine.easeInOut,
        yoyo: true,
        repeat: -1,
        y: 0.35,
      });
    }

    activatePing();

    return () => {
      clearTimeout(timeout.current);
      if (group.current) {
        group.current.remove(sprite);
      }
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      timeout.current = setTimeout(() => {
        setInitial(false);
        setActive(true);
      }, 2000);
    }
  }, [isLoaded]);

  const activatePing = () => {
    const duration = 1.5;
    const repeatDelay = 2;
    gsap.to(indicator.current.scale, {
      duration: duration,
      ease: Circ.easeOut,
      repeat: -1,
      repeatDelay: repeatDelay,
      x: 3,
      y: 3,
      z: 3,
    });
    gsap.to(indicator.current.material, {
      duration: duration,
      ease: Circ.easeOut,
      repeat: -1,
      repeatDelay: repeatDelay,
      opacity: 0,
    });
  };

  useEffect(() => {
    gsap.killTweensOf(group.current);
    if (!active) {
      gsap.to(group.current.scale, {
        duration: 0.3,
        ease: Power2.easeIn,
        x: 0,
        y: 0,
        z: 0,
        onComplete: () => {
          if (!group.current) return;
          group.current.visible = false;
        },
      });
    } else {
      group.current.visible = true;
      gsap.to(group.current.scale, { duration: 0.4, ease: Sine.easeOut, x: 1, y: 1, z: 1 });
    }
  }, [active]);

  const handleTransition = () => {
    if (progress === transitionTarget) return;
    setTransition('blend');
    setTransitionTarget(transitionTarget);
    playPrimaryClickUiSound();
    playHotspotSfx();
  };

  useFrame(() => {
    const progress = useScrollStore.getState().normalizedProgress;

    if (initial) return;

    if (transition !== 'none') {
      setActive(false);
    } else {
      if (progress >= visibleRange) {
        setActive(false);
      } else {
        setActive(true);
      }
    }
  });

  return (
    <group name="HotspotLobby" ref={group} position={position}>
      <mesh
        onPointerOver={() => {
          setHover(true);
        }}
        onPointerOut={() => {
          setHover(false);
        }}
        onClick={handleTransition}
      >
        <sphereGeometry args={[!isPhone ? 3 : 3, 24, 24]} />
        <meshBasicMaterial color={'#cc0000'} opacity={0} transparent={true} depthTest={false} deptWrite={false} />
      </mesh>

      <mesh ref={indicator} scale={[0, 0, 0]} position={[0, 0.05, 0]} renderOrder={0}>
        <sphereGeometry args={[!isPhone ? 0.35 : 0.7, 24, 24]} />
        <meshBasicMaterial color={'#dabe79'} opacity={1} transparent={true} depthTest={false} deptWrite={false} />
      </mesh>
    </group>
  );
}
