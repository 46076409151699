import { useNavigationStore } from '../../../../services/NavigationService';
import { useOutsideClick } from '../../../../utilities/hooks';
import { Close, Overlay } from './styles';
import productInfoIDToComponent, { SUPPORTDESK } from './productInfoIDToComponent';
import CloseButton from './images/close-button.inline.svg';
import { SvgButtonHoverEffect } from 'uiLivi/styles';

export default function ProductInfoLayer() {
  const productInfoID = useNavigationStore(state => state.productInfoID);

  const showProductInfoLayer = useNavigationStore(state => state.showProductInfoLayer);
  const setShowProductInfoLayer = useNavigationStore(state => state.setShowProductInfoLayer);

  const handleClose = () => {
    setShowProductInfoLayer(false);
  };

  const handleClickOutside = () => {
    setShowProductInfoLayer(false);
  };
  const overlay = useOutsideClick(handleClickOutside);

  const component = productInfoIDToComponent[productInfoID]?.component;

  return (
    <Overlay show={showProductInfoLayer} ref={overlay}>
      <Close bright={productInfoID === SUPPORTDESK}>
        <SvgButtonHoverEffect cursor="pointer" onClick={handleClose} data-sound-action="primaryClick">
          <CloseButton />
        </SvgButtonHoverEffect>
      </Close>
      {component}
    </Overlay>
  );
}
