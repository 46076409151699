import styled, { css } from 'styled-components';
import { useChatStore } from 'services/ChatService';
import { colors, lgBaseColors } from 'uiLivi/styles';
import IconDistrict from 'assets/icons/Icon_People.svg';
/* eslint-disable */
import IconPeopleRow from 'assets/icons/Icon_PeopleRow.svg';
import IconContentChat from 'assets/icons/Icon_ContentChat.svg';
import { iconFillAndStroke } from 'common/icon';
import { textOverflowEllipsis } from 'common/text';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: ${colors.font.light};
`;

const Icon = styled.div`
  position: relative;
  width: 42px;
  height: 42px;
  background: ${props => props.color};
  margin-right: 10px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  svg {
    ${iconFillAndStroke('white', 'white')};
  }

  ${props =>
    props.groupMemberCount &&
    css`
      border-radius: 50%;
    `}
`;

const UnseenNotification = styled.div`
  position: absolute;
  right: ${props => (props.isDisctrictOrSupportChat ? -4 : -2)}px;
  top: ${props => (props.isDisctrictOrSupportChat ? -4 : -2)}px;
  width: ${props => (props.isDisctrictOrSupportChat ? 12 : 7)}px;
  height: ${props => (props.isDisctrictOrSupportChat ? 12 : 7)}px;
  border-radius: 50%;
  background-color: ${props => (props.isDisctrictOrSupportChat ? lgBaseColors.red : colors.secondary.base)};
  ${props =>
    props.isDisctrictOrSupportChat &&
    `border 2px solid ${props.isContent ? lgBaseColors.grey.dark : lgBaseColors.grey.base}`};
`;

const StyledChatRoom = styled.div`
  padding: 5px 0;
  ${textOverflowEllipsis};
  max-width: 200px;
  text-transform: capitalize;
`;

const ChatSelector = props => {
  const { chat, isGroupChat = false } = props;
  const isContent = useChatStore(state => state.chatType === 'content');
  const chatName = chat.name || '';

  const onClick = () => {
    useChatStore.getState().enter(chat);
  };

  const renderIcon = () => {
    if (chat.type === 'district') {
      return (
        <Icon color={colors.accent.base}>
          <IconDistrict width={26} height={26} />
          {chat && !chat.seen && <UnseenNotification isDisctrictOrSupportChat isContent={isContent} />}
        </Icon>
      );
    }

    if (chat.type === 'content') {
      return (
        <Icon color={lgBaseColors.green}>
          <IconContentChat width={30} height={19} />
          {chat && !chat.seen && <UnseenNotification isDisctrictOrSupportChat isContent={isContent} />}
        </Icon>
      );
    }

    return (
      <Icon color={colors.secondary.base} groupMemberCount={isGroupChat && chat.users.length - 1}>
        <IconPeopleRow width={28} height={22} />
        {!chat.seen && <UnseenNotification />}
      </Icon>
    );
  };

  return (
    <Container onClick={onClick}>
      {renderIcon()}
      <StyledChatRoom>{chatName}</StyledChatRoom>
    </Container>
  );
};

export default ChatSelector;
