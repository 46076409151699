import moment from 'moment-timezone';

export default function format(utcString, pattern) {
  // this is a hack. moment does not support this kind of date format
  if (pattern === 'L_SIMPLE') {
    pattern = 'L';
    return moment(utcString)
      .format(pattern)
      .replace(new RegExp('[^.]?' + moment().format('YYYY') + '.?'), '');
  } else return moment(utcString).format(pattern);
}

export function isToday(utcString) {
  const now = moment(Date.now()).local();
  const time = moment(utcString).local();
  return now.isSame(time, 'day');
}

export const FORMAT_DATE_SIMPLE = 'L_SIMPLE';
export const FORMAT_DATE = 'L';
export const FORMAT_TIME_SIMPLE = 'h:mm';
export const FORMAT_TIME = 'LT';
export const FORMAT_TIME_AND_ZONE = 'LT (z)';
export const FORMAT_ZONE = 'z';
export const FORMAT_FULL_DATE = 'LL';
