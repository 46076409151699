import styled, { css } from 'styled-components';
import { iconFillAndStroke } from 'common/icon';
import { veGradientLtR } from '../../../uiLivi/styles';

export const BadgeContainer = styled.div`
  margin: 0 4px;
  color: white;
  border-radius: 20px;
  letter-spacing: 0.05em;
  font-size: 9px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;

  ${props =>
    props.withGradientBorder
      ? css`
          background: ${veGradientLtR};
        `
      : css`
          border: 1px solid ${props.color};
        `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: 1px;
    border-radius: inherit;
    background: black;
  }
`;

export const IconContainer = styled.div`
  svg {
    ${iconFillAndStroke('white', 'white')}
  }
`;

export const TextContainer = styled.div`
  vertical-align: center;
  margin: 0 3px;
  float: left;
`;
