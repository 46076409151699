import { useEffect, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { CameraControls } from '@react-three/drei';
import { DEG2RAD } from 'three/src/math/MathUtils';
import DetailTarget from './DetailTarget';
import { Models, useConstructionAssemblyService } from '../../../../services/ConstructionAssemblyService';
import { gsap, Power2 } from 'gsap';
import { useDeviceStore } from '../../../../services/DeviceService';

// https://github.com/yomotsu/camera-controls
// https://codesandbox.io/s/sew669?file=/src/App.js:281-287
// https://www.figma.com/file/dRLcAe3HEwbp0yKN7r7Y6U/%5BSCH%5D-Exploration?type=design&node-id=3871-38135&mode=design&t=sdbgrNfcT4WKLSvd-0

const CONFIG = {
  cameraDistance: { default: { min: 4, max: 12 }, detail: { min: 0.5, max: 2.5 } },
  // camera: { position: { desktop: [10, 2, 5], mobile: [10, 2.3, 2] }, target: { default: [0, 1, 0] } }, //12, 2.3, 2
  camera: {
    TrackingSystem: {
      position: { desktop: [3.728, 2.56, 10.472], mobile: [4.622, 2.188, 8.083] },
      target: { default: [0, 1, 0] },
    },
    RoofHooks: {
      position: { desktop: [3.456, 3.06, 8.389], mobile: [3.863, 2.146, 5.77] },
      target: { default: [0, 2, 0] },
    },
    FixGridPro: {
      position: { desktop: [1.993, 1.834, 5.598], mobile: [-2.849, 1.263, 4.426] },
      target: { default: [0, 1, 0] },
    },
  },
  fov: { desktop: { default: 50 }, mobile: { default: 80, detail: 50 } },
  polarAngle: {
    default: { min: 65 * DEG2RAD, max: (90 - 2.5) * DEG2RAD },
    detail: { min: 45 * DEG2RAD, max: 120 * DEG2RAD },
  },
};

export default function OrbitCameraControls() {
  const { camera } = useThree();
  const cameraControlsRef = useRef();
  const isMobile = useDeviceStore(state => state.device.isMobileOnly);
  const [enabled, setEnabled] = useState(true);
  const [maxDistance, setMaxDistance] = useState(12);
  const [minDistance, setMinDistance] = useState(1);
  const [minPolarAngle, setMinPolarAngle] = useState(CONFIG.polarAngle.default.min);
  const [maxPolarAngle, setMaxPolarAngle] = useState(CONFIG.polarAngle.default.max);
  const detailID = useConstructionAssemblyService(state => state.detailID);
  const showDetail = detailID !== null;
  const setDetailID = useConstructionAssemblyService(state => state.setDetailID);
  const selectedModelId = useConstructionAssemblyService(state => state.selectedModelId);
  const targets = Models[selectedModelId]?.targets;
  const [detailActive, setDetailActive] = useState(true);
  const isMobileOnly = useDeviceStore(state => state.device.isMobileOnly);

  // const { transformTarget, logCam, orbit, freeCam } = useControls({
  //   transformTarget: { value: false },
  //   logCam: { value: false },
  //   orbit: { value: true },
  //   freeCam: { value: false },
  // });

  const orbit = true;
  const freeCam = false;

  useEffect(() => {
    cameraControlsRef.current?.setTarget(...CONFIG.camera[selectedModelId].target.default);
    const position = isMobile
      ? CONFIG.camera[selectedModelId].position.mobile
      : CONFIG.camera[selectedModelId].position.desktop;
    cameraControlsRef.current?.setPosition(...position);
    setMinDistance(CONFIG.cameraDistance.default.min);
    setMaxDistance(CONFIG.cameraDistance.default.max);
    camera.fov = isMobile ? CONFIG.fov.mobile.default : CONFIG.fov.desktop.default;
    // cameraControlsRef.current?.setBoundary(bounds);
    // console.log(camera);
    // console.log(cameraControlsRef.current);
  }, []);

  // const logCamPosition = () => {
  //   const pos = camera.position;
  //   const x = pos.x.toFixed(3);
  //   const y = pos.y.toFixed(3);
  //   const z = pos.z.toFixed(3);
  //   console.log(x + ' , ' + y + ' , ' + z);
  // };

  // useFrame(() => {
  //   logCam && logCamPosition();
  //   // set({ polarAngle: cameraControlsRef.current?.polarAngle * RAD2DEG });
  //   // set({ azimuthAngle: (cameraControlsRef.current?.azimuthAngle * RAD2DEG) % 360 });
  // });

  const toggleDetailView = id => {
    const target = targets.find(t => t.id === id);
    setDetailID(id);
    cameraControlsRef.current?.setLookAt(...target.cameraPosition, ...target.targetPosition, true);
    isMobileOnly && cameraControlsRef.current?.setFocalOffset(0, 0.2, 0);
    setMinDistance(CONFIG.cameraDistance.detail.min);
    setMaxDistance(CONFIG.cameraDistance.detail.max);
    setMinPolarAngle(CONFIG.polarAngle.detail.min);
    setMaxPolarAngle(CONFIG.polarAngle.detail.max);
    if (isMobile) {
      gsap.to(camera, {
        duration: 1.25,
        ease: Power2.easeOut,
        fov: CONFIG.fov.mobile.detail,
        onUpdate: () => {
          camera.updateProjectionMatrix();
        },
      });
    }
  };

  const resetDetailView = () => {
    // TODO ?Alternative we could go back to the last cameraPosition
    const position = isMobile
      ? CONFIG.camera[selectedModelId].position.mobile
      : CONFIG.camera[selectedModelId].position.desktop;
    cameraControlsRef.current?.setLookAt(...position, ...CONFIG.camera[selectedModelId].target.default, true);
    setMinDistance(CONFIG.cameraDistance.default.min);
    setMaxDistance(CONFIG.cameraDistance.default.max);
    setMinPolarAngle(CONFIG.polarAngle.default.min);
    setMaxPolarAngle(CONFIG.polarAngle.default.max);
    if (isMobile) {
      gsap.to(camera, {
        duration: 1.25,
        ease: Power2.easeOut,
        fov: CONFIG.fov.mobile.default,
        onUpdate: () => {
          camera.updateProjectionMatrix();
        },
      });
    }
  };

  useEffect(() => {
    if (!showDetail) {
      resetDetailView();
    }
  }, [showDetail, selectedModelId]);

  const enableOrbit = () => {
    setEnabled(true);
  };

  const disableOrbit = () => {
    setEnabled(false);
  };

  useFrame(() => {
    const progress = useConstructionAssemblyService.getState().progress;
    setDetailActive(progress === 0);
  });

  return (
    <>
      {/*{transformTarget && (*/}
      {/*  <TargetHelper enableOrbit={enableOrbit} disableOrbit={disableOrbit} position={[0.606, 0.531, 1.51]} />*/}
      {/*)}*/}
      <CameraControls
        enabled={orbit && enabled}
        ref={cameraControlsRef}
        minDistance={!freeCam ? minDistance : 0}
        maxDistance={!freeCam ? maxDistance : 40}
        smoothTime={0.4}
        draggingSmoothTime={0.25}
        minPolarAngle={!freeCam ? minPolarAngle : 0} // top
        maxPolarAngle={!freeCam ? maxPolarAngle : 110 * DEG2RAD} // bottom
        dollyToCursor={false}
        dollySpeed={0.75}
        truckSpeed={!freeCam ? 0 : 1}
      />
      <>
        {targets?.map((target, index) => {
          return (
            <DetailTarget
              key={index}
              index={index}
              position={target.hotspotPosition}
              id={target.id}
              text={target.text}
              toggleDetailView={toggleDetailView}
              active={!showDetail && detailActive}
            />
          );
        })}
      </>
    </>
  );
}
