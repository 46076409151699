import DatGuiStack from './DatGuiStack';
import DebugTracking from './DebugTracking';
import { useDebugStore } from '../../storage/debug';

export default function DevContent() {
  const debugStore = useDebugStore();

  return (
    <>
      {debugStore.getTrackingDebugEnabled() && <DebugTracking />}
      <DatGuiStack />
    </>
  );
}
