import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useScrollStore } from '../../../../../services/ScrollService';
import { AnimatePresence, motion } from 'framer-motion';
import { hexToRgba } from '../../../../../utilities/color';
import Banners from '../../ProductInfoLayer/Banners';
import useContainerWidth from '../../ProductInfoLayer/useContainerWidth';
import { zIndex } from '../../../../../uiLivi/styles';
import { Padder } from '../../ProductInfoLayer/styles';
import { SkipButton } from '../../../../Onboarding/styles';

const Background = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, ${hexToRgba('#d6eade', 0.8)} 0%, ${hexToRgba('#005E61', 0.8)} 100%);
  backdrop-filter: blur(4px);
  pointer-events: all;

  z-index: ${zIndex.modalAndNotifications};
`;

const BannersContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  z-index: ${zIndex.modalAndNotifications};
`;

const BannersWrapper = styled.div`
  width: 800px;
  max-width: 100%;
  pointer-events: all;
`;
const SkipButtonContainer = styled(motion.div)`
  text-align: center;
`;

const Headline = styled(motion.div)`
  color: #fff;
  text-align: center;
  font-family: AzoSansBlack;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.5px;
  text-transform: uppercase;
`;

const range = [0.901, 0.99];

const backgroundTransition = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.4, ease: 'easeOut' } },
  exit: { opacity: 0, transition: { duration: 0.4, ease: 'easeIn' } },
};
const overlayTransition = dir => ({
  initial: { x: `${dir * 20}%`, opacity: 0 },
  animate: { x: '0%', opacity: 1, transition: { duration: 0.4, ease: 'easeOut' } },
  exit: { x: `${dir * 20}%`, opacity: 0, transition: { duration: 0.2, ease: 'easeIn' } },
});

function Wrapper({ onSkip }) {
  const [containerRef, containerWidth] = useContainerWidth();

  return (
    <BannersWrapper ref={containerRef}>
      <Padder>
        <Headline {...backgroundTransition}>
          Glad you are here! <br />
          What do you want to do next?
        </Headline>
      </Padder>
      <Banners containerWidth={containerWidth} bannerAnimation={overlayTransition} />
      <SkipButtonContainer {...overlayTransition(0)}>
        <SkipButton onClick={onSkip} data-sound-action="click">
          Skip
        </SkipButton>
      </SkipButtonContainer>
    </BannersWrapper>
  );
}

export default function SignPostOverlay() {
  const requestRef = useRef(null);
  const [isInRange, setIsInRange] = useState(false);
  const [isSkipped, setSkipped] = useState(false);
  const isVisible = isInRange && !isSkipped;

  useEffect(() => {
    const update = () => {
      const progress = useScrollStore.getState().normalizedProgress;
      const visible = range[0] <= progress && progress <= range[1];
      if (visible !== isInRange) {
        setIsInRange(visible);
        setSkipped(false);
      }

      requestRef.current = requestAnimationFrame(update);
    };

    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, [isInRange]);

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <Background key="SignPostBackground" {...backgroundTransition} />
          <BannersContainer key="BannersContainer">
            <Wrapper onSkip={() => setSkipped(true)} />
          </BannersContainer>
        </>
      )}
    </AnimatePresence>
  );
}
