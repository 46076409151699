const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const prefixEventListener = (element, event, cb, addListener = true) => {
  const listener = addListener ? 'addEventListener' : 'removeEventListener';
  return ['webkit', 'moz', 'MS', 'o', '']
    .map(prefix => (prefix ? prefix + capitalize(event) : event))
    .forEach(event => element[listener](event, cb));
};

export { prefixEventListener };
