import styled, { keyframes } from 'styled-components';
import { lgBaseColors } from 'uiLivi/styles';

const appearAnimation = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`;

export const Box = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: relative;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 50%;
  background-color: ${props => (props.color ? props.color : lgBaseColors.grey.medium)};
  cursor: pointer;
  opacity: 0;
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  animation: ${props => (props.show ? appearAnimation : null)} 0.4s linear;
  animation-delay: ${props => props.appearIndex * 0.03}s;
  animation-fill-mode: forwards;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.icon});
  background-size: contain;
  border-radius: 50%;

  transform: rotate(${props => (props.hover ? 45 : 0)}deg);
  transition: transform 0.25s ease-in-out;
`;
