import create from 'utilities/zustand/create';
import { isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';

export const useDeviceStore = create(set => ({
  device: {},
  keyboard: { isActive: false },
  init: () => {
    const device = {};
    device.isMobileOnly = isMobileOnly;
    device.isMobile = isMobile;
    device.isTablet = isTablet;
    set({ device: device });
  },

  setKeyboardActive: active => {
    const keyboardActive = { isActive: active };
    set({ keyboard: keyboardActive });
  },
}));
