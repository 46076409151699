import { Suspense } from 'react';
import Product from './Product';
import { useEventStore } from 'services/EventService';
import { useDebugStore } from 'storage/debug';
import { useAssetDetails } from 'services/AssetDetailService';
import { useDebugAssetVersion } from '../../../services/DebugAssetVersion';
import React from 'react';

export default function Products({ district, visible }) {
  const isDetailMobile = useAssetDetails(state => state.isDetailMobile);
  const debugAssetVersion = useDebugAssetVersion(state => state.version);

  const products = useEventStore(state =>
    state.event.products.filter(p => {
      // filter products with meshMobile === none
      if (isDetailMobile && p.meshMobile && p.meshMobile.toLowerCase() === 'none') {
        return false;
      }
      if (!p.district) return false;
      const cleanDistrictString = p.district.replace(/\s/g, '');
      const districts = cleanDistrictString.split(',');
      return districts.includes(district.room);
    })
  );
  const debugStore = useDebugStore();

  if (debugStore.getMinimal3d()) return null;
  if (!products) return null;
  return (
    <group name={'Products'} visible={visible}>
      {products.map((product, i) => {
        const key = debugAssetVersion + ' ' + i;
        if (debugAssetVersion === 0) {
          return <Product key={key} product={product} />;
        } else {
          return (
            <Suspense key={key} fallback={null}>
              <Product product={product} />
            </Suspense>
          );
        }
      })}
    </group>
  );
}
