import PersonImage from './PersonImage';
import PersonInfos from './PersonInfos';
import PersonActions from './PersonActions';
import { PersonContainer } from './styles';

export default function Person({
  image,
  infoComponents,
  actionComponents,
  isExpert = false,
  margin,
  chatNotice = false,
  verticalCenter = false,
}) {
  return (
    <PersonContainer margin={margin}>
      <PersonImage {...image} isExpert={isExpert} chatNotice={chatNotice} />
      {infoComponents && <PersonInfos verticalCenter={verticalCenter} infoComponents={infoComponents} />}
      {actionComponents && <PersonActions verticalCenter={verticalCenter} actionComponents={actionComponents} />}
    </PersonContainer>
  );
}
