import { Matrix4, Ray, Vector3 } from 'three';

function ascSort(a, b) {
  return a.distance - b.distance;
}

const _matrixWorld = new Matrix4();
const _inverseMatrix = new Matrix4();
const _ray = new Ray();
const _intersectionPoint = new Vector3();
const _intersectionPointWorld = new Vector3();

function checkIntersection(object, raycaster, ray, pA, pB, pC, normal, point, backFaceCulling) {
  var intersect;

  intersect = ray.intersectTriangle(pA, pB, pC, backFaceCulling, point);

  if (intersect === null) return null;

  _intersectionPointWorld.copy(point);
  _intersectionPointWorld.applyMatrix4(_matrixWorld);

  var distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);

  return {
    face: {
      normal: normal,
    },
    distance: distance,
    point: _intersectionPointWorld.clone(),
  };
}

function raycast(object, raycaster, intersects, backFaceCulling) {
  if (_ray.intersectsBox(object.space) === false) return false;

  for (var f = 0; f < object.vertexes.length; f += 3) {
    const va = object.vertexes[f];
    const vb = object.vertexes[f + 1];
    const vc = object.vertexes[f + 2];
    const plane = object.planes[f / 3];
    const intersection = checkIntersection(
      object,
      raycaster,
      _ray,
      va,
      vb,
      vc,
      plane.normal,
      _intersectionPoint,
      backFaceCulling
    );
    if (intersection) intersects.push(intersection);
  }
  return true;
}

function intersectObject(object, raycaster, intersects, recursive, backFaceCulling) {
  const bbAccepted = raycast(object, raycaster, intersects, backFaceCulling);

  if (recursive === true && bbAccepted) {
    const children = object.children;

    for (let i = 0, l = children.length; i < l; i++) {
      intersectObject(children[i], raycaster, intersects, true, backFaceCulling);
    }
  }
}

class Raycaster {
  constructor() {
    this.ray = new Ray();
  }

  set(origin, direction) {
    this.ray.set(origin, direction);
  }

  intersectObject(object, recursive, optionalTarget, backFaceCulling = true) {
    const intersects = optionalTarget || [];
    _matrixWorld.copy(object.matrixWorld);
    _inverseMatrix.copy(object.matrixWorld).invert();
    _ray.copy(this.ray).applyMatrix4(_inverseMatrix);
    intersectObject(object.root, this, intersects, recursive, backFaceCulling);
    intersects.sort(ascSort);
    return intersects;
  }

  intersectObjects(objects, recursive, optionalTarget, backFaceCulling = true) {
    const intersects = optionalTarget || [];
    for (let i = 0, l = objects.length; i < l; i++) {
      _matrixWorld.copy(objects[i].matrixWorld);
      _inverseMatrix.copy(objects[i].matrixWorld).invert();
      _ray.copy(this.ray).applyMatrix4(_inverseMatrix);
      intersectObject(objects[i].root, this, intersects, recursive, backFaceCulling);
    }
    intersects.sort(ascSort);
    return intersects;
  }
}

export { Raycaster };
