import { Button } from 'common/components/Button';
import { IconContainer } from './styles';
import IconBookmark from 'assets/icons/Icon_Bookmark.svg';

import { useTranslate } from 'services/TranslationService/hooks';

export default function BookmarkButtonRegular({ active, clickHandler, style }) {
  const translate = useTranslate();
  return (
    <Button onClick={clickHandler} accent small style={style}>
      <IconContainer active={active}>
        <IconBookmark />
      </IconContainer>
      {translate('schedule.bookmark.button') || 'Add to Calendar'}
    </Button>
  );
}
