import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserStore } from 'services/UserService';
import ProfileView from './ProfileView';
import EditView from './EditView';
import useOpenCloseTracking from 'services/TrackingService/useOpenCloseTracking';

export const Container = styled.div`
  height: 100%;
`;

function Profile({ updateSize, user, playerIsExpert, tabs }) {
  const [editMode, setEditMode] = useState(false);

  useEffect(updateSize, [user, editMode]);

  useOpenCloseTracking('Profile');

  const isSelf = user && user.id === useUserStore.getState().user.id;

  return !user ? null : isSelf && editMode ? (
    <EditView tabs={tabs} user={user} setEditMode={setEditMode} updateSize={updateSize} />
  ) : (
    <ProfileView tabs={tabs} user={user} isSelf={isSelf} playerIsExpert={playerIsExpert} setEditMode={setEditMode} />
  );
}

export default Profile;
