import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ThreeCanvas from 'three/ThreeCanvas';
import { CustomizationUiGen2 } from './CustomizationUiGen2';
import AvatarScene from './AvatarScene';
import { useAvatarStore } from 'services/AvatarService';
import { useUserStore } from 'services/UserService';
import { useHistory } from 'react-router-dom';
import VisualLoader from '../Play/VisualLoader';
import { useEventStore } from 'services/EventService';
import ErrorFallbackUI from 'components/ErrorFallbackUI';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';
import { useGlobalHubStore } from 'services/GlobalHubService';
import { CustomizationUi } from './CustomizationUi';

function Done() {
  useEffect(() => {
    useDistrictWarmupStore.setState({ phase: WarmupPhase.ENTERING });
    setTimeout(() => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.DONE });
    }, 100);
    return () => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.IDLE });
    };
  }, []);
  return null;
}

function Error({ error }) {
  useEffect(() => {
    useDistrictWarmupStore.setState({ phase: WarmupPhase.ENTERING });
    setTimeout(() => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.DONE });
    }, 100);
    return () => {
      useDistrictWarmupStore.setState({ phase: WarmupPhase.IDLE });
    };
  }, []);
  return <ErrorFallbackUI message={error.message} />;
}

export default function CreateAvatar() {
  const history = useHistory();
  const useAvatars = useEventStore(state => state.event.useAvatars);
  const avatarGen = useEventStore(state => state.event.avatar.gen);

  const doneCallback = useCallback(() => {
    useUserStore.getState().changeAppearance(useAvatarStore.getState().getAppearance());
    useGlobalHubStore.getState().refreshGlobalPlayer();
    history.push('/');
  }, []);

  useEffect(() => {
    useDistrictWarmupStore.setState({ phase: WarmupPhase.LOADING });
  }, []);

  useEffect(() => {
    // skip avatar creation when in orbit mode (in the dumbest way possible)
    if (!useAvatars) doneCallback();
  }, [useAvatars, doneCallback]);

  const uiProps = useMemo(() => ({ initial: true, onDone: doneCallback }), [doneCallback]);

  return (
    <>
      <ThreeCanvas DoneComponent={Done} ErrorComponent={Error}>
        <AvatarScene />
      </ThreeCanvas>
      {avatarGen === 2 ? <CustomizationUiGen2 {...uiProps} /> : <CustomizationUi {...uiProps} />}
      <VisualLoader />
    </>
  );
}
