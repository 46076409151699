import React, { Suspense, useEffect, useMemo, useRef } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { usePostStore } from 'three/Renderer/store';
import ThreeCanvas from 'three/ThreeCanvas';
import {
  ArrowContainer,
  Container,
  Description,
  Headline,
  HeadlineContainer,
  Styled360Icon,
  StyledCanvas,
  StyledCol,
  StyledCols,
  StyledContentContainer,
  StyledFullScreenContent,
} from './styles';
import { useObjectViewerStore } from 'services/ObjectViewerService';
import { Color, LinearFilter, LinearMipMapLinearFilter, sRGBEncoding } from 'three';
import { useWindowStore } from 'services/WindowService';
import { useLightmaps } from 'services/MaterialService/hooks';
import turbineOpenImage from 'assets/turbine_open.png';
import turbineClosedImage from 'assets/turbine_closed.png';
import engineImage from 'assets/engine.png';
import { smallText } from './dummyCopy';
import VeTextLink from 'common/components/VeTextLink';
import Lights from '../../../Play/Environment/Lights';
import CubemapRGBM from '../../../Play/Environment/CubemapRGBM';

const productData = {
  turbineopenright_product: {
    type: 'Turbine',
    image: turbineOpenImage,
    imageCaption: 'Abb. 1.1 — Jet Engine Maintenance',
  },
  turbineclosed_product: {
    type: 'Turbine',
    image: turbineClosedImage,
    imageCaption: 'Abb. 1.2 — Jet Engine',
  },
  engine_product: {
    type: 'Engine',
    image: engineImage,
    imageCaption: 'Abb. 1.3 — V12 Engine',
  },
};

extend({ OrbitControls });

const configureLightMap = lightMap => {
  lightMap.flipY = false;
  lightMap.generateMipmaps = true;
  lightMap.anisotropy = 16;
  lightMap.minFilter = LinearMipMapLinearFilter;
  lightMap.magFilter = LinearFilter;
  lightMap.encoding = sRGBEncoding;
};

const ProductView = props => {
  const { product } = props.content;
  const { object, productLocation } = product;
  const offset = (productLocation && productLocation.split(',').map(a => parseFloat(a))) || [0, -1, 0];
  const productContents = useObjectViewerStore(state => state.productContents);

  const lightmaps = useLightmaps();

  useEffect(() => {
    const enabledContent = productContents.find(a => a.enabled);
    object.traverse(o => {
      if (o.material && !o.origMaterial) {
        o.origMaterial = o.material;
        o.material = o.material.clone();

        const lightmap = lightmaps.find(lm => {
          return o.name.includes(lm.tag);
        });

        if (lightmap) {
          configureLightMap(lightmap.texture);
          o.material.lightMap = lightmap.texture;
        }
      }
    });
    object.traverse(o => {
      const isOutside = o.name.endsWith('_component_outside');
      const isComponent = o.name.endsWith('_component');
      const tag = enabledContent && enabledContent.tag;
      const isHighlighted = isComponent && tag && o.name.includes(tag);
      if (isOutside) {
        o.visible = !enabledContent;
      } else if (isComponent) {
        o.visible = true;
      } else {
        o.visible = true;
      }
      if (isComponent) {
        o.traverse(p => {
          if (p.material) {
            p.material.color = isHighlighted ? new Color('#A50020') : p.origMaterial.color;
          }
        });
      }
    });
  }, [object, productContents]);
  return (
    <>
      <primitive object={object} position={offset} />
    </>
  );
};

const CameraAndProduct = props => {
  const { camera, gl } = useThree();
  const { domElement } = gl;
  const controls = useRef();

  useEffect(() => {
    camera.fov = 30;
    camera.zoom = 2;
    camera.position.z = 35;
    camera.position.y = 10;
    camera.updateProjectionMatrix();
  }, []);

  useFrame(() => {
    controls.current.update();
  });
  return (
    <>
      <orbitControls
        ref={controls}
        args={[camera, domElement]}
        enableDamping={true}
        enablePan={false}
        enableZoom={true}
        minDistance={20}
        maxDistance={50}
      />
      <ProductView {...props} />
    </>
  );
};

const cycleForward = () => {
  const { productContents } = useObjectViewerStore.getState();
  const current = productContents.find(a => a.enabled);
  const nextIndex = (productContents.indexOf(current) + 1) % productContents.length;
  useObjectViewerStore.setState({
    productContents: productContents.map((a, i) => {
      a.enabled = nextIndex === i;
      return a;
    }),
  });
};
const cycleBackward = () => {
  const { productContents } = useObjectViewerStore.getState();
  const current = productContents.find(a => a.enabled);
  const nextIndex = productContents.indexOf(current) - 1;
  useObjectViewerStore.setState({
    productContents: productContents.map((a, i) => {
      a.enabled = (nextIndex < 0 ? productContents.length - 1 : nextIndex) === i;
      return a;
    }),
  });
};

const ContentContainer = props => {
  const { product } = props.content;
  const productContents = useObjectViewerStore(state => state.productContents);
  useEffect(() => {
    if (product.productContents != null) {
      const productContents = product.productContents.map(a => {
        a.enabled = false;
        return a;
      });
      useObjectViewerStore.setState({ productContents });
    }
  }, []);
  const enabledContent = productContents.find(a => a.enabled);
  const enableArrows = enabledContent && productContents.length > 1;
  // const view = enabledContent || product;
  // const view = enabledContent || product;
  const isMobile = useWindowStore(state => state.isMobile);
  const productContent = productContents.find(c => c);
  const content = useMemo(() => {
    if (!productContent) return;
    return productData[productContent.tag];
  }, [productContent]);
  const { type = null, image = null, imageCaption = null } = content || {};

  return (
    <StyledContentContainer>
      <Container>
        <HeadlineContainer>
          <Styled360Icon />
          {enableArrows && isMobile && <ArrowContainer direction={'left'} onClick={cycleBackward} />}
          <Headline>{type && type}</Headline>
          <Headline>{productContent && productContent.headline}</Headline>
          <Headline>Detail 3D View</Headline>
          {enableArrows && isMobile && <ArrowContainer direction={'right'} onClick={cycleForward} />}
        </HeadlineContainer>
        <StyledCols>
          <StyledCol>
            <Description>{productContent && productContent.description}</Description>
            <Description small>{smallText}</Description>
          </StyledCol>
          <StyledCol>
            <img src={image} />
            <span className={'image-caption'}>{imageCaption}</span>
          </StyledCol>
        </StyledCols>
        <div>
          <VeTextLink as={'button'} size={20} role="link">
            Learn more
          </VeTextLink>
        </div>
        {/*<FooterNavigation currentProduct={product} />*/}
      </Container>

      {enableArrows && !isMobile && <ArrowContainer direction={'left'} onClick={cycleBackward} />}
      {enableArrows && !isMobile && <ArrowContainer direction={'right'} onClick={cycleForward} />}
    </StyledContentContainer>
  );
};

export default function ObjectViewer(props) {
  const isPaused = usePostStore(state => state.isPaused);
  return (
    <>
      {isPaused && (
        <StyledFullScreenContent>
          <StyledCanvas>
            <ThreeCanvas forceRendering={true} clearColor={0x444444}>
              <CubemapRGBM />
              <Lights />
              <CameraAndProduct {...props} />
            </ThreeCanvas>
          </StyledCanvas>
          <ContentContainer {...props} />
        </StyledFullScreenContent>
      )}
    </>
  );
}
