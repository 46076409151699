export const character = {
  skin: {
    1: '#f1d0c0',
    2: '#e6b8a3',
    3: '#bd8b6f',
    4: '#c7886a',
    5: '#9d714f',
    6: '#412e27',
  },
  hair: {
    1: '#3D3637',
    2: '#6C5F5B',
    3: '#A18066',
    4: '#ADADAD',
    5: '#E4E4E4',
    6: '#FFFFFF',
    7: '#e45241',
    8: '#d4a45d',
    9: '#ffd667',
    10: '#7a1e1e',
    11: '#32acaf',
    12: '#662df5',
  },
  top: {
    1: '#000000',
    2: '#3D3D3D',
    3: '#86e2c1',
    4: '#FFFFFF',
    5: '#10105f',
    6: '#213ed4',
    7: '#b01a0a',
    8: '#1ad05a',
    9: '#ffd156',
    10: '#ff4b37',
    11: '#a711dc',
    12: '#ed345e',
  },
};
