import styled from 'styled-components';
import { useChatStore } from 'services/ChatService';
import { animations, darkChatBackgroundStyle, lightChatBackgroundStyle } from 'uiLivi/styles';

export const StyledChatPendingOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
  opacity: 1;
  ${props => (props.isContent ? darkChatBackgroundStyle : lightChatBackgroundStyle)};
  ${props => props.show && animations.fadeOut};
`;

const ChatPendingOverlay = ({ isContent }) => {
  const isPending = useChatStore(state => state.pending);
  return <StyledChatPendingOverlay show={!isPending} isContent={isContent} />;
};

export default ChatPendingOverlay;
