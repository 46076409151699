import React, { useState } from 'react';
import { ColorPickerWrapper, Label, Toggle, Icon, ColorSelection, Color } from './styles';
import ColorPickerIcon from '../../../../assets/color_picker.png';

const colors = [
  '#FF0099',
  '#DB00FF',
  '#7000FF',
  '#0075FF',
  '#00D1FF',
  '#00FFC2',
  '#94DC4C',
  '#FFE600',
  '#FF7A00',
  '#FF0000',
];

const ColorPicker = () => {
  const [open, setOpen] = useState(false);
  return (
    <ColorPickerWrapper>
      <Toggle onClick={() => setOpen(!open)}>
        <Icon open={open} src={ColorPickerIcon} />
        <Label>
          hair
          <br /> color
        </Label>
      </Toggle>
      <ColorSelection open={open} count={colors.length}>
        {colors.map((color, index) => (
          <Color key={color} color={color} delayIndex={colors.length - index} />
        ))}
      </ColorSelection>
    </ColorPickerWrapper>
  );
};

export default ColorPicker;
