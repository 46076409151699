import { useWeatherStore } from '../Play/WeatherScene/store';
import { useDistrictStore } from '../../services/DistrictService';
import PlayAll from './PlayAll';
import WindHandler from './WindHandler';
import SunHandler from './SunHandler';

export default function AudioController() {
  const districtRoom = useDistrictStore(state => state.district?.room);
  const isWindSimulation = useWeatherStore(state => state.isWeather);

  switch (districtRoom) {
    case 'house':
    case 'construction':
      return <PlayAll />;
    case 'weather':
      return isWindSimulation ? <WindHandler /> : <SunHandler />;
  }

  return null;
}
