import React, { useRef, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';

import { useSceneStore } from 'services/SceneService';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';
import { useWeatherStore } from '../../store';
import { MathUtils } from 'three';

const MESH_TO_EXCLUDE = ['Cloud']; // add more, sun fog mountains?

function isSubstringMatch(string, array) {
  const lowercaseString = string.toLowerCase();
  return array.some(element => lowercaseString.includes(element.toLowerCase()));
}

export default function EnvironmentChoreography() {
  const renderable = useSceneStore(state => state.scene && state.scene.renderable);
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const isAnimating = useWeatherStore(state => state.isAnimating);
  const isTrackerScene = useWeatherStore(state => !state.isWeather);
  const { env } = smoothedValues;

  const phase = useDistrictWarmupStore(state => state.phase);
  const isLoaded = phase === WarmupPhase.DONE;

  const { scene } = useThree();

  const directionalLightShadowRef = useRef();
  const lightDependantMeshes = useRef([]);

  const animateLights = () => {
    lightDependantMeshes.current.forEach(el => {
      if (el.material.lightMap) {
        el.material.envMapIntensity = env.envMap.intensityLightMaps;
        el.material.lightMapIntensity = env.lightmap.intensity;
      } else {
        el.material.envMapIntensity = env.envMap.intensity;
      }
    });

    // DIRECTIONAL LIGHT SHADOW
    if (directionalLightShadowRef.current) {
      directionalLightShadowRef.current.intensity = env.directionalLight.intensity;
    }
  };

  useEffect(() => {
    if (!renderable && !isLoaded) return;

    lightDependantMeshes.current = [];
    scene.traverse(el => {
      if (el.name === 'dirLightWithShadow') {
        directionalLightShadowRef.current = el;
      }
      if (el.material && !isSubstringMatch(el.name, MESH_TO_EXCLUDE)) {
        lightDependantMeshes.current.push(el);
      }
      if (el.name === 'Sky') {
        el.material.uniforms.color1.value = env.sky.bottom;
        el.material.uniforms.color2.value = env.sky.top;
        el.material.uniforms.color3.value = env.sky.top;
      }
      if (el.name === 'Mountains') {
        el.material.emissive.set(0x000000);
      }
    });

    animateLights();
  }, [renderable, isLoaded]);

  useFrame(() => {
    if (!renderable || !isAnimating) return;

    // CHANGE LIGHTMAPS INTENSITY
    animateLights();
  });

  return null;
}
