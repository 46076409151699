import { usePlayerStore } from 'services/PlayerService';
import { useControlsStore } from 'services/ControlsService';
import { useFrame } from '@react-three/fiber';
import { handleBlur, handleKeyDown, handleKeyUp, isKeyPressed } from 'utilities/keyboard';
import { useEffect } from 'react';
import { globalPlayers, useGlobalHubStore } from 'services/GlobalHubService';
import { useDebugStore } from 'storage/debug';

export default function PlayerControls() {
  const playerActions = usePlayerStore.getState().playerActions;
  const flyCamEnabled = useDebugStore(state => state.getFlyCamEnabled());

  useFrame((state, delta) => {
    const joystickInput = useControlsStore.getState().joystickInput;
    const movement = { ...joystickInput };
    let isJoystick = true;
    const moveY = isKeyPressed('w') || isKeyPressed('s') || isKeyPressed('ArrowUp') || isKeyPressed('ArrowDown');
    const moveX = isKeyPressed('d') || isKeyPressed('a') || isKeyPressed('ArrowRight') || isKeyPressed('ArrowLeft');
    const forward = isKeyPressed('w') || isKeyPressed('ArrowUp');
    const backward = isKeyPressed('s') || isKeyPressed('ArrowDown');
    const left = isKeyPressed('a') || isKeyPressed('ArrowLeft');
    const right = isKeyPressed('d') || isKeyPressed('ArrowRight');
    if (moveY) {
      isJoystick = false;
      movement.y = forward ? 0.9 : backward ? -0.25 : 0;
    }
    if (moveX) {
      isJoystick = false;
      movement.x = left ? 1 : right ? -1 : 0;
    }
    if (flyCamEnabled) return;
    playerActions.move(movement.x, movement.y, isJoystick, delta);
    playerActions.simulate();
    playerActions.updatePosition(delta);
    useGlobalHubStore.getState().updatePosition();
  }, -50);

  const keyDownListener = event => {
    if (!['input', 'textarea'].includes(event.target.localName)) {
      handleKeyDown(event);
    }
  };

  useEffect(() => {
    window.addEventListener('blur', handleBlur);
    window.addEventListener('keydown', keyDownListener);
    window.addEventListener('keyup', handleKeyUp);
    playerActions.move(0, 0, false);
    return () => {
      handleKeyUp({ key: 'w' });
      handleKeyUp({ key: 'a' });
      handleKeyUp({ key: 's' });
      handleKeyUp({ key: 'd' });
      handleKeyUp({ key: 'ArrowUp' });
      handleKeyUp({ key: 'ArrowDown' });
      handleKeyUp({ key: 'ArrowLeft' });
      handleKeyUp({ key: 'ArrowRight' });
      window.removeEventListener('keydown', keyDownListener);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return null;
}
