import { useMemo, useRef, useState, Fragment, useCallback, useEffect } from 'react';
import {
  Bg2,
  Container,
  Description,
  Headline,
  Padder,
  YoutubeVideo,
  YoutubeVideoContainer,
  SmallHeadline,
  SystemsBrochureButtonContainer,
  VariationsList,
  StyledVariationWithVideo,
  Headline26_900,
  OtherVariationsHeadline,
  Fg,
  Bg1,
  VariationDivider,
  RoundedOverlayMargin,
  Header,
} from '../styles';
import Carousel from '../Carousel';
import { usePrevious } from '../../../../../utilities/hooks';
import { AnimatePresence } from 'framer-motion';
import Variation from '../Variation';
import CTAs from '../CTAs';
import Footer from '../Footer';
import Banners from '../Banners';
import SystemsBrochureButton from '../icons/systems-brochure-button.inline.svg';
import useContainerWidth from '../useContainerWidth';
import { VIEWPORT_S_MAX } from '../config';
import { SvgButtonHoverEffect } from '../../../../../uiLivi/styles';
import { useNavigationStore } from '../../../../../services/NavigationService';

function VariationWithVideo({ children }) {
  const [containerRef, containerWidth] = useContainerWidth();
  const isNarrow = containerWidth < VIEWPORT_S_MAX;

  return (
    <StyledVariationWithVideo
      $isNarrow={isNarrow}
      ref={containerRef}
      style={{ marginTop: `${120 + containerWidth * 0.05}px` }}
    >
      {children}
    </StyledVariationWithVideo>
  );
}

export default function ProductVariations({
  headline,
  bgSrc,
  video,
  description,
  isCarousel,
  constructionAssemblyId = null,
  variations = null,
  ctas,
}) {
  const [variationIndex, setVariationIndex] = useState(0);
  const nextVariationIndex = useRef(variationIndex);
  const prevVariationIndex = usePrevious(variationIndex);
  const scrollContainer = useRef(null);

  useEffect(() => {
    scrollContainer.current.scrollTo({ top: 0 });
  }, [headline]);

  const selectVariation = i => {
    nextVariationIndex.current = i;
    setVariationIndex(i);
  };

  const variation = variations && variations[variationIndex];
  const otherVariations = useMemo(() => variations && variations.filter((_, index) => index !== 0), [variations]);
  const animation = useMemo(
    () => ({
      initial: () => ({ x: `${(variationIndex - prevVariationIndex) * 110}%`, opacity: 0 }),
      animate: { x: '0%', opacity: 1, transition: { ease: 'easeOut', duration: 0.3 } },
      exit: () => ({
        opacity: 0,
        x: `${(nextVariationIndex.current - variationIndex) * -110}%`,
        transition: { ease: 'easeOut', duration: 0.3 },
      }),
    }),
    [variationIndex, prevVariationIndex]
  );

  const [containerRef, containerWidth] = useContainerWidth();
  const showProductInfoLayer = useNavigationStore(state => state.showProductInfoLayer);

  useEffect(() => {
    setVariationIndex(0);
  }, [headline]);

  const openWindow = useCallback(url => {
    return () => {
      window.open(url);
    };
  }, []);

  return (
    <Container ref={scrollContainer}>
      <RoundedOverlayMargin>
        <Bg1 />
        <Bg2 src={bgSrc} $containerWidth={containerWidth} />
        <Fg ref={containerRef}>
          <Header>
            <Padder>
              <SmallHeadline>TAKE A LOOK AT OUR</SmallHeadline>
              <Headline>{headline}</Headline>
              <Description maxWidth="433px">{description}</Description>
              {ctas.downloadBrochure && (
                <SystemsBrochureButtonContainer onClick={openWindow(ctas.downloadBrochure)}>
                  <SvgButtonHoverEffect>
                    <SystemsBrochureButton />
                  </SvgButtonHoverEffect>
                </SystemsBrochureButtonContainer>
              )}
            </Padder>
          </Header>
          {isCarousel && variations && (
            <Carousel variations={variations} index={variationIndex} selectVariation={selectVariation}>
              <Padder style={{ display: 'grid', gridTemplateAreas: '"center"' }}>
                <AnimatePresence initial={false}>
                  <Variation key={variationIndex} {...variation} animation={animation} isCarousel={isCarousel} isMain />
                </AnimatePresence>
              </Padder>
            </Carousel>
          )}
          {!isCarousel && variation && (
            <Padder>
              <VariationWithVideo>
                <Variation {...variation} isCarousel={isCarousel} isMain />
                {video && showProductInfoLayer && (
                  <YoutubeVideoContainer>
                    <YoutubeVideo src={video} />
                  </YoutubeVideoContainer>
                )}
              </VariationWithVideo>
            </Padder>
          )}
          {isCarousel && <CTAs {...ctas} constructionAssemblyId={constructionAssemblyId} />}
          {!isCarousel && otherVariations && (
            <VariationsList>
              <Padder>
                <Headline26_900>Switch rooms to learn more.</Headline26_900>
              </Padder>
              <Banners containerWidth={containerWidth} constructionAssemblyId={constructionAssemblyId} />
              <Padder>
                <OtherVariationsHeadline>
                  <Headline26_900>FIND THE RIGHT FIT.</Headline26_900>
                </OtherVariationsHeadline>
                {otherVariations.map((v, i) => (
                  <Fragment key={i}>
                    <Variation {...v} isCarousel={isCarousel} index={i} />
                    {i !== otherVariations.length - 1 && <VariationDivider />}
                  </Fragment>
                ))}
              </Padder>
            </VariationsList>
          )}
          {!isCarousel && <CTAs {...ctas} constructionAssemblyId={constructionAssemblyId} />}
          <Footer {...ctas} />
        </Fg>
      </RoundedOverlayMargin>
    </Container>
  );
}
