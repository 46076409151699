import moment from 'moment';

export function filterTalks(talks, context) {
  const { selectedDate, onlyBookmarks } = context;

  let filteredTalks = [...talks];

  if (selectedDate) {
    const date = new Date(selectedDate);
    const isAtSameDay = talk => moment(talk.startTime).isSame(date, 'day');
    filteredTalks = filteredTalks.filter(isAtSameDay);
  }

  if (onlyBookmarks) filteredTalks = filteredTalks.filter(t => t.isBookmarked);

  return filteredTalks;
}

export function filterMeetingsForExpert(meetings, context) {
  const { user, selectedDate, slotTopic, onlyMyCalls, onlyUnclaimed } = context;

  let filteredMeetings = [...meetings];

  if (selectedDate) {
    const date = new Date(selectedDate);
    const isAtSameDay = reservation => moment(reservation.slot.startDate).isSame(date, 'day');
    filteredMeetings = filteredMeetings.filter(isAtSameDay);
  }

  if (slotTopic) {
    const isSlotTopic = reservation => reservation.topic.id === slotTopic.id;
    filteredMeetings = filteredMeetings.filter(isSlotTopic);
  }

  if (onlyMyCalls) {
    const isMine = reservation => {
      if (reservation.expert == null) {
        return false;
      }
      return reservation.expert.id === user.id;
    };
    filteredMeetings = filteredMeetings.filter(isMine);
  } else if (onlyUnclaimed) {
    const isUnclaimed = reservation => reservation.expert == null;
    filteredMeetings = filteredMeetings.filter(isUnclaimed);
  }

  return filteredMeetings;
}

const groupByTime = (entries, oneGroup = true) => {
  if (entries.length === 0) return [];
  if (oneGroup) return [entries];
  const groups = [];
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    if (groups.length > 0 && groups[groups.length - 1][0].date.start.isSame(entry.date.start))
      groups[groups.length - 1].push(entry);
    else groups.push([entry]);
  }
  return groups;
};

export const getAllowedDatesFromTalks = talks => talks.map(t => new Date(t.startTime));

export const mapTimetableEntryGroupsFromTalks = (talks, clickHandler) => {
  return groupByTime(
    talks.map(talk => {
      // TODO: talks need startDate / endDate
      const startDate = new Date(talk.startTime);
      const endDate = new Date(startDate.getTime() + talk.duration * 60000);
      return {
        id: talk.id,
        topline: talk.speaker && `${talk.speaker.forename} ${talk.speaker.surname}`,
        headline: talk.title,
        date: { start: startDate, end: endDate },
        showLiveIndicator: true,
        bookmark: {
          show: true,
          active: talk.isBookmarked,
        },
        clickHandler: () => clickHandler(talk.id),
        button: null,
      };
    }),
    true
  );
};
