import { backButtonStyle } from 'common/components/CloseButton';
import Toast from 'common/components/Toast';
import { useNotificationStore } from 'services/NotificationService';
import { useSocketStore } from 'services/SocketService';
import create from 'utilities/zustand/create';

let socket = null;
const BUSY_DURATION_MILLIS = 1000 * 60 * 30;

export const useSlackStore = create((set, get) => ({
  notified: [],
  busy: [],
  open: null,
  updateTimeout: null,
  init: managedSocket => {
    socket = managedSocket;
    socket.on('slack/busy', data => {
      const busy = [...get().busy];
      busy.push({ name: data.targetName, time: Date.now() });
      get().updateBusyList();
      const updateTimeout = setTimeout(get().updateBusyList, BUSY_DURATION_MILLIS);
      set({ busy, updateTimeout });
      const toast = (
        <Toast
          headline={`${data.targetName} is not available at the moment.`}
          text={`Try again later or leave a message.`}
        />
      );
      useNotificationStore.getState().addNotification(toast);
    });
  },
  updateBusyList: () => {
    if (get().updateTimeout) clearTimeout(get().updateTimeout);
    const busy = get().busy.filter(b => Date.now() - b.time < BUSY_DURATION_MILLIS);
    set({ busy });
  },
  openUi: open => {
    set({ open });
  },
  closeUi: () => {
    set({ open: null });
  },
  requestTalk: (userId, userName, hubId, eventShortname) => {
    const notified = [...get().notified];
    const hubUrl = useSocketStore.getState().url + hubId + '/';
    if (!notified.includes(get().open.name)) {
      notified.push(get().open.name);
      set({ notified });
    }
    socket.emit(
      'slack/requestTalk',
      {
        userId,
        userName,
        targetName: get().open.name,
        webUrl: window.location.href.split('?')[0] + `?event=${eventShortname}&hubId=${hubId}`,
        hubUrl,
      },
      response => {
        console.log(response);
      }
    );
  },
  sendForm: (userName, form) => {
    socket.emit('slack/sendForm', { userName, targetName: get().open.name, form }, response => {
      console.log(response);
    });
  },
}));
