import React, { useMemo, useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Vector3, MathUtils, InstancedBufferAttribute, Object3D } from 'three';
import { useTexture } from '@react-three/drei';

import { useWeatherStore } from '../../WeatherScene/store';
import fromCdn from 'utilities/cdn';

import vertex from './shaders/index.vert';
import fragment from './shaders/index.frag';

import useFakeShadows from './useFakeShadows';
import FakeFloor from './FakeFloor';

const AMOUNT = 100;
const V3 = new Vector3();
const OBJ = new Object3D();

export default React.memo(() => {
  const data = useRef(null);
  const ref = useRef(null);
  const material = useRef(null);
  const geometry = useRef(null);

  const smoothedValues = useWeatherStore(state => state.smoothedValues);

  const map1 = useTexture(fromCdn(`/shared/districts/intersolar/weather/dom/particles/1.png`));
  const map2 = useTexture(fromCdn(`/shared/districts/intersolar/weather/dom/particles/2.png`));
  const map3 = useTexture(fromCdn(`/shared/districts/intersolar/weather/dom/particles/3.png`));
  const map4 = useTexture(fromCdn(`/shared/districts/intersolar/weather/dom/particles/4.png`));
  const map5 = useTexture(fromCdn(`/shared/districts/intersolar/weather/dom/particles/5.png`));
  const maps = [map1, map2, map3, map4, map5];

  const { fbo, quadCamera } = useFakeShadows();

  const createData = () => {
    data.current = Array.from({ length: AMOUNT }).map(() => {
      const speed = MathUtils.randFloat(0.8, 1.3);
      let threshold = 1.0 - Math.pow(MathUtils.randFloat(0.0, 1.0), 2);
      threshold = MathUtils.clamp(threshold - 0.1, 0, 1);
      const scale = MathUtils.randFloat(0.24, 0.65);
      const position = V3.set(0, 1 + Math.random() * 10, Math.random() * 40 + 20).clone();

      const progress = 1 + Math.random() * 0.5;
      const timeShift = Math.random() * 3;

      const axis = V3.set(
        MathUtils.randFloat(-1.0, 1.0),
        MathUtils.randFloat(-1.0, 1.0),
        MathUtils.randFloat(-1.0, 1.0)
      ).clone();

      return { position, progress, threshold, speed, axis, scale, timeShift };
    });
  };

  const updateGeometry = () => {
    const speedArr = [];
    const axisArr = [];
    const progressArr = [];
    const timeShiftArr = [];
    const idArr = [];

    data.current.forEach(el => {
      speedArr.push(el.speed);
      axisArr.push(el.axis.x, el.axis.y, el.axis.z);
      progressArr.push(el.progress);
      timeShiftArr.push(el.timeShift);
      idArr.push(MathUtils.randInt(0, maps.length - 1));
    });

    const iba1 = new InstancedBufferAttribute(new Float32Array(speedArr), 1);
    geometry.current.setAttribute('aSpeeed', iba1);

    const iba2 = new InstancedBufferAttribute(new Float32Array(progressArr), 1);
    geometry.current.setAttribute('aProgress', iba2);

    const iba4 = new InstancedBufferAttribute(new Float32Array(timeShiftArr), 1);
    geometry.current.setAttribute('aTimeShift', iba4);

    const iba3 = new InstancedBufferAttribute(new Float32Array(axisArr), 3);
    geometry.current.setAttribute('aAxis', iba3);

    const iba5 = new InstancedBufferAttribute(new Float32Array(idArr), 1);
    geometry.current.setAttribute('aMapId', iba5);
  };

  const setInitial = () => {
    data.current.forEach((el, i) => {
      OBJ.position.copy(el.position);
      OBJ.scale.set(el.scale, el.scale, el.scale);
      OBJ.updateMatrix();

      ref.current.setMatrixAt(i, OBJ.matrix);
    });

    ref.current.instanceMatrix.needsUpdate = true;
  };

  const shaderArgs = useMemo(() => {
    return {
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        uTime: { value: 0 },
        uRange: { value: [-120, 120] }, // dinamic?
        uMaps: { value: maps },
        uLight: { value: 1 },
        uIsShadow: { value: false },
      },
      defines: {
        MAPS_LENGTH: maps.length,
      },
      side: 2,
      transparent: true,
    };
  }, []);

  useEffect(() => {
    createData();
    updateGeometry();
    setInitial();
  }, []);

  useFrame((state, delta) => {
    shaderArgs.uniforms.uTime.value = state.clock.elapsedTime;
    shaderArgs.uniforms.uLight.value = MathUtils.lerp(
      smoothedValues.dampedSliderProgress,
      0,
      smoothedValues.scenesInterpolator
    );

    if (!data.current) return;

    data.current.forEach((el, i) => {
      let step =
        el.speed * delta * 0.05 +
        smoothedValues.wind.kmSpeed * 0.008 * 0.003 * (smoothedValues.dampedSliderProgress * 0.7 + 0.3);

      step = step > 0.53 ? 0.53 : step;
      el.progress += step;

      // RESPAWN
      if (
        el.progress >= 1 &&
        el.threshold < smoothedValues.dampedSliderProgress &&
        smoothedValues.scenesInterpolator < 0.5
      ) {
        el.progress = -Math.random() * 0.3;
      }
      geometry.current.attributes.aProgress.array[i] = el.progress;
    });

    // UPDATE
    geometry.current.attributes.aProgress.needsUpdate = true;

    state.gl.setRenderTarget(fbo);
    state.gl.clear();
    state.gl.setClearAlpha(0);
    shaderArgs.uniforms.uIsShadow.value = true;
    state.gl.render(ref.current, quadCamera);
    shaderArgs.uniforms.uIsShadow.value = false;
    state.gl.setRenderTarget(null);
  });

  return (
    <>
      <instancedMesh ref={ref} args={[null, null, AMOUNT]} matrixAutoUpdate={true} name={`Particles`} renderOrder={10}>
        <planeGeometry args={[1, 1, 1, 20]} ref={geometry} />
        <shaderMaterial ref={material} args={[shaderArgs]} />
      </instancedMesh>
      <FakeFloor camera={quadCamera} fbo={fbo} />
    </>
  );
});
