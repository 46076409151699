import { Container } from './styles';
import CloseButton from 'common/components/CloseButton';

export default function DetailContainer({ children, closeHandler }) {
  return (
    <Container>
      <CloseButton black isMinimizeButton position={{ top: 0, right: 0 }} handler={closeHandler} />
      {children}
    </Container>
  );
}
