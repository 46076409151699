import styled, { css } from 'styled-components';
import { panelStyle } from '../styles';
import { lgBaseColors } from 'uiLivi/styles';
import { H3, smallTextStyle, textOverflowEllipsis } from 'common/text';

const padding = '20px';

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: ${props => (!props.simplify ? '16px' : null)};
  bottom: ${props => (props.simplify ? '0px' : null)};
  width: 230px;
  height: ${props => (!props.simplify ? 'calc(100% - 32px)' : null)};
  display: flex;
  flex-direction: column;
  padding: ${padding};
  ${panelStyle}
`;

const flexContainerStyle = css`
  position: relative;
`;

export const BottomContainer = styled.div`
  ${flexContainerStyle}
`;

export const MiddleContainer = styled.div`
  flex-grow: 1;
  ${flexContainerStyle}
`;

export const TopContainer = styled.div`
  ${flexContainerStyle}
`;

export const Topline = styled.div`
  max-width: 170px;
  ${textOverflowEllipsis};
  ${smallTextStyle};
  color: ${lgBaseColors.grey.light};
`;

export const Headline = styled(H3)`
  margin-top: 4px;
  color: #fff;
`;
