import { InstructionHeadline, InstructionIconContainer, InstructionText, InstructionWrapper } from '../../../../styles';
import React from 'react';
import styled from 'styled-components';
import IconControl from '../../../assets/control.png';

const InstructionIcon = styled.div`
  width: 183px;
  height: 65px;
  background-image: url(${IconControl});
  background-size: cover;
`;

export default function OnboardingAssemble() {
  return (
    <InstructionWrapper>
      <InstructionIconContainer>
        <InstructionIcon />
      </InstructionIconContainer>
      <InstructionHeadline>MOVE THE SLIDER</InstructionHeadline>
      <InstructionText>Drag right for a step-by-step installation guide.</InstructionText>
    </InstructionWrapper>
  );
}
