import { usePlayerStore } from 'services/PlayerService';
import { useUserStore } from 'services/UserService';
import CharacterHitVolume from 'components/Play/CharacterHitVolume';
import Character from 'components/Play/Character';
import { BoxGeometry, Euler, Quaternion } from 'three';
import { MODE_DISTRICT, MODE_FITTING } from 'services/PlayerService/constants';
import { useDebugStore } from 'storage/debug';
import { useGlobalHubStore } from 'services/GlobalHubService';
import { Billboard, Text } from '@react-three/drei';
import React, { useRef, Suspense } from 'react';
import { useFrame } from '@react-three/fiber';
import fromCdn from 'utilities/cdn';

const MAX_NAME_LENGTH = 14;
export const limitTextLength = name => {
  if (!name) return '';
  return name.length > MAX_NAME_LENGTH ? name.slice(0, MAX_NAME_LENGTH - 4) + '...' : name;
};
function Player({ playerId }) {
  const player = usePlayerStore.getState().players[playerId];
  const reactionId = usePlayerStore(state => state.players[playerId].reaction);

  const selectedPlayer = usePlayerStore(state => state.selectedPlayer);
  const playerActions = usePlayerStore(state => state.playerActions);

  const isFitting = usePlayerStore(state => state.players[playerId].user.isFitting);
  const appearance = usePlayerStore(state => state.players[playerId].user.appearance);
  const userAppearance = useUserStore(state => state.player.user.appearance);

  const selfIsFitting = usePlayerStore(state => state.mode === MODE_FITTING);
  const selfIsOnDistrict = usePlayerStore(state => state.mode === MODE_DISTRICT);
  const isSelf = player.user.isSelf;

  const bilRef = useRef();
  const dummyObjectRef = useRef();

  useFrame((_, delta) => {
    if (bilRef.current) {
      const isVisible = selfIsFitting ? isSelf : selfIsOnDistrict;
      const position = usePlayerStore.getState().position;

      const dx = position[0] - player.position[0];
      const dz = position[2] - player.position[2];
      const dist = dx * dx + dz * dz;
      bilRef.current.visible = isVisible && dist < 100;
      bilRef.current.position.set(player.position[0], player.position[1] + 2.0, player.position[2]);
    }
    if (dummyObjectRef.current) {
      dummyObjectRef.current.position.set(player.position[0], player.position[1], player.position[2]);
      const quaternion = new Quaternion().setFromEuler(new Euler().set(0, player.rotation, 0));
      dummyObjectRef.current.quaternion.slerp(quaternion, 1.0 - Math.pow(0.00002, delta));
    }
  }, 1000);

  const onPlayerClick = e => {
    e.stopPropagation();
    if (selectedPlayer === player) {
      playerActions.select(null);
    } else {
      playerActions.select(player);
    }
    useUserStore.getState().selectUserById(player.user.id);
  };

  const isVisible = selfIsFitting ? isSelf : selfIsOnDistrict;
  const isSelfAndFitting = selfIsFitting && isSelf;

  if (useDebugStore.getState().getMinimal3d())
    return (
      <>
        <group ref={dummyObjectRef}>
          <mesh position={[-0.07, 0.7, 0]} scale={[0.1, 1.7, 0.1]}>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={'white'} roughness={0.5} metalness={0} envMapIntensity={0} />
          </mesh>
          <mesh position={[-0.07, 1.63, 0]} scale={[0.13, 0.13, 0.14]}>
            <sphereGeometry args={[1, 32, 32]} />
            <meshStandardMaterial color={'white'} roughness={0.5} metalness={0} envMapIntensity={0} />
          </mesh>
        </group>
      </>
    );

  return (
    <>
      <Character
        name={'Character-' + player.user.name}
        visible={isVisible}
        frustumCulled={!isSelf}
        playerData={!isSelfAndFitting ? player : { ...player, velocity: [0, 0, 0, 0] }}
        reactionId={reactionId}
        isSelf={isSelf}
        indicatorVisible={isFitting}
        appearance={isSelf ? userAppearance : appearance}
      >
        {!isSelf && <CharacterHitVolume handleClick={onPlayerClick} name={player.user.name} />}
      </Character>
      {!isSelf && (
        <Suspense fallback={null}>
          <Billboard ref={bilRef} name={'NamePlate-' + player.user.name}>
            <Text
              position={[0, 0, 0]}
              fillOpacity={0.7}
              color={'#ffffff'}
              fontSize={0.1}
              maxWidth={200}
              lineHeight={1}
              letterSpacing={0.02}
              textAlign={'left'}
              font={fromCdn('/shared/fonts/demodern-medium-webfont.woff')}
              anchorX="center"
              anchorY="middle"
            >
              {limitTextLength(player.user.name)}
            </Text>
          </Billboard>
        </Suspense>
      )}
    </>
  );
}

export default function Players() {
  const playerIds = usePlayerStore(state => state.playerIds);
  const userIds = useGlobalHubStore(state => state.userIds);

  return (
    <group name={'Players'}>
      {playerIds.map(playerId => {
        const players = usePlayerStore.getState().players;
        const isGlobalPlayer = userIds.includes(players[playerId].user.id);

        if (isGlobalPlayer) return null;

        return <Player key={playerId} playerId={playerId} />;
      })}
    </group>
  );
}
