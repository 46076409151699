const baseAttributes = {
  width: '100%',
  height: '100%',
  frameBorder: '0',
};

export default function IFrame({ onFinishInitialize, attributes }) {
  const combinedAttributes = { ...baseAttributes, ...attributes };
  return <iframe onLoad={onFinishInitialize} {...combinedAttributes} />;
}
