/* eslint-disable object-property-newline */
/* eslint-disable no-multi-assign */
import React, { useMemo, useRef, useEffect } from 'react';
import { extend, useFrame } from '@react-three/fiber';
import { Vector3, InstancedBufferAttribute, Object3D, MeshDepthMaterial, RGBADepthPacking } from 'three';

import Material from './Material';
import CustomDepthMaterial from './CustomDepth';
import useAssets from './useAssets';

import { useWeatherStore } from '../../WeatherScene/store';
import { useEnvironmentStore } from 'components/Play/Environment/store';

import renderOrderConfig from '../../WeatherScene/config/renderOrder';

extend({ Material });

const OBJ = new Object3D();
const V3 = new Vector3();

/**
 *  leavesPivot: is essentially the center of the tree where leaves are attached to.
 *               this is useful because Palms models are not vertically center, used to prevent stretch on leaves in the same direction of wind
 */

export default function InstancedTrees({
  data,
  assets,
  leavesPivot = [0, 0, 0],
  config,
  renderOrder,
  castShadow = false,
}) {
  const weatherConfiguration = useWeatherStore(state => state.weatherConfiguration);
  const isAnimating = useWeatherStore(state => state.isAnimating);
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const environmentConfiguration = useEnvironmentStore(state => state.environmentConfiguration);

  const isWeatherTree = !config;

  const ref = useRef(null);
  const amount = data.length;

  const { dataTxt, originalMaterial, noiseTxt, originalGeometry } = useAssets({ assets });

  const material = useMemo(() => {
    const windConf = config ? config : weatherConfiguration.wind;

    return new Material({
      map: originalMaterial?.map ? originalMaterial.map.clone() : noiseTxt.clone(), // mostly debug
      dataTexture: dataTxt,
      noiseTexture: noiseTxt,
      leavesPivot: leavesPivot,
      ...windConf,
    });
  }, []);

  const customDepthMaterial = useMemo(() => {
    if (!castShadow) return null;
    const windConf = config ? config : weatherConfiguration.wind;

    return new CustomDepthMaterial({
      depthPacking: RGBADepthPacking,
      map: originalMaterial?.map ? originalMaterial.map.clone() : noiseTxt.clone(), // mostly debug
      dataTexture: dataTxt,
      noiseTexture: noiseTxt,
      leavesPivot: leavesPivot,
      ...windConf,
    });
  }, []);

  const geometry = useMemo(() => {
    // MAKE THE ATTRIBUTE NAME DYNAMIC?
    const c = originalGeometry.clone();
    if (c.attributes?.texcoord_2) {
      c.attributes.uvWind = c.attributes?.texcoord_2?.clone();
    } else {
      c.attributes.uvWind = c.attributes?.uv?.clone();
    }
    return c;
  }, []);

  useEffect(() => {
    ref.current.renderOrder = isWeatherTree ? renderOrderConfig.TREE : renderOrder;

    /**
     * ADD ATRTIBUTES
     */
    const overAllFactorArr = [];
    const timeShiftArr = [];

    data.forEach(el => {
      overAllFactorArr.push(el.overAllFactor);
      timeShiftArr.push(el.timeShift);
    });

    const iba1 = new InstancedBufferAttribute(new Float32Array(overAllFactorArr), 1);
    geometry.setAttribute('aOverAllFactor', iba1);

    const iba2 = new InstancedBufferAttribute(new Float32Array(timeShiftArr), 1);
    geometry.setAttribute('aTimeShift', iba2);

    data.forEach((el, i) => {
      OBJ.position.fromArray(el.position);
      OBJ.rotation.fromArray(el.rotation);
      OBJ.scale.set(el.scale, el.scale, el.scale);
      OBJ.updateMatrix();
      ref.current.setMatrixAt(i, OBJ.matrix);
    });

    ref.current.instanceMatrix.needsUpdate = true;

    /**
     * SET THE HEIGHT TO NORMALIZE THE PIVOT AND HEIGHT
     */
    geometry.computeBoundingBox();
    const v = new Vector3();
    geometry.boundingBox.getSize(v);

    material.setBounds(v);

    if (castShadow) {
      customDepthMaterial.setBounds(v);
    }
  }, [data]);

  useEffect(() => {
    if (castShadow) {
      ref.current.customDepthMaterial = customDepthMaterial;
    }
  }, []);

  useEffect(() => {
    if (isWeatherTree) return;
    // HARDCODE TO LOWER DOWN OVER-EXPOSURE IN LOBBY
    material.envMapIntensity = 1.4;

    material.windStrength.value = config.windStrength;
    material.baseWind.value = config.baseWind;
    material.heightFactor.value = config.heightFactor;
    material.blueFactor.value = config.blueFactor;
    material.redFactor.value = config.redFactor;
    material.greenFactor.value = config.greenFactor;
    material.noiseScale.value = config.noiseScale;

    if (castShadow) {
      customDepthMaterial.windStrength.value = config.windStrength;
      customDepthMaterial.baseWind.value = config.baseWind;
      customDepthMaterial.heightFactor.value = config.heightFactor;
      customDepthMaterial.blueFactor.value = config.blueFactor;
      customDepthMaterial.redFactor.value = config.redFactor;
      customDepthMaterial.greenFactor.value = config.greenFactor;
      customDepthMaterial.noiseScale.value = config.noiseScale;
    }
  }, []);

  useEffect(() => {
    if (!isWeatherTree) return;
    // UPDATE PARAMS GUI
    material.windStrength.value = smoothedValues.wind.windStrength;
    material.baseWind.value = smoothedValues.wind.baseWind;
    material.heightFactor.value = smoothedValues.wind.heightFactor;
    material.blueFactor.value = smoothedValues.wind.blueFactor;
    material.redFactor.value = smoothedValues.wind.redFactor;
    material.greenFactor.value = smoothedValues.wind.greenFactor;
    material.noiseScale.value = smoothedValues.wind.noiseScale;

    if (castShadow) {
      customDepthMaterial.windStrength.value = smoothedValues.wind.windStrength;
      customDepthMaterial.baseWind.value = smoothedValues.wind.baseWind;
      customDepthMaterial.heightFactor.value = smoothedValues.wind.heightFactor;
      customDepthMaterial.blueFactor.value = smoothedValues.wind.blueFactor;
      customDepthMaterial.redFactor.value = smoothedValues.wind.redFactor;
      customDepthMaterial.greenFactor.value = smoothedValues.wind.greenFactor;
      customDepthMaterial.noiseScale.value = smoothedValues.wind.noiseScale;
    }
  }, [weatherConfiguration]);

  useFrame((state, delta) => {
    if (!isWeatherTree) {
      material.time.value += 0.5 * delta * config.speedFactor;

      if (castShadow) {
        customDepthMaterial.time.value += 0.5 * delta * config.speedFactor;
      }
    }

    if (isWeatherTree) {
      material.time.value = smoothedValues.time;
      if (castShadow) {
        customDepthMaterial.time.value = smoothedValues.time;
      }

      if (!isAnimating) return;
      material.windStrength.value = smoothedValues.wind.windStrength;
      material.baseWind.value = smoothedValues.wind.baseWind;
      material.heightFactor.value = smoothedValues.wind.heightFactor;
      material.blueFactor.value = smoothedValues.wind.blueFactor;
      material.redFactor.value = smoothedValues.wind.redFactor;
      material.greenFactor.value = smoothedValues.wind.greenFactor;
      material.noiseScale.value = smoothedValues.wind.noiseScale;

      if (castShadow) {
        customDepthMaterial.windStrength.value = smoothedValues.wind.windStrength;
        customDepthMaterial.baseWind.value = smoothedValues.wind.baseWind;
        customDepthMaterial.heightFactor.value = smoothedValues.wind.heightFactor;
        customDepthMaterial.blueFactor.value = smoothedValues.wind.blueFactor;
        customDepthMaterial.redFactor.value = smoothedValues.wind.redFactor;
        customDepthMaterial.greenFactor.value = smoothedValues.wind.greenFactor;
        customDepthMaterial.noiseScale.value = smoothedValues.wind.noiseScale;
      }
    }
  });

  return (
    <instancedMesh name={`Tree`} ref={ref} args={[null, null, amount]} matrixAutoUpdate={false} castShadow>
      <primitive object={geometry} />
      <primitive object={material} />
    </instancedMesh>
  );
}
