import { useModalService } from 'services/ModalService';
import styled from 'styled-components';
import { colors, zIndex } from 'uiLivi/styles';
import { hexToRgba } from 'utilities/color';

const StyledModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.modalAndNotifications};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${hexToRgba(colors.background.base, 0.5)};
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, ${hexToRgba('#d6eade', 0.8)} 0%, ${hexToRgba('#005E61', 0.8)} 100%);
  backdrop-filter: blur(4px);
  pointer-events: all;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.5s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  overflow-y: auto;
`;

const Modal = () => {
  const modals = useModalService(state => state.modals);
  const isVisible = useModalService(state => state.modals.length > 0);

  return <StyledModal isVisible={isVisible}>{modals[0]}</StyledModal>;
};

export default Modal;
