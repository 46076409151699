import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SlackWrapper, ContentWrapper, Topline, Headline, Paragraph } from './styles';
import { useSlackStore } from 'services/SlackService';
import { Button } from 'common/components/Button';
import { useUserStore } from 'services/UserService';
import CloseButton from 'common/components/CloseButton';
import Start from './contents/Start';
import Notified from './contents/Notified';
import MessageForm from './contents/MessageForm';
import MessageSent from './contents/MessageSent';
import { useEventStore } from 'services/EventService';
import { useSocketStore } from 'services/SocketService';
import Toast from 'common/components/Toast';
import { useNotificationStore } from 'services/NotificationService';

const getScreens = (name, requestTalk, validateAndSendForm, setActiveContent) => ({
  start: {
    contentComponentFN: Start,
    button: {
      label: `Notify ${name}`,
      handler: requestTalk,
    },
  },
  notified: {
    contentComponentFN: Notified,
    button: {
      label: 'Leave a message',
      handler: () => setActiveContent('messageForm'),
    },
  },
  messageForm: {
    contentComponentFN: MessageForm,
    button: {
      label: 'Send message',
      handler: validateAndSendForm,
    },
  },
  messageSent: {
    contentComponentFN: MessageSent,
    button: {
      label: 'Got it!',
      handler: useSlackStore.getState().closeUi,
    },
  },
});

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Slack = () => {
  const [activeContent, setActiveContent] = useState('start');
  const formState = useState({ mail: null, phone: null, message: null });
  const [form] = formState;
  const userName = useUserStore(state => state.user.forename);
  const userId = useUserStore(state => state.user.id);
  const hubId = useSocketStore(state => state.hubId);
  const eventShortname = useEventStore(state => state.event.shortname);
  const open = useSlackStore(state => state.open);
  const { name } = open;

  const validateAndSendForm = useCallback(() => {
    const { mail, phone, message } = form;
    if (!mail && !phone && !message) {
      const toast = (
        <Toast
          level="error"
          position="bottom"
          headline="Nothing to send."
          text="Please fill out at least one of the fields."
        />
      );
      useNotificationStore.getState().addNotification(toast);
    } else if (mail && !validateEmail(mail)) {
      const toast = (
        <Toast
          level="error"
          position="bottom"
          headline="Mail is not valid."
          text="Enter a valid mail adress. Please check the format: yourname@example.com"
        />
      );
      useNotificationStore.getState().addNotification(toast);
    } else if (!phone) {
      const toast = (
        <Toast level="error" position="bottom" headline="Phone number required." text="Enter a valid phone number." />
      );
      useNotificationStore.getState().addNotification(toast);
    } else {
      useSlackStore.getState().sendForm(userName, form);
      setActiveContent('messageSent');
    }
  }, [form]);

  const screens = useMemo(
    () =>
      getScreens(
        name,
        () => useSlackStore.getState().requestTalk(userId, userName, hubId, eventShortname),
        validateAndSendForm,
        setActiveContent
      ),
    [name, userName, form]
  );

  const activeScreen = useMemo(() => screens[activeContent], [activeContent, screens]);
  const busy = useSlackStore(state => state.busy);
  const notified = useSlackStore(state => state.notified);
  const isNotified = useMemo(() => notified.includes(name), [notified, name]);
  const isBusy = useMemo(() => busy.filter(b => b.name === name).length > 0, [busy, name]);
  useEffect(() => {
    if (isNotified) setActiveContent('notified');
  }, [isNotified]);

  useEffect(() => {
    if (isBusy && activeContent === 'start') setActiveContent('notified');
  }, [isBusy, activeContent]);

  return (
    <SlackWrapper>
      <ContentWrapper>
        <CloseButton noBackground handler={useSlackStore.getState().closeUi} position={{ top: 0, right: 0 }} />
        <activeScreen.contentComponentFN open={open} formState={formState} isBusy={isBusy} />
      </ContentWrapper>
      <Button fullWidth noRadius veStyle onClick={activeScreen.button.handler}>
        {activeScreen.button.label}
      </Button>
    </SlackWrapper>
  );
};

export default Slack;
