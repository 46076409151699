import create from 'utilities/zustand/create';

export const useResourceStore = create(set => ({
  activeResource: null,

  setActiveResource: resource => {
    set({ activeResource: resource });
  },

  clearActiveResource: () => {
    set({ activeResource: null });
  },
}));
