import {
  A,
  Headline_34_700,
  Headline_18_700,
  Headline_18_900,
  Side,
  Sides,
  VariationContainer,
  Description,
} from '../styles';
import NewWindowIcon from '../icons/new-window-icon.svg';
import useContainerWidth from '../useContainerWidth';
import { VIEWPORT_S_MAX } from '../config';

export default function Variation({ isCarousel, name, url, rendering, description, animation, index, isMain }) {
  const [containerRef, containerWidth] = useContainerWidth();
  const isNarrow = containerWidth < VIEWPORT_S_MAX;

  return (
    <VariationContainer {...animation} ref={containerRef}>
      {isMain && isCarousel && <Headline_34_700>{name}</Headline_34_700>}

      {isMain || isCarousel ? (
        <>
          {!isCarousel && <Headline_18_700>{name}</Headline_18_700>}
          <Description $textAlign="left">{description?.join('')}</Description>
          <A href={url} target="_blank" data-sound-action="click" $isCarousel={isCarousel}>
            LEARN MORE <NewWindowIcon />
          </A>
        </>
      ) : (
        <Sides flipped={index % 2 === 0} $isNarrow={isNarrow}>
          <Side width={0.62} $isNarrow={isNarrow}>
            <img src={rendering} />
          </Side>
          <Side width={0.38} $isNarrow={isNarrow}>
            <Headline_18_900>{name}</Headline_18_900>
            <Description maxWidth={isNarrow ? '80%' : '100%'}>{description?.join('')}</Description>
            <A href={url} target="_blank" data-sound-action="click" $isCarousel={isCarousel}>
              LEARN MORE <NewWindowIcon />
            </A>
          </Side>
        </Sides>
      )}
    </VariationContainer>
  );
}
