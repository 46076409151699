export default /* glsl */ `
#if defined( RE_IndirectDiffuse )

	#ifdef USE_LIGHTMAP

		vec4 lightMapTexel = texture2D( lightMap, vUv2 );
    vec3 lightMapIrradiance = 2.0 * czm_saturation(hueShift(lightMapTexel.rgb, hueShiftValue), saturationValue);

		vec4 lightMapScreenTexel = texture2D( lightMapScreen, vUv2);

    vec3 screenLightmap = lightMapScreenTexel.rgb;
    float screenIntensity = screenLightmap.b;
    vec2 screenUV = vec2(
      1.0/screenLightmap.b * screenLightmap.r,
      1.0/screenLightmap.b * screenLightmap.g
    );

    vec3 lmColor = abs(texture2D(stream, screenUV).rgb * 4.0);
    lmColor = pow(lmColor, vec3(0.5));
    
    lightMapIrradiance += lmColor * pow(screenLightmap.b, 1.5) * 2.0;
        
		irradiance += lightMapIrradiance;

	#endif

	#if defined( USE_ENVMAP ) && defined( STANDARD ) && defined( ENVMAP_TYPE_CUBE_UV )

		iblIrradiance += getIBLIrradiance( geometry.normal );

	#endif

#endif

#if defined( USE_ENVMAP ) && defined( RE_IndirectSpecular )

	radiance += getIBLRadiance( geometry.viewDir, geometry.normal, material.roughness );

	#ifdef USE_CLEARCOAT

		clearcoatRadiance += getIBLRadiance( geometry.viewDir, geometry.clearcoatNormal, material.clearcoatRoughness );

	#endif

#endif
`;
