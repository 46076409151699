import { useEffect } from 'react';
import { useDebugStore } from 'storage/debug';
import { isKeyPressed } from 'utilities/keyboard';

export default function useDebugHotkeys() {
  useEffect(() => {
    const debugStore = useDebugStore.getState();
    const keydown = e => {
      if (!debugStore.getDeveloperContentEnabled()) {
        return;
      }
      if (isKeyPressed('control')) {
        if (e.key === '1') {
          const { uiOff } = debugStore.getUi();
          if (uiOff) {
            debugStore.setUi('livi');
            debugStore.setUiDisabled(false);
          } else {
            debugStore.setUi('off');
            debugStore.setUiDisabled(true);
          }
          e.preventDefault();
          e.stopPropagation();
        }
        if (e.key === '2') {
          const enabled = debugStore.getFlyCamEnabled();
          debugStore.setFlyCamEnabled(!enabled);
          e.preventDefault();
          e.stopPropagation();
        }
      }
    };
    window.addEventListener('keydown', keydown);
    return () => {
      window.removeEventListener('keydown', keydown);
    };
  }, []);
}
