import { useEffect, useMemo, useRef, useState } from 'react';
import { useWindowStore } from '../../../../services/WindowService';

export default function useContainerWidth() {
  const [render, setRender] = useState(false);
  const width = useWindowStore(state => state.width);
  const containerRef = useRef(null);

  const containerWidth = useMemo(() => {
    if (!containerRef.current) return 0;

    const computedStyle = getComputedStyle(containerRef.current);
    const padding = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    return containerRef.current.getBoundingClientRect().width - padding;
  }, [containerRef.current, width, render]);

  useEffect(() => {
    setRender(true);
  }, []);

  return [containerRef, containerWidth];
}
