import styled from 'styled-components';
import { easings, lgBaseColors } from 'uiLivi/styles';

const borderRadius = '20px';

export const TextContainer = styled.div`
  padding-left: 50px;
  position: relative;
  top: 11px;
  z-index: 1;
  transition: color 0.15s;
  color: ${props => (props.selected ? '#000' : '#fff')};
`;

export const WhiteBox = styled.div`
  position: absolute;
  height: 40px;
  width: ${props => (props.selected ? '90%' : '40px')};
  background-color: ${lgBaseColors.grey.light};
  border-radius: ${borderRadius};
  transition: width 0.5s ${easings.easeOutStrong};
`;

export const IconContainer = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  left: 0;
`;

export const ButtonContainer = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  height: 40px;
  margin: 5px 0 15px 0;
  cursor: pointer;
  &:hover {
    ${WhiteBox} {
      width: 90%;
    }
  }
`;

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: contain;
`;

export const OptionalButton = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: absolute;
  pointer-events: all;
  cursor: pointer;
  height: 40px;
  width: 40px;
  left: 0;
  background-color: ${lgBaseColors.red.base};
  border-radius: ${borderRadius};
`;

export const OptionalButtonText = styled.div`
  color: #fff;
  position: absolute;
  line-height: 40px;
  left: 50px;
  width: 140px;
`;
