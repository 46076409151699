import styled from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { mediaMax, mediaMaxPx } from 'common/layout';
import { H2, H3 } from 'common/text';
import { hexToRgba } from 'utilities/color';

export const CountdownContainer = styled.div`
  color: ${colors.font.strong};
  padding: calc(10vw + 20px) 10vw 10vw;
  text-align: center;
  font-weight: bold;
`;

export const TimeContainer = styled.div`
  font-weight: bold;
  color: ${colors.accent.base};
`;
export const IntroText = styled(H2)`
  text-transform: capitalize;
  margin-bottom: 46px;
  ${mediaMax('small', 'margin-top: 40px;')};
`;
export const TimeColumn = styled.div`
  display: inline-block;
  ${mediaMax('small', 'display: block;  margin-bottom: 40px;')};
  vertical-align: top;
  margin: 0 5px;
`;

export const TimeDigits = styled.div`
  ${mediaMaxPx(910, 'font-size: 6vw;')};
  transition: ease-in-out 0.2s;
  font-size: 75px;
  color: white;
`;

export const ColonDigit = styled(TimeDigits)`
  ${mediaMax('small', 'display: none')};
  display: inline-block;
  padding: 4px;
  margin: 0 4px 0 4px;
`;

export const SingleDigit = styled.div`
  background-color: ${hexToRgba(lgBaseColors.grey.dark, 0.85)};
  backdrop-filter: blur(4px);
  display: inline-block;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  padding: 2px 8px;
  margin: 0 4px 0 4px;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(black, rgba(0, 0, 0, 0.3)) 1;
`;
export const TimeUnit = styled(H3)`
  margin: 8px auto 0 auto;
`;
