import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Like from 'uiLivi/assets/Emojis/emoji_like.png';
import Love from 'uiLivi/assets/Emojis/emoji_love.png';
import Clap from 'uiLivi/assets/Emojis/emoji_clap.png';
import Smile from 'uiLivi/assets/Emojis/emoji_smile.png';
import Celebration from 'uiLivi/assets/Emojis/emoji_celebration.png';
import Greet from 'uiLivi/assets/Emojis/emoji_greet.png';

import { useChatStore } from 'services/ChatService';
import { zIndex } from 'uiLivi/styles';

import { gsap, Power2, Sine, TweenMax } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

const offsetCollapsed = 66;
const offsetExpanded = offsetCollapsed + 320;

const FXContainer = styled.div`
  position: absolute;
  z-index: ${zIndex.chat};
  width: 10px;
  height: 10px;
  bottom: 0;
  right: ${props => (props.chatExpanded ? offsetExpanded : offsetCollapsed)}px;
  pointer-events: none;
`;

const EmojiSprite = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: -30px;
  right: 30px;
  pointer-events: none;
  background: url(${props => props.background}) no-repeat;
  background-size: contain;
`;

const getSign = () => {
  return Math.random() > 0.5 ? -1 : 1;
};

const getRandomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getImageByType = type => {
  switch (type) {
    case 'like':
      return Like;
    case 'love':
      return Love;
    case 'clap':
      return Clap;
    case 'smile':
      return Smile;
    case 'celebration':
      return Celebration;
    case 'greet':
      return Greet;
  }
};

function Emote(props) {
  const { type } = props;
  const sprite = useRef(null);

  useEffect(() => {
    const duration = 1.5 + Math.random();
    const path = [
      { x: 0, y: -30 },
      { x: getSign() * getRandomBetween(10, 25), y: -getRandomBetween(65, 85) },
      { x: 0, y: -getRandomBetween(140, 160) },
      { x: getSign() * getRandomBetween(10, 50), y: -getRandomBetween(215, 235) },
      { x: 0, y: -getRandomBetween(280, 350) },
    ];
    TweenMax.set(sprite.current, { right: 30, x: 0, y: -30 });
    TweenMax.to(sprite.current, duration, {
      motionPath: { curviness: 0.45, path: path, autoRotate: 90 },
      ease: Sine.easeOut,
    });
    TweenMax.to(sprite.current, 0.5, {
      opacity: 0,
      scale: 1.5,
      delay: duration - 0.5,
      ease: Power2.easeIn,
    });
  }, []);

  return <EmojiSprite ref={sprite} background={getImageByType(type)} />;
}

export default function EmojiFX() {
  const [emoteSprites, setEmoteSprites] = useState([]);
  const emoji = useChatStore(state => state.emoji);
  const isChatOpen = useChatStore(state => state.isActive);
  const timeout = useRef();

  useEffect(() => {
    if (emoji != null) {
      setEmoteSprites(emoteSprites => emoteSprites.concat(emoji));
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        setEmoteSprites([]);
      }, 3000);
    }
  }, [emoji]);

  return (
    <FXContainer chatExpanded={isChatOpen}>
      {emoteSprites.map((item, i) => {
        return <Emote key={i} type={item.emoji} />;
      })}
    </FXContainer>
  );
}
