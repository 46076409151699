import create from 'utilities/zustand/create';
import axios from 'axios';
import debugStore from 'storage/debug';

export const LOCAL = 'LOCAL';
export const DEVELOPMENT = 'DEVELOPMENT';
export const STAGING = 'STAGING';
export const PRODUCTION = 'PRODUCTION';

const INTEGRATIONS = [
  { hostname: 'localhost', target: DEVELOPMENT },
  { hostname: 'metaverse.dev-machine.de', target: DEVELOPMENT },
  { hostname: 'metaverse.staging.schletter-group.com', target: STAGING },
  //{ hostname: 'metaverse.production.schletter-group.com', target: PRODUCTION },
  { hostname: 'houseofschletter.schletter-group.com', target: PRODUCTION },
];

const BACKEND_ENDPOINTS = {
  [LOCAL]: `http://${location.hostname}:1337/`,
  [DEVELOPMENT]: 'https://metaverse.dev-machine.de/strapi/',
  [STAGING]: 'https://metaverse.staging.schletter-group.com/strapi/',
  // [PRODUCTION]: 'https://metaverse.production.schletter-group.com/strapi/',
  [PRODUCTION]: 'https://houseofschletter.schletter-group.com/strapi/',

};

export const useBuildStore = create((set, get) => ({
  integration: '',
  init: () => {
    const hostname = window.location.hostname;

    // search for the first rule that matches
    let defaultIntegration = DEVELOPMENT;
    for (const { hostname: matchHostname, target } of INTEGRATIONS) {
      if (hostname.endsWith(matchHostname)) {
        defaultIntegration = target;
        break;
      }
    }
    // eslint-disable-next-line no-console
    const debugSocketTarget = debugStore.getSocketTarget();
    const integration = debugSocketTarget === 'AUTO' ? defaultIntegration : debugSocketTarget;

    // eslint-disable-next-line no-console
    console.log('Integration default:', defaultIntegration, 'used:', integration);
    set({ integration });
  },
  getBackendUrl: () => {
    const { integration } = useBuildStore.getState();
    return BACKEND_ENDPOINTS[integration];
  },

  getBackendStatus: async () => {
    const backendUrl = get().getBackendUrl();
    let data;
    try {
      const response = await axios.get(backendUrl + 'status');
      data = response.data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    if (data) {
      const { status, hubId } = data;
      return { status, hubId };
    }
    return {};
  },
}));
