import { MeshDepthMaterial, MeshStandardMaterial } from 'three';
import { windNoiseGlsl } from '../../WeatherScene/helpers';

import { vertexBody } from './shaders/vertex-snippets';

const glsl = x => x;
/**
 * IT WAS STARTED WITH SINGLE TREE IN MODEL-SPACE, it would be awesome to refactor into WORLD-SPACE maybe
 */

const ROTATION_SNIPPET = glsl`
  mat4 rotation3d(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;

    return mat4(
      oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
      oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
      oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
      0.0,                                0.0,                                0.0,                                1.0
    );
  }
`;

const MATRICES_SNIPPET = glsl`
  mat3 extractRotation(mat4 matrix) {
    vec3 col1 = matrix[0].xyz;
    vec3 col2 = matrix[1].xyz;
    vec3 col3 = matrix[2].xyz;
    
    return mat3(normalize(col1), normalize(col2), normalize(col3));
  }

  mat4 getTranslationMatrix(mat4 instanceMatrix) {
    vec3 translation = instanceMatrix[3].xyz;
    mat4 translationMatrix = mat4(1.0);
    translationMatrix[3] = vec4(translation, 1.0);

    return translationMatrix;
  }
  
  
  mat4 getRotationMatrix(mat4 instanceMatrix) {
    mat4 rotationMatrix = mat4(instanceMatrix);
    rotationMatrix[0] = normalize(rotationMatrix[0]);
    rotationMatrix[1] = normalize(rotationMatrix[1]);
    rotationMatrix[2] = normalize(rotationMatrix[2]);

    return rotationMatrix;
  }
  
  mat4 getScaleMatrix(mat4 instanceMatrix) {
    vec3 scale;
    scale.x = length(instanceMatrix[0].xyz);
    scale.y = length(instanceMatrix[1].xyz);
    scale.z = length(instanceMatrix[2].xyz);

    mat4 scaleMatrix = mat4(1.0);
    scaleMatrix[0] = vec4(scale.x, 0.0, 0.0, 0.0);
    scaleMatrix[1] = vec4(0.0, scale.y, 0.0, 0.0);
    scaleMatrix[2] = vec4(0.0, 0.0, scale.z, 0.0);

    return scaleMatrix;
  }
`;

const NOISE_SNIPPET = windNoiseGlsl;

export default class CustomDepthTreeMaterial extends MeshDepthMaterial {
  /**
   * Define new mateterial properties in the constructor
   */
  constructor(props) {
    super({
      map: props.map,
      depthPacking: props.depthPacking,
    });

    this._props = props;
    this._dataTexture = props.dataTexture;
    this._noiseTexture = props.noiseTexture;

    // this.transparent = true;
    // this.alphaTest = 0.2;

    // this.depthTest = true;
    // this.depthWrite = true;
    // this.depthFunc = 2;

    this.modelHeight = { value: 1 };
    this.normalizedHeightFactor = { value: 1 };
    this.noiseScale = { value: 1 };
    this.blueFactor = { value: 1 };
    this.redFactor = { value: 1 };
    this.greenFactor = { value: 1 };
    this.heightFactor = { value: 1 };
    this.baseWind = { value: 1 };
    this.overAllFactor = { value: 1 };
    this.windStrength = { value: 1 };
    this.bb = { value: [1, 1, 1] };
    this.time = { value: 1 };

    this.leavesPivot = { value: props.leavesPivot };

    this.noiseTxt = { value: this._noiseTexture };
    this.dataTxt = { value: this._dataTexture };

    // this.side = 2;
  }

  setBounds(v) {
    this.modelHeight.value = v.y;
    this.normalizedHeightFactor.value = v.y / 1.5;
    this.bb.value = v.toArray();
  }

  onBeforeCompile(shader) {
    shader.uniforms.uDataTxt = this.dataTxt;
    shader.uniforms.uNoiseTxt = this.noiseTxt;
    shader.uniforms.uLeavesPivot = this.leavesPivot;

    shader.uniforms.uNoiseScale = this.noiseScale;
    shader.uniforms.uBlueFactor = this.blueFactor;
    shader.uniforms.uRedFactor = this.redFactor;
    shader.uniforms.uGreenFactor = this.greenFactor;
    shader.uniforms.uHeightFactor = this.heightFactor;
    shader.uniforms.uBaseWind = this.baseWind;
    shader.uniforms.uOverAllFactor = this.overAllFactor;
    shader.uniforms.uModelHeight = this.modelHeight;
    shader.uniforms.uNormalizedHeightFactor = this.normalizedHeightFactor;
    shader.uniforms.uStrength = this.windStrength;
    shader.uniforms.uBb = this.bb;
    shader.uniforms.uTime = this.time;

    // VERTEX
    shader.vertexShader = shader.vertexShader.replace(
      '#include <common>',
      `
        #include <common>
        attribute float aOverAllFactor;
        attribute float aTimeShift;
        attribute vec2 uvWind;

        uniform float uStrength;
        uniform float uTime;
        uniform float uNoiseScale;
        uniform float uBlueFactor;
        uniform float uRedFactor;
        uniform float uGreenFactor;
        uniform float uHeightFactor;
        uniform float uOverAllFactor;
        uniform float uModelHeight;
        uniform float uBaseWind;
        uniform float uNormalizedHeightFactor;

        uniform vec3 uLeavesPivot;
        uniform vec3 uBb;

        uniform sampler2D uDataTxt;
        uniform sampler2D uNoiseTxt;

        varying float vHeight;
        varying vec2 vUvWind;

       ${NOISE_SNIPPET}
       ${MATRICES_SNIPPET}
      `
    );

    shader.vertexShader = shader.vertexShader.replace('#include <project_vertex>', vertexBody);
  }
}
