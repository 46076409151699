import styled from 'styled-components';
import { Button } from 'common/components/Button';
import { lgBaseColors } from 'uiLivi/styles';
import { menuPaddingPx } from '../../../styles';

export const ButtonFooterContainer = styled.div`
  padding: 12px ${menuPaddingPx} ${menuPaddingPx};
  ${Button} {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
`;

export const LogOutLink = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  color: ${lgBaseColors.grey.medium};
  font-weight: bold;
  margin-bottom: 16px;
  text-align: right;
  cursor: pointer;
  &:hover {
    color: ${lgBaseColors.grey.veryLight};
  }
`;
