import styled, { css } from 'styled-components';

export const ColorPickerWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
`;

const COLOR_SIZE = 27;

export const Toggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  cursor: pointer;
  width: ${27 + 24 + 24}px;
`;

export const Color = styled.div`
  width: ${COLOR_SIZE}px;
  height: ${COLOR_SIZE}px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin: 12px;
  transition: all 0.15s;
  transition-delay: ${({ delayIndex }) => 0.02 * delayIndex}s;
  cursor: pointer;
`;

export const ColorSelection = styled.div`
  position: absolute;
  bottom: 50px;
  right: 32px;
  pointer-events: none;
  ${Color} {
    opacity: ${({ open }) => (open ? 1 : 0)};
    transform: translateY(${({ open }) => (open ? 0 : 30)}px);
    pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  }
`;

export const Label = styled.div`
  margin-top: 5px;
  text-align: center;
  size: 11px;
  font-family: 'demodernbold', sans-serif;
  line-height: 12px;
`;

export const Icon = styled.img`
  transition: opacity 0.15s;
  opacity: ${({ open }) => (open ? 0 : 1)};
`;
