import styled from 'styled-components';
import { colors } from 'uiLivi/styles';
import { H2 } from '../../../common/text';

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
`;

export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.secondary.base};
  justify-content: center;
  align-items: center;
  color: ${props => props.color};
`;

export const MessageHeadline = styled(H2)`
  line-height: 47px;
  font-weight: bold;
  text-align: left;
  color: ${colors.accent.base};
`;

export const MessageSubline = styled.div`
  line-height: 47px;
  font-weight: bold;
  text-align: left;
  color: white;
`;
