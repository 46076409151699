import { useEffect, useRef } from 'react';
import { useChatStore } from '../ChatService';
import { useTrackingStore } from './index';

function useChange(a, b) {
  const initialSet = useRef(false);
  useEffect(() => {
    if (!initialSet.current) {
      initialSet.current = true;
      return;
    }
    a();
  }, b);
}

function getChannelLabel(activeChat) {
  return activeChat.type + (activeChat.identifier ? '/' + activeChat.identifier : '');
}

export function trackSend(activeChat) {
  const label = getChannelLabel(activeChat);
  useTrackingStore.getState().trackEvent({ category: 'Chat', action: 'Send', label });
}

export default function ChatTracking() {
  const isChatOpen = useChatStore(state => state.isActive);
  const isFullHeight = useChatStore(state => state.isFullHeight);
  const activeChat = useChatStore(state => state.activeChat);

  useChange(() => {
    useTrackingStore.getState().trackEvent({ category: 'Chat', action: isChatOpen ? 'Open' : 'Close' });
  }, [isChatOpen]);

  useChange(() => {
    useTrackingStore.getState().trackEvent({ category: 'Chat', action: isFullHeight ? 'Maximize' : 'Normalize' });
  }, [isFullHeight]);

  useChange(() => {
    if (activeChat) {
      useTrackingStore.getState().trackEvent({
        category: 'Chat',
        action: 'EnterChannel',
        label: getChannelLabel(activeChat),
      });
    } else {
      useTrackingStore.getState().trackEvent({
        category: 'Chat',
        action: 'ViewChannelList',
      });
    }
  }, [!!activeChat]);

  return null;
}
