import Storage from 'utilities/storage';

const EVENT = 'event';

const sessionStorage = new Storage({ type: 'session' });
sessionStorage.init('live_user_1');
sessionStorage.defineEntry(EVENT, null);

export default {
  setEvent: event => {
    sessionStorage.setItem(EVENT, event);
  },
  getEvent: () => {
    return sessionStorage.getItem(EVENT);
  },
};
