import styled, { css } from 'styled-components';
import { colors, easings } from 'uiLivi/styles';
import { iconFillAndStroke, iconHideFill } from 'common/icon';
import { hexToRgba } from 'utilities/color';

const inactiveStyle = css`
  polygon,
  path {
    transition: stroke 0.25s;
    transition-timing-function: ${easings.easeOutStrong};
    &[stroke] {
      stroke: ${colors.accent.base};
    }
  }
  ${iconHideFill};
`;

const inactiveHoverStyle = css`
  ${iconFillAndStroke(colors.accent.base, colors.accent.base)}
  ${iconHideFill};
`;

export const BookmarkButtonContainer = styled.div`
  display: inline-block;
  width: 17px;
  padding: 3px;
  cursor: pointer;
  ${props =>
    props.position !== null
      ? `
          position: absolute;
          top: ${'top' in props.position ? props.position.top : 'auto'};
          right: ${'right' in props.position ? props.position.right : 'auto'};
          bottom: ${'bottom' in props.position ? props.position.bottom : 'auto'};
          left: ${'left' in props.position ? props.position.left : 'auto'};
        `
      : null};
  svg {
    transition: fill 0.2s, stroke 0.2s, box-shadow 0.2s ${easings.easeOutStrong};
    ${props => (props.active ? iconFillAndStroke(colors.accent.base, colors.accent.base) : inactiveStyle)}
  }
  &:hover {
    svg {
      box-shadow: 0 0 15px ${hexToRgba(colors.accent.base, 0.8)};
      ${props =>
        props.active ? iconFillAndStroke(colors.accent.highlight, colors.accent.highlight) : inactiveHoverStyle}
    }
  }
`;
