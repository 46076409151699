export default /* glsl */ `
#define USE_UV
#define LUT_FLIP_Y

uniform vec3 diffuse;
uniform vec3 diffuse2;
uniform vec3 options;
uniform sampler2D lut;

varying vec3 vWorldPosition;

#include <common>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <lut>

float circle(in vec2 st, in float r){
    vec2 dist = st-vec2(0.5);
    return 1.0 - smoothstep(0.0, r, dot(dist, dist));
}

void main() {
    vec2 uv = vWorldPosition.xz * 0.1;
    float x = min(max((3.0 * (1.0 - distance(vec3(0), vWorldPosition) * 0.01) - 2.0), 0.0), 1.0);
    vec3 ground = mix(diffuse * 0.01, pow(diffuse, vec3(1.0)) * 0.8, x);
    
    float y = distance(vec2(0.15,0.05), vUv);
    y = (1.0 - y);
    y = pow(y, 3.0);
    vec3 plane = mix(pow(diffuse, vec3(1.0)), pow(diffuse, vec3(1.0)) + 0.1, y);
    
    vec3 dome = mix(diffuse * 0.01, pow(diffuse, vec3(1.0)), min((vWorldPosition.y) * 0.0025, 1.0));

    vec3 outgoingLight = vec3(0);
    outgoingLight += ground * options.x;
    outgoingLight += plane * options.y;
    outgoingLight += dome * options.z;

    gl_FragColor = vec4(lookup(vec4( outgoingLight, 1.0 ), lut).rgb, 1.0);

    #include <tonemapping_fragment>
    #include <encodings_fragment>
}
`;
