import React, { useEffect } from 'react';
import ChatBubble from 'uiLivi/components/Chat/ChatBubble';
import { useChatStore } from 'services/ChatService';
import { useWindowStore } from 'services/WindowService';
import styled, { css } from 'styled-components';
import { center, chat, colors } from 'uiLivi/styles';
import { fontSize, smallFontSize } from '../../../../common/text';
import { useTranslate } from 'services/TranslationService/hooks';

// TODO: are you sure that 1px more than 100% is necessary? Overflow is visible although it should not. To scared to touch in you holidays before release.
const StyledChatMessageContainer = styled.div`
  min-height: ${props => (props.isFullHeight ? `calc(100% + 1px)` : chat.defaultHeight + 1 + `px`)};
  padding: ${props => (props.isMobile ? 20 : 10)}px 0 0 0;
`;

const topNoticeStyle = css`
  position: relative;
  margin: ${props => (props.isMobile ? 0 : 10)}px auto 20px auto;
  text-align: center;
  font-weight: bold;
`;

const bottomNoticeStyle = css`
  ${center.horizontal()};
  bottom: 10px;
`;

const StyledNotice = styled.div`
  width: 80%;
  color: ${colors.font.light};
  font-size: ${props => (props.largeText ? fontSize : smallFontSize)};
  line-height: ${props => (props.largeText ? 22 : 16)}px;
  ${props => (props.isTop ? topNoticeStyle : bottomNoticeStyle)};
  span {
    display: block;
    font-weight: bold;
  }
`;

const ChatMessageContainer = ({ chat, isFullHeight }) => {
  const messages = chat.messages;
  const messageCount = messages.length;
  const isSupportChat = chat.type === 'support';
  const isMobile = useWindowStore(state => state.isMobile);
  const initialLoadId = useChatStore(state => state.initialLoadId);
  const translate = useTranslate();

  useEffect(() => {
    // TODO: remove this hotfix. It was used to fix the content stream chat when you enter the theater live stream while the chat is already opened. Is initialLoadId really necessary?
    return () => {
      useChatStore.setState({ initialLoadId: null });
    };
  }, []);

  useEffect(() => {
    if (messageCount === 0 && initialLoadId !== chat.id) {
      useChatStore.getState().loadMessages(chat);
    } else {
      useChatStore.getState().setPending(false);
    }
  }, [chat, initialLoadId]);

  return (
    <StyledChatMessageContainer isMobile={isMobile} isFullHeight={isFullHeight || isMobile}>
      {messageCount
        ? chat.last && (
            <StyledNotice isMobile={isMobile} isTop>
              {translate('chat.room.noolder') || 'No older messages'}
            </StyledNotice>
          )
        : null}
      {messages.map((message, i) => (
        <ChatBubble key={i} message={message} />
      ))}
      {isSupportChat && messages.length === 0 && (
        <StyledNotice largeText>
          <span>{translate('chat.support.message') || 'How can we help you?'}</span>
          {translate('chat.support.supplement') ||
            'Our support team is happy to assist you and will get back to you as soon as possible.'}
        </StyledNotice>
      )}
      {!isSupportChat
        ? !messageCount && (
            <StyledNotice>
              {translate('chat.room.nomessages') ||
                'There are no messages in this chat yet. You can be the first to say something.'}
            </StyledNotice>
          )
        : null}
    </StyledChatMessageContainer>
  );
};

export default ChatMessageContainer;
