import React from 'react';

export default function ErrorFallbackUI({ message }) {
  return (
    <div style={{ textAlign: 'center', height: '100%', padding: '10vh', transform: 'translateY(30%)' }}>
      <h1>Oops! Something went wrong.</h1>
      <div style={{ opacity: 0.5 }}>{message}</div>
    </div>
  );
}
