import styled from 'styled-components';
import { colors, darkChatBackgroundStyle, lgBaseColors, lightChatBackgroundStyle, zIndex } from 'uiLivi/styles';

export const StyledChatContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${props => props.chatWidth}px;
  height: ${props => props.vh * 100}px;
  z-index: ${zIndex.chat};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateX(${props => (props.show ? 0 : props.chatWidth)}px);
  transition: transform 0.15s ease;
  pointer-events: none;
`;

export const StyledChatContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: ${props => (props.isFullHeight ? 1 : 0)};
  bottom: 0;
  width: 100%;
  max-height: 100%;
  transition: all 0.15s ease;
  pointer-events: all;
  overflow: hidden;
  ${props => (props.isContent ? darkChatBackgroundStyle : lightChatBackgroundStyle)};
`;

export const StyledInputContainer = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 16px;
  background: ${props => (props.isContent ? lgBaseColors.grey.dark : colors.background.base)};
  margin-top: auto;
  transition: background 0.35s cubic-bezier(0.17, 0.84, 0.44, 1);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
`;
