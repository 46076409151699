import create from 'utilities/zustand/create';
import { useEventStore } from 'services/EventService';
import { useUserStore } from 'services/UserService';
import { useDebugStore } from 'storage/debug';

export const useTranslationStore = create(() => ({
  debug: false,
  translate: (key, language) => {
    if (useDebugStore.getState().getRenderTranslationKeysEnabled()) {
      return key;
    }
    const user = useUserStore.getState().user;
    const lang = language || (user && user.language) || 'en_US';
    const { event } = useEventStore.getState();
    if (!event || !event.languages) {
      return false;
    }
    const languageData = event.languages.find(a => a.iso === lang);
    return languageData && languageData.translations && languageData.translations[key];
  },
}));
