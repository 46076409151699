import React, { useEffect } from 'react';
import Contents from 'components/Contents';
import BackgroundEvents from 'components/Play/BackgroundEvents';
import DebugEnvironmentObjects from './DebugEnvironmentObjects';
import { useSceneStore } from 'services/SceneService';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import dracoLoader from 'utilities/dracoLoader';
import Octree from 'utilities/octree/Octree';
import { useDebugStore } from 'storage/debug';
import fromCdn from 'utilities/cdn';
import { BackSide, DoubleSide, FrontSide, MeshBasicMaterial, MeshPhongMaterial, MeshStandardMaterial } from 'three';

export default function Scene({ url, visible }) {
  const debugMinimal3d = useDebugStore(state => state.getMinimal3d());
  const gltf = useLoader(GLTFLoader, fromCdn(url), dracoLoader);
  const scene = useSceneStore(state => state.scene);

  useEffect(() => {
    const renderable = gltf.scene.clone();
    renderable.traverse(o => {
      if (o.material) {
        const mat = (o.orgMaterial = o.material);
        if (mat.map) mat.map.anisotropy = 16;
      }
    });

    let walls = null;
    let ground = null;
    let contentGroup = null;
    let zoneGroup = null;
    let areaGroup = null;
    renderable.traverse(o => {
      if (o.name === 'wall_collision_mesh') {
        walls = new Octree(o, 2, false);
        o.visible = false;
      } else if (o.name === 'ground_collision_mesh') {
        ground = new Octree(o, 2, false);
        o.visible = false;
      } else if (o.name === 'content_mesh') {
        contentGroup = o;
      } else if (o.name === 'zone_mesh') {
        zoneGroup = o;
        o.visible = false;
      } else if (o.name === 'area_mesh') {
        areaGroup = o;
        o.visible = false;
      } else if (o.name.endsWith('_content_screen')) {
        o.visible = false;
      }
    });
    if (walls) renderable.add(walls);
    if (ground) renderable.add(ground);

    const scene = {
      contentGroup,
      renderable,
      zoneGroup,
      areaGroup,
      collisionMesh: { walls, ground },
    };

    useSceneStore.getState().setScene(scene);
    return () => {
      useSceneStore.setState({ scene: null });
    };
  }, []);

  useEffect(() => {
    if (!scene?.renderable) return;
    scene.renderable.traverse(o => {
      if (o.name === 'wall_collision_mesh') {
        o.visible = debugMinimal3d;
        o.receiveShadow = false;
        o.castShadow = false;
        o.material = new MeshStandardMaterial({ color: '#6699ff', opacity: 0.6, transparent: true });
        o.material.envMapIntensity = 0;
        o.material.side = DoubleSide;
        o.material.visible = debugMinimal3d;
      } else if (o.name === 'ground_collision_mesh') {
        o.receiveShadow = false;
        o.castShadow = false;
        o.visible = debugMinimal3d;
        o.material = new MeshStandardMaterial({ color: '#ffdddd' });
        o.material.envMapIntensity = 0;
        o.material.side = DoubleSide;
        o.material.visible = debugMinimal3d;
      } else if (o.name === 'environment_mesh') {
        o.visible = !debugMinimal3d;
      }
    });
  }, [scene, debugMinimal3d]);

  if (!scene) return;

  return (
    <>
      <BackgroundEvents />
      {scene.contentGroup && <Contents contentGroup={scene.contentGroup} visible={visible} />}
      <primitive name="District" object={scene.renderable} visible={visible} />
      <DebugEnvironmentObjects />
    </>
  );
}
