import { useChatStore } from 'services/ChatService';
import styled from 'styled-components';
import { hexToRgba } from 'utilities/color';
import { colors } from 'uiLivi/styles';

export const StyledNewMessagesBelowHint = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  height: 15px;
  background: rgb(249, 249, 249);
  background: linear-gradient(180deg, ${hexToRgba(colors.background.base, 0)}, ${hexToRgba(colors.accent.base, 0.5)});
  opacity: ${props => (props.show ? 1 : 0)};
  transition: 0.15s ease;
  pointer-events: none;
`;

const ChatMessageHint = () => {
  const activeChatSeen = useChatStore(state => {
    return state.activeChat ? state.activeChat.seen : false;
  });

  return <StyledNewMessagesBelowHint show={!activeChatSeen} />;
};

export default ChatMessageHint;
