import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import emojiSheet from './assets/emojiSheet.png';
import particles_vert from './shaders/particles.vert';
import particles_frag from './shaders/particles.frag';
import {
  getDataForPreset,
  getParticleAmountForPreset,
  getSpawnPositionForPreset,
  getTimelineForPreset,
} from './assets/ParticleAnimationPresets';
import * as PARTICLE_FX from './ParticleFXConstants';
import { useReactionStore } from 'services/ReactionService';

function PlayerParticles(props) {
  const { particleSheetIndex, animationType, frustumCulled } = props;
  const texture = useLoader(TextureLoader, emojiSheet);
  const maxParticleCount = 35;
  const particleSpawnIntervalDuration = 0.05;

  const [active, setActive] = useState(false);
  const [particlesToBeSpawned, SetParticlesToBeSpawned] = useState(0);
  const [curParticleIndex, SetCurParticleIndex] = useState(0);

  const adaptedScale = 1.0 / window.devicePixelRatio;

  const [particles, setParticles] = useState(null);

  useEffect(() => {
    // create particle material
    const pMaterial = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Color(0xffffff) },
        pointTexture: { value: texture },
        tileSize: { value: 120 },
        tileAmount: { value: { x: 5, y: 2 } },
      },
      vertexShader: particles_vert,
      fragmentShader: particles_frag,

      blending: THREE.NormalBlending,
      depthTest: true,
      depthWrite: false,
      transparent: true,
    });

    // create particle buffer geometry
    const positions = new Float32Array(maxParticleCount * 3);
    const colors = new Float32Array(maxParticleCount * 3);
    const sizes = new Float32Array(maxParticleCount);
    const sheetTileIndices = new Float32Array(maxParticleCount);
    let vertex = new THREE.Vector3();
    for (let i = 0; i < maxParticleCount; i++) {
      vertex = new THREE.Vector3(1, 1, 1);
      vertex.toArray(positions, i * 3);
      new THREE.Color(0xff0000).toArray(colors, i * 3);
      sizes[i] = 0;
      sheetTileIndices[i] = Math.floor(Math.random() * 3);
    }
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
    geometry.setAttribute('sheetTile', new THREE.BufferAttribute(sheetTileIndices, 1));
    for (let p = 0; p < maxParticleCount; p++) {
      new THREE.Vector3(0, 0, 0).toArray(geometry.attributes.position.array, p * 3);
      geometry.attributes.size.array[p] = 0;
    }

    // create particle system
    const particleSystem = new THREE.Points(geometry, pMaterial);
    particleSystem.sortParticles = true;
    particleSystem.frustumCulled = frustumCulled;

    // Store data for every particle separately
    const particleData = [];
    for (let p = 0; p < maxParticleCount; p++) {
      particleData.push({
        sheetTile: 0,
        pos: new THREE.Vector3(0, 0, 0),
        size: 0.4 + Math.random() * 0.3,
        prog: 1,
        animationType: null,
      });
    }

    setParticles({ particleSystem, particleData });
    // const { particleSystem, particleData } =
    // return { particleSystem, particleData };
  }, []);

  useEffect(() => {
    if (!particles) return;
    const { particleData } = particles;

    let nextParticleTimeout = null;
    if (particlesToBeSpawned > 0) {
      let index = curParticleIndex + 1;
      if (index >= maxParticleCount) index = 0;
      SetCurParticleIndex(index);

      if (particleSheetIndex !== -1) {
        //Spawn particle
        particleData[index].prog = 0;
        particleData[index].sheetTile = particleSheetIndex;
        particleData[index].animationType = animationType;
        particleData[index].pos = getSpawnPositionForPreset(animationType);
      }

      nextParticleTimeout = setTimeout(() => {
        SetParticlesToBeSpawned(particlesToBeSpawned - 1);
      }, particleSpawnIntervalDuration * 1000);
    }
    return () => {
      if (nextParticleTimeout) clearTimeout(nextParticleTimeout);
    };
  }, [particlesToBeSpawned]);

  useEffect(() => {
    if (particleSheetIndex !== -1) {
      SetParticlesToBeSpawned(getParticleAmountForPreset(animationType));
    }
  }, [particleSheetIndex]);

  useEffect(() => {
    if (!particles) return;
    const { particleSystem } = particles;

    // Buffer cleanup on instance removal
    return () => {
      particleSystem.geometry.dispose();
    };
  }, []);

  useFrame((state, delta) => {
    if (!particles) return;
    const { particleSystem, particleData } = particles;

    let geometryNeedsUpdate = false;
    for (let p = 0; p < maxParticleCount; p++) {
      if (particleData[p].prog < 1) {
        geometryNeedsUpdate = true;
        // Move and animate particles
        particleData[p].prog += delta / 1.2;
        if (particleData[p].prog < 1) {
          const particleAnimationData = getDataForPreset(particleData[p].animationType);
          const particleAnimationTimeline = getTimelineForPreset(particleData[p].animationType);
          particleAnimationTimeline.seek(particleData[p].prog);

          const size = particleAnimationData.size * particleData[p].size * adaptedScale;
          const aniPos = new THREE.Vector3(
            particleAnimationData.x ? particleAnimationData.x : 0,
            particleAnimationData.y ? particleAnimationData.y : 0,
            particleAnimationData.z ? particleAnimationData.z : 0
          );

          const pos = new THREE.Vector3().addVectors(particleData[p].pos, aniPos);
          // Apply particle data to buffer geometry
          particleSystem.geometry.attributes.sheetTile.array[p] = particleData[p].sheetTile;
          pos.toArray(particleSystem.geometry.attributes.position.array, p * 3);
          particleSystem.geometry.attributes.size.array[p] = size;
        } else {
          new THREE.Vector3(0, 0, 0).toArray(particleSystem.geometry.attributes.position.array, p * 3);
          particleSystem.geometry.attributes.size.array[p] = 0;
        }
      }
    }
    if (geometryNeedsUpdate) {
      particleSystem.geometry.attributes.position.needsUpdate = true;
      particleSystem.geometry.attributes.size.needsUpdate = true;
      particleSystem.geometry.attributes.sheetTile.needsUpdate = true;
    }
    setActive(geometryNeedsUpdate);
  });

  if (!active || !particles) return null;
  return <primitive name="Particles" object={particles.particleSystem} />;
}

export default function ParticleFX({ reactionId, frustumCulled }) {
  let params = {
    particleSheetIndex: -1,
    animationType: PARTICLE_FX.ANIMATION_PRESET_UNDEFINED,
  };

  const reaction = useReactionStore.getState().getReactionById(reactionId);
  if (reaction && reaction.particleFx) {
    params = reaction.particleFx;
  }

  return (
    <PlayerParticles
      particleSheetIndex={params.particleSheetIndex}
      animationType={params.animationType}
      frustumCulled={frustumCulled}
    />
  );
}
