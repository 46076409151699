import styled, { css, keyframes } from 'styled-components';
import { hexToRgba } from 'utilities/color';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { H3 } from 'common/text';
import { mediaMax } from 'common/layout';

export const pulse = keyframes`
  0% {
    transform: scale3D(1, 1, 1);
  }
  50% {
    transform: scale3D(1.17, 1.17, 1);
  }
  100% {
    transform: scale3D(1, 1, 1);
  }
`;

export const SkipButton = styled.div`
  font-weight: bold;
  font-size: 12px;
  font-family: 'AzoSans', sans-serif;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 18px;
  text-shadow: 0px 0px 4px #ffffff;
  opacity: 0.5;
  transition: opacity 0.5s ease-out;
  &:hover {
    opacity: 1;
  }
`;

export const ContentContainer = styled.div`
  text-align: center;
  color: ${colors.font.strong};
  max-width: 400px;
  height: ${props => (props.modifier ? '275px' : '220px')};
  min-height: 100%;

  ${mediaMax(
    'small',
    css`
      padding: 0 5%;
    `
  )}
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled(H3)`
  line-height: inherit;
  margin-top: 0;
  text-align: center;
`;

export const InstructionText = styled.div`
  display: inline-block;
  line-height: 1.5;
  font-family: 'AzoSansLight', sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  width: 90%;
  ${mediaMax(
    'small',
    css`
      font-size: 14px;
    `
  )}
`;

export const InstructionIconContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const InstructionWrapper = styled.div`
  text-align: center;
`;

export const InstructionHeadline = styled.div`
  font-size: 29px;
  font-weight: bold;
  font-family: 'AzoSansBold', sans-serif;
  padding-bottom: 16px;
  text-align: center;
  ${mediaMax(
    'small',
    css`
      font-size: 24px;
    `
  )}
`;

export const InstructionTopline = styled.div`
  font-size: 10px;
  opacity: 0.5;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.icon});
  background-size: contain;
  background-repeat: no-repeat;
  //border: 1px solid #1ed36f;
`;

export const InstructionVisualContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 50px;
  flex-wrap: nowrap;
  justify-content: center;
  //border: 1px solid green;
`;

export const InstructionVisualColumn = styled.div`
  width: 150px;
  position: relative;
`;

export const SpacerColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const InstructionVisualBox = styled.div`
  padding-top: 80%;
  position: relative;
  height: 150px;
  svg {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 80%;
    transform: translateY(-50%);
  }
`;

export const InstructionSpacerBox = styled.div`
  background-color: transparent;
  color: ${lgBaseColors.grey.medium};
  padding: 0 20px;
  line-height: 100%;
  white-space: nowrap;
`;

export const InstructionVisualCaption = styled.div`
  position: absolute;
  width: 100%;
  bottom: -35px;
  padding: 10px;
  color: ${lgBaseColors.grey.medium};
`;

export const SlideTransitionWrapper = styled.div`
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity ${props => (props.show ? 0.5 : 0)}s ease-out;
`;
