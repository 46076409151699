import styled, { css } from 'styled-components';
import { center } from '../../../../../uiLivi/styles';
import { mediaMax } from '../../../../../common/layout';

export const HANDLE_SIZE = 48;

export const SliderWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 680px;
  height: 1px;
  ${center.horizontal()};
  bottom: ${props => (!props.isMobile ? 80 : 80)}px;
  pointer-events: ${props => (props.active ? 'all' : 'none')};
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.2s ease-out;

  ${mediaMax(
    'small',
    css`
      width: 75%;
    `
  )};
`;

export const Track = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 8px;
`;

export const GradientMask = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Gradient = styled.div`
  width: ${props => props.width}px;
  max-width: 680px;
  height: 100%;
  border-radius: 10px;
  background: #00676b;
`;

export const Line = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  opacity: 0.3;
  background-color: white;
`;

export const DragHandle = styled.div`
  position: absolute;
  left: -24px;
  top: -24px;
  width: ${HANDLE_SIZE}px;
  height: ${HANDLE_SIZE}px;
  border-radius: 50%;
  background: rgba(0, 103, 107, 0.3);
  cursor: pointer;
  backdrop-filter: blur(6px);
  border: 1px solid rgba(255, 255, 255, 0.35);

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.75);
  }
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: white;
  ${center.absolute()};
`;

export const Markers = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const MarkerPosition = styled.div`
  position: absolute;
  bottom: 0;
  left: ${props => props.xp}%;
`;

export const MarkerLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  ${center.horizontal()};
  min-width: 300px;
  text-transform: uppercase;
  font-family: 'AzoSans', serif;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1.1px;
`;

export const DebugProgress = styled.div`
  position: absolute;
  font-size: 20px;
  color: red;
  left: -100px;
`;
