import { Button } from 'common/components/Button';
import { useHistory } from 'react-router-dom';

const JoinTalkButton = ({ children, small, accent, accept }) => {
  const history = useHistory();

  const onClick = () => {
    history.push('/play/auditorium');
    accept();
  };

  return (
    <Button small={small} accent={accent} onClick={onClick}>
      {children}
    </Button>
  );
};

export default JoinTalkButton;
