import styled from 'styled-components';

import { useTrackingStore } from 'services/TrackingService';

export const Container = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50%;
  width: 200px;
  z-index: 10000;
  border: 1px solid rgba(255, 255, 255, 1);
  background-color: rgba(240, 240, 240, 0.9);
  backdrop-filter: blur(1px);
  pointer-events: scroll;
  overflow-y: auto;
  font-family: monospace;
  font-size: 12px;
  line-height: 12px;
  padding: 0px;
`;

export const Page = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2px;
  margin-bottom: 2px;
  background-color: rgba(255, 0, 255, 0.2);
`;

export const Event = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2px;
  margin-bottom: 2px;
  background-color: rgba(0, 255, 0, 0.2);
`;

export const Activity = styled.div`
  white-space: pre;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2px;
  margin-bottom: 2px;
  background-color: rgba(0, 0, 255, 0.2);
`;

export default function DebugTracking() {
  const trackPoints = [...useTrackingStore(state => state.trackPoints)].reverse();
  return (
    <Container>
      {trackPoints.map((t, i) => {
        switch (t.type) {
          case 'page': {
            return <Page key={i}>{`"${t.path}" - "${t.title}"`}</Page>;
          }
          case 'event': {
            return <Event key={i}>{`"${t.category}" - "${t.action}" - "${t.label}"`}</Event>;
          }
          case 'activity': {
            return <Activity key={i}>{JSON.stringify(t.data, null, 1)}</Activity>;
          }
        }
      })}
    </Container>
  );
}
