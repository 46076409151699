import create from 'utilities/zustand/create';
import { Scene, Object3D } from 'three';
export const useDebugThreeStore = create(set => ({
  /** @type {Scene} */
  debugScene: null,

  transformControls: null,
  setDebugScene: debugScene => {
    set({ debugScene });
  },
}));
