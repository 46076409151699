import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { ImageLoader, NormalBlending, Sprite, Texture } from 'three';
import InfoButtonTexture from './infoButton-texture.png';
import React, { useEffect, useRef, useState } from 'react';
import { gsap, Power2 } from 'gsap';
import HUDSpriteMaterialNoScale from '../../../../../utilities/canvas/HUDSpriteMaterialNoScale';
import { useWindowStore } from '../../../../../services/WindowService';
import { useDeviceStore } from '../../../../../services/DeviceService';
import { useHowlerStore } from '../../../../../services/HowlerService';
import { Html } from '@react-three/drei';
import styled from 'styled-components';
import { useConstructionAssemblyService } from '../../../../../services/ConstructionAssemblyService';

const Description = styled.div`
  position: absolute;
  top: -20px;
  left: 30px;
  min-width: max-content;
  font-family: 'AzoSansBold', sans-serif;
  font-size: 12px;
  letter-spacing: 1.2px;
  background-color: rgba(72, 72, 72, 0.3);
  padding: 10px 15px 7px 15px;
  border-radius: 35px;
  border: 1px solid rgba(255, 255, 255, 0.35);
  text-transform: uppercase;
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity ${props => (props.showDetail ? 0.05 : 0.35)}s ease-out;
  backdrop-filter: blur(4px);
  pointer-events: none;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.6);
`;

const getSprite = (srcTexture, isPhone) => {
  const canvas = document.createElement('canvas');
  canvas.width = 256;
  canvas.height = 256;

  const context = canvas.getContext('2d');
  context.drawImage(srcTexture, 0, 0, 256, 256);

  const texture = new Texture(canvas);
  texture.needsUpdate = true;

  const material = new HUDSpriteMaterialNoScale();
  material.map = texture;
  material.transparent = true;
  material.depthTest = false;
  material.depthWrite = false;
  material.blending = NormalBlending;

  const sprite = new Sprite(material);
  const scale = !isPhone ? 0.4 : 0.8;
  sprite.renderOrder = 1;
  sprite.scale.set(scale, scale, scale);
  sprite.material.uniforms.opacity.value = 1;

  return sprite;
};

export default function DetailTarget({ position, id, index, text, active, toggleDetailView }) {
  const { camera } = useThree();
  const group = useRef(null);
  const mesh = useRef(null);
  const sprite = useRef(null);
  const tween = useRef(null);
  const [texture] = useLoader(ImageLoader, [InfoButtonTexture]);
  const setHover = useWindowStore(state => state.setHover);
  const [isHovering, setIsHovering] = useState(false);
  const isPhone = useDeviceStore(state => state.device.isMobileOnly);
  const playPrimaryClickUiSound = useHowlerStore(state => state.playPrimaryClickUiSound);
  const playHotspotSfx = useHowlerStore(state => state.playHotspotSfx);
  const detailID = useConstructionAssemblyService(state => state.detailID);
  const showDetail = detailID !== null;
  const timeout = useRef(null);

  useEffect(() => {
    sprite.current = getSprite(texture, isPhone);
    if (group.current) {
      group.current.add(sprite.current);
      tween.current = gsap.fromTo(
        group.current.scale,
        { x: 0, y: 0, z: 0 },
        {
          duration: 0.35,
          ease: Power2.easeOut,
          x: 1,
          y: 1,
          z: 1,
        }
      );
    }

    return () => {
      clearTimeout(timeout.current);
      if (group.current) {
        group.current.remove(sprite.current);
      }
    };
  }, []);

  useEffect(() => {
    if (active) {
      group.current.visible = true;
      timeout.current = setTimeout(() => {
        tween.current.seek(0).play();
      }, index * 50);
    } else {
      group.current.visible = false;
      clearTimeout(timeout.current);
      tween.current.seek(0).pause();
    }
  }, [active]);

  useFrame(() => {
    const scale = group.current.position.distanceTo(camera.position) * 0.1;
    mesh.current.scale.set(scale, scale, scale);
  });

  const handleOver = () => {
    setHover(true);
    setIsHovering(true);
  };

  const handleOut = () => {
    setHover(false);
    setIsHovering(false);
  };

  const handleClick = () => {
    playPrimaryClickUiSound();
    playHotspotSfx();
    toggleDetailView(id);
  };

  return (
    <>
      <group name="HotspotLobby" ref={group} position={position}>
        <mesh ref={mesh} onPointerOver={handleOver} onPointerOut={handleOut} onClick={handleClick}>
          <sphereGeometry args={[!isPhone ? 0.2 : 0.4, 24, 24]} />
          <meshBasicMaterial color={'#cc0000'} opacity={0} transparent={true} depthTest={false} deptWrite={false} />
        </mesh>
        <Html>
          <Description visible={isHovering && !showDetail} showDetail={showDetail}>
            {text}
          </Description>
        </Html>
      </group>
    </>
  );
}
