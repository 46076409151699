import { SCROLL_RANGES, useScrollStore } from '../../../../../services/ScrollService';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { mapRange } from '../../../../../utilities/math';

const ProgressBar = styled.div`
  position: relative;
  cursor: ${props => (!props.active ? 'pointer' : 'default')};
  width: ${props => (props.active ? 80 : 10)}px;
  height: 10px;
  background-color: ${props => (props.active ? 'rgba(255, 255, 255, 0.5)' : '#fff')};
  clip-path: ${props =>
    props.active
      ? 'polygon(0px 0px, 80px 0px, 75px 10px, 5px 10px)'
      : 'polygon(0px 0px, 10px 0px, 5px 10px, 5px 10px)'};
  margin: 0 5px;
  transition: 0.3s ease-in-out;
`;

const Bar = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
  transform: translateX(-25px);
  background-color: white;
  clip-path: polygon(0px 0px, 80px 0px, 75px 10px, 5px 10px);
`;

export default function PartialProgressBar({ range, index, selectedIndex }) {
  const requestRef = useRef(null);
  const bar = useRef(null);
  const setTransition = useScrollStore(state => state.setTransition);
  const setTransitionTarget = useScrollStore(state => state.setTransitionTarget);
  const active = index === selectedIndex;

  const handleTransition = () => {
    if (active) return;

    const progress = useScrollStore.getState().normalizedProgress;

    let transitionType = 'blend';
    if (index !== 0 && index - selectedIndex === 1) {
      const scrollTriggerValue =
        SCROLL_RANGES[selectedIndex][0] + (SCROLL_RANGES[selectedIndex][1] - SCROLL_RANGES[selectedIndex][0]) * 0.75;

      if (progress > scrollTriggerValue) {
        transitionType = 'scroll';
      }
    }

    setTransition(transitionType);
    setTransitionTarget(range[0] + 0.0001);
  };

  const update = () => {
    const progress = useScrollStore.getState().normalizedProgress;
    const partialProgress = Math.min(mapRange(progress, range[0], range[1], 0, 1), 1);
    bar.current.style.transform = `translateX(${-100 + partialProgress * 100}%)`;
    requestRef.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <ProgressBar active={active} onClick={handleTransition} data-sound-action={(!active && 'hotspotSfx') || undefined}>
      <Bar ref={bar} />
    </ProgressBar>
  );
}
