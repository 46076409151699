import styled, { css, keyframes } from 'styled-components';
import { mediaMax } from 'common/layout';
import { center, colors, easings } from 'uiLivi/styles';
import { H1, textOverflowEllipsis } from 'common/text';
import ArrowIcon from 'assets/icons/Icon_Arrow_Down.svg';
import icon360 from 'assets/360-icon.svg';

export const StyledFullScreenContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-direction: column;
  ${mediaMax(
    'small',
    css`
      flex-direction: column;
    `
  )};
`;

export const StyledContentContainer = styled.div`
  position: relative;
  width: 100%;
  background: black;
  height: fit-content;
  display: flex;
  justify-content: center;
  padding: 0 0 6vw 0;

  ${mediaMax(
    'small',
    css`
      width: 100%;
      //padding: 0 calc(56px + 5%) 8% 24px;
    `
  )}
`;

export const StyledCanvas = styled.div`
  width: 100%;
  min-height: 70%;
  canvas {
    outline: none;
  }
  //animation: ${videoAppear}1s ${easings.easeOutStrong};
  ${mediaMax(
    'small',
    css`
      height: 50%;
      width: 100%;
    `
  )}
`;

export const Container = styled.div`
  width: min(96%, 1300px);
  display: flex;
  flex-direction: column;
  padding: 0;

  ${mediaMax(
    'small',
    css`
      padding: 0 56px 0 16px;
    `
  )}
`;

export const StyledCols = styled.div`
  display: flex;
  justify-content: space-between;
  //margin: 0 0 6em 0;

  ${mediaMax(
    'medium',
    css`
      flex-direction: column;
      margin: 0 0 2em 0;
    `
  )}
`;

export const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 48%;
  margin: 4em 0;

  img {
    width: 100%;
  }

  .image-caption {
    color: #4e4e4e;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    padding: 4px 0;
  }

  ${mediaMax(
    'medium',
    css`
      margin: 3em 0;

      img {
        transform: translateX(calc(-16px - 2%));
      }

      .image-caption {
        text-align: right;
        transform: translateX(calc(-16px - 2%));
      }
    `
  )}
`;

export const HeadlineContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  transform: translateY(-50%);

  ${mediaMax(
    'medium',
    css`
      width: 100%;
    `
  )}
`;

export const Styled360Icon = styled(icon360)`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  transform: translateY(-250%);
`;

export const Headline = styled(H1)`
  margin: 0;
  word-break: initial;
  text-transform: initial;
  font-size: 60px;
  max-width: 48%;
  font-weight: 400;
  line-height: 72px;
  ${mediaMax(
    'medium',
    css`
      font-size: 36px;
      line-height: 48px;
      max-width: 100%;
      ${textOverflowEllipsis}
    `
  )}
`;

export const Description = styled.div`
  margin: 1.5em 0;
  line-height: ${props => (props.small ? 1.75 : 1.5)};
  color: ${colors.font.strong};
  white-space: break-spaces;
  width: 100%;
  font-size: ${props => (props.small ? 16 : 24)}px;
  font-weight: 400;
`;

export const ArrowContainer = styled(ArrowIcon)`
  position: absolute;
  width: 20px;
  height: 10px;
  ${props => (props.isMobile ? 0 : center.vertical())};
  transform: rotate(${props => (props.direction && props.direction === 'left' ? '90deg' : '-90deg')});
  right: ${props => props.direction && props.direction === 'right' && 0};
  left: ${props => props.direction && props.direction === 'left' && 0};
  margin: 30px;
  cursor: pointer;
  ${mediaMax(
    'small',
    css`
      margin: 0;
    `
  )}
`;

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  width: 200px;
  height: 40px;
`;

export const DotName = styled.div`
  margin-left: 16px;
  max-width: 144px;
  overflow-wrap: break-word;
`;

export const StyledDot = styled.div`
  width: 39.07px;
  height: 39.07px;
  border-radius: 50%;
  background-color: ${colors.accent.base};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;

  svg {
    width: 40%;
  }

  &:hover {
    background-color: ${colors.accent.highlight};
  }
`;

const videoAppear = keyframes`

  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  20% {
      opacity: 1;
  }
  100% {
    transform: scale(1);
  }

`;
