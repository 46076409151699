import styled from 'styled-components';
import { useSoundStore } from 'services/SoundService';
import { useTrackingStore } from 'services/TrackingService';
import SoundOnIcon from './icons/SoundOn.svg';
import SoundOffIcon from './icons/SoundOff.svg';
import { lgBaseColors } from 'uiLivi/styles';

export const Container = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  bottom: 0;
  left: 0;
  width: 100%;
  height: 32px;
  text-align: center;
  box-sizing: content-box;
  background-color: ${props => (props.enabled ? lgBaseColors.red.base : '#000')};
  border-bottom: 4px solid ${props => (props.enabled ? lgBaseColors.red.base : '#000')};
  transition: all 0.15s ease-out;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background-color: ${props => (props.enabled ? lgBaseColors.red.highlight : '#222')};
    border-color: ${props => (props.enabled ? lgBaseColors.red.highlight : '#222')};
  }
`;

export default function SoundButton() {
  const enabledByUser = useSoundStore(state => state.enabled);
  const enabledBySystem = useSoundStore(state => state.isPlayAllowed);
  const enabled = enabledByUser && enabledBySystem;

  return (
    <Container
      enabled={enabled}
      onClick={() => {
        if (enabledBySystem) {
          useTrackingStore.getState().trackEvent({ category: 'Sound', action: enabled ? 'Mute' : 'Unmute' });
          useSoundStore.getState().toggle();
        }
      }}
    >
      {enabled ? <SoundOnIcon /> : <SoundOffIcon />}
    </Container>
  );
}
