// @ts-nocheck
import React, { useRef, useMemo, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';

import { useWeatherStore } from '../../store';

import vertex from './shaders/index.vert';
import fragment from './shaders/index.frag';

import renderOrder from '../../config/renderOrder';

export default function Fog({ model }) {
  const ref = useRef({});
  const isReady = useRef(false);
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const isAnimating = useWeatherStore(state => state.isAnimating);
  const { fog } = smoothedValues.env;

  const shaderArgs = useMemo(() => {
    return {
      uniforms: {
        uColor: { value: [0, 0, 0] },
        uOpacity: { value: 0 },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      side: 1,
      transparent: true,
      depthTest: true,
      depthWrite: false,
    };
  }, []);

  useEffect(() => {
    ref.current.renderOrder = renderOrder.FOG;
    shaderArgs.uniforms.uColor.value = fog.color;
    shaderArgs.uniforms.uOpacity.value = fog.opacity;

    isReady.current = true;
  }, []);

  useFrame((state, delta) => {
    if (!isReady.current) return;

    if (isAnimating) {
      shaderArgs.uniforms.uOpacity.value = fog.opacity;
      //   sun.current.material.opacity = env.sun.opacity;
      //   fog.current.material.opacity = env.fog.opacity;
    }
  });
  return (
    <>
      <primitive ref={ref} object={model.nodes.Fog}>
        <shaderMaterial args={[shaderArgs]} attach="material" />
      </primitive>
    </>
  );
}
