import { useSceneStore } from '../../SceneService';
import { useEffect, useMemo, useState } from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import {
  AdditiveBlending,
  BackSide,
  Color,
  FrontSide,
  LinearEncoding,
  LinearFilter,
  LinearMipMapLinearFilter,
  Mesh,
  MeshStandardMaterial,
  OrthographicCamera,
  PlaneGeometry,
  RepeatWrapping,
  Scene,
  ShaderChunk,
  ShaderMaterial,
  TextureLoader,
  UniformsLib,
  Vector2,
  Vector3,
  WebGLRenderTarget,
} from 'three';

import lights_fragment_maps from 'services/MaterialService/glsl/lights_fragment_maps.glsl';
import { get_color, hue_shift, saturation } from 'services/MaterialService/glsl/color.gls';
import goldVert from 'services/MaterialService/glsl/goldVert.glsl';
import goldFrag from 'services/MaterialService/glsl/goldFrag.glsl.frag';
import floorVert from 'services/MaterialService/glsl/floorVert.glsl';
import floorFrag from 'services/MaterialService/glsl/floorFrag.glsl';
import lutFrag from 'services/MaterialService/glsl/lut.glsl';
import portalFrag from 'three/Portal/portal.frag';
import portalVert from 'three/Portal/portal.vert';

import matcapMirror from 'services/MaterialService/textures/matcap_glossy_03.jpg';
import matcapRough from 'services/MaterialService/textures/matcap_02.jpg';
import roughnessSrc from 'services/MaterialService/textures/gold_roughness.jpg';
import glossSrc from 'services/MaterialService/textures/colorGloss_kl2.png';
import patternNormalSrc from 'services/MaterialService/textures/pattern_normal.png';
import patternRoughnessSrc from 'services/MaterialService/textures/pattern_roughness.jpg';
import triangles from 'services/MaterialService/textures/Triangles-normal-map.jpg';
import lutSrc from 'services/MaterialService/textures/lookup.png';
import { useLightmaps } from '../hooks';
import useBranding from '../features/useBranding';

export const sharedTextures = {
  videoTexture: null,
};

export default function AuditoriumMaterials() {
  const lightmaps = useLightmaps();
  const renderable = useSceneStore(state => state.scene?.renderable);

  const lightMapSource = lightmaps.find(lm => lm.tag === 'lightMap');
  const lightMapFloorSource = lightmaps.find(lm => lm.tag === 'lightMapFloor');
  const lightMapScreen01Source = lightmaps.find(lm => lm.tag === 'lightMapScreen01');
  const lightMapScreen02Source = lightmaps.find(lm => lm.tag === 'lightMapScreen02');

  const lightMap = lightMapSource ? lightMapSource.texture : null;
  const lightMapFloor = lightMapFloorSource ? lightMapFloorSource.texture : null;
  const lightMapScreen01 = lightMapScreen01Source ? lightMapScreen01Source.texture : null;
  const lightMapScreen02 = lightMapScreen02Source ? lightMapScreen02Source.texture : null;

  const [
    matcapMirrorTex,
    matcapRoughTex,
    roughness,
    patterNormal,
    patternRoughness,
    trianglesNormal,
    gloss,
    lut,
  ] = useLoader(TextureLoader, [
    matcapMirror,
    matcapRough,
    roughnessSrc,
    patternNormalSrc,
    patternRoughnessSrc,
    triangles,
    glossSrc,
    lutSrc,
  ]);

  useBranding();

  const { gl } = useThree();

  const rtSize = {
    x: 16,
    y: 16,
  };
  const [sceneData, setSceneData] = useState(null);

  useEffect(() => {
    const rt = new WebGLRenderTarget(rtSize.x, rtSize.y, {
      generateMipmaps: true,
      minFilter: LinearMipMapLinearFilter,
      magFilter: LinearFilter,
    });
    const cameraRTT = new OrthographicCamera(-rtSize.x / 2, rtSize.x / 2, -rtSize.y / 2, rtSize.y / 2, -10000, 10000);
    cameraRTT.position.z = 100;
    const scene = new Scene();
    const plane = new PlaneGeometry(rtSize.x, rtSize.y, 1, 1);

    const material = new ShaderMaterial({
      vertexShader: `
      uniform mat3 uvTransform;
    	varying vec2 vUv;

      void main() {
      	vUv = ( uvTransform * vec3( uv, 1 ) ).xy;

        vec3 transformed = vec3( position );

        vec4 mvPosition = vec4( transformed, 1.0 );

        mvPosition = modelViewMatrix * mvPosition;
        gl_Position = projectionMatrix * mvPosition;
      }
    `,
      fragmentShader: `
    	varying vec2 vUv;
    	uniform vec2 resolution;
      uniform sampler2D video;
      uniform vec3 color;

      void main() {
        vec2 uv = vec2( 0.8 * (1.0 - vUv.x) + 0.1, 0.3 * (1.0 - vUv.y) + 0.1);
        vec3 result = vec3(uv.x,uv.y,0.0);
        result = vec3(vUv,0.0);

        vec4 color = vec4(0.0);
        vec2 off1 = vec2(1.3846153846) * vec2(0.0,0.5);
        vec2 off2 = vec2(3.2307692308) * vec2(0.0,0.5);
        color += texture2D(video, uv) * 0.2270270270;
        color += texture2D(video, uv + (off1 / resolution)) * 0.3162162162;
        color += texture2D(video, uv - (off1 / resolution)) * 0.3162162162;
        color += texture2D(video, uv + (off2 / resolution)) * 0.0702702703;
        color += texture2D(video, uv - (off2 / resolution)) * 0.0702702703;
        vec2 off3 = vec2(1.3846153846) * vec2(0.5,0.0);
        vec2 off4 = vec2(3.2307692308) * vec2(0.5,0.0);
        color += texture2D(video, uv) * 0.2270270270;
        color += texture2D(video, uv + (off3 / resolution)) * 0.3162162162;
        color += texture2D(video, uv - (off3 / resolution)) * 0.3162162162;
        color += texture2D(video, uv + (off4 / resolution)) * 0.0702702703;
        color += texture2D(video, uv - (off4 / resolution)) * 0.0702702703;

        result = color.rgb;
        gl_FragColor = vec4(result, 1.0);
       }
    `,

      side: BackSide,
      uniforms: {
        ...UniformsLib.common,
        video: { value: sharedTextures.videoTexture },
        color: { value: new Vector3(1.0, 0.0, 0.0) },
        resolution: { value: new Vector2(rtSize.x, rtSize.y) },
      },
    });
    material.needsUpdate = true;

    const quad = new Mesh(plane, material);
    quad.position.z = -100;
    scene.add(quad);
    setSceneData({
      rt,
      scene,
      cameraRTT,
      material,
    });
  }, [sharedTextures.videoTexture]);

  useFrame((context, dt) => {
    if (!sceneData) return;

    const { rt, scene, cameraRTT, material } = sceneData;
    gl.setRenderTarget(rt);
    material.uniforms['video'].value = sharedTextures.videoTexture;
    gl.clear();
    gl.render(scene, cameraRTT);
    gl.setRenderTarget(null);

    renderable?.traverse(o => {
      if (o.material && o.material.name === 'portal_mat' && o.material.uniforms) {
        o.material.uniforms['time'].value += dt;
      }
    });
  });

  useEffect(() => {
    if (!renderable) return;
    if (!sceneData) return;

    const { rt, scene, cameraRTT, material } = sceneData;

    ShaderChunk['lights_fragment_maps_2'] = lights_fragment_maps;
    ShaderChunk['hue_shift'] = hue_shift;
    ShaderChunk['saturation'] = saturation;
    ShaderChunk['get_color'] = get_color;
    ShaderChunk['lut'] = lutFrag;

    function configureLightMap(lightMap) {
      if (!lightMap) return;
      lightMap.flipY = false;
      lightMap.generateMipmaps = true;
      lightMap.anisotropy = 16;
      lightMap.minFilter = LinearMipMapLinearFilter;
      lightMap.magFilter = LinearFilter;
      lightMap.encoding = LinearEncoding;
    }

    function configurePatternTexture(texture) {
      texture.wrapS = RepeatWrapping;
      texture.wrapT = RepeatWrapping;
      texture.generateMipmaps = true;
      texture.anisotropy = 16;
    }

    configureLightMap(lightMap);
    configureLightMap(lightMapFloor);
    configureLightMap(lightMapScreen01);
    configureLightMap(lightMapScreen02);
    configurePatternTexture(roughness);
    configurePatternTexture(patterNormal);
    configurePatternTexture(patternRoughness);
    configurePatternTexture(trianglesNormal);
    lut.generateMipmaps = false;
    lut.minFilter = LinearFilter;
    lut.magFilter = LinearFilter;

    const colors = {
      default: 0x5d557a, // default color for stage
      floor: 0x2d2741, // Environment
      pasted__gold_pattern_mat: 0xc0c0c0, // Silver Leiste unten
      pasted__gold_mat: 0xd3b76e, // Gold Lamps
      // gold_mat: '0x9598a3', // Gold Lamps
    };

    const createMetallicMaterial = (color, lm, lmScreen, usePattern) => {
      const mat = new ShaderMaterial({
        vertexShader: goldVert,
        fragmentShader: goldFrag,
        extensions: { derivatives: true },
        uniforms: {
          ...UniformsLib.common,
          diffuse: { value: new Color(color) },
          map: { value: matcapMirrorTex },
          matcapRough: { value: matcapRoughTex },
          env: { value: gloss },
          roughnessMap: { value: roughness },
          lightMap: { value: lm },
          lightMapScreen: { value: lmScreen },
          patternNormal: { value: usePattern ? patterNormal : null },
          stream: { value: rt.texture },
          lut: { value: lut },
          hueShiftValue: { value: Math.PI * 1.97 }, // Hue for emissive color
          saturationValue: { value: 1.0 }, // saturation for emissive color
        },
      });
      if (usePattern) mat.defines['USE_PATTERN'] = '';
      return mat;
    };

    const createEnvironmentMaterial = (color, options = {}) => {
      return new ShaderMaterial({
        vertexShader: floorVert,
        fragmentShader: floorFrag,
        uniforms: {
          ...UniformsLib.common,
          diffuse: { value: new Color(color) },
          options: { value: new Vector3(options.isGround ? 1 : 0, options.isPlane ? 1 : 0, options.isSphere ? 1 : 0) },
          lut: { value: lut },
        },
      });
    };

    renderable.traverse(o => {
      const remove = o.name.includes('collision_mesh');
      if (o.orgMaterial) {
        const nonLightMap = ['glass_mat', 'metal_bright_mat', 'metal_dark_mat', 'bg_mat', 'pasted__companylogo_mat'];
        const goldMaterials = ['pasted__gold_pattern_mat', 'pasted__gold_mat'];
        nonLightMap.push(...goldMaterials);
        const useLightMap = o.geometry.attributes.uv2 && !nonLightMap.includes(o.orgMaterial.name);
        const useMatCap = goldMaterials.includes(o.orgMaterial.name);
        const usePattern = o.orgMaterial.name === 'pasted__gold_pattern_mat';
        const lm = o.name.includes('LM01') ? lightMap : lightMapFloor;
        const lmScreen = o.name.includes('LM01') ? lightMapScreen01 : lightMapScreen02;

        if (o.orgMaterial.name === 'ground_mat')
          o.material = createEnvironmentMaterial(colors.floor, { isGround: true });
        if (o.orgMaterial.name === 'plane_mat') o.material = createEnvironmentMaterial(colors.floor, { isPlane: true });
        if (o.orgMaterial.name === 'dome_mat') o.material = createEnvironmentMaterial(colors.floor, { isSphere: true });

        if (useMatCap) {
          o.material = createMetallicMaterial(colors[o.orgMaterial.name], lm, lmScreen, usePattern);
        }

        if (useLightMap) {
          o.material = new MeshStandardMaterial();
          o.material.defines = { DISTANCE: '', LUT_FLIP_Y: '' };
          if (o.material.map) o.material.map.anisotropy = 16;
          o.material.color = new Color(colors[o.orgMaterial.name] ? colors[o.orgMaterial.name] : colors['default']);

          o.material.emissive = o.orgMaterial.emissive;

          o.material.roughness = o.orgMaterial.roughness;
          o.material.envMapIntensity = 0;
          o.material.normalMap =
            o.orgMaterial.name === 'pasted__gray_dark_mat'
              ? patterNormal
              : o.orgMaterial.name === 'pasted__wall_geometric_mat'
              ? trianglesNormal
              : null;
          o.material.metalness = o.orgMaterial.name === 'pasted__gray_dark_mat' ? 1 : 0;
          o.material.metalnessMap = o.orgMaterial.name === 'pasted__gray_dark_mat' ? patternRoughness : null;
          o.material.lightMap = lm; // just standard Lightmap stuff

          //Custom Shader Properties
          o.material.userData.hueShiftValue = { value: Math.PI * 1.45 }; // Hue for emissive color
          o.material.userData.saturationValue = { value: 0.65 }; // saturation for emissive color
          o.material.userData.lightMapScreen = {
            value: o.orgMaterial.name === 'pasted__logo_glow_mat' ? null : lmScreen,
          }; // Lightmap for Ambient Light with uv information for video texture
          o.material.userData.stream = { value: rt.texture }; // video texture
          o.material.userData.lut = { value: lut }; // lookup table

          //uncomment this for hot updates
          // o.material.customProgramCacheKey = function() {
          //   return Math.random();
          // };

          // Patching Three MeshStandartMaterial with own features
          o.material.onBeforeCompile = shader => {
            // Add Varying for vWorldPosition
            const varyingMarker = 'varying vec3 vViewPosition;\n';
            const varyingReplacement = 'varying vec3 vViewPosition;\nvarying vec3 vWorldPosition;\n';
            shader.fragmentShader = shader.fragmentShader.replace(varyingMarker, varyingReplacement);
            shader.vertexShader = shader.vertexShader.replace(varyingMarker, varyingReplacement);

            // Apply worldPosition to varying
            shader.vertexShader = shader.vertexShader.replace(
              '#include <worldpos_vertex>\n',
              `
              #if defined( USE_ENVMAP ) || defined( DISTANCE ) || defined ( USE_SHADOWMAP )
                vec4 worldPosition = vec4( transformed, 1.0 );
                #ifdef USE_INSTANCING
                  worldPosition = instanceMatrix * worldPosition;
                #endif
                worldPosition = modelMatrix * worldPosition;
                vWorldPosition = worldPosition.xyz;
              #endif
              `
            );

            // Add Includes for ShaderChunks
            const marker = '#include <common>\n';
            const replacement = `
                   #include <common>
                   #include <hue_shift>
                   #include <saturation>
                   #include <lut>
                  `;
            shader.fragmentShader = shader.fragmentShader.replace(marker, replacement);

            // change include for lighmapping shaderChunk
            const LmMarker = '#include <lights_fragment_maps>\n';
            ShaderChunk['lights_fragment_maps_2'] = lights_fragment_maps;
            shader.fragmentShader = shader.fragmentShader.replace(LmMarker, '#include <lights_fragment_maps_2>\n');

            // add Uniforms to Shader
            const uniformMarker = 'uniform float opacity;\n';
            const uniformReplacement = `
              uniform float opacity; 
              uniform float hueShiftValue;
              uniform float saturationValue;
              uniform sampler2D lightMapScreen;
              uniform sampler2D stream;
              uniform sampler2D lut;
            `;
            shader.fragmentShader = shader.fragmentShader.replace(uniformMarker, uniformReplacement);

            // Add hueShift and Saturation feature to the emissive value
            const emissiveMarker = 'vec3 totalEmissiveRadiance = emissive;\n';
            const emissiveReplacement =
              'vec3 totalEmissiveRadiance = czm_saturation(hueShift(emissive, hueShiftValue), saturationValue);\n';
            shader.fragmentShader = shader.fragmentShader.replace(emissiveMarker, emissiveReplacement);

            // Color Lookup
            shader.fragmentShader = shader.fragmentShader.replace(
              '#include <output_fragment>\n',
              '	gl_FragColor = vec4(lookup(vec4( outgoingLight, 1.0 ), lut).rgb, diffuseColor.a);\n'
            );

            // Apply custom userData Properties to shader uniforms
            shader.uniforms['hueShiftValue'] = o.material.userData.hueShiftValue;
            shader.uniforms['saturationValue'] = o.material.userData.saturationValue;
            shader.uniforms['lightMapScreen'] = o.material.userData.lightMapScreen;
            shader.uniforms['stream'] = o.material.userData.stream;
            shader.uniforms['lut'] = o.material.userData.lut;

            shader.needsUpdate = true;
          };
          if (o.material) o.material.needsUpdate = true;
        }

        if (o.orgMaterial.name === 'guide_lines_mat') {
          o.material = o.orgMaterial.clone();
          o.material.emissiveMap = o.orgMaterial.map;
          o.material.emissive = new Color(0xffffff);
          o.material.emissiveIntensity = 1.0;
          o.material.emissiveMap = o.orgMaterial.map;
          o.material.envMapIntensity = 0;
          o.material.blending = AdditiveBlending;
        }

        if (o.orgMaterial.name === 'portal_mat') {
          o.material = new ShaderMaterial({
            vertexShader: portalVert,
            fragmentShader: portalFrag,
            uniforms: {
              time: { value: 0.0 },
              hueShiftValue: { value: Math.PI * 0.2 }, // Hue for emissive color
              saturationValue: { value: 0.5 }, // saturation for emissive color
            },
          });
          o.material.name = o.orgMaterial.name;
          o.material.side = FrontSide;
          o.material.transparent = true;
          o.material.needsUpdate = true;
        }

        if (remove) o.material.visible = false;
      }
    });
  }, [renderable, sceneData]);

  return null;
}
