import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MathUtils } from 'three';
import { gsap } from 'gsap';
import { useDebugStore } from '../../../../../storage/debug';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import IconMarker from './marker.svg';
import {
  DebugProgress,
  Dot,
  DragHandle,
  Gradient,
  GradientMask,
  HANDLE_SIZE,
  Line,
  MarkerLabel,
  MarkerPosition,
  Markers,
  SliderWrapper,
  Track,
} from './styles';
import { useDeviceStore } from '../../../../../services/DeviceService';
import { useOnboardingStore } from '../../../../../services/OnboardingService';
import { useWindowStore } from '../../../../../services/WindowService';
import { useHowlerStore } from '../../../../../services/HowlerService';
import { damp } from 'maath/easing';
import { DotScala, PeakIndicator } from '../../../WeatherScene/WeatherUI/WeatherSlider/styles';
import Polygon from '../../../WeatherScene/WeatherUI/WeatherSlider/icons/polygon.svg';
import { clamp } from 'maath/misc';
import { useConstructionAssemblyService } from '../../../../../services/ConstructionAssemblyService';

gsap.registerPlugin(MotionPathPlugin);

const snapSliderDuration = 0.3;
const forwardAnimationDuration = 0.4;

function Marker({ index, len, xp }) {
  const showIcon = index !== 0 && index !== len - 1;
  let label = '';
  if (index === 0) {
    label = 'Features';
  } else {
    label = index;
  }
  return (
    <MarkerPosition xp={xp}>
      {showIcon && <IconMarker />}
      <MarkerLabel>{label}</MarkerLabel>
    </MarkerPosition>
  );
}

export default function ConstructionSlider() {
  const isMobile = useDeviceStore(state => state.device.isMobile);
  // const isPhone = useDeviceStore(state => state.device.isMobileOnly);
  const playClickUiSound = useHowlerStore(state => state.playClickUiSound);
  const windowWidth = useWindowStore(state => state.width);

  const [sliderWidth, setSliderWidth] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const slider = useRef(null);
  const dragHandle = useRef(null);
  const dragHandleRect = useRef(null);
  const gradientRef = useRef(null);
  const tweenRef = useRef(null);

  const dragPosition = useRef(0);
  const startPos = useRef(0);
  const startEventPos = useRef(0);

  const weatherProgressEnabled = useDebugStore(state => state.getWeatherProgressEnabled());
  const debugProgress = useRef(null);
  const setProgress = useConstructionAssemblyService(state => state.setProgress);
  const setTargetProgress = useConstructionAssemblyService(state => state.setTargetProgress);
  const numSteps = useConstructionAssemblyService(state => state.numSteps);
  const selectedModelId = useConstructionAssemblyService(state => state.selectedModelId);

  const linearProgress = useRef(0);
  const targetProgress = useRef(0);

  const requestRef = useRef(null);
  const time = useRef(NaN);
  const indicator = useRef(null);

  const positions = useMemo(() => new Array(numSteps).fill(0).map((_, i, ar) => i / (ar.length - 1)), [numSteps]);

  const detailID = useConstructionAssemblyService(state => state.detailID);
  const showDetail = detailID !== null;

  const startDrag = e => {
    tweenRef.current?.kill();

    setIsDragging(true);

    startPos.current = dragPosition.current;
    startEventPos.current = (e.targetTouches ? e.targetTouches[0] : e).clientX;

    dragHandleRect.current = dragHandle.current.getBoundingClientRect();
    useOnboardingStore.getState().startInactivityTimer();
    playClickUiSound();
  };

  const handleDrag = useCallback(e => {
    const sliderRange = slider.current.offsetWidth + HANDLE_SIZE - dragHandleRect.current.width;
    const event = e.targetTouches ? e.targetTouches[0] : e;

    dragPosition.current = startPos.current + (event.clientX - startEventPos.current);
    dragPosition.current = MathUtils.clamp(dragPosition.current, 0, sliderRange);

    const progress = dragPosition.current / sliderRange;
    targetProgress.current = progress;
    gsap.set(dragHandle.current, { x: dragPosition.current });
    gsap.set(gradientRef.current, { width: progress * 100 + '%' });
  }, []);

  const moveToPosition = t => {
    const sliderRange = slider.current.offsetWidth + HANDLE_SIZE - dragHandleRect.current.width;
    const relativeProgress = Math.round(t * (numSteps - 1)) / (numSteps - 1);

    const relativeStart = dragPosition.current / sliderRange;
    const target = { x: relativeStart };
    setTargetProgress(relativeProgress);
    tweenRef.current?.kill();
    tweenRef.current = gsap.to(target, {
      x: relativeProgress,
      duration: snapSliderDuration,
      onUpdate: () => {
        dragPosition.current = target.x * sliderRange;
        gsap.set(dragHandle.current, { x: target.x * sliderRange });
        gsap.set(gradientRef.current, { width: target.x * 100 + '%' });
        targetProgress.current = target.x;
      },
      onComplete: () => {
        dragPosition.current = target.x * sliderRange;
        gsap.set(dragHandle.current, { x: target.x * sliderRange });
        gsap.set(gradientRef.current, { width: target.x * 100 + '%' });
        targetProgress.current = target.x;
      },
    });
  };

  const stopDrag = () => {
    const sliderRange = slider.current.offsetWidth + HANDLE_SIZE - dragHandleRect.current.width;
    moveToPosition(dragPosition.current / sliderRange);
    setIsDragging(false);
    if (isDragging) {
      // playClickUiSound();
    }
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('touchmove', handleDrag);
      window.addEventListener('mouseup', stopDrag);
    } else {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('touchmove', handleDrag);
      window.removeEventListener('mouseup', stopDrag);
    }

    return () => {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('touchmove', handleDrag);
      window.removeEventListener('mouseup', stopDrag);
    };
  }, [isDragging]);

  useEffect(() => {
    if (slider.current) {
      setSliderWidth(slider.current.clientWidth);
    }
  }, [windowWidth]);

  useEffect(() => {
    dragPosition.current = 0;
    startPos.current = dragPosition.current;
    startEventPos.current = 0;
    targetProgress.current = linearProgress.current = useConstructionAssemblyService.getState().progress;
    dragHandleRect.current = dragHandle.current.getBoundingClientRect();
    const sliderRange = slider.current.offsetWidth + HANDLE_SIZE - dragHandleRect.current.width;
    gsap.set(dragHandle.current, { x: targetProgress.current * sliderRange });
    gsap.set(gradientRef.current, { width: targetProgress.current * 100 + '%' });
  }, [selectedModelId]);

  useEffect(() => {
    const maxSpeed = 1;
    const easing = undefined;
    const epsilon = 0.0001;

    const update = t => {
      const deltaTime = (t - time.current) * 0.001 || 0;
      damp(
        linearProgress,
        'current',
        targetProgress.current,
        forwardAnimationDuration,
        deltaTime,
        maxSpeed,
        easing,
        epsilon
      );
      linearProgress.current = clamp(linearProgress.current, 0, 1);

      if (indicator.current)
        indicator.current.style.transform = `translateX(${linearProgress.current * sliderWidth - 6}px)`;
      setProgress(linearProgress.current);
      if (weatherProgressEnabled) debugProgress.current.innerText = linearProgress.current.toFixed(3);

      time.current = t;
      requestRef.current = requestAnimationFrame(update);
    };
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, [selectedModelId, sliderWidth]);

  return (
    <>
      <SliderWrapper ref={slider} isMobile={isMobile} active={!showDetail}>
        <Track>
          {weatherProgressEnabled && <DebugProgress ref={debugProgress}>{0}</DebugProgress>}

          <GradientMask ref={gradientRef}>
            <Gradient width={sliderWidth} />
          </GradientMask>

          <Line />
          <Markers>
            {positions.map((position, i) => (
              <Marker key={i} index={i} len={positions.length} xp={position * 100} />
            ))}
          </Markers>
          <DotScala />
          <DotScala last={true} />
        </Track>
        <DragHandle
          ref={dragHandle}
          dragging={isDragging}
          onPointerDown={startDrag}
          onPointerUp={stopDrag}
          onMouseUp={stopDrag}
        >
          <Dot />
        </DragHandle>
        <PeakIndicator ref={indicator}>
          <Polygon />
        </PeakIndicator>
      </SliderWrapper>
    </>
  );
}
