import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Form, IntroHeadline, IntroSubline, NameFieldWrapper, ToggleRoute } from '../styles';
import { useUserStore } from 'services/UserService';
import { Input } from 'common/components/Input';
import { disableForm, useLoginStatus } from '../helper';
import nameValidation from 'utilities/validation/nameValidation';
import { useTranslate } from 'services/TranslationService/hooks';
import VeTextLink from 'common/components/VeTextLink';
import { useEventStore } from 'services/EventService';
import { useHistory } from 'react-router-dom';

function getPseudoRandomString(length = 10) {
  return Math.random()
    .toString(36)
    .replace(/[^a-z0-9]+/g, '')
    .substring(1, 1 + Math.min(length, 10));
}

function ReducedRegisterForm({ showHostCodeInput = false }) {
  const [forename, setForename] = useState('');
  const [surname, setSurname] = useState('');
  const [expertCode, setExpertCode] = useState('');
  const [eventCode, setEventCode] = useState('');
  const { id: eventId, useEventCode, useExpertCode } = useEventStore(state => state.event);
  const [registerButtonEnabled, setRegisterButtonEnabled] = useState(false);
  const registerForm = useRef();

  const register = useUserStore(state => state.register);

  const loginStatus = useLoginStatus();
  const disabled = loginStatus.isPending;
  const translate = useTranslate();

  const history = useHistory();
  const hostLogin = useExpertCode && showHostCodeInput;

  useEffect(() => {
    disableForm(registerForm.current, disabled);
  }, [disabled]);

  useEffect(() => {
    setRegisterButtonEnabled(
      nameValidation(forename) &&
        nameValidation(surname) &&
        (hostLogin ? nameValidation(expertCode) : true) &&
        (!useEventCode || eventCode.length === 4)
    );
  }, [forename, surname, expertCode, eventCode]);

  const handleInputChange = (e, handler) => {
    const value = e.nativeEvent.target.value;
    handler(value);
  };

  const submit = e => {
    e.preventDefault();
    if (!registerButtonEnabled) return;
    register({
      credentials: {
        forename,
        surname,
        // the backend still needs both a password and email to work.
        // We give it a (hopefully) unique address here as a dirty workaround.
        email: `${Date.now()}-${getPseudoRandomString(6)}@notarealaddress.demodern.de`,
        password: getPseudoRandomString(),
        eventId,
        expertCode,
        eventCode,
      },
    });
  };

  return (
    <Form ref={registerForm} onSubmit={submit}>
      {useExpertCode && (
        <>
          <ToggleRoute
            onClick={() => {
              history.push(hostLogin ? '/' : '/host');
              setForename('');
              setSurname('');
            }}
          >
            <VeTextLink size={15}>{hostLogin ? 'Attendee access' : 'Host access'}</VeTextLink>
          </ToggleRoute>
        </>
      )}

      {hostLogin && (
        <>
          <IntroHeadline>{'Host access'}</IntroHeadline>
          <IntroSubline>
            {'Type in your access code to host Q&A sessions in the Auditorium and speak live to all attendees.'}
          </IntroSubline>
        </>
      )}

      <IntroSubline style={{ marginBottom: '.5vh', fontWeight: 'bold', color: '#ffffff' }}>
        {translate('register.form.headline') || 'Enter your personal information'}
      </IntroSubline>

      <ErrorMessage>{loginStatus.message.copy}</ErrorMessage>
      <NameFieldWrapper style={{ marginBottom: hostLogin ? '2em' : '4em' }}>
        <Input
          border
          placeholder={translate('register.placeholder.firstname') || 'First name*'}
          value={forename}
          onChange={e => {
            handleInputChange(e, setForename);
          }}
          style={{ marginBottom: '0px', marginRight: '15px' }}
          disabled={disabled}
        />
        <Input
          border
          placeholder={translate('register.placeholder.surname') || 'Last name*'}
          value={surname}
          onChange={e => {
            handleInputChange(e, setSurname);
          }}
          style={{ marginBottom: '0px', marginRight: '15px' }}
          disabled={disabled}
        />
        {useEventCode && (
          <Input
            border
            placeholder={'Code*'}
            maxLength={4}
            value={eventCode}
            onChange={e => {
              useUserStore.getState().resetMessage();
              handleInputChange(e, setEventCode);
            }}
            style={{ marginBottom: '0px' }}
            disabled={disabled}
          />
        )}
      </NameFieldWrapper>
      {hostLogin && (
        <>
          <NameFieldWrapper style={{ marginBottom: '4em' }}>
            <Input
              border
              placeholder={translate('register.placeholder.expertCode') || 'Your access code*'}
              value={expertCode}
              onChange={e => {
                useUserStore.getState().resetMessage();
                handleInputChange(e, setExpertCode);
              }}
              style={{ marginBottom: '0px', marginRight: '15px' }}
              disabled={disabled}
            />
          </NameFieldWrapper>
        </>
      )}

      <VeTextLink size={20} as={'button'} disabled={!registerButtonEnabled || disabled} type="submit" role="button">
        {translate('register.button.submit') || 'Join now'}
      </VeTextLink>
    </Form>
  );
}

export { ReducedRegisterForm };
