import userStorage from 'storage/user';
import { Redirect } from 'react-router-dom';

export default function MeetingReservationRedirector({ match, to }) {
  const { reservationId } = match.params;
  if (reservationId) {
    userStorage.setMeetingReservationId(parseInt(reservationId));
  }

  return <Redirect to={to} />;
}
