import create from 'utilities/zustand/create';
import { gsap } from 'gsap';

import userStorage from 'storage/user';

import { useModalService } from 'services/ModalService';
import { useEventStore } from 'services/EventService';
import { isMobile } from 'mobile-device-detect';
import { useUserStore } from '../UserService';
import { useDistrictStore } from 'services/DistrictService';

import Onboarding from '../../components/Onboarding';
import OnboardingControls from '../../components/Onboarding/Slides/ve/OnboardingControls';
import OnboardingHost from '../../components/Onboarding/Slides/ve/OnboardingHost';
import OnboardingScroll from '../../components/Onboarding/Slides/schletter/house/OnboardingScroll';
import OnboardingOrientate from '../../components/Onboarding/Slides/schletter/house/OnboardingOrientate';
import OnboardingJourney from '../../components/Onboarding/Slides/schletter/house/OnboardingJourney';
import OnboardingControl from '../../components/Onboarding/Slides/schletter/weather/OnboardingControl';
import OnboardingToggle from '../../components/Onboarding/Slides/schletter/weather/OnboardingToggle';
import OnboardingMove from '../../components/Onboarding/Slides/schletter/construction/OnboardingMove';
import OnboardingAssemble from '../../components/Onboarding/Slides/schletter/construction/OnboardingAssemble';

const TIMEOUT_DELAY = 30;

export const useOnboardingStore = create((set, get) => ({
  needsInactivity: false,
  inactivityTimeout: undefined,
  done: userStorage.getOnboardingDone(),

  startOnboarding: () => {
    if (!get().done) {
      get().repeatOnboarding();
    }
  },

  repeatOnboarding: () => {
    const useAvatars = useEventStore.getState().event.useAvatars;
    const isExpert = useUserStore.getState().user.role.type === 'expert';
    const district = useDistrictStore.getState().district;

    const onBoardingSlides = [];

    if (useAvatars) {
      onBoardingSlides.push(<OnboardingControls isMobile={isMobile} />);
    }

    // switch between onboarding components
    if (district.room === 'house') {
      onBoardingSlides.push(<OnboardingScroll />);
      onBoardingSlides.push(<OnboardingOrientate />);
      onBoardingSlides.push(<OnboardingJourney />);
    } else if (district.room === 'weather') {
      onBoardingSlides.push(<OnboardingToggle />);
      onBoardingSlides.push(<OnboardingControl />);
    } else if (district.room === 'construction') {
      onBoardingSlides.push(<OnboardingMove />);
      onBoardingSlides.push(<OnboardingAssemble />);
    }

    if (isExpert) {
      onBoardingSlides.push(<OnboardingHost />);
    }

    useModalService.getState().addModal(<Onboarding onBoardingSlides={onBoardingSlides} />);
  },

  finishOnboarding: () => {
    userStorage.setOnboardingDone();
    set({ done: true });
  },

  setNeedsInactivity: v => {
    set({ needsInactivity: v });
  },

  startInactivityTimer: () => {
    // CALL THIS WHENEVER YOU WANT, IT WON'T DO ANYTHING IF needsInactivity IS FALSE
    if (!get().needsInactivity) return;

    get().inactivityTimeout?.kill();
    get().inactivityTimeout = gsap.delayedCall(TIMEOUT_DELAY, () => {
      get().repeatOnboarding();
    });
  },
}));
