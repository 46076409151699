import { useTheaterStore } from 'services/TheaterService';
import {
  Bold,
  Cell,
  DescriptionText,
  DescriptionTitle,
  Entry,
  EventDescription,
  EventInformation,
  InformationDate,
  InformationSpeaker,
  Regular,
  SpeakerName,
  SpeakerPicture,
  SpeakerText,
  TeaserContainer,
} from './styles';
import PersonImagePlaceholder from 'common/components/Person/PersonImage/assets/personImagePlaceholder.png';
import BookmarkButton from 'common/components/Timetable/common/BookmarkButton';
import moment from 'moment';
import { useTranslate } from 'services/TranslationService/hooks';

export default function TimetableTeaser() {
  const translate = useTranslate();
  const featuredTalk = useTheaterStore(state => state.theaterTalks.find(t => t.featured));
  if (!featuredTalk) return null;
  const profilePicture = featuredTalk.speaker.profilePicture
    ? featuredTalk.speaker.profilePicture
    : PersonImagePlaceholder;
  const foreName = featuredTalk.speaker.forename;
  const surName = featuredTalk.speaker.surname;
  const company = featuredTalk.speaker.company;
  const time = featuredTalk.startTime;
  const duration = featuredTalk.duration;
  const talkTitle = featuredTalk.title;
  const talkDescription = featuredTalk.description;

  const date = moment(time).format('dddd, DD/MM/YYYY ');
  const startTime = moment(time).format('hh:mm');
  const endTime = moment(time)
    .add(duration, 'hours')
    .format(' hh:mm ');

  return (
    <TeaserContainer>
      <EventInformation>
        <Cell>
          <InformationSpeaker>
            <SpeakerPicture url={profilePicture}> </SpeakerPicture>
            <SpeakerText accent>{translate('schedule.talks.speaker') || 'Speaker'}</SpeakerText>
            <Entry>
              <SpeakerName>{foreName} </SpeakerName>
              <SpeakerName>{surName} </SpeakerName>
            </Entry>
            <SpeakerText>{company}</SpeakerText>
          </InformationSpeaker>
        </Cell>
        <Cell>
          <InformationDate>
            <Entry>
              <Bold>{date}</Bold>
              <Regular>
                <br /> {startTime} - {endTime}
              </Regular>
            </Entry>
            <br />
            <Entry>
              <Bold>{translate('schedule.talks.videocall') || 'Video Call'}: </Bold>
              <Regular>{translate('schedule.talks.link') || 'Link'} </Regular>
            </Entry>
            <Entry>
              <Bold>{translate('schedule.talks.topic') || 'Topic'}: </Bold>
              <Regular>{talkTitle}</Regular>
            </Entry>
            <BookmarkButton talkId={featuredTalk.id} active={featuredTalk.isBookmarked} style={{ marginTop: '20px' }} />
          </InformationDate>
        </Cell>
      </EventInformation>
      <EventDescription>
        <DescriptionTitle>{talkTitle}</DescriptionTitle>
        <DescriptionText>{talkDescription}</DescriptionText>
      </EventDescription>
    </TeaserContainer>
  );
}
