import IconHelp from './icons/icon-help.svg';
import styled, { css } from 'styled-components';
import { useOnboardingStore } from '../../../../services/OnboardingService';
import SliceHoverEffect, { parentElementHoverCSS } from '../SliceHoverEffect';
import { mediaMax } from '../../../../common/layout';
import { IconShadow } from '../index';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: all;
  padding-right: 20px;
  padding-bottom: 0;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.35);

  ${mediaMax(
    'medium',
    css`
      padding-right: 10px;
    `
  )};

  ${mediaMax(
    'small',
    css`
      padding-bottom: 15px;
    `
  )};

  ${parentElementHoverCSS}
`;

export default function ButtonHelp() {
  const handleClick = () => {
    useOnboardingStore.getState().repeatOnboarding();
  };

  return (
    <ButtonWrapper onClick={handleClick} data-sound-action="click">
      <IconShadow style={{ height: 16 }}>
        <IconHelp style={{ marginRight: '8px' }} />
      </IconShadow>
      <SliceHoverEffect>HELP</SliceHoverEffect>
    </ButtonWrapper>
  );
}
