import styled, { css } from 'styled-components';
import { easings, lgBaseColors } from 'uiLivi/styles';
import { H5 } from 'common/text';
import { GRID_SPACE_CONTENT_PERCENTAGE, GRID_SPACE_GAPS_PERCENTAGE } from '../config';
import { mediaMax } from 'common/layout';

export const Container = styled.div`
  position: relative;
  width: ${props => GRID_SPACE_CONTENT_PERCENTAGE / props.columns}%;
  margin-right: ${props => ((props.index + 1) % props.columns ? GRID_SPACE_GAPS_PERCENTAGE / (props.columns - 1) : 0)}%;
  margin-bottom: 30px;
  ${mediaMax(
    'small',
    css`
      width: 100%;
      margin: 0 0 30px 0;
    `
  )}
`;

export const Image = styled.div`
  background-image: url("${props => props.src}");
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: transform 1s ${easings.easeOutStrong};
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  padding-top: 56.6%;
  background-color: black;
  position: relative;
  overflow: hidden;
  &:hover {
    ${Image} {
      transform: scale(1.1);
    }
  }
`;

export const Headline = styled(H5)`
  margin-block-end: 0.2em;
`;

export const Subline = styled.div`
  color: ${lgBaseColors.grey.medium};
`;
