import React, { useMemo, useState } from 'react';
import { BottomContainer, Container, Headline, MiddleContainer, TopContainer, Topline } from './styles';
import { Button } from 'common/components/Button';
import CategoryDot from './CategoryDot';
import { useUserStore } from 'services/UserService';
import HBFLayout from 'common/components/HBFLayout';
import { useTranslate } from 'services/TranslationService/hooks';
import { getInitialCustomizationConfiguration } from 'services/AvatarService/config';
import { useAvatarStore } from 'services/AvatarService';
import NextStepButton from '../NextStepButton';
import OptionIndicator from './OptionIndicator';

export default function CategoryPanel({ customizationConfiguration, onDone, randomize }) {
  const userName = useUserStore(state => state.user.forename);
  const [hover, setHover] = useState(false);
  const translate = useTranslate();
  const language = useUserStore(state => state.user && state.user.language);
  const initialConfig = useMemo(() => {
    return getInitialCustomizationConfiguration();
  }, [language]);
  const selectedCategoryIndex = useAvatarStore(state => state.getSelectedCategoryIndex());
  const selectedCategory = useAvatarStore(state => state.getSelectedCategory());
  const categoryCount = useAvatarStore(state => state.getCategoryCount());
  const isLastCategory = useMemo(() => selectedCategoryIndex === categoryCount - 1, [
    selectedCategoryIndex,
    categoryCount,
  ]);

  const header = (
    <TopContainer>
      {/*       <Topline>
        {translate('avatar.hello.subline') || 'Hi'} {userName}! {selectedCategoryIndex}/{categoryCount}
      </Topline>*/}
      <Headline>Choose {selectedCategory && selectedCategory.name}</Headline>
      <OptionIndicator />
      {/*       <OptionalButton
        onClick={() => {
          randomize();
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <OptionalButtonText>{translate('avatar.cta.randomize') || 'Surprise me'}</OptionalButtonText>
        <IconContainer icon={IconDice} hover={hover} />
      </OptionalButton> */}
    </TopContainer>
  );

  const getTranslatedName = catId => {
    const category = initialConfig.find(({ id }) => id === catId);
    return category.name;
  };

  const body = (
    <MiddleContainer>
      {customizationConfiguration.map(category => {
        return <CategoryDot key={category.id} category={category} translated={getTranslatedName(category.id)} />;
      })}
    </MiddleContainer>
  );
  const footer = (
    <BottomContainer style={{ marginTop: '15px' }}>
      {isLastCategory ? (
        <Button
          accent
          onClick={() => {
            onDone();
          }}
        >
          {translate('avatar.cta.done') || 'I am ready'}
        </Button>
      ) : (
        <NextStepButton />
      )}
    </BottomContainer>
  );

  return (
    <Container>
      <HBFLayout header={header} body={body} footer={footer} takeFullHeight fullHeightMinus={30} />
    </Container>
  );
}
