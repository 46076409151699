import { useEffect, useMemo, useRef, useState } from 'react';
import { useWindowStore } from '../../../../../services/WindowService';

export const activeWidth = 0.775; // times container width
const inactiveWidth = 0.25; // times container width
const gap = 8; // px

const imageAspect = 850 / 480;

export default function useVariationStyles(variations, activeIndex) {
  const containerRef = useRef(null);
  const [render, setRender] = useState(false);
  const width = useWindowStore(state => state.width);
  const containerWidth = useMemo(() => {
    if (!containerRef.current) return 0;

    return containerRef.current.getBoundingClientRect().width;
  }, [containerRef.current, width, render]);

  const variationStyles = useMemo(() => {
    const rightEdge = containerWidth * (activeIndex * inactiveWidth + activeWidth);
    const offset = Math.max(
      rightEdge -
        containerWidth -
        (activeIndex === 0 ? -inactiveWidth * containerWidth * 0.5 : -inactiveWidth * containerWidth * 0.5) -
        1.5 * gap,
      -inactiveWidth * containerWidth * 0.5
    );

    return variations.map((variation, i) => {
      const variationActiveWidth = containerWidth * activeWidth;
      const variationInactiveWidth = containerWidth * inactiveWidth;

      const scale = i === activeIndex ? 1 : (variationInactiveWidth - gap) / (variationActiveWidth - gap);
      const left =
        (i <= activeIndex ? i * variationInactiveWidth : (i - 1) * variationInactiveWidth + variationActiveWidth) -
        offset;

      return {
        style: {
          position: 'absolute',
          width: `${variationActiveWidth - gap}px`,
          height: `${(variationActiveWidth - gap) / imageAspect}px`,
          transform: `translateX(${left}px) scale(${scale})`,
          transformOrigin: 'left center',
        },
      };
    });
  }, [variations, activeIndex, containerWidth]);

  useEffect(() => {
    setRender(true);
  }, []);

  return [
    variationStyles,
    `${((containerWidth - gap) / imageAspect) * activeWidth}px`,
    containerRef,
    render,
    `${containerWidth}px`,
  ];
}
