import styled, { css } from 'styled-components';
import { mediaMaxPx } from '../../../../common/layout';
import { motion } from 'framer-motion';

export const breakPoint = 1278;

export const Padder = styled.div`
  padding: 0 32px;
  margin: 0 auto;

  ${mediaMaxPx(
    breakPoint,
    css`
      padding: 0 24px;
    `
  )}
`;
export const ProductSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  letter-spacing: normal;
`;

export const Headline_26_900 = styled.div`
  color: #fff;
  text-align: center;
  font-family: AzoSansBlack;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.5px;
  text-transform: uppercase;

  ${mediaMaxPx(
    breakPoint,
    css`
      font-size: 22px;
    `
  )}
`;

export const Subline = styled.div`
  color: #fff;
  text-align: center;
  font-family: AzoSans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  line-height: 170%; /* 27.2px */

  margin: 0 auto;
  margin-top: 8px;
  max-width: 742px;
  opacity: 0.8;

  ${mediaMaxPx(
    breakPoint,
    css`
      margin-top: 16px;
      font-size: 14px;
      line-height: 149%; /* 20.86px */
      text-shadow: 0px 0px 4px rgba(109, 182, 163, 0.6);
    `
  )}
`;

export const ProductOverview = styled.div`
  & > div {
    display: flex;
    gap: 32px;
    min-width: fit-content;
  }
  width: 100%;

  margin: 0 auto;
  margin-top: 24px;
  max-width: 1278px;
  scroll-snap-type: x mandatory;
  overflow-x: auto;

  ${mediaMaxPx(
    breakPoint,
    css`
      margin-top: 7px;
      & > div {
        gap: 24px;
      }
    `
  )}
`;

export const Content = styled.div`
  overflow: hidden;
  grid-area: center;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  outline: 1px solid rgba(0, 103, 107, 0.15);

  padding: 0px;

  z-index: 1;
`;

export const ProductImage = styled.div`
  width: 100%;
  aspect-ratio: 393 / 249;
  background-image: url('${props => props.src}'), linear-gradient(#fafcfc, #fafcfc 97%, #fff);
  background-color: #fff;
  background-size: cover;
  background-position: center;
`;

export const Bottom = styled.div`
  flex-grow: 1;

  display: grid;
  grid-template-areas: 'center';

  padding: 64px 32px;
  background: #fff;

  width: 100%;
  ${mediaMaxPx(
    breakPoint,
    css`
      padding: 48px 32px;
    `
  )}
`;

export const ProductData = styled.div`
  grid-area: center;

  transition: opacity 0.2s;
`;

export const Box = styled.div`
  scroll-snap-align: center;

  background: #fff;
  box-shadow: ${props =>
    props.$isHighlighted
      ? '0px 4px 9px 0px rgba(0, 0, 0, 0.05), 0px 8px 32px 0px rgba(0, 103, 107, 0.2), 0px 0px 4px 0px rgba(109, 182, 163, 0.6)'
      : '0px 4px 9px 0px rgba(0, 0, 0, 0.05), 0px 0px 4px 0px rgba(109, 182, 163, 0.6)'};

  transition: box-shadow 0.2s, transform 0.2s;

  flex-basis: 100%;
  flex-grow: 1;
  min-width: 282px;
  margin: 32px 0;

  cursor: pointer;

  display: grid;
  grid-template-areas: 'center';

  transform: translateY(${props => (props.$isHighlighted ? '-16px' : '0px')});
`;

export const ProductType = styled.div`
  color: #00676b;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6);
  font-family: AzoSans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  opacity: 0.8;
`;

export const ProductName = styled.div`
  color: #00676b;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6);
  font-family: AzoSansBlack;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;

  margin-top: 8px;

  ${mediaMaxPx(
    breakPoint,
    css`
      font-size: 14px;
    `
  )}
`;

export const CloseButtonContainer = styled.div`
  width: 48px;
  height: 48px;

  margin: 0 auto;
  margin-top: 24px;

  svg {
    display: block;
    cursor: pointer;
  }
`;

export const PaginationContainer = styled.div`
  position: relative;
  z-index: 1;

  margin: 0 auto;

  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  gap: 12px;

  svg {
    display: block;
  }
`;

export const TriangleWrapper = styled(motion.div)`
  cursor: pointer;

  svg {
    opacity: 0.66;
    transition: opacity 0.2s;
    path {
      transition: fill 0.2s;
      fill: #ffffff;
    }
  }

  ${props =>
    props.$active &&
    css`
      svg {
        cursor: auto;
        opacity: 1;
        path {
          fill: #00676b;
        }
      }
    `}
`;
