import IconSchletter from './assets/icon-schletter.svg';
import IconMap from './assets/icon-map.svg';
import IconBurger from './assets/icon-burger.svg';
import IconClose from './assets/icon-close.svg';
import { useDistrictStore } from '../../services/DistrictService';
import { useWindowStore } from '../../services/WindowService';
import { useNavigationStore } from '../../services/NavigationService';
import MenuButtons from './MenuButtons';
import MapOverlay from './MapOverlay';
import {
  ButtonsMobile,
  ImprintButtons,
  LocationIcon,
  LocationSelector,
  LocationText,
  Logo,
  LogoText,
  MobileMenuButton,
  NavigationBar,
  NavigationContainer,
  StyledAnchor,
} from './styles';
import { useDebugStore } from '../../storage/debug';
import DevContent from './DevContent';
import { useContentStore } from '../../services/ContentService';
import React, { useEffect, useState } from 'react';
import { useScrollStore } from '../../services/ScrollService';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useModalService } from '../../services/ModalService';
import { useOnboardingStore } from '../../services/OnboardingService';
import { useHowlerStore } from '../../services/HowlerService';

export default function SchletterUI() {
  const district = useDistrictStore(state => state.district);
  const isWeatherScene = district?.configuration?.customScene === 'weather';

  const isMobile = useWindowStore(state => state.isMobile);
  const showNavigationOverlay = useNavigationStore(state => state.showNavigationOverlay);
  const setShowNavigationOverlay = useNavigationStore(state => state.setShowNavigationOverlay);
  const focusContent = useContentStore(state => state.focusContent);
  const debugEnabled = useDebugStore.getState().getDeveloperContentEnabled();
  const [show, setShow] = useState(false);
  const playPrimaryClickUiSound = useHowlerStore(state => state.playPrimaryClickUiSound);
  const playHotspotSfx = useHowlerStore(state => state.playHotspotSfx);

  // WE MIGHT WANT TO SAVE THIS INTO A STORE, but where? (🤔)
  const [minimalUi, setMinimalUI] = useState(false);

  const history = useHistory();
  const setTransition = useScrollStore(state => state.setTransition);
  const setTransitionTarget = useScrollStore(state => state.setTransitionTarget);
  const hideUi = useDebugStore(state => state.getHideUi());
  const queryParams = qs.parse(window.location.search);

  const showProductInfoLayer = useNavigationStore(state => state.showProductInfoLayer);
  const content = useContentStore(state => state.activeContent);
  const fullscreenContentInFocus = content && (content.type.isFullscreen || content.contentType.isFullscreen);

  useEffect(() => {
    if (queryParams.minimal != null) {
      setMinimalUI(true);
      useModalService.getState().closeModal();
      useOnboardingStore.getState().finishOnboarding();
    }
  }, []);

  useEffect(() => {
    if (isWeatherScene && queryParams.minimal != null) {
      useOnboardingStore.getState().setNeedsInactivity(true);
      useOnboardingStore.getState().startInactivityTimer();
    } else {
      useOnboardingStore.getState().setNeedsInactivity(false);
    }
  }, [isWeatherScene]);

  useEffect(() => {
    if (focusContent) {
      playPrimaryClickUiSound();
      const { setActiveContent } = useContentStore.getState();
      setActiveContent(focusContent.content);
      setShow(false);
    } else {
      setShow(true);
    }
  }, [focusContent]);

  const toggleNavigationOverlay = () => {
    setShowNavigationOverlay(!showNavigationOverlay);
  };

  const handleLogoNavigation = () => {
    if (minimalUi) return;
    if (district.room === 'house') {
      const progress = useScrollStore.getState().progress;
      if (progress > 0.05) {
        playHotspotSfx();
        setTransition('blend');
        setTransitionTarget(0.025);
      }
    } else {
      useDistrictStore.getState().navigateToDistrict(history, 'house');
    }
  };

  return (
    <>
      <NavigationContainer show={show} showOverlay={showNavigationOverlay} disabled={hideUi}>
        <NavigationBar>
          <Logo onClick={handleLogoNavigation} data-sound-action="primaryClick">
            <IconSchletter />
            <LogoText>
              HOUSE OF
              <br />
              SCHLETTER
            </LogoText>
          </Logo>
          {!isMobile && !minimalUi && (
            <>
              <LocationSelector
                onClick={toggleNavigationOverlay}
                show={!showProductInfoLayer}
                data-sound-action="click"
              >
                <LocationIcon>{!showNavigationOverlay ? <IconMap /> : <IconClose />}</LocationIcon>
                <LocationText>{!showNavigationOverlay ? 'explore all rooms' : 'close'}</LocationText>
              </LocationSelector>
              <MenuButtons />
            </>
          )}
          {isMobile && !minimalUi && (
            <>
              <MobileMenuButton
                onClick={toggleNavigationOverlay}
                show={!showProductInfoLayer && !fullscreenContentInFocus}
                data-sound-action="click"
              >
                {!showNavigationOverlay ? <IconBurger /> : <IconClose />}
              </MobileMenuButton>
            </>
          )}
        </NavigationBar>
      </NavigationContainer>
      <MapOverlay />
      {showNavigationOverlay && isMobile && (
        <ButtonsMobile>
          <MenuButtons />
        </ButtonsMobile>
      )}
      {showNavigationOverlay && (
        <ImprintButtons>
          <StyledAnchor
            style={{ paddingRight: '30px' }}
            href="https://www.schletter-group.com/legal-notice/"
            target="_blank"
          >
            {'IMPRINT'}
          </StyledAnchor>
          <StyledAnchor href="https://www.schletter-group.com/privacy-policy/" target="_blank">
            {'PRIVACY POLICY'}
          </StyledAnchor>
        </ImprintButtons>
      )}
      {debugEnabled && <DevContent />}
    </>
  );
}
