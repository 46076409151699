import { useUserStore } from 'services/UserService';

const useLoginStatus = () => {
  const loginState = useUserStore(state => state.loginState);
  const message = useUserStore(state => state.message);

  const uiMessage = loginState === 'LOGGED_OUT' && message ? message.copy : '\u00a0';
  return { isPending: loginState === 'PENDING', message: { copy: uiMessage } };
};

const disableForm = (form, disabled) => {
  const disable_elements = elements => {
    let length = elements.length;
    while (length--) {
      elements[length].disabled = disabled;
    }
  };

  const inputs = form.getElementsByTagName('input');
  const buttons = form.getElementsByTagName('button');

  disable_elements(inputs);
  disable_elements(buttons);
};

export { useLoginStatus, disableForm };
