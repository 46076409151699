import create from 'utilities/zustand/create';

export const usePostStore = create(set => ({
  isPaused: false,
  renderConfiguration: {
    pixelRatio: 1.0,
    maxFps: 120,
    maxPixelArea: 4000,
    shadow: {
      enabled: true,
      size: 8192,
      bias: 0.06,
    },
    water: {
      reflections: true,
      size: 256,
    },

    // post
    postEnabled: true,
    postMultisampling: 4,
    smaa: {
      enabled: true,
    },
    bloom: {
      enabled: true,
      intensity: 0.5,
      radius: 0.4,
      luminanceThreshold: 0.9,
      luminanceSmoothing: 0.1,
    },
    vignette: {
      enabled: true,
      offset: 0.5,
      darkness: 0.3,
    },
  },
  set: renderConfiguration => {
    set({ renderConfiguration: JSON.parse(JSON.stringify(renderConfiguration)) });
  },
}));
