import { LoopOnce, LoopRepeat } from 'three';
import * as PARTICLE_FX from 'components/Play/ParticleFX/ParticleFXConstants';

const config = [
  {
    id: 0,
    name: 'Idle',
  },
  {
    id: 1,
    name: 'GestureGreeting',
    label: 'greet',
    animation: {
      glbAnimationIndex: 6,
      loop: LoopOnce,
    },
    // particleFx: {
    //   particleSheetIndex: 7,
    //   animationType: PARTICLE_FX.ANIMATION_PRESET_EMOJI,
    // },
    button: {
      color: '#FF00FF',
      //color: baseColors.yellow,
      emoji: '👋',
    },
  },
  {
    id: 2,
    name: 'GestureWaving',
    label: 'wave',
    animation: {
      glbAnimationIndex: 7,
      loop: LoopOnce,
    },
    // particleFx: {
    //   particleSheetIndex: 6,
    //   animationType: PARTICLE_FX.ANIMATION_PRESET_EMOJI,
    // },
    button: {
      color: '#FF00FF',
      //color: baseColors.red,
      emoji: '👐',
    },
  },
  {
    id: 3,
    name: 'GesturePointing',
    label: 'nice',
    animation: {
      glbAnimationIndex: 10,
      loop: LoopOnce,
    },
    // particleFx: {
    //   particleSheetIndex: 5,
    //   animationType: PARTICLE_FX.ANIMATION_PRESET_EMOJI,
    // },

    button: {
      color: '#FF00FF',
      //color: baseColors.brown.veryDark,
      emoji: '👍',
    },
  },

  {
    id: 4,
    name: 'GestureClapping',
    label: 'applause',
    animation: {
      glbAnimationIndex: 5,
      loop: LoopOnce,
    },
    // particleFx: {
    //   particleSheetIndex: 8,
    //   animationType: PARTICLE_FX.ANIMATION_PRESET_EMOJI,
    // },
    button: {
      color: '#FF00FF',
      //color: baseColors.green,
      emoji: '👏',
    },
  },
  {
    id: 5,
    name: 'GestureCheering',
    label: 'cheer',
    animation: {
      glbAnimationIndex: 9,
      loop: LoopOnce,
    },
    // particleFx: {
    //   particleSheetIndex: 0,
    //   animationType: PARTICLE_FX.ANIMATION_PRESET_EMOJI,
    // },
    button: {
      color: '#FF00FF',
      //color: baseColors.magenta.medium,
      emoji: '🙌',
    },
  },
  {
    id: 6,
    name: 'GestureDanceTwist',
    label: 'dance',
    animation: {
      glbAnimationIndex: 8,
      loop: LoopRepeat,
      repetitions: 2,
    },
    particleFx: {
      particleSheetIndex: 3,
      animationType: PARTICLE_FX.ANIMATION_PRESET_SPARKLE,
    },
    button: {
      color: '#FF00FF',
      //color: baseColors.grey.medium,
      emoji: '🕺',
    },
  },
  {
    id: 10,
    name: 'Teleport',
    animation: {
      glbAnimationIndex: 6,
      loop: LoopOnce,
    },
    // particleFx: {
    //   particleSheetIndex: 9,
    //   animationType: PARTICLE_FX.ANIMATION_PRESET_TELEPORT,
    // },
  },
];
export default config;
