import { ChatUnseenNotification, ImageContainer, StarBadge } from './styles';
import PersonImagePlaceholder from './assets/personImagePlaceholder.png';
import Star from './assets/star.svg';

export default function PersonImage({ src, size, isExpert, chatNotice }) {
  if (!src) src = PersonImagePlaceholder;
  return (
    <ImageContainer size={size} src={src}>
      {isExpert && (
        <StarBadge size={size}>
          <Star />
        </StarBadge>
      )}
      {chatNotice && <ChatUnseenNotification />}
    </ImageContainer>
  );
}
