import styled, { css } from 'styled-components';
import { colors } from 'uiLivi/styles';
import { mediaMax, styledScrollbar } from 'common/layout';
import Bg from './assests/backgroundImage.png';
import { Button } from 'common/components/Button';

export const Container = styled.div``;

const centeredAndMaxWidth = css`
  max-width: 1400px;
  margin: 0 auto 0 auto;
`;

export const BackgroundContainer = styled.div`
  background-image: url(${Bg});
  background-size: cover;
  background-position: 20% 100%;
  position: relative;
`;

export const CenterContainer = styled.div`
  position: relative;
  ${centeredAndMaxWidth};
`;

export const LogoContainer = styled.div`
  position: absolute;
  left: 5%;
  height: 10%;
  width: 10%;
`;

export const TimetableContainer = styled.div`
  padding: 0 5%;
  ${centeredAndMaxWidth};
  color: ${colors.font.light};
  ${mediaMax(
    'small',
    css`
      padding: 0;
    `
  )}
`;

export const ScrollContainer = styled.div`
  position: relative;
  max-height: 100%;
  overflow-y: auto;
  ${styledScrollbar};
  background-color: black;
`;

export const BackButton = styled(Button)`
  margin: 10vh auto;
`;
