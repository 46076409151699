import create from 'utilities/zustand/create';
import { useDistrictStore } from 'services/DistrictService';
import { Euler, Quaternion, Raycaster, Vector3 } from 'three';

const zoneRaycaster = new Raycaster();

export const useZoneStore = create((set, get) => ({
  zones: [],
  getSpawn: (zoneId = null) => {
    const { zones } = get();
    const defaultZone = zones.find(a => a.default);
    if (zones && zones.length > 0 && defaultZone) {
      const zone = zones.find(a => a.id == (zoneId || defaultZone.id));
      const len = zone.spawnPoints.length;
      const spawnInfo = zone.spawnPoints[Math.floor(Math.random() * len)];
      return spawnInfo;
    } else {
      return null;
    }
  },
  initZones: zoneGroup => {
    const district = useDistrictStore.getState().district;
    const zones = district.zones.map(a => {
      const zoneMesh = zoneGroup.children.find(b => b.name === a.id + '_zone');
      zoneMesh.updateMatrixWorld();
      const spawnPoints = zoneMesh.children.map(c => {
        const worldPos = new Vector3();
        c.getWorldPosition(worldPos);
        const worldQuat = new Quaternion();
        c.getWorldQuaternion(worldQuat);
        const worldEuler = new Euler();
        worldEuler.setFromQuaternion(worldQuat);
        const spawnInfo = { position: [worldPos.x, worldPos.y, worldPos.z], rotation: worldEuler.y };
        return spawnInfo;
      });
      return { ...a, spawnPoints: spawnPoints };
    });
    set({ zones: zones });
  },
  getZone: (position, zoneGroup) => {
    const district = useDistrictStore.getState().district;
    if (district.room === 'auditorium') {
      if (position[2] > 30 && Math.abs(position[0]) < 0.5) {
        // TODO Figure out why raycasting zones in auditorium GLB doesn't work
        return 'portal_lobby';
      }
    }
    if (!zoneGroup) {
      return null;
    }
    zoneRaycaster.set(
      new Vector3(position[0], position[1], position[2]).add(new Vector3(0, 1, 0)),
      new Vector3(0, -1, 0)
    );
    const results = zoneRaycaster.intersectObject(zoneGroup, true);
    if (results.length > 0) {
      return results[0].object.name.replace('_zone', '');
    } else {
      return null;
    }
  },
}));
