import React, { useEffect, useMemo } from 'react';
import { DescriptionCopy, Headline, LeftContainer, RightContainer, SpeakerDescription, TopContainer } from './styles';
import { useTrackingStore } from 'services/TrackingService';
import TimetablePerson from '../TimetablePerson';
import LiveIndicator from 'common/components/LiveIndicator';
import BookmarkButton from 'common/components/Timetable/common/BookmarkButton';
import DetailContainer from '../DetailContainer';
import DateAndTime from 'common/components/Timetable/common/DateAndTime';
import { useUserStore } from 'services/UserService';
import { useTranslate } from 'services/TranslationService/hooks';
import moment from 'moment';

export default function TalkDetail({ talk, setActiveTalkId }) {
  const translate = useTranslate();
  useEffect(() => {
    useTrackingStore.getState().trackEvent({ category: 'Talk', action: 'ViewDetails', label: talk.title });
  }, [talk.title]);
  const startDate = useMemo(() => new Date(talk.startTime), [talk.startTime]);
  const endDate = useMemo(() => new Date(startDate.getTime() + talk.duration * 60000), [startDate, talk.duration]);
  const isLive = useMemo(() => moment().isAfter(moment(startDate)) && moment().isBefore(moment(endDate)), [
    endDate,
    startDate,
  ]);
  const speaker = talk.speaker;

  return (
    <DetailContainer
      closeHandler={() => {
        setActiveTalkId(null);
      }}
    >
      <TopContainer>
        <LeftContainer>
          {isLive && <LiveIndicator style={{ marginRight: '12px' }} />}
          <BookmarkButton small active={talk.isBookmarked} talkId={talk.id} />
        </LeftContainer>
        <RightContainer>
          <DateAndTime startDate={startDate} endDate={endDate} align="right" />
        </RightContainer>
      </TopContainer>
      <Headline>{talk.title}</Headline>
      <DescriptionCopy>{talk.description}</DescriptionCopy>
      {speaker && (
        <>
          <TimetablePerson
            onClick={() => {
              speaker && useUserStore.getState().selectUserById(speaker.id);
            }}
            role={translate('schedule.talks.speaker') || 'Speaker'}
            name={speaker.forename + ' ' + speaker.surname}
            company={speaker.company}
            image={speaker.profilePicture}
          />
          <SpeakerDescription>{speaker.description}</SpeakerDescription>
        </>
      )}
    </DetailContainer>
  );
}
