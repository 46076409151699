import IconLive from 'assets/icons/Icon_Live.svg';
import styled from 'styled-components';
import { lgBaseColors } from 'uiLivi/styles';
import { smallTextStyle } from 'common/text';
import { useTranslate } from 'services/TranslationService/hooks';
import { iconFillAndStroke } from 'common/icon';

const Container = styled.div`
  display: inline-block;
  padding: 2px 8px;
  background-color: white;
  color: ${lgBaseColors.red.base};
  ${smallTextStyle};
  text-transform: uppercase;
  font-weight: bold;
  svg {
    height: 9px;
    position: relative;
    top: 1px;
    margin-left: 4px;
    ${iconFillAndStroke(lgBaseColors.red.base, lgBaseColors.red.base)}
  }
`;

export default function LiveIndicator({ style }) {
  const translate = useTranslate();
  return (
    <Container style={style}>
      {translate('live.badge') || 'Live'}
      <IconLive />
    </Container>
  );
}
