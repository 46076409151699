import React, { useEffect } from 'react';
import { Bubble, ChatBubbleContainer, ChatBubbleVisibilityToggle, Sender, StyledMessageTime } from './styles';
import { useUserStore } from 'services/UserService';
import { useChatStore } from 'services/ChatService';
import textLink from 'utilities/textLink';
import format, { FORMAT_DATE_SIMPLE, FORMAT_TIME, isToday } from 'utilities/date/format';
import moment from 'moment-timezone';
import { useTranslate } from 'services/TranslationService/hooks';

const ChatBubble = ({ message }) => {
  const translate = useTranslate();
  const { user: userId, text, isHidden, id, created_at: date } = message;
  const user = useUserStore(state => state.users.find(u => u.id === userId));
  const isContentChat = useChatStore(state => state.chatType === 'content');
  const isExpert = useUserStore(state => state.user && state.user.role.type === 'expert');
  const isSupporter = useUserStore(state => state.user && state.user.role.type === 'supporter');
  const isAdmin = useUserStore(state => state.user && state.user.role.type === 'admin');
  const isSelf = userId === useUserStore.getState().user.id;
  const forename = user ? user.forename : translate('chat.sender.default') || 'Unknown';
  const surname = user ? user.surname : '';

  const showHideOrShowButton = message.id && (isExpert || isSupporter || isAdmin);

  useEffect(() => {
    if (!user) {
      useUserStore.getState().requestUser(userId);
    }
  });

  const onShowOrHideMessage = () => {
    if (message.isHidden) useChatStore.getState().showMessage(id);
    else useChatStore.getState().hideMessage(id);
  };

  const timeIndicatorFormat = !isToday(date) ? FORMAT_DATE_SIMPLE : FORMAT_TIME;
  const messageTime = format(moment(date).local(), timeIndicatorFormat);

  return (
    <ChatBubbleContainer floatRight={isSelf}>
      <Bubble
        isSelf={isSelf}
        isContentChat={isContentChat}
        onClick={() => {
          if (userId) {
            useUserStore.getState().selectUserById(userId);
          }
        }}
        interactive={userId}
        hide={showHideOrShowButton && isHidden}
      >
        <Sender isSelf={isSelf}>
          {forename} {surname}
        </Sender>
        <div>{textLink(text)}</div>
        <StyledMessageTime>{messageTime}</StyledMessageTime>
      </Bubble>
      {showHideOrShowButton ? (
        <ChatBubbleVisibilityToggle onClick={onShowOrHideMessage} isSelf={isSelf}>
          {message.isHidden ? 'SHOW' : 'HIDE'}
        </ChatBubbleVisibilityToggle>
      ) : null}
    </ChatBubbleContainer>
  );
};

export default ChatBubble;
