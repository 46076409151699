import { useEnvironmentStore } from 'components/Play/Environment/store';

// CLOUDS CONFIGURATION HAS TO BE THE SAME AS THE CONFIG BELOW
const state = useEnvironmentStore.getState().presets.schletterTestcenter;
export default {
  // WIND WEATHER SIMULATION
  weather: {
    clouds: [
      {
        id: 'clouds_stage0',
        specs: [
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/0C1_1.png',
            sizeRange: [300, 350],
            speedRange: [0.3, 0.8],
            opacityRange: [0.8, 1],
            amount: 5,
            spreadY: [60, 90],
            spreadZ: [-250, -300],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/0C1_3.png',
            sizeRange: [200, 340],
            speedRange: [0.3, 0.8],
            opacityRange: [0.8, 1],
            amount: 6,
            spreadY: [30, 180],
            spreadZ: [-350, -450],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/0C1_4.png',
            sizeRange: [200, 340],
            speedRange: [0.3, 0.8],
            opacityRange: [0.8, 1],
            amount: 2,
            spreadY: [3, 180],
            spreadZ: [-450, -550],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/1C0_2.png',
            sizeRange: [250, 340],
            speedRange: [0.3, 0.8],
            opacityRange: [0.8, 1],
            amount: 6,
            spreadY: [45, 180],
            spreadZ: [-250, -450],
          },
        ],
      },
      {
        id: 'clouds_stage1',
        specs: [
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/1C0_0.png',
            sizeRange: [200, 340],
            speedRange: [0.3, 0.8],
            opacityRange: [0.8, 1],
            amount: 10,
            spreadY: [50, 180],
            spreadZ: [-250, -450],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/1C0_2.png',
            sizeRange: [200, 340],
            speedRange: [0.3, 0.8],
            opacityRange: [0.8, 1],
            amount: 8,
            spreadY: [45, 180],
            spreadZ: [-250, -450],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/1C1_0.png',
            sizeRange: [200, 240],
            speedRange: [0.6, 1.4],
            opacityRange: [0.8, 1],
            amount: 14,
            spreadY: [35, 180],
            spreadZ: [-350, -450],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/1C2_1.png',
            sizeRange: [140, 240],
            speedRange: [0.6, 1.4],
            opacityRange: [0.8, 1],
            amount: 14,
            spreadY: [35, 180],
            spreadZ: [-450, -650],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/1C2_4.png',
            sizeRange: [140, 240],
            speedRange: [0.6, 1.4],
            opacityRange: [0.8, 1],
            amount: 14,
            spreadY: [35, 180],
            spreadZ: [-450, -650],
          },
        ],
      },
      {
        id: 'clouds_stage2',
        specs: [
          // foreground
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/2C0_5.png',
            sizeRange: [120, 240],
            speedRange: [0.8, 1.5],
            opacityRange: [0.8, 1],
            amount: 10,
            spreadY: [35, 180],
            spreadZ: [-250, -450],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/2C0_2.png',
            sizeRange: [120, 340],
            speedRange: [0.8, 1.6],
            opacityRange: [0.8, 1],
            amount: 5,
            spreadY: [35, 180],
            spreadZ: [-350, -450],
          },
          // middleground
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/2C0_6.png',
            sizeRange: [150, 230],
            speedRange: [0.8, 1.5],
            opacityRange: [0.9, 1],
            amount: 10,
            spreadY: [40, 180],
            spreadZ: [-450, -650],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/2C0_7.png',
            sizeRange: [150, 230],
            speedRange: [0.8, 1.5],
            opacityRange: [0.9, 1],
            amount: 10,
            spreadY: [35, 180],
            spreadZ: [-450, -450],
          },
          // background
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/2C1_0.png',
            sizeRange: [300, 340],
            speedRange: [0.8, 1.5],
            opacityRange: [0.8, 1],
            amount: 8,
            spreadY: [25, 150],
            spreadZ: [-550, -650],
          },
          // spreading this cloud from fore- to background:
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/2C1_1.png',
            sizeRange: [120, 200],
            speedRange: [0.8, 1.5],
            opacityRange: [1, 1],
            amount: 7,
            spreadY: [45, 150],
            spreadZ: [-250, -650],
          },
        ],
      },
      {
        id: 'clouds_stage3',
        specs: [
          // foreground
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/3C0_0.png',
            sizeRange: [120, 190],
            speedRange: [1, 1.6],
            opacityRange: [0.8, 1],
            amount: 6,
            spreadY: [20, 180],
            spreadZ: [-200, -300],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/3C0_1.png',
            sizeRange: [120, 340],
            speedRange: [1, 1.6],
            opacityRange: [0.8, 1],
            amount: 6,
            spreadY: [40, 180],
            spreadZ: [-200, -300],
          },
          // middleground
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/3C1_2.png',
            sizeRange: [120, 190],
            speedRange: [1, 1.6],
            opacityRange: [0.8, 1],
            amount: 12,
            spreadY: [20, 180],
            spreadZ: [-350, -400],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/3C1_3.png',
            sizeRange: [120, 340],
            speedRange: [1, 1.6],
            opacityRange: [0.8, 1],
            amount: 12,
            spreadY: [40, 180],
            spreadZ: [-350, -400],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/3C1_6.png',
            sizeRange: [120, 340],
            speedRange: [1, 1.6],
            opacityRange: [0.8, 1],
            amount: 12,
            spreadY: [40, 180],
            spreadZ: [-350, -400],
          },
          // background
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/3C2_0.png',
            sizeRange: [200, 340],
            speedRange: [1, 1.6],
            opacityRange: [0.8, 1],
            amount: 5,
            spreadY: [40, 180],
            spreadZ: [-400, -450],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/3C2_1.png',
            sizeRange: [200, 340],
            speedRange: [1, 1.6],
            opacityRange: [0.8, 1],
            amount: 5,
            spreadY: [40, 180],
            spreadZ: [-400, -450],
          },
        ],
      },
      {
        id: 'clouds_stage4',
        specs: [
          // foreground
          // middleground
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/4C1_0.png',
            sizeRange: [160, 340],
            speedRange: [1.2, 1.9],
            opacityRange: [0.8, 1],
            amount: 16,
            spreadY: [25, 180],
            spreadZ: [-250, -400],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/4C1_1.png',
            sizeRange: [160, 190],
            speedRange: [1.2, 1.9],
            opacityRange: [0.8, 1],
            amount: 16,
            spreadY: [25, 180],
            spreadZ: [-250, -400],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/4C1_3.png',
            sizeRange: [160, 340],
            speedRange: [1.2, 1.9],
            opacityRange: [0.8, 1],
            amount: 16,
            spreadY: [30, 180],
            spreadZ: [-350, -400],
          },

          // background
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/4C2_0.png',
            sizeRange: [300, 350],
            speedRange: [1.2, 1.9],
            opacityRange: [0.8, 1],
            amount: 3,
            spreadY: [25, 180],
            spreadZ: [-400, -450],
          },
          {
            asset: '/shared/districts/intersolar/weather/dom/clouds/4C2_1.png',
            sizeRange: [300, 350],
            speedRange: [1.2, 1.9],
            opacityRange: [0.8, 1],
            amount: 4,
            spreadY: [25, 180],
            spreadZ: [-400, -450],
          },
        ],
      },
    ],
    stages: [
      {
        position: 0,
        sun: { name: 'sun', opacity: 1 },
        fog: { name: 'fog', opacity: 0.1, color: '#f9eee1' },
        sky: { bottom: '#f9eee1', top: '#93B5DA' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.2, opacity: 1 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.35, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.7, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 0.7, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 0.7, opacity: 0 },

        directionalLight: { intensity: state.dirLightWithShadow.intensity },
        lightmap: { intensity: state.lightmap.intensity },
        envMap: { intensity: state.envMap.intensity, intensityLightMaps: state.envMap.intensityLightMaps },

        vignette: { offset: 0.0, darkness: 0.0 },
      },
      {
        position: 0.08,
        sun: { name: 'sun', opacity: 1 },
        fog: { name: 'fog', opacity: 0.1, color: '#f9eee1' },
        sky: { bottom: '#f9eee1', top: '#93B5DA' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.2, opacity: 1 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.35, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.7, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 0.7, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 0.7, opacity: 0 },

        directionalLight: { intensity: 0.35 },
        lightmap: { intensity: 0.3 },
        envMap: { intensity: 0.8, intensityLightMaps: 0.08 },

        vignette: { offset: 0.0, darkness: 0.0 },
      },
      {
        position: 0.15,
        sun: { name: 'sun', opacity: 0.1 },
        fog: { name: 'fog', opacity: 0.2, color: '#f1dac0' },
        sky: { bottom: '#bac0cd', top: '#435a80' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.25, opacity: 0 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.6, opacity: 1 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.7, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 0.012, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 0.012, opacity: 0 },

        directionalLight: { intensity: 0.15 },
        lightmap: { intensity: 0.3 },
        envMap: { intensity: 0.7, intensityLightMaps: 0.07 },

        vignette: { offset: 0.0, darkness: 0.0 },
      },
      {
        position: 0.35,
        sun: { name: 'sun', opacity: 0.0 },
        fog: { name: 'fog', opacity: 0.68, color: '#4c5e6a' },
        sky: { bottom: '#4c5e6a', top: '#202837' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.5, opacity: 0 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.7, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.9, opacity: 1 },
        clouds_stage3: { name: 'clouds_stage3', speed: 1.2, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 0.012, opacity: 0 },

        directionalLight: { intensity: 0.1 },
        lightmap: { intensity: 0.25 },
        envMap: { intensity: 0.4, intensityLightMaps: 0.04 },

        vignette: { offset: 0.0, darkness: 0.0 },
      },

      {
        position: 0.7,
        sun: { name: 'sun', opacity: 0 },
        fog: { name: 'fog', opacity: 0.75, color: '#425159' },
        sky: { bottom: '#425159', top: '#181f2a' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.05, opacity: 0.0 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.7, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 1.2, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 1.5, opacity: 1 },
        clouds_stage4: { name: 'clouds_stage4', speed: 1.7, opacity: 0 },

        directionalLight: { intensity: 0.1 },
        lightmap: { intensity: 0.25 },
        envMap: { intensity: 0.4, intensityLightMaps: 0.04 },

        vignette: { offset: 0.1, darkness: 0.4 },
      },

      {
        position: 1,
        sun: { name: 'sun', opacity: 0 },
        fog: { name: 'fog', opacity: 0.85, color: '#333d43' },
        sky: { bottom: '#333d43', top: '#161b24' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.05, opacity: 0.0 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.7, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.012, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 1.7, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 1.9, opacity: 1 },

        directionalLight: { intensity: 0.0 },
        lightmap: { intensity: 0.2 },
        envMap: { intensity: 0.1, intensityLightMaps: 0.01 },

        vignette: { offset: 0.18, darkness: 0.7 },
      },
    ],
  },

  // SUN TRACKER SIMULATION
  sunTracker: {
    stages: [
      {
        position: 0,
        sun: { name: 'sun', opacity: 0 },
        fog: { name: 'fog', opacity: 0.3, color: '#ee6b40' },
        sky: { bottom: '#af7638', top: '#4776bf' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.2, opacity: 1 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.35, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.7, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 0.7, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 0.7, opacity: 0 },

        directionalLight: { intensity: 0 },
        lightmap: { intensity: 0.2 },
        envMap: { intensity: 0.3, intensityLightMaps: 0.08 },

        vignette: { offset: 0.0, darkness: 0.1 },
      },
      {
        position: 0.2,
        sun: { name: 'sun', opacity: 0 },
        fog: { name: 'fog', opacity: 0.1, color: '#f9eee1' },
        sky: { bottom: '#ebb4a2', top: '#93B5DA' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.25, opacity: 1 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.6, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.7, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 0.012, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 0.012, opacity: 0 },

        directionalLight: { intensity: 0 },
        lightmap: { intensity: 0.3 },
        envMap: { intensity: 0.8, intensityLightMaps: 0.08 },

        vignette: { offset: 0.0, darkness: 0.0 },
      },
      {
        position: 0.5,
        sun: { name: 'sun', opacity: 0.0 },
        fog: { name: 'fog', opacity: 0.1, color: '#f9eee1' },
        sky: { bottom: '#93B5DA', top: '#AFD5FF' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.3, opacity: 1 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.7, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.9, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 1.2, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 0.012, opacity: 0 },

        directionalLight: { intensity: 0 },
        lightmap: { intensity: 0.4 },
        envMap: { intensity: 0.9, intensityLightMaps: 0.08 },

        vignette: { offset: 0.0, darkness: 0.0 },
      },

      {
        position: 0.8,
        sun: { name: 'sun', opacity: 0 },
        fog: { name: 'fog', opacity: 0.1, color: '#f9eee1' },
        sky: { bottom: '#ebb4a2', top: '#93B5DA' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.3, opacity: 1 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.7, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 1.2, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 1.5, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 1.7, opacity: 0 },

        directionalLight: { intensity: 0 },
        lightmap: { intensity: 0.3 },
        envMap: { intensity: 0.8, intensityLightMaps: 0.08 },

        vignette: { offset: 0.0, darkness: 0.0 },
      },

      {
        position: 1,
        sun: { name: 'sun', opacity: 0 },
        fog: { name: 'fog', opacity: 0.3, color: '#ee6b40' },
        sky: { bottom: '#af7638', top: '#4776bf' },

        clouds_stage0: { name: 'clouds_stage0', speed: 0.2, opacity: 1 },
        clouds_stage1: { name: 'clouds_stage1', speed: 0.7, opacity: 0 },
        clouds_stage2: { name: 'clouds_stage2', speed: 0.012, opacity: 0 },
        clouds_stage3: { name: 'clouds_stage3', speed: 1.7, opacity: 0 },
        clouds_stage4: { name: 'clouds_stage4', speed: 1.9, opacity: 0 },

        directionalLight: { intensity: 0 },
        lightmap: { intensity: 0.2 },
        envMap: { intensity: 0.3, intensityLightMaps: 0.08 },

        vignette: { offset: 0.0, darkness: 0.1 },
      },
    ],
  },
};
