import styled, { css, keyframes } from 'styled-components';
import { mediaMax } from '../../../common/layout';

import bg_desktop from './assets/KEYVISUAL_desktop.jpg';
import bg_mobile from './assets/KEYVISUAL_mobile.jpg';

const appear = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 24px;
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${bg_desktop}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: ${appear} 0.4s ease-out forwards;

  ${mediaMax(
    'medium',
    css`
      background-image: url('${bg_mobile}');
    `
  )};
`;

export const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 90px;
  font-family: 'AzoSans', serif;
  animation: ${appear} 0.4s ease-out forwards;
  background: linear-gradient(79.63deg, rgba(0, 0, 0, 0.5) 7.21%, rgba(0, 0, 0, 0) 85.55%);

  ${mediaMax(
    'medium',
    css`
      padding: 0 32px 60px 32px;
      align-items: flex-end;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    `
  )};
`;

export const Headline = styled.div`
  font-family: 'AzoSansBlack', serif;
  font-weight: 900;
  line-height: 90%;
  font-size: 80px;
  margin-bottom: 16px;

  ${mediaMax(
    'medium',
    css`
      font-size: 47px;
    `
  )};
`;

export const Copy = styled.div`
  font-family: 'AzoSansLight', serif;
  font-size: 16px;
  margin-bottom: 32px;
`;

export const Content = styled.div`
  max-width: 460px;
`;
