import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { useAnimations } from '@react-three/drei';
import { LoopOnce } from 'three';
import { useFrame } from '@react-three/fiber';
import { useConstructionAssemblyService } from '../../../../services/ConstructionAssemblyService';

export default function useConstructionSliderAnimation(selected, model, animations) {
  const { mixer, clips } = useAnimations(animations);

  const currentAction = useRef(null);
  const lastProgress = useRef(0);

  const actions = useMemo(() => {
    return clips.map(clip => {
      const action = mixer.clipAction(clip, model);
      action.clampWhenFinished = true;
      action.loop = LoopOnce;
      action.paused = true;
      return action;
    });
  }, [mixer, clips]);

  const setProgress = useCallback(
    progress => {
      const numSteps = actions.length - 1;
      let index = Math.floor(progress * numSteps) + 1;
      let relativeProgress = progress * numSteps - index + 1;

      if (index === actions.length && relativeProgress === 0) {
        index = actions.length - 1;
        relativeProgress = 1;
      }

      const prevAction = currentAction.current;
      if (prevAction && prevAction !== actions[index]) {
        prevAction.reset().setEffectiveWeight(0);
        currentAction.current.enabled = false;
      }
      actions[0].stop().setEffectiveWeight(0);
      actions[0].enabled = false;

      currentAction.current = actions[index];
      if (!currentAction.current) return;
      currentAction.current.paused = true;
      currentAction.current.play().setEffectiveWeight(1).enabled = true;
      currentAction.current.time = currentAction.current.getClip().duration * relativeProgress;
      // console.log(`apply time index=${index}/${actions.length - 1}, progress=${relativeProgress}`);
    },
    [actions]
  );

  useFrame((state, delta) => {
    if (!selected) return;

    const nextProgress = useConstructionAssemblyService.getState().progress;
    if (lastProgress.current !== nextProgress) {
      setProgress(nextProgress);
      lastProgress.current = nextProgress;
    }
  });

  useEffect(() => {
    if (!selected) return;
    setProgress(0);
  }, [selected]);
}
