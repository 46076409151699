import { useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import fromCdn from 'utilities/cdn';
import dracoLoader from 'utilities/dracoLoader';
import React, { useEffect, useState } from 'react';
import { clone } from 'three/examples/jsm/utils/SkeletonUtils';
import { AdditiveBlending, AnimationMixer, LoopOnce } from 'three';

export default function TeleportFX({ reactionId }) {
  const gltf = useLoader(GLTFLoader, fromCdn('/shared/products/Portal_in.glb'), dracoLoader);

  const [data, setData] = useState();

  useEffect(() => {
    const clonedScene = clone(gltf.scene);

    clonedScene.visible = reactionId === 10;
    clonedScene.traverse(o => {
      if (o.material) {
        if (o.material.name.includes('_add')) {
          o.material.blending = AdditiveBlending;
          o.material.transparent = true;
          o.receiveShadow = false;
          o.castShadow = false;
        }
      }
    });

    const animations = gltf.animations;

    const animationMixer = new AnimationMixer();
    const actions = animations.map(animation => {
      const action = animationMixer.clipAction(animation, clonedScene);
      action.setLoop(LoopOnce, 1);
      action.clampWhenFinished = true;
      action.time = 0;
      action.paused = true;
      action.play();

      return action;
    });

    animationMixer.addEventListener('finished', () => {
      clonedScene.visible = false;
    });

    setData({ actions, animationMixer, clonedScene });
  }, []);

  useEffect(() => {
    if (!data) return;
    const { actions, clonedScene } = data;

    if (reactionId === 10) {
      clonedScene.visible = true;
      actions.forEach(action => {
        action.time = 0;
        action.paused = false;
      });
    }
  }, [data, reactionId]);

  useFrame((c, delta) => {
    if (!data) return;
    const { animationMixer } = data;
    animationMixer.update(delta);
  });

  if (!data) return null;
  return <primitive name="Teleport" object={data.clonedScene} />;
}
