import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Euler, MathUtils, Quaternion } from 'three';
import { DEG2RAD } from 'three/src/math/MathUtils';
import { gsap } from 'gsap';

import { useWeatherStore } from '../store';

import { useCameraStore } from '../../../../services/CameraService';
import { useDeviceStore } from '../../../../services/DeviceService';
import { useNavigationStore } from '../../../../services/NavigationService';
import { useDistrictWarmupStore, WarmupPhase } from '../../../../services/DistrictWarmupService';
import { useOnboardingStore } from '../../../../services/OnboardingService';
import { useModalService } from '../../../../services/ModalService';

import { windNoiseJs } from '../helpers';

const CAMERA_POSITION = {
  desktop: [0, 2.74, 64],
  mobile: [0, 2.74, 65],
};

const CAMERA_FOV = {
  desktop: 35,
  mobile: 40,
};

// INITIAL ROTATION IS camAxisOffsetX / camAxisOffsetY, grab it from the console
const INITIAL_ROTATION = {
  desktop: [0.002, 0.002], // workaround to prevent black line. Can't solve it in other ways :(
  mobile: [0.0329, 0.00477],
};
const SHAKE_THRESHOLD = 0.7;

export default function WeatherCamera() {
  const { camera } = useThree();

  const isMobile = useDeviceStore(state => state.device.isMobile);
  const isMobileOnly = useDeviceStore(state => state.device.isMobileOnly);

  const cameraLimit = useMemo(() => {
    return { vertical: 2.5 * DEG2RAD, horizontal: 10 * DEG2RAD };
    // return { vertical: 10 * DEG2RAD, horizontal: 90 * DEG2RAD };
  }, []);
  const dragIntensity = useMemo(() => {
    return isMobile ? 0.001 : 0.0001;
  }, [isMobile]);

  const cameraFov = isMobileOnly ? CAMERA_FOV['mobile'] : CAMERA_FOV['desktop'];
  const cameraPosition = isMobileOnly ? CAMERA_POSITION['mobile'] : CAMERA_POSITION['desktop'];
  const initialRotation = isMobileOnly ? INITIAL_ROTATION['mobile'] : INITIAL_ROTATION['desktop'];

  const camAxisTargetX = useRef(initialRotation[0]);
  const camAxisTargetY = useRef(initialRotation[1]);
  const camAxisOffsetX = useRef(initialRotation[0]);
  const camAxisOffsetY = useRef(initialRotation[1]);

  const isAnimating = useRef(true);
  const dc = useRef();

  const euler = useRef(new Euler());
  const qResult = useRef(new Quaternion());

  const initial = useRef({ x: 0, y: 0 });
  const prevDirectionX = useRef('');
  const prevDirectionY = useRef('');
  const prevX = useRef(0);
  const prevY = useRef(0);
  const [isDragging, setIsDragging] = useState(false);
  const sliderActive = useWeatherStore(state => state.sliderActive);
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const navigationMapOpen = useNavigationStore(state => state.showNavigationOverlay);
  const modalOpen = useModalService(state => state.modals.length > 0);
  const overlayOpen = navigationMapOpen || modalOpen;

  const phase = useDistrictWarmupStore(state => state.phase);
  const isLoaded = phase === WarmupPhase.DONE;

  const createDc = (delay = 3) => {
    dc.current?.kill();
    isAnimating.current = true;
    dc.current = gsap.delayedCall(delay, () => {
      isAnimating.current = false;
    });
  };

  const onMove = useCallback(
    event => {
      if (sliderActive) return;
      createDc(3);
      const deltaX = (initial.current.x - event.clientX) * dragIntensity;
      const deltaY = (initial.current.y - event.clientY) * dragIntensity;
      let dirX = prevDirectionX.current;
      let dirY = prevDirectionY.current;
      if (event.clientX !== prevX.current) dirX = event.clientX < prevX.current ? 'left' : 'right';
      if (event.clientY !== prevY.current) dirY = event.clientY < prevY.current ? 'up' : 'down';
      if (dirX !== prevDirectionX.current) initial.current.x = event.clientX;
      if (dirY !== prevDirectionY.current) initial.current.y = event.clientY;
      prevDirectionX.current = dirX;
      prevDirectionY.current = dirY;
      prevX.current = event.clientX;
      prevY.current = event.clientY;
      camAxisTargetX.current = camAxisOffsetX.current + deltaX;
      camAxisTargetX.current = MathUtils.clamp(camAxisTargetX.current, -cameraLimit.horizontal, cameraLimit.horizontal);
      camAxisTargetY.current = camAxisOffsetY.current + deltaY;
      // camAxisTargetY.current = MathUtils.clamp(camAxisTargetY.current, -cameraLimit.vertical, cameraLimit.vertical);
      camAxisTargetY.current = MathUtils.clamp(camAxisTargetY.current, 0, 5 * DEG2RAD);

      // TRIGGER THE INACTIVITY THING
      useOnboardingStore.getState().startInactivityTimer();
    },
    [sliderActive]
  );

  useEffect(() => {
    if (isLoaded) {
      createDc(1);
    }
  }, [isLoaded]);

  useEffect(() => {
    window.addEventListener('pointerdown', onStartMove);
    window.addEventListener('pointerup', onEndMove);

    return () => {
      window.removeEventListener('pointerdown', onStartMove);
      window.removeEventListener('pointerup', onEndMove);
      dc.current?.kill();
    };
  }, []);

  const onStartMove = e => {
    setIsDragging(true);
    initial.current.x = e.clientX;
    initial.current.y = e.clientY;
  };

  const onEndMove = e => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging && !overlayOpen) {
      window.addEventListener('pointermove', onMove);
    } else {
      window.removeEventListener('pointermove', onMove);
    }
  }, [isDragging, navigationMapOpen]);

  useEffect(() => {
    useCameraStore.setState({ mode: 'free' });
    camera.position.fromArray(cameraPosition);
    camera.fov = cameraFov;
    camera.updateProjectionMatrix();
    camera.lookAt(0, 0, 0);
  }, []);

  useFrame((state, delta) => {
    if (
      !(
        smoothedValues.dampedSliderProgress > SHAKE_THRESHOLD ||
        isAnimating.current ||
        smoothedValues.scenesInterpolator > 0
      )
    )
      return;

    camera.position.x = cameraPosition[0];
    camera.position.y = cameraPosition[1];
    camera.position.z = cameraPosition[2];

    const intensity = MathUtils.smootherstep(smoothedValues.dampedSliderProgress, SHAKE_THRESHOLD, 1.0);
    const xShake = (windNoiseJs(state.clock.elapsedTime * 70) * 2.0 - 1.0) * 0.0006 * intensity;
    const yShake = (windNoiseJs(state.clock.elapsedTime * 30) * 2.0 - 1.0) * 0.0006 * intensity;

    // camera.position.x -= (smoothedValues.noisyIntensity * 2 - 1.0) * 0.1;
    camera.fov = cameraFov;
    camera.updateProjectionMatrix();
    camera.lookAt(0, 0, 0);

    camAxisOffsetX.current = MathUtils.lerp(camAxisOffsetX.current, camAxisTargetX.current, 0.07);
    camAxisOffsetY.current = MathUtils.lerp(camAxisOffsetY.current, camAxisTargetY.current, 0.07);
    camAxisOffsetX.current += xShake * (1.0 - smoothedValues.scenesInterpolator);
    camAxisOffsetY.current += yShake * (1.0 - smoothedValues.scenesInterpolator);

    euler.current.setFromQuaternion(camera.quaternion);
    euler.current.x = camAxisOffsetY.current;
    euler.current.y = camAxisOffsetX.current;
    qResult.current.setFromEuler(euler.current);
    camera.quaternion.copy(qResult.current);
  });

  return null;
}
