import styled, { css } from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { mediaMax, mediaMaxPx } from 'common/layout';
import { H3, smallTextStyle, textOverflowEllipsis } from 'common/text';

export const TeaserContainer = styled.div`
  padding: 40px 40px 60px 40px;
  background-color: black;
  color: white;
  width: 100%;
  display: flex;
  ${mediaMaxPx(
    900,
    css`
      flex-direction: column;
    `
  )}
`;

export const EventDescription = styled.div`
  display: table-cell;
  width: 50%;
  vertical-align: top;
  ${mediaMaxPx(
    900,
    css`
      width: 100%;
      border-left: none;
      border-top: 1px solid ${lgBaseColors.grey.dark};
      padding: 15px 0 15px 0;
      margin-top: 20px;
    `
  )};
  padding: 0 0 0 40px;
  border-left: 1px solid ${lgBaseColors.grey.dark};
`;

export const DescriptionTitle = styled(H3)`
  color: ${colors.accent.base};
  margin-bottom: 14px;
  margin-top: 0;
  width: 100%;
  overflow-wrap: break-word;
`;

export const DescriptionText = styled.div``;

export const EventInformation = styled.div`
  display: flex;
  width: 50%;
  align-items: flex-start;
  ${mediaMaxPx(
    500,
    css`
      flex-direction: column;
    `
  )};
  ${mediaMaxPx(
    900,
    css`
      width: 100%;
    `
  )};
`;

export const InformationDate = styled.div`
  ${mediaMaxPx(
    500,
    css`
      margin: 10px 0 0 0;
      width: 100%;
    `
  )};
  padding: 5px 0 0 0;
  width: 100%;
`;

export const Cell = styled.div`
  display: table-cell;
  width: 40%;
  margin-right: 20px;
  ${mediaMaxPx(
    500,
    css`
      width: 100%;
    `
  )};
`;

export const InformationSpeaker = styled.div`
  ${mediaMax(
    'small',
    css`
      width: 60%;
    `
  )};
  width: 100%;
`;

export const SpeakerPicture = styled.div`
  background-image: url(${props => props.url});
  background-size: contain;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 14px;
`;

export const SpeakerText = styled.div`
  ${smallTextStyle};
  color: ${props => (props.accent ? colors.accent.base : lgBaseColors.grey.medium)};
  font-weight: ${props => props.accent && 'bold'};
  margin: 1px 0 1px;
`;

export const Entry = styled.div``;

export const Bold = styled.span`
  overflow-wrap: break-word;
  font-weight: bold;
`;

export const Regular = styled.span`
  overflow-wrap: break-word;
`;

export const SpeakerName = styled.div`
  font-weight: bold;
  max-width: 150px;
  ${textOverflowEllipsis};
`;
