import create from 'utilities/zustand/create';

export const useNavigationStore = create((set, get) => ({
  showNavigationOverlay: false,
  setShowNavigationOverlay: value => {
    set({ showNavigationOverlay: value });
  },
  showProductInfoLayer: false,
  setShowProductInfoLayer: value => {
    set({ showProductInfoLayer: value });
  },
  productInfoID: null,
  setProductInfoID: id => {
    set({ productInfoID: id });
  },
}));
