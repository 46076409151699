import React, { useEffect, useRef } from 'react';
import { Container } from 'components/FullscreenContent/Content/LiveStream/styles';
import { configureVideo } from 'components/FullscreenContent/Content/LiveStream';
export default function Video({ url }) {
  const videoRef = useRef();

  useEffect(() => {
    const hls = configureVideo(videoRef.current, url);
    const video = videoRef.current;
    return () => {
      if (hls) {
        hls.detachMedia();
        hls.destroy();
      }
      video.pause();
    };
  }, [url, videoRef.current]);

  return (
    <Container>
      <video width="100%" height="100%" ref={videoRef} />
    </Container>
  );
}
