import { useAnimations, useGLTF } from '@react-three/drei';
import { useEffect, useMemo, useRef } from 'react';
import fromCdn from '../../../../../utilities/cdn';
import { useConstructionAssemblyService } from '../../../../../services/ConstructionAssemblyService';
import { LoopOnce, LoopPingPong } from 'three';
import { useFrame } from '@react-three/fiber';
import useConstructionSliderAnimation from '../useConstructionSliderAnimation';

export default function TrackingSystem() {
  const sceneRef = useRef();
  const selectedModelId = useConstructionAssemblyService(state => state.selectedModelId);
  const selected = selectedModelId === 'TrackingSystem';
  const setNumSteps = useConstructionAssemblyService(state => state.setNumSteps);
  const detailID = useConstructionAssemblyService(state => state.detailID);
  const showDetail = detailID !== null;
  const timeout = useRef(null);

  const { scene, animations } = useGLTF(
    fromCdn(`https://assets.schletter-group.com/bucket/shared/products/construction/karnobat_construction.glb`),
    true
  );

  useConstructionSliderAnimation(selected, scene, animations);

  const { mixer, clips } = useAnimations(animations);
  const actions = useMemo(() => {
    return clips.map(clip => {
      const action = mixer.clipAction(clip, scene);
      action.clampWhenFinished = true;
      action.loop = LoopOnce;
      return action;
    });
  }, [mixer, clips]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  useEffect(() => {
    clearTimeout(timeout.current);

    if (showDetail) {
      if (detailID !== 'TrackingSystem_Girders') return;

      actions[0].enabled = true;
      actions[0].play();
      actions[0].paused = true;
      actions[0].time = 5;
      actions[0].setEffectiveWeight(1);
      actions[0].timeScale = 1;
      actions[0].setLoop(LoopPingPong, Infinity);

      timeout.current = setTimeout(() => {
        actions[0].paused = false;
        actions[0].play();
      }, 500);
    } else {
      actions.forEach(action => {
        action.reset().setEffectiveWeight(0);
        action.enabled = false;
      });

      actions[0].setEffectiveWeight(1);
      actions[0].setLoop(LoopOnce, 0);
      actions[0].time = 5;
      actions[0].play();
      actions[0].paused = true;
      actions[0].enabled = true;
    }
  }, [showDetail, detailID]);

  useEffect(() => {
    if (selected) {
      actions[0].time = 5;
      setNumSteps(actions.length);
    }
  }, [selected]);

  return <primitive ref={sceneRef} object={scene} visible={selected} position={[-0.05, 0.68, 0]} />;
}
