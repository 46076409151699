import { Vector3 } from 'three';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import Ecke from './ecke.svg';
import {
  eckeStyle,
  LiveBadge,
  LiveIcon,
  PinContent,
  PinHeadline,
  PinSubline,
  PinWrapper,
  StyledPin,
  StyledPinIcon,
} from './styles';
import { zIndex } from 'uiLivi/styles';
import { PinContainerRef } from 'common/components/PinContainer';
import { useWindowStore } from 'services/WindowService';
import { useTranslate } from 'services/TranslationService/hooks';
import { Html } from '@react-three/drei';
import { useHowlerStore } from 'services/HowlerService';
import { useEventStore } from 'services/EventService';

const canvas = document.createElement('canvas');
const context = canvas.getContext('2d');

export default function Pin({ content, onClick, disabled }) {
  // as long as we dont have cool pins, we disable it when having avatars
  // so it's only enabled in orbit mode
  const { useAvatars } = useEventStore(state => state.event);
  if (useAvatars) return null;

  const html = useRef();
  const wasVisible = useRef(false);
  const wasOpen = useRef(false);
  const translate = useTranslate();
  const groupRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [isInVisibilityTransition, setInVisibilityTransition] = useState(false);
  const [isInOpenTransition, setInOpenTransition] = useState(false);
  const isMobile = useWindowStore(state => state.isMobile);
  const playClickUiSound = useHowlerStore(state => state.playClickUiSound);

  const { position, scale, textWidth } = useMemo(() => {
    const offset = content.pin.offset;
    const position = new Vector3(offset[0], offset[1], offset[2]);
    const scale = content.pin.scale;

    const headlineFont = 'bold 14px Arial';
    const sublineFont = '10px Arial';
    context.font = headlineFont;
    const { width: headlineWidth } = context.measureText(content.headline);
    context.font = sublineFont;
    const { width: sublineWidth } = context.measureText(content.subline);
    const textWidth = Math.max(headlineWidth, sublineWidth) + 10;

    return { position, scale, textWidth };
  }, [content]);

  useEffect(() => {
    if (html.current) {
      html.current.style.visibility = visible || isInVisibilityTransition ? 'visible' : 'hidden';
    }
  }, [visible, isInVisibilityTransition]);
  useFrame(({ camera }) => {
    if (!groupRef.current) {
      return;
    }
    const v = new Vector3();
    const c = new Vector3();
    groupRef.current.getWorldPosition(v);
    camera.getWorldPosition(c);
    const distance = c.distanceTo(v);
    const isNear = distance < 24;
    const isNearer = distance < 12;
    const shouldBeOpen = isNearer;
    const shouldBeVisible = isNear && !disabled;
    if (wasVisible.current !== shouldBeVisible) {
      wasVisible.current = shouldBeVisible;
      setInVisibilityTransition(true);
      setVisible(shouldBeVisible);
    }

    if (wasOpen.current !== shouldBeOpen) {
      wasOpen.current = shouldBeOpen;
      setInOpenTransition(true);
      setOpen(shouldBeOpen);
    }
  });

  const { pinIcon: PinIcon } = content.type;
  const fullWidth = 48 + textWidth;

  const onPinClick = e => {
    e.stopPropagation();
    if (visible) {
      playClickUiSound();
      onClick();
    }
  };

  return (
    <group ref={groupRef} position={position} visible={visible || isInVisibilityTransition}>
      <Html
        ref={html}
        distanceFactor={5}
        center
        zIndexRange={zIndex.pins}
        portal={PinContainerRef.current}
        style={{ pointerEvents: visible ? 'auto' : 'none', cursor: 'pointer' }}
        visible={visible || isInVisibilityTransition}
      >
        <PinWrapper
          scale={isMobile ? scale * 0.75 : scale}
          visible={visible}
          onMouseOver={() => {
            setHover(true);
          }}
          onMouseOut={() => {
            setHover(false);
          }}
        >
          <StyledPin
            hover={hover}
            data-track-category="Pin"
            data-track-action="Open"
            data-track-label={content.headline}
            onClick={onPinClick}
            onTransitionEnd={() => setInVisibilityTransition(false)}
          >
            <StyledPinIcon isOpen={open} fullWidth={fullWidth} hover={hover}>
              <PinIcon />
            </StyledPinIcon>
            <PinContent isOpen={open} onTransitionEnd={() => setInOpenTransition(false)} textWidth={textWidth}>
              {(open || isInOpenTransition) && (
                <>
                  <PinHeadline>{content.headline}</PinHeadline>
                  <PinSubline>{content.subline}</PinSubline>
                </>
              )}
            </PinContent>
            <Ecke style={eckeStyle} />
          </StyledPin>
          {content.talk && (
            <LiveBadge>
              <LiveIcon />
              {translate('live.badge') || 'Live'}
            </LiveBadge>
          )}
        </PinWrapper>
      </Html>
    </group>
  );
}
