import styled, { css } from 'styled-components';
import { iconFillAndStroke } from '../../icon';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { styledScrollbar } from 'common/layout';

export const CalendarIconContainer = styled.div`
  vertical-align: middle;
  margin: -5px 8px -5px 0;
  display: inline-block;
  max-width: 20px;

  svg {
    ${props =>
      props.open
        ? iconFillAndStroke(lgBaseColors.white, lgBaseColors.white)
        : iconFillAndStroke(lgBaseColors.grey.medium, lgBaseColors.grey.medium)};
  }
`;

export const DropdownContainer = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: relative;
`;

export const IconContainer = styled.div`
  position: absolute;
  width: 8px;
  height: 4px;
`;

export const DeleteIconContainer = styled(IconContainer)`
  top: 16px;
  right: 48px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const ArrowIconContainer = styled(IconContainer)`
  top: 12px;
  right: 20px;
`;

export const DropdownButton = styled.div`
  border: 1px solid ${props => (!props.open ? lgBaseColors.grey.medium : lgBaseColors.grey.medium)};
  padding: 16px 13px;
  cursor: pointer;
  background-color: ${props => (props.open ? colors.background.highlight : colors.background.highlight)};
  color: ${props => (props.open ? 'white' : props.greyedOut ? '#c8c8c8' : colors.font.strong)};
  ${IconContainer} {
    svg {
      ${props =>
        props.open
          ? iconFillAndStroke(lgBaseColors.white, lgBaseColors.white)
          : iconFillAndStroke(lgBaseColors.grey.medium, lgBaseColors.grey.medium)};
    }
  }
  ${ArrowIconContainer} {
    svg {
      transform: rotate(${props => (props.open ? 180 : 0)}deg);
    }
  }
`;

const scrollable = css`
  max-height: 235px;
  overflow-y: scroll;
  ${styledScrollbar}
`;

export const DropdownOptionList = styled.div`
  display: ${props => (props.open ? 'block' : 'none')};
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: auto;
  ${props => (props.numItems > 5 ? scrollable : null)};
`;
