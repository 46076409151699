import { useFrame, useLoader } from '@react-three/fiber';
import { ImageLoader, NormalBlending, Sprite, Texture } from 'three';
import InfoButtonTexture from './infoButton-texture.png';
import React, { useEffect, useRef, useState } from 'react';
import HUDSpriteMaterialNoScale from '../../../../utilities/canvas/HUDSpriteMaterialNoScale';
import { useScrollStore } from '../../../../services/ScrollService';
import { useWindowStore } from '../../../../services/WindowService';
import { gsap, Power2, Sine } from 'gsap';
import { useDeviceStore } from '../../../../services/DeviceService';
import { useNavigationStore } from '../../../../services/NavigationService';
import { useHowlerStore } from '../../../../services/HowlerService';

const getSprite = (srcTexture, isPhone) => {
  const canvas = document.createElement('canvas');
  canvas.width = 256;
  canvas.height = 256;

  const context = canvas.getContext('2d');
  context.drawImage(srcTexture, 0, 0, 256, 256);

  const texture = new Texture(canvas);
  texture.needsUpdate = true;

  const material = new HUDSpriteMaterialNoScale();
  material.map = texture;
  material.transparent = true;
  material.depthTest = false;
  material.depthWrite = false;
  material.blending = NormalBlending;
  // material.sizeAttenuation = false;

  const sprite = new Sprite(material);
  const scale = !isPhone ? 0.4 : 0.7;
  // const scale = !isPhone ? 0.1 : 0.1;
  sprite.renderOrder = 1;
  sprite.scale.set(scale, scale, scale);
  sprite.material.uniforms.opacity.value = 1;

  return sprite;
};

export default function InfoButton({ position, id, visibleRange }) {
  const group = useRef(null);
  const sprite = useRef(null);
  const [active, setActive] = useState(true);
  const [texture] = useLoader(ImageLoader, [InfoButtonTexture]);
  const setHover = useWindowStore(state => state.setHover);
  const isPhone = useDeviceStore(state => state.device.isMobileOnly);
  const playPrimaryClickUiSound = useHowlerStore(state => state.playPrimaryClickUiSound);

  const setShowProductInfoLayer = useNavigationStore(state => state.setShowProductInfoLayer);
  const showProductInfoLayer = useNavigationStore(state => state.showProductInfoLayer);
  const setProductInfoID = useNavigationStore(state => state.setProductInfoID);

  useEffect(() => {
    sprite.current = getSprite(texture, isPhone);
    if (group.current) {
      group.current.add(sprite.current);
    }

    return () => {
      if (group.current) {
        group.current.remove(sprite.current);
      }
    };
  }, []);

  useEffect(() => {
    gsap.killTweensOf(group.current);
    if (!active) {
      gsap.to(group.current.scale, {
        duration: 0.3,
        ease: Power2.easeIn,
        x: 0,
        y: 0,
        z: 0,
        onComplete: () => {
          if (!group.current) return;
          group.current.visible = false;
        },
      });
    } else {
      group.current.visible = true;
      gsap.to(group.current.scale, { duration: 0.4, ease: Sine.easeOut, x: 1, y: 1, z: 1 });
    }
  }, [active]);

  const toggleInfoLayer = () => {
    if (active) {
      setShowProductInfoLayer(true);
      setProductInfoID(id);
      playPrimaryClickUiSound();
    }
  };

  useEffect(() => {
    if (showProductInfoLayer) {
      gsap.to(sprite.current.material.uniforms.opacity, { duration: 0.2, easing: Power2.easeIn, value: 0 });
    } else {
      gsap.to(sprite.current.material.uniforms.opacity, { duration: 0.5, easing: Power2.easeOut, value: 1 });
    }
  }, [showProductInfoLayer]);

  useFrame(() => {
    const progress = useScrollStore.getState().normalizedProgress;

    if (progress >= visibleRange[0] && progress < visibleRange[1]) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return (
    <group name="HotspotLobby" ref={group} position={position}>
      <mesh
        onPointerOver={() => {
          if (showProductInfoLayer) return;
          setHover(true);
        }}
        onPointerOut={() => {
          setHover(false);
        }}
        onClick={toggleInfoLayer}
      >
        <sphereGeometry args={[!isPhone ? 0.2 : 0.4, 24, 24]} />
        <meshBasicMaterial color={'#cc0000'} opacity={0} transparent={true} depthTest={false} deptWrite={false} />
      </mesh>
    </group>
  );
}
