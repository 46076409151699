import { useEffect, useRef, useState } from 'react';
import {
  Models,
  productsData,
  useConstructionAssemblyService,
} from '../../../../../services/ConstructionAssemblyService';
import AssemblyStep from '../../AssemblyStep';
import { useWindowStore } from '../../../../../services/WindowService';

export default function BuildInstructions() {
  const requestRef = useRef(null);
  const [index, setIndex] = useState(-1);
  const buildInstructions = useConstructionAssemblyService(state => Models[state.selectedModelId]?.buildInstructions);
  const productData = useConstructionAssemblyService(state => productsData.find(p => p.id === state.selectedModelId));

  useEffect(() => {
    const update = t => {
      const { progress, numSteps } = useConstructionAssemblyService.getState();
      let stepIndex = Math.floor(progress * numSteps);
      if (stepIndex >= numSteps) stepIndex = numSteps - 1;
      if (stepIndex !== index) setIndex(stepIndex);
      requestRef.current = requestAnimationFrame(update);
    };
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, [index]);

  return (
    <AssemblyStep
      productData={productData}
      instruction={buildInstructions[index - 1] || buildInstructions[0]}
      show={index !== 0}
    />
  );
}
