import default_background from '../assets/loading_bg_default.jpg';
import styled from 'styled-components';

const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('${props => props.src}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity:  ${props => (props.isLoaded ? 0 : 1)};
  transition: opacity .3s ease-in;
  transition-delay: .2s;
`;

export default function LoaderBackgroundImage({ isLoaded, bgImage }) {
  const img = bgImage ? bgImage : default_background;
  return <BackgroundImage src={img} isLoaded={isLoaded} />;
}
