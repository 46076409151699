import { BackSide } from 'three';

import { usePlayerStore } from 'services/PlayerService';

export default function BackgroundEvents() {
  const playerActions = usePlayerStore(state => state.playerActions);
  return (
    <mesh
      name={'Background'}
      onClick={() => {
        playerActions.select(null);
      }}
      position={[0, 0, 0]}
      scale={[500, 500, 500]}
    >
      <boxGeometry attach="geometry" />
      <meshStandardMaterial attach="material" color={'hotpink'} side={BackSide} visible={false} />
    </mesh>
  );
}
