import styled from 'styled-components';
import { H2 } from 'common/text';

export const DescriptionCopy = styled.p`
  white-space: pre-line;
`;

export const SpeakerDescription = styled.p`
  white-space: pre-line;
  max-width: 270px;
  margin-top: 20px;
`;

export const TopContainer = styled.div`
  margin-bottom: 28px;
  display: flex;
`;

export const LeftContainer = styled.div`
  flex-grow: 1;
`;

export const Headline = styled(H2)`
  line-height: 1.2em;
`;

export const RightContainer = styled.div``;
