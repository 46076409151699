import styled, { css } from 'styled-components';
import { mediaMax, mediaMin } from 'common/layout';
import { cardMarginPx, menuPaddingMinusCardMarginPx } from '../styles';
import { colors } from 'uiLivi/styles';

export const Container = styled.div`
  padding: 10px ${menuPaddingMinusCardMarginPx};
  background-color: ${colors.background.base};
`;

export const FilterRow = styled.div`
  display: flex;
  ${props =>
    props.wrapOnMobile
      ? mediaMax(
          'small',
          css`
            display: block;
          `
        )
      : null};
`;

export const DropdownContainer = styled.div`
  margin: ${cardMarginPx};
  ${mediaMin(
    'medium',
    css`
      width: 50%;
    `
  )}
`;

export const CheckboxContainer = styled.div`
  margin: 8px;
  &:nth-of-type(2) {
    margin-left: 18px;
  }
`;
