import { MathUtils } from 'three';
export default {
  weather: [
    { position: 0.15, angle: MathUtils.degToRad(-60) }, //upper limit, so [0.0, 0.15] > -60deg
    { position: 0.19, angle: MathUtils.degToRad(-45) }, //upper limit, so [0.15, 0.19] > -45deg...
    { position: 0.24, angle: MathUtils.degToRad(-30) },
    { position: 0.26, angle: MathUtils.degToRad(-15) },
    { position: 0.36, angle: MathUtils.degToRad(0) }, // additional legs
    { position: 1.0, angle: MathUtils.degToRad(0) },
  ],
  sunTracker: [
    { position: 0.0, angle: MathUtils.degToRad(-60), delayLegs: 0 }, //upper limit, so [0.0, 0.15] > -60deg
    { position: 0.1, angle: MathUtils.degToRad(-50), delayLegs: 0 }, //upper limit, so [0.0, 0.15] > -60deg
    { position: 0.2, angle: MathUtils.degToRad(-40), delayLegs: 0 }, //upper limit, so [0.15, 0.19] > -45deg...
    { position: 0.3, angle: MathUtils.degToRad(-30), delayLegs: 0 },
    { position: 0.4, angle: MathUtils.degToRad(-20), delayLegs: 0 },
    { position: 0.5, angle: MathUtils.degToRad(-10), delayLegs: 0 },
    { position: 0.6, angle: MathUtils.degToRad(0), delayLegs: 1.2 },
    { position: 0.65, angle: MathUtils.degToRad(10), delayLegs: 1.2 },
    { position: 0.7, angle: MathUtils.degToRad(20), delayLegs: 1.4 },
    { position: 0.75, angle: MathUtils.degToRad(30), delayLegs: 1.4 },
    { position: 0.8, angle: MathUtils.degToRad(40), delayLegs: 1.9 },
    { position: 0.9, angle: MathUtils.degToRad(50), delayLegs: 2.1 },
    { position: 1.0, angle: MathUtils.degToRad(60), delayLegs: 2.1 },
  ],
};
