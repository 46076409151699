import { CatmullRomCurve3, Vector3 } from 'three';

export default class Curve {
  constructor(resolution = 32, tension = 0.6) {
    // this.points = [new Vector3(-1000, 0, -450), new Vector3(0, 200, -450), new Vector3(1000, 0, -450)];
    // this.points = [new Vector3(-820, 0, -1024), new Vector3(0, 200, -450), new Vector3(820, 0, -124)]; //35deg
    // this.points = [new Vector3(-986, 0, -1024), new Vector3(-167, 200, -450), new Vector3(652, 0, -124)]; //35deg
    this.points = [new Vector3(-820 - 100, 0, -1024), new Vector3(0 - 100, 200, -450), new Vector3(1134 - 100, 0, 145)]; //35deg
    this.curve = new CatmullRomCurve3([this.points[0], this.points[1], this.points[2]]);
    this.curve.resolution = resolution;
    this.curve.tension = tension;
    this.curve.curveType = 'catmullrom';
  }

  getCurvePoints() {
    return this.curve.getPoints(this.curve.resolution);
  }

  getPointAt(p = 0) {
    return this.curve.getPointAt(p);
  }

  getTangentAt(p = 0) {
    return this.curve.getTangentAt(p);
  }
}
