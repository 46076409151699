// @ts-nocheck
import React, { useRef, useEffect, useMemo } from 'react';
import { gsap } from 'gsap';
import { damp } from 'maath/easing';
import { useFrame } from '@react-three/fiber';
import { MathUtils } from 'three';

import WindStoreInterpolator from './WindStoreInterpolator';
import EnvStoreInterpolator from './EnvStoreInterpolator';

import { useWeatherStore } from '../store';

export default function Interpolators() {
  const dc = useRef(false);

  const isAnimating = useWeatherStore(state => state.isAnimating);
  const setIsAnimating = useWeatherStore(state => state.setIsAnimating);
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const isWeather = useWeatherStore(state => state.isWeather);
  const smoothMultiplier = useMemo(() => {
    return isWeather ? 0.4 : 0.8;
  }, [isWeather]);

  useEffect(() => {
    const onSliderUpdate = () => {
      setIsAnimating(true);
    };

    const unsub = useWeatherStore.subscribe(state => state.sliderProgress, onSliderUpdate);

    return () => {
      unsub();
    };
  }, []);

  useFrame((state, delta) => {
    const { sliderProgress } = useWeatherStore.getState();

    if (!isAnimating) return;
    damp(smoothedValues, 'sliderProgress', sliderProgress, 0.75, delta);
    damp(smoothedValues, 'dampedSliderProgress', sliderProgress, 0.8, delta * smoothMultiplier);

    smoothedValues.sliderProgress = MathUtils.clamp(smoothedValues.sliderProgress, 0, 1);
    smoothedValues.dampedSliderProgress = MathUtils.clamp(smoothedValues.dampedSliderProgress, 0, 1);
  });

  return (
    <>
      <WindStoreInterpolator />
      <EnvStoreInterpolator />
    </>
  );
}
