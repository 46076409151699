import { useFrame, useLoader, useThree } from '@react-three/fiber';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import fromCdn from '../../../../utilities/cdn';
import dracoLoader from '../../../../utilities/dracoLoader';
import { AnimationMixer, Euler, LoopRepeat, MathUtils, Quaternion, Vector3 } from 'three';
import { useScrollStore } from '../../../../services/ScrollService';
import { DEG2RAD } from 'three/src/math/MathUtils';
import { useDeviceStore } from '../../../../services/DeviceService';
import { mapRange } from '../../../../utilities/math';
import { gsap, Power2 } from 'gsap';
import { useDistrictWarmupStore, WarmupPhase } from '../../../../services/DistrictWarmupService';

const X_AXIS = new Vector3(1, 0, 0);
const Y_AXIS = new Vector3(0, 1, 0);

export default function ScrollCamera() {
  const isMobile = useDeviceStore(state => state.device.isMobile);
  const gltf = useLoader(
    GLTFLoader,
    fromCdn(
      isMobile
        ? '/shared/districts/intersolar/house/intersolar_lobby_camera_mobile_loop.glb'
        : '/shared/districts/intersolar/house/intersolar_lobby_camera_loop.glb'
    ),
    dracoLoader
  );
  const mixer = useMemo(() => new AnimationMixer(gltf.scene), [gltf.scene]);
  const animationClip = useMemo(() => gltf.animations[0], gltf.animations);
  const { duration } = animationClip;
  const q = useMemo(() => new Quaternion(-0.707, 0, 0, 0.707), []);
  const set = useThree(state => state.set);
  const { camera, mouse } = useThree();
  const [update, setUpdate] = useState(false);
  const phase = useDistrictWarmupStore(state => state.phase);
  const isLoaded = phase === WarmupPhase.DONE;

  const camAxisOffsetX = useRef(0);
  const camAxisOffsetY = useRef(0);
  const fov = useRef(0);
  const qx = useRef(new Quaternion());
  const qy = useRef(new Quaternion());
  const cameraLimit = useMemo(() => {
    return { vertical: 5 * DEG2RAD, horizontal: 7.5 * DEG2RAD };
  }, []);

  const updateCloneCam = dt => {
    if (!update) return;

    const camPos = gltf.cameras[0].parent.position;
    camera.position.copy(camPos);

    const newQuaternion = gltf.cameras[0].parent.quaternion;
    camera.quaternion.copy(newQuaternion);
    camera.quaternion.multiply(q);

    if (!isMobile) {
      camAxisOffsetX.current = MathUtils.lerp(camAxisOffsetX.current, -mouse.x * cameraLimit.horizontal, 0.1);
      camAxisOffsetY.current = MathUtils.lerp(camAxisOffsetY.current, mouse.y * cameraLimit.vertical, 0.1);
      qx.current.setFromAxisAngle(X_AXIS, camAxisOffsetY.current);
      qy.current.setFromAxisAngle(Y_AXIS, camAxisOffsetX.current);
      camera.quaternion.multiply(qx.current);
      camera.quaternion.multiply(qy.current);
    }
  };

  useFrame((_, delta) => {
    const normalizedProgress = useScrollStore.getState().normalizedProgress;
    const progress = useScrollStore.getState().progress + 0.0015;
    const m = progress < 0.5 ? 1 : -1;
    mixer.setTime(progress * duration + m * Math.random() * 0.00000001);
    updateCloneCam(delta);

    if (isMobile) {
      const nextFov = Math.max(
        normalizedProgress < 0.5
          ? mapRange(normalizedProgress, 0, 0.1, 60, 35)
          : mapRange(normalizedProgress, 1.0, 0.9, 60, 35),
        35
      );
      fov.current = MathUtils.lerp(fov.current, nextFov, 0.1);
      camera.fov = fov.current;
      camera.updateProjectionMatrix();
    }
  });

  useEffect(() => {
    const action = mixer.clipAction(animationClip);
    action.setLoop(LoopRepeat);
    action.play();
  }, [gltf.animations, gltf.cameras, gltf.scene, mixer, set, camera]);

  useEffect(() => {
    camera.fov = fov.current = 35;
    camera.updateProjectionMatrix();

    if (isLoaded) {
      if (update) return;
      const camPos = gltf.cameras[0].parent.position;
      const newQuaternion = gltf.cameras[0].parent.quaternion.multiply(q);
      camera.position.copy(camPos);
      camera.quaternion.copy(newQuaternion);

      const e = new Euler().setFromQuaternion(camera.quaternion);
      camera.rotation.x = -0.3;
      camera.rotation.y = e.y;
      camera.rotation.z = e.z;
      gsap.to(camera.rotation, {
        duration: 1,
        ease: Power2.easeOut,
        x: e.x,
        y: e.y,
        z: e.z,
        onComplete: () => {
          setUpdate(true);
        },
      });
    }
  }, [isLoaded]);

  return null;
}
