import { PlaneGeometry, RepeatWrapping, TextureLoader, Vector3 } from 'three';
import React, { useMemo } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import WaterMesh from './WaterMesh';
import fromCdn from 'utilities/cdn';
import { useDebugStore } from 'storage/debug';

export default function Water({ reflections, position, config }) {
  const debugMinimal3d = useDebugStore(state => state.getMinimal3d());

  const texture = useLoader(TextureLoader, fromCdn('/shared/textures/waterNoise.png'));

  const geom = useMemo(() => {
    return new PlaneGeometry(15000, 15000, 10, 10);
  }, []);

  const water = useMemo(() => {
    texture.wrapS = texture.wrapT = RepeatWrapping;
    const water = new WaterMesh(geom, {
      textureWidth: 1024,
      textureHeight: 1024,
      waterNormals: debugMinimal3d ? null : texture,
      alpha: 1.0,
      sunDirection: new Vector3(-0.2, -1, -0.2).normalize(),
      sunColor: 0xffffff,
      waterColor: 0x001e0f,
      distortionScale: 1,
      fog: false,
      reflections,
    });
    water.rotation.x = -Math.PI / 2;
    return water;
  }, [debugMinimal3d, reflections]);

  useFrame((context, dt) => {
    const x = Math.sin(config.inclination) * Math.cos(config.azimuth);
    const y = Math.cos(config.inclination);
    const z = Math.sin(config.inclination) * Math.sin(config.azimuth);

    water.material.uniforms['time'].value += dt * 0.2;
    water.material.uniforms['sunDirection'].value = new Vector3(x, y, z).normalize();
  });

  return <primitive renderOrder={-1000} object={water} position={position} />;
}
