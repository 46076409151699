import styled from 'styled-components';
import { Button } from 'common/components/Button';
import { useTranslate } from 'services/TranslationService/hooks';

const StyledChatUserList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;

  button {
    height: calc(100% + 1px);
    flex-shrink: 0;
    padding: 12px 24px;
  }
`;

const StyledUserContainer = styled.div`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 10px;
  font-weight: bold;
`;

const ChatUserList = ({ chatList, startNewChat }) => {
  const translate = useTranslate();
  return (
    <StyledChatUserList>
      <StyledUserContainer>
        {chatList.map((user, id) => (
          <span key={id}>
            {user.forename}
            {id !== chatList.length - 1 ? ', ' : null}
          </span>
        ))}
      </StyledUserContainer>
      <Button disabled={chatList.length === 0} smallText accent onClick={startNewChat}>
        {translate('chat.group.start') || 'Start'}
      </Button>
    </StyledChatUserList>
  );
};

export default ChatUserList;
