import { useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { useControlsStore } from '../../services/ControlsService';

// TODO: implement handler for touch controls
export default function PointerControls() {
  const eventHandler = useControlsStore(state => state.eventHandler);
  const {
    onMouseMove,
    onMouseDown,
    onMouseUp,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    beginFrame,
    updateDomElement,
  } = eventHandler;

  const {
    gl: { domElement },
  } = useThree();

  const handleResize = () => {
    updateDomElement(domElement);
  };

  useEffect(() => {
    updateDomElement(domElement);
    domElement.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mouseleave', onMouseUp);

    domElement.addEventListener('touchstart', onTouchStart);
    document.addEventListener('touchmove', onTouchMove);
    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('touchcancel', onTouchEnd);

    window.addEventListener('resize', handleResize);
    return () => {
      domElement.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mouseleave', onMouseUp);

      domElement.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchcancel', onTouchEnd);

      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useFrame(beginFrame, 0);

  return null;
}
