import { Matrix4, Triangle, Vector3 } from 'three';

function ascSort(a, b) {
  return a.distanceSquared - b.distanceSquared;
}

const _matrixWorld = new Matrix4();
const _inverseMatrix = new Matrix4();
const _origin = new Vector3();
const _target = new Vector3();
const _closestPoint = new Vector3();
const _intersectionPoint = new Vector3();
const _intersectionPointWorld = new Vector3();
const _direction = new Vector3();
const _triangle = new Triangle();

function checkIntersection(object, spherecaster, origin, target, pA, pB, pC, plane) {
  _triangle.set(pA, pB, pC);
  _triangle.closestPointToPoint(target, _closestPoint);
  const distanceSquaredToTriangle = _closestPoint.distanceToSquared(target);
  if (distanceSquaredToTriangle > spherecaster.radius * spherecaster.radius) {
    // target of the spherecast is not within <radius> distance of the wall
    return null;
  }

  if (plane.distanceToPoint(origin) < 0) {
    return null;
  }

  _direction
    .copy(target)
    .sub(origin)
    .normalize();
  const dot = _direction.dot(plane.normal);

  if (dot > 0.0) {
    return null;
  }

  _intersectionPoint
    .copy(target)
    .sub(_closestPoint)
    .setY(0)
    .normalize()
    .multiplyScalar(spherecaster.radius)
    .add(_closestPoint)
    .setY(target.y);
  _intersectionPointWorld.copy(_intersectionPoint).applyMatrix4(_matrixWorld);

  return {
    face: {
      normal: plane.normal,
    },
    distanceSquared: distanceSquaredToTriangle,
    correctedPoint: _intersectionPointWorld.clone(),
  };
}

function spherecast(object, spherecaster, intersects) {
  if (object.space.distanceToPoint(_target) > spherecaster.radius) return false;

  for (var f = 0; f < object.vertexes.length; f += 3) {
    const va = object.vertexes[f];
    const vb = object.vertexes[f + 1];
    const vc = object.vertexes[f + 2];
    const plane = object.planes[f / 3];
    const intersection = checkIntersection(object, spherecaster, _origin, _target, va, vb, vc, plane);
    if (intersection) intersects.push(intersection);
  }
  return true;
}

function intersectObject(object, spherecaster, intersects, recursive) {
  const bbAccepted = spherecast(object, spherecaster, intersects);

  if (recursive === true && bbAccepted) {
    const children = object.children;

    for (let i = 0, l = children.length; i < l; i++) {
      intersectObject(children[i], spherecaster, intersects, true);
    }
  }
}

class Spherecaster {
  constructor() {
    this.origin = new Vector3();
    this.target = new Vector3();
    this.radius = 0;
  }

  set(origin, target, radius) {
    this.origin.copy(origin);
    this.target.copy(target);
    this.radius = radius;
  }

  intersectObject(object, recursive, optionalTarget) {
    return this.intersectObjects([object], recursive, optionalTarget);
  }

  intersectObjects(objects, recursive, optionalTarget) {
    const intersects = optionalTarget || [];
    for (let i = 0, l = objects.length; i < l; i++) {
      _matrixWorld.copy(objects[i].matrixWorld);
      _inverseMatrix.copy(objects[i].matrixWorld).invert();
      _origin.copy(this.origin).applyMatrix4(_inverseMatrix);
      _target.copy(this.target).applyMatrix4(_inverseMatrix);
      intersectObject(objects[i].root, this, intersects, recursive);
    }
    intersects.sort(ascSort);
    return intersects;
  }
}

export { Spherecaster };
