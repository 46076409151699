import React, { useMemo } from 'react';
import { Color, MathUtils } from 'three';
import { useFrame } from '@react-three/fiber';
import get from 'lodash-es/get';
import cloneDeep from 'lodash-es/cloneDeep';
import { gsap } from 'gsap';

import { useWeatherStore } from '../../store';
import { createInterpolator } from '../../helpers';

const COLOR = new Color();

/** README
  Might worth the time to refactor this, convert every interpolator instance into a class
  that supports both values and some helper functions.
 */

const interpolateValue = (weather, sun, t) => {
  return MathUtils.lerp(weather, sun, t);
};

const createColorInterpolator = (data, key) => {
  const tl = gsap.timeline({ paused: true });
  const info = data.map(el => ({
    position: el.position,
    color: COLOR.clone()
      .set(get(el, key))
      .convertLinearToSRGB(),
  }));

  const colorInstance = COLOR.clone();

  colorInstance.copy(info[0].color);

  for (var i = 0; i < info.length - 1; i++) {
    var keyframe = info[i];
    var nextKeyframe = info[i + 1];

    tl.to(colorInstance, {
      duration: nextKeyframe.position - keyframe.position,
      r: nextKeyframe.color.r,
      g: nextKeyframe.color.g,
      b: nextKeyframe.color.b,
      ease: 'none',
    });
  }

  const getPoint = v => {
    tl.seek(v);
    return colorInstance;
  };

  return { getPoint, tl };
};

export default function EnvStoreInterpolator() {
  const isWeather = useWeatherStore(state => state.isWeather);
  const envConfig = useWeatherStore(state => state.envConfig);
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const isAnimating = useWeatherStore(state => state.isAnimating);

  const interpolators = useMemo(() => {
    const weatherStages = envConfig.weather.stages;
    const sunTrackerStages = envConfig.sunTracker.stages;

    const weatherObject = cloneDeep(weatherStages[0]);
    const sunTrackerObject = cloneDeep(sunTrackerStages[0]);
    smoothedValues.env = cloneDeep(weatherStages[0]);

    // SUN
    weatherObject.sun.opacity = createInterpolator(weatherStages, 'sun.opacity');
    sunTrackerObject.sun.opacity = createInterpolator(sunTrackerStages, 'sun.opacity');

    // FOG
    weatherObject.fog.opacity = createInterpolator(weatherStages, 'fog.opacity');
    weatherObject.fog.color = createColorInterpolator(weatherStages, 'fog.color');
    sunTrackerObject.fog.opacity = createInterpolator(sunTrackerStages, 'fog.opacity');
    sunTrackerObject.fog.color = createColorInterpolator(sunTrackerStages, 'fog.color');
    smoothedValues.env.fog.color = weatherObject.fog.color.getPoint(0).clone();

    // SKY
    weatherObject.sky.top = createColorInterpolator(weatherStages, 'sky.top');
    sunTrackerObject.sky.top = createColorInterpolator(sunTrackerStages, 'sky.top');
    smoothedValues.env.sky.top = weatherObject.sky.top.getPoint(0).clone();

    weatherObject.sky.bottom = createColorInterpolator(weatherStages, 'sky.bottom');
    sunTrackerObject.sky.bottom = createColorInterpolator(sunTrackerStages, 'sky.bottom');
    smoothedValues.env.sky.bottom = weatherObject.sky.bottom.getPoint(0).clone();

    // CLOUDS
    weatherObject.clouds_stage0.speed = createInterpolator(weatherStages, 'clouds_stage0.speed');
    weatherObject.clouds_stage0.opacity = createInterpolator(weatherStages, 'clouds_stage0.opacity');
    sunTrackerObject.clouds_stage0.speed = createInterpolator(sunTrackerStages, 'clouds_stage0.speed');
    sunTrackerObject.clouds_stage0.opacity = createInterpolator(sunTrackerStages, 'clouds_stage0.opacity');

    weatherObject.clouds_stage1.speed = createInterpolator(weatherStages, 'clouds_stage1.speed');
    weatherObject.clouds_stage1.opacity = createInterpolator(weatherStages, 'clouds_stage1.opacity');
    sunTrackerObject.clouds_stage1.speed = createInterpolator(sunTrackerStages, 'clouds_stage1.speed');
    sunTrackerObject.clouds_stage1.opacity = createInterpolator(sunTrackerStages, 'clouds_stage1.opacity');

    weatherObject.clouds_stage2.speed = createInterpolator(weatherStages, 'clouds_stage2.speed');
    weatherObject.clouds_stage2.opacity = createInterpolator(weatherStages, 'clouds_stage2.opacity');
    sunTrackerObject.clouds_stage2.speed = createInterpolator(sunTrackerStages, 'clouds_stage2.speed');
    sunTrackerObject.clouds_stage2.opacity = createInterpolator(sunTrackerStages, 'clouds_stage2.opacity');

    weatherObject.clouds_stage3.speed = createInterpolator(weatherStages, 'clouds_stage3.speed');
    weatherObject.clouds_stage3.opacity = createInterpolator(weatherStages, 'clouds_stage3.opacity');
    sunTrackerObject.clouds_stage3.speed = createInterpolator(sunTrackerStages, 'clouds_stage3.speed');
    sunTrackerObject.clouds_stage3.opacity = createInterpolator(sunTrackerStages, 'clouds_stage3.opacity');

    weatherObject.clouds_stage4.speed = createInterpolator(weatherStages, 'clouds_stage4.speed');
    weatherObject.clouds_stage4.opacity = createInterpolator(weatherStages, 'clouds_stage4.opacity');
    sunTrackerObject.clouds_stage4.speed = createInterpolator(sunTrackerStages, 'clouds_stage4.speed');
    sunTrackerObject.clouds_stage4.opacity = createInterpolator(sunTrackerStages, 'clouds_stage4.opacity');

    // LIGHTS
    weatherObject.directionalLight.intensity = createInterpolator(weatherStages, 'directionalLight.intensity');
    sunTrackerObject.directionalLight.intensity = createInterpolator(sunTrackerStages, 'directionalLight.intensity');

    weatherObject.lightmap.intensity = createInterpolator(weatherStages, 'lightmap.intensity');
    sunTrackerObject.lightmap.intensity = createInterpolator(sunTrackerStages, 'lightmap.intensity');

    weatherObject.envMap.intensity = createInterpolator(weatherStages, 'envMap.intensity');
    sunTrackerObject.envMap.intensity = createInterpolator(sunTrackerStages, 'envMap.intensity');

    weatherObject.envMap.intensityLightMaps = createInterpolator(weatherStages, 'envMap.intensityLightMaps');
    sunTrackerObject.envMap.intensityLightMaps = createInterpolator(sunTrackerStages, 'envMap.intensityLightMaps');

    weatherObject.vignette.offset = createInterpolator(weatherStages, 'vignette.offset');
    sunTrackerObject.vignette.offset = createInterpolator(sunTrackerStages, 'vignette.offset');

    weatherObject.vignette.darkness = createInterpolator(weatherStages, 'vignette.darkness');
    sunTrackerObject.vignette.darkness = createInterpolator(sunTrackerStages, 'vignette.darkness');

    return { weather: weatherObject, sunTracker: sunTrackerObject };
  }, []);

  useFrame((state, delta) => {
    // DOING IT MANUALLY FASTER THAN LOOP AND USE LODASH-GET
    if (!isAnimating) return;
    const t = smoothedValues.scenesInterpolator;
    const p = smoothedValues.dampedSliderProgress;

    // SUN
    smoothedValues.env.sun.opacity = interpolateValue(
      interpolators.weather.sun.opacity.getPoint(p),
      interpolators.sunTracker.sun.opacity.getPoint(p),
      t
    );

    // FOG
    smoothedValues.env.fog.opacity = interpolateValue(
      interpolators.weather.fog.opacity.getPoint(p),
      interpolators.sunTracker.fog.opacity.getPoint(p),
      t
    );
    smoothedValues.env.fog.color.lerpColors(
      interpolators.weather.fog.color.getPoint(p),
      interpolators.sunTracker.fog.color.getPoint(p),
      t
    );

    // LERP BETWEEN WEATHER AND SUN-TRACKER SKY
    smoothedValues.env.sky.top.lerpColors(
      interpolators.weather.sky.top.getPoint(p),
      interpolators.sunTracker.sky.top.getPoint(p),
      t
    );
    smoothedValues.env.sky.bottom.lerpColors(
      interpolators.weather.sky.bottom.getPoint(p),
      interpolators.sunTracker.sky.bottom.getPoint(p),
      t
    );

    // CLOUDS STAGE 0
    smoothedValues.env.clouds_stage0.speed = interpolateValue(
      interpolators.weather.clouds_stage0.speed.getPoint(p),
      interpolators.sunTracker.clouds_stage0.speed.getPoint(p),
      t
    );
    smoothedValues.env.clouds_stage0.opacity = interpolateValue(
      interpolators.weather.clouds_stage0.opacity.getPoint(p),
      interpolators.sunTracker.clouds_stage0.opacity.getPoint(p),
      t
    );

    // CLOUDS STAGE 1
    smoothedValues.env.clouds_stage1.speed = interpolateValue(
      interpolators.weather.clouds_stage1.speed.getPoint(p),
      interpolators.sunTracker.clouds_stage1.speed.getPoint(p),
      t
    );
    smoothedValues.env.clouds_stage1.opacity = interpolateValue(
      interpolators.weather.clouds_stage1.opacity.getPoint(p),
      interpolators.sunTracker.clouds_stage1.opacity.getPoint(p),
      t
    );

    // CLOUDS STAGE 2
    smoothedValues.env.clouds_stage2.speed = interpolateValue(
      interpolators.weather.clouds_stage2.speed.getPoint(p),
      interpolators.sunTracker.clouds_stage2.speed.getPoint(p),
      t
    );
    smoothedValues.env.clouds_stage2.opacity = interpolateValue(
      interpolators.weather.clouds_stage2.opacity.getPoint(p),
      interpolators.sunTracker.clouds_stage2.opacity.getPoint(p),
      t
    );

    // CLOUDS STAGE 3
    smoothedValues.env.clouds_stage3.speed = interpolateValue(
      interpolators.weather.clouds_stage3.speed.getPoint(p),
      interpolators.sunTracker.clouds_stage3.speed.getPoint(p),
      t
    );
    smoothedValues.env.clouds_stage3.opacity = interpolateValue(
      interpolators.weather.clouds_stage3.opacity.getPoint(p),
      interpolators.sunTracker.clouds_stage3.opacity.getPoint(p),
      t
    );

    // CLOUDS STAGE 4
    smoothedValues.env.clouds_stage4.speed = interpolateValue(
      interpolators.weather.clouds_stage4.speed.getPoint(p),
      interpolators.sunTracker.clouds_stage4.speed.getPoint(p),
      t
    );
    smoothedValues.env.clouds_stage4.opacity = interpolateValue(
      interpolators.weather.clouds_stage4.opacity.getPoint(p),
      interpolators.sunTracker.clouds_stage4.opacity.getPoint(p),
      t
    );

    // LIGHTS
    smoothedValues.env.directionalLight.intensity = interpolateValue(
      interpolators.weather.directionalLight.intensity.getPoint(p),
      interpolators.sunTracker.directionalLight.intensity.getPoint(p),
      t
    );

    smoothedValues.env.lightmap.intensity = interpolateValue(
      interpolators.weather.lightmap.intensity.getPoint(p),
      interpolators.sunTracker.lightmap.intensity.getPoint(p),
      t
    );
    smoothedValues.env.envMap.intensity = interpolateValue(
      interpolators.weather.envMap.intensity.getPoint(p),
      interpolators.sunTracker.envMap.intensity.getPoint(p),
      t
    );
    smoothedValues.env.envMap.intensityLightMaps = interpolateValue(
      interpolators.weather.envMap.intensityLightMaps.getPoint(p),
      interpolators.sunTracker.envMap.intensityLightMaps.getPoint(p),
      t
    );

    // VIGNETTE
    smoothedValues.env.vignette.offset = interpolateValue(
      interpolators.weather.vignette.offset.getPoint(p),
      interpolators.sunTracker.vignette.offset.getPoint(p),
      t
    );

    smoothedValues.env.vignette.darkness = interpolateValue(
      interpolators.weather.vignette.darkness.getPoint(p),
      interpolators.sunTracker.vignette.darkness.getPoint(p),
      t
    );
  });

  return null;
}
