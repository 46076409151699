/**
 * Storage that exposes setItem, getItem, removeItem
 * uses "window.localStorage" if available, otherwise inMemory
 */
const inMemoryStorage = {};
function isSupported() {
  try {
    const testKey = '__safestorage__testkey__';
    window.localStorage.setItem(testKey, testKey);
    window.localStorage.removeItem(testKey);
    return true;
  } catch (e) {
    return false;
  }
}

function setItem(name, value) {
  if (isSupported()) {
    window.localStorage.setItem(name, value);
  } else {
    inMemoryStorage[name] = String(value);
  }
}

function getItem(name) {
  if (isSupported()) {
    return window.localStorage.getItem(name);
  } else if (Object.prototype.hasOwnProperty.call(inMemoryStorage, name)) {
    return inMemoryStorage[name];
  }
  return null;
}

function removeItem(name) {
  if (isSupported()) {
    return window.localStorage.removeItem(name);
  } else if (Object.prototype.hasOwnProperty.call(inMemoryStorage, name)) {
    delete inMemoryStorage[name];
  }
  return null;
}

export default { getItem, setItem, removeItem };
