import React, { useEffect, useRef } from 'react';
import { useUserStore } from 'services/UserService';
import { disableForm, useLoginStatus } from '../helper';
import { useEventStore } from 'services/EventService';
import { Button } from 'common/components/ButtonIntersolar';
import IconSchletter from '../../SchletterUI/assets/icon-schletter.svg';
import { Logo, LogoText } from '../../SchletterUI/styles';
import { BackgroundImage, Container, Content, Copy, Headline, LogoWrapper } from './styles';
import DeviceHint from '../../Play/DeviceHint';

function getPseudoRandomString(length = 10) {
  return Math.random()
    .toString(36)
    .replace(/[^a-z0-9]+/g, '')
    .substring(1, 1 + Math.min(length, 10));
}

function Welcome() {
  const { id: eventId } = useEventStore(state => state.event);
  const registerForm = useRef();
  const register = useUserStore(state => state.register);
  const loginStatus = useLoginStatus();
  const disabled = loginStatus.isPending;

  useEffect(() => {
    disableForm(registerForm.current, disabled);
  }, [disabled]);

  const submit = e => {
    e.preventDefault();
    // if (!registerButtonEnabled) return;
    register({
      credentials: {
        forename: getPseudoRandomString(),
        surname: getPseudoRandomString(),
        // the backend still needs both a password and email to work.
        // We give it a (hopefully) unique address here as a dirty workaround.
        email: `${Date.now()}-${getPseudoRandomString(6)}@notarealaddress.demodern.de`,
        password: getPseudoRandomString(),
        eventId,
        // expertCode,
        // eventCode,
      },
    });
  };

  return (
    <>
      <BackgroundImage />
      <Container>
        <LogoWrapper>
          <Logo>
            <IconSchletter />
            <LogoText>
              HOUSE OF
              <br />
              SCHLETTER
            </LogoText>
          </Logo>
        </LogoWrapper>
        <Content>
          <Headline>Welcome.</Headline>
          <Copy>
            Hey! It's great to have you here at the House of Schletter, our new digital home. Feel free to explore our
            products in The Hub, learn to assemble them in The Workshop, and, if you're feeling brave, face the elements
            in The Weather Lab—all from the comfort of your own home. Come on in!
          </Copy>
          <form ref={registerForm} onSubmit={submit}>
            <Button type="submit" role="button">
              Enter
            </Button>
          </form>
        </Content>
      </Container>
      <DeviceHint />
    </>
  );
}

export { Welcome };
