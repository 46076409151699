import { useTraverse } from '../hooks';

export default function useCloneOrg() {
  useTraverse(() => {
    return o => {
      if (o.orgMaterial) {
        o.material = o.orgMaterial.clone();
        o.material.color = o.orgMaterial.color.clone();
      }
    };
  });
}
