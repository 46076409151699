import create from 'utilities/zustand/create';

export const useControlsStore = create((set, get) => ({
  cursorPosition: { x: 0, y: 0 },
  cursorDelta: { x: 0, y: 0 },
  cursorDown: false,
  prevCursorDown: false,
  joystickInput: { x: 0, y: 0 },
  setJoystickInput: newJoystickInput => {
    set({ joystickInput: newJoystickInput });
  },
  domSize: { w: 1024, h: 1024 },
  domElement: null,
  eventHandler: {
    updateDomElement: domElement => {
      const domSize = { w: domElement.clientWidth, h: domElement.clientHeight };
      set({ domElement: domElement, domSize: domSize });
    },
    beginFrame: () => {
      const cursorDelta = { x: 0, y: 0 };
      set({ cursorDelta });
    },
    onMouseMove: event => {
      const { cursorPosition: prevCursorPosition, domSize, cursorDelta } = get();
      const cursorPosition = { x: event.clientX / domSize.w, y: event.clientY / domSize.h };
      cursorDelta.x += cursorPosition.x - prevCursorPosition.x;
      cursorDelta.y += cursorPosition.y - prevCursorPosition.y;
      set({ cursorPosition, cursorDelta });
    },
    onMouseDown: event => {
      const { domSize } = get();
      const cursorDown = true;
      const cursorPosition = { x: event.clientX / domSize.w, y: event.clientY / domSize.h };
      set({ cursorDown, cursorPosition });
    },
    onMouseUp: () => {
      const cursorDown = false;
      set({ cursorDown });
    },

    onTouchMove: event => {
      const { domElement } = get();
      const touch = Object.values(event.touches).find(t => t.target === domElement);
      if (!touch) {
        return;
      }
      const { cursorPosition: prevCursorPosition, domSize, cursorDelta } = get();
      const cursorPosition = { x: touch.clientX / domSize.w, y: touch.clientY / domSize.h };
      cursorDelta.x += cursorPosition.x - prevCursorPosition.x;
      cursorDelta.y += cursorPosition.y - prevCursorPosition.y;
      set({ cursorPosition, cursorDelta });
    },
    onTouchStart: event => {
      const { domElement } = get();
      const touch = Object.values(event.touches).find(t => t.target === domElement);
      if (!touch) {
        return;
      }
      const { domSize } = get();
      const cursorDown = true;
      const cursorPosition = { x: touch.clientX / domSize.w, y: touch.clientY / domSize.h };
      set({ cursorDown, cursorPosition });
    },
    onTouchEnd: event => {
      const { domElement } = get();
      const touch = Object.values(event.touches).find(t => t.target === domElement);
      if (touch) {
        return;
      }
      const cursorDown = false;
      set({ cursorDown });
    },
  },
}));
