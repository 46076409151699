import { CONTENT_TYPE } from './contentTypes';

export const getTeaserType = url => {
  if (url.endsWith('.jpeg') || url.endsWith('.jpg') || url.endsWith('.png')) return 'image';
  else if (url.endsWith('.mp4') || url.endsWith('m3u8')) return 'video';
};

export const mapContent = content => {
  return {
    ...content,
    teaserType: content.teaser ? getTeaserType(content.teaser) : 'image',
    contentType: CONTENT_TYPE[content.type],
    headline: content.headline ? content.headline : '',
    subline: content.subline ? content.subline : '',
  };
};

export const mapContents = contents => {
  return contents?.map(mapContent);
};
