import styled from 'styled-components';
import { colors, easings } from 'uiLivi/styles';

const burgerSize = 48;
const burgerMargin = 14;

export const BurgerContainer = styled.div`
  position: absolute;
  transition: transform 0.35s ${easings.easeOutStrong};
  transform: translateX(${props => (props.show ? 0 : -(burgerSize + burgerMargin + 1))}px);
  //top: ${burgerMargin}px;
  top: 100px; //tbr => schletter
  left: ${burgerMargin}px;
  width: ${burgerSize}px;
  height: ${burgerSize}px;
  cursor: pointer;
  background-color: ${colors.background.base};
  pointer-events: all;
  z-index: 10;
  border-radius: 50%;
`;

export const Line = styled.div`
  height: 2px;
  width: 16px;
  background-color: ${colors.font.strong};
  position: absolute;
  border-radius: 1px;
  left: 16px;
  top: ${props => (props.top ? 18 : props.middle ? 23 : 28)}px;
`;
