import { useDistrictStore } from 'services/DistrictService';
import { useUserStore } from 'services/UserService';
import { useEventStore } from '../EventService';
import { useSceneStore } from '../SceneService';
import { usePlayerStore } from './index';

export function getSpawnInfo() {
  const { previousRoom } = usePlayerStore.getState();
  const { useAvatars } = useEventStore.getState().event;
  const { zoneGroup } = useSceneStore.getState().scene;

  const result = { position: [0, 0, 0], rotation: 0 };

  // Spawn in place of prop that has a slack integration and a fitting user id
  const products = useEventStore.getState().event.products.filter(p => {
    if (!p.district) return false;
    const cleanDistrictString = p.district.replace(/\s/g, '');
    const districts = cleanDistrictString.split(',');
    const currentDistrict = useDistrictStore.getState().district;
    return districts.includes(currentDistrict.room);
  });
  const user = useUserStore.getState().user;
  if (user.role.type === 'expert') {
    const productThatIsMe = products.find(
      p => !!p.slack && p.slack.name == user.forename && p.slack.lastName === user.surname
    );
    if (productThatIsMe) {
      const productPosition = productThatIsMe.location.split(',').map(p => +p);
      const productRotation = productThatIsMe.rotation.split(',').map(p => +p);
      result.position = productPosition;
      result.rotation = productRotation[1];
      return result;
    }
  }

  // eslint-disable-next-line no-console
  // console.log('zoneGroup', zoneGroup);

  const applyToResult = (spawnZone, useRandomOffset) => {
    const p = spawnZone.position;
    const r = spawnZone.rotation;

    result.position[0] = p.x;
    result.position[1] = p.y;
    result.position[2] = p.z;
    result.rotation = -r.y + Math.PI;

    if (useRandomOffset) {
      result.position[0] += 2 * ((Math.random() - 0.5) * 0.5);
      result.position[2] += 2 * ((Math.random() - 0.5) * 0.5);
    }
  };

  if (!zoneGroup) {
    // eslint-disable-next-line no-console
    console.warn('No zone group', zoneGroup);
    return result;
  }

  const orbitSpawnZone = zoneGroup.children.find(z => z.name.startsWith('orbit_spawn'));
  const mainSpawnZone = zoneGroup.children.find(z => z.name.startsWith('main_spawn'));
  const portalZone = zoneGroup.children.find(z => z.name.startsWith(previousRoom));

  if (!useAvatars && orbitSpawnZone) {
    // eslint-disable-next-line no-console
    // console.log('Found orbit_spawn', orbitSpawnZone);
    applyToResult(orbitSpawnZone, false);
  } else if (previousRoom && portalZone) {
    // eslint-disable-next-line no-console
    // console.log('Found portalZone', portalZone);
    applyToResult(portalZone, true);
  } else if (mainSpawnZone) {
    // eslint-disable-next-line no-console
    // console.log('Found main_spawn', mainSpawnZone);
    applyToResult(mainSpawnZone, true);
  } else {
    // eslint-disable-next-line no-console
    console.warn('No spawn found.', zoneGroup, previousRoom);
  }

  return result;
}
