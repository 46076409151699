import React, { useEffect, useState } from 'react';
import qs from 'query-string';

import HBFLayout from 'common/components/HBFLayout';
import { Button } from 'common/components/ButtonIntersolar';
import { useModalService } from 'services/ModalService';
import { useOnboardingStore } from 'services/OnboardingService';
import { useDistrictStore } from 'services/DistrictService';

import { ContentContainer, SkipButton, SlideTransitionWrapper, FooterContainer } from './styles';

import Pagination from './Pagination';
import SwipeDetect from './SwipeDetect';

export default function Onboarding({ onBoardingSlides }) {
  const district = useDistrictStore(state => state.district);
  const isWeatherScene = district?.configuration?.customScene === 'weather';
  const queryParams = qs.parse(window.location.search);

  const [slideContent, setSlideContent] = useState(<></>);
  const [slideCount, setSlideCount] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [showSlide, setShowSlide] = useState(false);

  const lastSlide = slideIndex === slideCount - 1;
  const isTradeFair = isWeatherScene && queryParams.minimal != null;

  const buttonCopy = isTradeFair ? 'Touch to start' : lastSlide ? 'Get Started' : 'Next';

  useEffect(() => {
    setSlideContent(onBoardingSlides[0]);
    setSlideCount(onBoardingSlides.length);
  }, []);

  useEffect(() => {
    const t = setTimeout(() => {
      setShowSlide(true);
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [slideIndex]);

  const closeModal = () => {
    useModalService.getState().closeModal();
    useOnboardingStore.getState().finishOnboarding();
    useOnboardingStore.getState().startInactivityTimer();
  };

  const gotoSlide = index => {
    setShowSlide(false);
    setSlideIndex(index);
    setSlideContent(onBoardingSlides[index]);
  };

  const nextSlide = () => {
    if (slideIndex < slideCount - 1) {
      const nextIndex = slideIndex + 1;
      gotoSlide(nextIndex);
    }
  };

  const prevSlide = () => {
    if (slideIndex > 0) {
      const nextIndex = slideIndex - 1;
      gotoSlide(nextIndex);
    }
  };

  const onSwipeDetect = direction => {
    if (direction === -1) nextSlide();
    if (direction === 1) prevSlide();
  };

  const body = (
    <ContentContainer modifier={onBoardingSlides.length > 1}>
      <SlideTransitionWrapper show={showSlide}>{slideContent}</SlideTransitionWrapper>
      <SwipeDetect onSwipeDetect={onSwipeDetect} />
      {onBoardingSlides.length > 1 && (
        <Pagination slideCount={slideCount} slideIndex={slideIndex} gotoSlide={gotoSlide} />
      )}
    </ContentContainer>
  );

  const footer = (
    <FooterContainer>
      <Button
        onClick={lastSlide ? closeModal : nextSlide}
        soundAction={lastSlide && showSlide ? 'primaryClick' : undefined}
      >
        {buttonCopy}
      </Button>
      {!lastSlide && (
        <SkipButton onClick={closeModal} data-sound-action="click">
          Skip
        </SkipButton>
      )}
    </FooterContainer>
  );

  return <HBFLayout body={body} footer={footer} positionedBottom={true} />;
}
