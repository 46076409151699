import axios from 'axios';
import { useSocketStore } from 'services/SocketService';
import create from 'utilities/zustand/create';
import eventStore from 'storage/event';
import moment from 'moment';
import { CONTENT_TYPE } from 'services/ContentService/contentTypes';
import { getTeaserType } from 'services/ContentService/utils';
import fromCdn, { configureCdn } from 'utilities/cdn';

const qs = require('query-string');

export const useEventStore = create((set, get) => ({
  event: null,
  eventState: 'PENDING',
  eventLive: false,
  useAvatars: true,
  startLiveTimeout: event => {
    const nowTime = moment();
    const startDateString = event.startDate;
    const startTime = moment(new Date(startDateString));
    const timeRemainingMS = startTime.diff(nowTime) + 1000;
    if (timeRemainingMS <= 0) {
      set({ eventLive: true });
    } else {
      setTimeout(() => {
        set({ eventLive: true });
      }, timeRemainingMS);
    }
  },
  init: () => {
    const queryParams = qs.parse(window.location.search);
    const hostname = window.location.hostname;
    const splits = hostname.split('.');

    let shortname;

    const isSubdomainWithShortNameInSubdomain = hostname.endsWith('schletter.dmdr.io');

    if (isSubdomainWithShortNameInSubdomain && splits.length > 1) {
      if (splits[0] !== 'develop') {
        shortname = splits[0];
      }
    }

    if (shortname == null) {
      shortname = eventStore.getEvent();
    }

    // force event parameter
    if (queryParams.event != null) {
      shortname = queryParams.event;
    }

    /* eslint-disable */
    return new Promise(async (resolve, reject) => {
      const restUrl = useSocketStore.getState().getRestUrl();
      let event = null;
      try {
        const response = await axios.get(restUrl + 'event-pick' + (shortname ? '?shortname=' + shortname : ''));
        event = response.data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        set({ eventState: 'FAILED', event: null });
        reject();
        return;
      }

      const hasDistricts = event.districts?.length > 0;
      if (!event || !hasDistricts) {
        set({ eventState: 'NONE_AVAILABLE', event: null });
        reject();
        return;
      }

      // from here on, event is loaded and is supposed to work fine

      // flo disable metrics
      /*
      axios.get(
        'https://metrics-collector.virtexp.virtual-events.dmdr.io/metric/' + event.shortname + '/incr_user_count_total'
      ); */

      configureCdn(event.cdn);

      moment.tz.setDefault(event.timeZone);
      if ('simple' in queryParams) {
        event.useSimpleRegisterForm = true;
      }
      if ('orbit' in queryParams) {
        event.useAvatars = false;
      }
      eventStore.setEvent(shortname);

      event.districts = event.districts.map(d => {
        //merge json
        const newDistrict = { ...d, ...d.configuration };

        //convert type string to type
        newDistrict.portals = newDistrict.portals?.map(p => {
          return { ...p, type: CONTENT_TYPE[p.type] };
        });
        return newDistrict;
      });

      // patch contents
      // screens are the new general event contents (they include stuff and also the content itself)
      event.contents = event.screens?.map(screen => {
        const {content} = screen;
        return {
          ...content,
          ...screen,
          teaserType: content.teaser ? getTeaserType(content.teaser) : 'image',
          type: CONTENT_TYPE[content.type],
        };
      });

      set({ eventState: 'SUCCESSFUL', event });
      get().startLiveTimeout(event);

      resolve();
    });
  },
  setEventIsOverloaded: () => {
    set({ eventState: 'OVERLOADED', event: null });
  },
}));
