import styled from 'styled-components';

export const Button = styled.div`
  pointer-events: all;
  cursor: pointer;
  text-align: center;
  padding: 10px;
`;

export const NextStep = styled.div`
  font-family: 'demodernbold', sans-serif;
  opacity: 0.4;
`;

export const NextStepTitle = styled.div`
  font-family: 'demodernbold', sans-serif;
`;

export const ArrowWrapper = styled.div`
  width: 11px;
  display: inline-block;
  margin-top: 15px;
`;
