import React, { useEffect, useState } from 'react';
import { Container, Toggle } from './styles';

export default function ToggleButton({ active, disabled, onToggle = () => {} }) {
  const [isActive, setIsActive] = useState(active);

  const handleOnToggle = () => {
    setIsActive(!isActive);
    onToggle(!isActive);
  };

  useEffect(() => {
    if (isActive != active) setIsActive(active);
  }, [active]);

  return (
    <Container isActive={isActive} disabled={disabled} onClick={handleOnToggle}>
      <Toggle isActive={isActive} />
    </Container>
  );
}
