import { createTheme } from '@material-ui/core/styles';

import { fontFamily } from 'common/text';
import { colors, lgBaseColors } from 'uiLivi/styles';

export const datepickerTheme = createTheme({
  typography: {
    fontFamily: fontFamily,
  },

  palette: {
    primary: {
      main: '#fff',
      contrastText: lgBaseColors.white,
    },
  },
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        backgroundColor: colors.background.highlight,
      },
    },
    MuiSvgIcon: {
      root: {
        fill: colors.font.strong,
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: 'space-between',
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        backgroundColor: 'transparent', //colors.background.highlight,
      },
      dayLabel: {
        color: lgBaseColors.grey.light,
      },
      daysHeader: {
        justifyContent: 'space-between',
      },
      switchHeader: {
        marginBottom: '4.5%',
      },
    },
    MuiPickersBasePicker: {
      containerLandscape: {
        flexDirection: 'column',
      },
      pickerView: {
        maxWidth: 'initial',
        padding: '8px 24px',
      },
      pickerViewLandscape: {
        padding: '8px 24px',
      },
    },
    MuiPickersDay: {
      day: {
        margin: 0,
        color: colors.font.light,
        '&:hover': {
          backgroundColor: lgBaseColors.grey.dark,
        },
      },
      dayDisabled: {
        color: lgBaseColors.grey.medium,
      },
      current: {
        backgroundColor: colors.background.highlight,
      },
      daySelected: {
        backgroundColor: colors.accent.base,
        '&:hover': {
          backgroundColor: colors.accent.highlight,
        },
      },
    },
  },
});
