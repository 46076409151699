import { Tab, TabIconContainer, TabNavigationContainer, TabTextContainer } from './styles';

export default function TabNavigation({ tabs, activeTab, setActiveTab }) {
  return (
    <TabNavigationContainer>
      {tabs.map((tab, i) => (
        <Tab
          isHidden={tab.isHidden}
          key={i}
          totalTabs={tabs.length}
          active={activeTab === tab.id}
          onClick={() => !tab.isHidden && setActiveTab(tab.id)}
        >
          <TabIconContainer>{tab.icon && <tab.icon />}</TabIconContainer>
          <TabTextContainer>{tab.title}</TabTextContainer>
        </Tab>
      ))}
    </TabNavigationContainer>
  );
}
