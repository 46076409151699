import React, { useRef, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';

import fromCdn from 'utilities/cdn';

import useModelAnimation from './useModelAnimation';
import karnobatConfig from '../config/karnobatConfig';

/** MODEL PARSED IN https://gltf.pmnd.rs/ */
export default React.memo(({ onMovementStart, onMovementEnd, onSupportOut, onSupportIn }) => {
  const { nodes, materials } = useGLTF(fromCdn(`/shared/districts/intersolar/weather/karnobat/index.glb`));
  const { animations, ...additionalModel } = useGLTF(
    fromCdn(`/shared/districts/intersolar/weather/karnobat/additional.glb`)
  );

  const rotatingMeshRef = useRef();
  const additionalRef = useRef();
  const staticRef = useRef();

  useModelAnimation({
    weatherRanges: karnobatConfig.weather,
    sunTrackerRanges: karnobatConfig.sunTracker,
    animations,
    rotatingMesh: rotatingMeshRef,
    additionalMesh: additionalRef,
    additionalMaterials: additionalModel.materials,
    onMovementStart,
    onMovementEnd,
    onSupportOut,
    onSupportIn,
  });

  useEffect(() => {
    staticRef.current.traverse(el => {
      if (el.isObject3D) {
        el.matrixAutoUpdate = false;
        el.updateMatrix();
      }
    });
  }, []);

  return (
    <group position={[-0.078, 1.25, 44.699]} rotation={[0, 2.33874, 0]} scale={[0.9, 0.9, 0.9]} name="Karnobat Group">
      <primitive ref={additionalRef} object={additionalModel.scene} />
      <group ref={staticRef}>
        <mesh
          castShadow
          receiveShadow
          ref={rotatingMeshRef}
          name="upper_geo"
          geometry={nodes.upper_geo.geometry}
          material={materials.aimetal}
          position={[-0.02, 1.6, 0]}
        >
          <mesh
            castShadow
            receiveShadow
            name="panels_geo_1"
            geometry={nodes.panels_geo_1.geometry}
            material={materials.aigreyplastic}
            renderOrder={20}
          />
          <mesh
            castShadow
            receiveShadow
            name="panels_geo_2"
            geometry={nodes.panels_geo_2.geometry}
            material={materials.aisolar}
            renderOrder={20}
          />
        </mesh>
        <mesh
          castShadow
          receiveShadow
          name="lower_geo"
          geometry={nodes.lower_geo.geometry}
          material={materials.aimetal}
        />
      </group>
    </group>
  );
});
