import { usePostStore } from 'three/Renderer/store';
import { useThree } from '@react-three/fiber';

import { CameraHelper, Color, DirectionalLight, PCFSoftShadowMap } from 'three';
import React, { useEffect, useMemo, useState } from 'react';
import { useEnvironmentStore } from '../store';

export function renderShadowOnce(gl, scene, camera) {
  const lastAutoUpdate = gl.shadowMap.autoUpdate;

  gl.shadowMap.autoUpdate = true;
  gl.shadowMap.needsUpdate = true;
  const players = scene.getObjectByName('Players');
  const products = scene.getObjectByName('Products');
  const lastVisiblePlayers = players?.visible;
  const lastVisibleProducts = products?.visible;
  players && (players.visible = false);
  products && (products.visible = false);
  gl.render(scene, camera);
  players && (players.visible = lastVisiblePlayers);
  products && (products.visible = lastVisibleProducts);

  gl.shadowMap.autoUpdate = lastAutoUpdate;
}

export default function Lights() {
  const env = useEnvironmentStore(state => state.environmentConfiguration);
  const shadow = usePostStore(state => state.renderConfiguration).shadow;
  const { gl, camera, scene } = useThree();

  const [dirLightWithShadow, setDirLightWithShadow] = useState(null);

  useEffect(() => {
    gl.shadowMap.type = PCFSoftShadowMap;
    gl.shadowMap.enabled = true;

    const dirLightWithShadow = new DirectionalLight();
    setDirLightWithShadow(dirLightWithShadow);
  }, []);

  useEffect(() => {
    if (!dirLightWithShadow) return;
    const cameraHelperEnabled = false;
    if (cameraHelperEnabled) {
      const helper = new CameraHelper(dirLightWithShadow.shadow.camera);
      scene.add(helper);
      return () => {
        scene.remove(helper);
      };
    }
  }, [dirLightWithShadow]);

  useEffect(() => {
    if (!dirLightWithShadow) return;

    gl.shadowMap.autoUpdate = shadow.enabled;

    dirLightWithShadow.intensity = env.dirLightWithShadow.intensity;
    dirLightWithShadow.color = new Color(env.dirLightWithShadow.color);
    dirLightWithShadow.name = 'dirLightWithShadow';

    const { sky } = env;

    const distance = 1000;
    const x = distance * Math.sin(sky.inclination) * Math.cos(sky.azimuth);
    const y = distance * Math.cos(sky.inclination);
    const z = distance * Math.sin(sky.inclination) * Math.sin(sky.azimuth);
    dirLightWithShadow.position.set(Math.round(x), Math.round(y), Math.round(z));

    dirLightWithShadow.castShadow = true;
    const size = 60;
    dirLightWithShadow.shadow.camera.near = 0;
    dirLightWithShadow.shadow.camera.far = 2000;
    dirLightWithShadow.shadow.camera.left = -size;
    dirLightWithShadow.shadow.camera.right = size;
    dirLightWithShadow.shadow.camera.bottom = -size;
    dirLightWithShadow.shadow.camera.top = size;
    dirLightWithShadow.shadow.camera.updateProjectionMatrix();
    dirLightWithShadow.shadow.bias = shadow.bias / 100000;

    //init and resize shadow map
    dirLightWithShadow.shadow.mapSize.width = shadow.size;
    dirLightWithShadow.shadow.mapSize.height = shadow.size;
    if (dirLightWithShadow.shadow.map && dirLightWithShadow.shadow.map.width !== shadow.size) {
      dirLightWithShadow.shadow.map.setSize(shadow.size, shadow.size);
    }

    renderShadowOnce(gl, scene, camera);
  }, [dirLightWithShadow, env, shadow]);

  if (!dirLightWithShadow) return null;

  return (
    <>
      <primitive object={dirLightWithShadow} />
    </>
  );
}
