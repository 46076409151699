import styled from 'styled-components';
import { H3 } from 'common/text';
import { colors } from 'uiLivi/styles';
import { menuPaddingPx } from '../styles';

export const Headline = styled(H3)`
  margin-top: 0;
`;

export const BodyContainer = styled.div`
  padding: ${menuPaddingPx};
`;

export const Link = styled.span.attrs(props => ({
  'data-sound-action': 'click',
}))`
  cursor: pointer;
  text-decoration: underline;
  color: ${colors.accent.base};
  font-weight: bold;
`;
