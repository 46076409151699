import styled from 'styled-components';
import { colors, easings, lgBaseColors } from 'uiLivi/styles';
import IconLive from 'assets/icons/Icon_Live.svg';
import { iconFillAndStroke } from 'common/icon';
import { hexToRgba } from 'utilities/color';
import { smallTextStyle } from '../../../common/text';

export const PinWrapper = styled.div`
  transform-origin: center 117%;
  transform: scale(${props => 5 * props.scale});
  transition: opacity 0.3s ${easings.easeOutStrong};
  opacity: ${props => (props.visible ? 1 : 0)};
`;

export const StyledPin = styled.div`
  position: relative;
  transition: background 0.2s, box-shadow 0.2s ${easings.easeOutStrong};
  background: ${props => (props.hover ? colors.accent.highlight : colors.accent.base)};
  display: flex;
  border-radius: 23px;
  box-shadow: ${props =>
    props.hover ? `0 0 20px ${hexToRgba(colors.accent.base, 0.5)}` : `0 0 10px ${hexToRgba(colors.accent.base, 0.1)}`};
  > svg {
    ${props => iconFillAndStroke(colors.accent.base, props.hover ? colors.accent.highlight : colors.accent.base)};
  }
`;

export const LiveBadge = styled.div`
  position: absolute;
  right: 19px;
  top: -13px;
  width: 48px;
  height: 20px;
  line-height: 20px;
  background-color: ${lgBaseColors.red};
  box-shadow: 0 0 20px ${hexToRgba(lgBaseColors.red.base, 0.75)};
  ${smallTextStyle};
  text-align: center;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  text-transform: uppercase;
  backface-visibility: hidden;
  padding-right: 2px;
`;

export const LiveIcon = styled(IconLive)`
  position: relative;
  top: 1px;
  height: 9px;
  margin-right: 3px;
  transform: scale3d(1, 1, 1);
`;

export const StyledPinIcon = styled.div`
  margin: 8px;
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: transform 0.2s ${easings.easeOutStrong};
  transform: scale(${props => (props.hover ? 1.1 : 1.0)});

  svg {
    top: 0;
    width: 32px;
    height: 32px;
    transform: scale(0.65);
    ${iconFillAndStroke(colors.accent.base, colors.accent.base)};
  }
`;

export const PinContent = styled.div`
  left: 48px;
  transition: width 0.6s ${easings.easeOutStrong}, opacity 0.3s ${easings.easeOutStrong},
    margin 0.6s ${easings.easeOutStrong};
  opacity: ${props => `${props.isOpen ? 1 : 0}`};
  margin: ${props => (props.isOpen ? `8px 12px 8px 0` : `8px 0`)};
  white-space: nowrap;
  width: ${props => (props.isOpen ? props.textWidth : 0)}px;
  overflow: hidden;
  color: white;
  backface-visibility: hidden;
`;

export const PinHeadline = styled.div`
  font-weight: bold;
`;

export const PinSubline = styled.div`
  margin-top: -4px;
  ${smallTextStyle};
  line-height: 16px;
`;

export const eckeStyle = {
  position: 'absolute',
  left: '50%',
  marginLeft: '-11px',
  top: '46px',
};
