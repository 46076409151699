import styled from 'styled-components';
import IconError from 'assets/icons/Icon_Error.svg';
import { StyledInput } from 'common/components/Input';
import { iconStyle } from '../../icon';

export const Container = styled.div`
  display: flex;
  position: relative;
  margin: 15px 0;
`;

export const ErrorIcon = styled(IconError)`
  position: absolute;
  left: -20px;
  top: 18px;
`;

export const EyeToggle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 50px;
  border-left-width: 0;
  cursor: pointer;
`;

export const StyledIconEye = styled.div`
  position: absolute;
  left: 15px;
  top: 15px;
  transform: scale(1.3);
  opacity: ${props => (props.passwordVisible ? 0.5 : 1)};
  svg {
    ${iconStyle}
  }
`;

export const StyledPasswordInput = styled(StyledInput)`
  margin: 0;
`;
