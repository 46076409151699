import { InstructionHeadline, InstructionText, InstructionTopline, InstructionWrapper } from '../../../styles';
import React from 'react';

export default function OnboardingHost() {
  return (
    <InstructionWrapper>
      <InstructionTopline>Get Started</InstructionTopline>
      <InstructionHeadline>Test your audio before speaking</InstructionHeadline>
      <InstructionText>
        Select the <p style={{ display: 'inline', fontWeight: 'bold' }}>test auditorium</p> in your locations to make
        sure your audio works correctly.
        <br />
        <br />
        Only hosts can see and access the test auditorium.
      </InstructionText>
    </InstructionWrapper>
  );
}
