import React, { useMemo } from 'react';
import Clouds from './Clouds';

export default function CloudsStage({ data }) {
  const nodes = useMemo(() => {
    return data.specs.map((el, i) => {
      return <Clouds data={el} id={data.id} key={i} />;
    });
  }, []);

  return <>{nodes}</>;
}
