import styled from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';

export const Container = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: relative;
  width: 34px;
  height: 18px;
  background-color: ${props => (props.isActive ? colors.accent.base : lgBaseColors.grey.light)};
  border-radius: 9px;
  padding: 2px;
  transition: background-color 0.2s ease-in-out;
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: pointer;
`;

export const Toggle = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  background-color: ${lgBaseColors.white};
  box-shadow: 0 2px 5px rgba(44, 52, 73, 0.1);
  border-radius: 50%;
  transform: translate3d(${props => (props.isActive ? 16 : 0)}px, 0, 0);
  transition: transform 0.1s ease-in-out;
`;
