import create from 'utilities/zustand/create';

const presets = {
  schletterLobby: {
    fog: { density: 0, color: '#353b64' },
    dirLightWithShadow: { color: '#ffffff', intensity: 0 },
    camera: { fov: 50 },
    sky: { enabled: true, azimuth: 2.738, inclination: -0.649, mixValue: 0 },
    water: { enabled: false, seaLevel: 5.179 },
    lightmap: { intensity: 1.39 },
    envMap: { intensity: 2, intensityLightMaps: 0.636 },
  },

  schletterTestcenter: {
    fog: { density: 0, color: '#353b64' },
    dirLightWithShadow: { color: '#FCEEF8', intensity: 0.705 },
    camera: { fov: 50 },
    sky: { enabled: true, azimuth: 1.317, inclination: -0.649, mixValue: 0.164 },
    water: { enabled: false, seaLevel: 0 },
    lightmap: { intensity: 0.35 },
    envMap: { intensity: 1, intensityLightMaps: 0.1 },
  },

  schletter_house_textured: {
    fog: { density: 0, color: '#353b64' },
    dirLightWithShadow: { color: '#f1f9ff', intensity: 0.924 },
    camera: { fov: 50 },
    sky: { enabled: true, azimuth: -1.935, inclination: -0.474, mixValue: 0 },
    water: { enabled: false, seaLevel: 12.762 },
    lightmap: { intensity: 1.55 },
    envMap: { intensity: 1.201, intensityLightMaps: 0 },
  },

  schletterConstruction: {
    fog: { density: 0, color: '#353b64' },
    dirLightWithShadow: { color: '#ffffff', intensity: 0.4 },
    camera: { fov: 50 },
    sky: { enabled: true, azimuth: 2.738, inclination: -0.649, mixValue: 0 },
    water: { enabled: false, seaLevel: 5.179 },
    lightmap: { intensity: 1.37 },
    envMap: { intensity: 5, intensityLightMaps: 0.49 },
  },

  lobby: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ffecbb',
      intensity: 0.6,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: true,
      azimuth: -0.929,
      inclination: -1.039,
      mixValue: 0.248,
    },
    water: {
      enabled: true,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 0.85,
    },
    envMap: {
      intensity: 0.8,
      intensityLightMaps: 0.1,
    },
  },
  auditorium: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#92BFF1',
      intensity: 0.8,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: false,
      azimuth: -1.559,
      inclination: 0.6,
      mixValue: 0.5,
    },
    water: {
      enabled: false,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.4,
      intensityLightMaps: 0.15,
    },
  },
  fittingRoom: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ff9a44',
      intensity: 0.6,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: false,
      azimuth: 0.787,
      inclination: 0.4,
      mixValue: 0.248,
    },
    water: {
      enabled: false,
      seaLevel: -5.5,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.7,
      intensityLightMaps: 0.15,
    },
  },
  showroom: {
    fog: {
      density: 0,
      color: '#353b64',
    },
    dirLightWithShadow: {
      color: '#ffecbb',
      intensity: 0.6,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: true,
      azimuth: 1.94,
      inclination: 0.814,
      mixValue: 0.0,
    },
    water: {
      enabled: true,
      seaLevel: -0.6,
    },
    lightmap: {
      intensity: 1,
    },
    envMap: {
      intensity: 0.5,
      intensityLightMaps: 0.1,
    },
  },
  modulrLobby: {
    fog: {
      density: 0.001,
      color: '#e6d2c7',
    },
    dirLightWithShadow: {
      color: '#DCDCDC',
      intensity: 1.5,
    },
    camera: {
      fov: 400,
    },
    sky: {
      enabled: true,
      azimuth: 1.41340734641021,
      inclination: 0.736,
      mixValue: -3,
    },
    water: {
      enabled: true,
      seaLevel: -5.32,
    },
    lightmap: {
      intensity: 1.1,
    },
    envMap: {
      intensity: 1,
      intensityLightMaps: 0.1,
    },
  },
  modulrLobbyDebug: {
    fog: {
      density: 0.001,
      color: '#e6d2c7',
    },
    dirLightWithShadow: {
      color: '#DCDCDC',
      intensity: 1.5,
    },
    camera: {
      fov: 400,
    },
    sky: {
      enabled: true,
      azimuth: 1.41340734641021,
      inclination: 0.736,
      mixValue: -3,
    },
    water: {
      enabled: true,
      seaLevel: -5.32,
    },
    lightmap: {
      intensity: 1.1,
    },
    envMap: {
      intensity: 1,
      intensityLightMaps: 0.1,
    },
  },
  modulrAuditorium: {
    fog: {
      density: 0,
      color: '#353B64',
    },
    dirLightWithShadow: {
      color: '#DCDCDC',
      intensity: 0.6,
    },
    camera: {
      fov: 50,
    },
    sky: {
      enabled: true,
      azimuth: -0.71,
      inclination: -0.765,
      mixValue: -3,
    },
    water: {
      enabled: true,
      seaLevel: -5.32,
    },
    lightmap: {
      intensity: 0.86,
    },
    envMap: {
      intensity: 1,
      intensityLightMaps: 0.136,
    },
  },
};

export const useEnvironmentStore = create(set => ({
  presets: presets,
  environmentConfiguration: JSON.parse(JSON.stringify(presets.lobby)),

  set: environmentConfiguration => {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(environmentConfiguration, null, 2));
    set({ environmentConfiguration: JSON.parse(JSON.stringify(environmentConfiguration)) });
  },

  setPresetByName: name => {
    set(state => {
      if (state.presets[name]) {
        return { environmentConfiguration: JSON.parse(JSON.stringify(state.presets[name])) };
      } else {
        return state;
      }
    });
  },
}));
