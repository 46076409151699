import Storage from 'utilities/storage';

const TOKEN = 'token'; // JSON Web Token
const QUALITY = 'QUALITY11';
const ASSET_DETAIL_IS_MOBILE = 'ASSET_DETAIL_IS_MOBILE';
const CONTROL_SCHEMA_ID = 'control_schema_index';
const MEETING_RESERVATION_ID_FROM_URL = 'meeting_reservation_id_from_url';
const SOUND_ENABLED = 'SOUND_ENABLED';
const ONBOARDING_DONE = 'ONBOARDING_DONE2';
const storage = new Storage();
storage.init('live_user_1');
storage.defineEntry(TOKEN, null);
storage.defineEntry(CONTROL_SCHEMA_ID, 0);
storage.defineEntry(SOUND_ENABLED, true);
storage.defineEntry(ONBOARDING_DONE, false, 1000 * 60 * 60 * 24);

const sessionStorage = new Storage({ type: 'session' });
sessionStorage.init('live_user_1');
sessionStorage.defineEntry(TOKEN, null);
sessionStorage.defineEntry(MEETING_RESERVATION_ID_FROM_URL, null);

export default {
  setToken: token => {
    storage.setItem(TOKEN, token);
    sessionStorage.setItem(TOKEN, token);
  },
  getToken: () => {
    return sessionStorage.getItem(TOKEN) || storage.getItem(TOKEN);
  },
  initQualitySetting: quality => {
    storage.defineEntry(QUALITY, quality);
  },
  setQuality: q => {
    storage.setItem(QUALITY, q);
  },
  getQuality: () => {
    return storage.getItem(QUALITY);
  },
  initAssetDetailIsMobile: isMobile => {
    storage.defineEntry(ASSET_DETAIL_IS_MOBILE, isMobile);
  },
  setAssetDetailIsMobile: isMobile => {
    storage.setItem(ASSET_DETAIL_IS_MOBILE, isMobile);
  },
  getAssetDetailIsMobile: () => {
    return storage.getItem(ASSET_DETAIL_IS_MOBILE);
  },
  setSoundEnabled: q => {
    storage.setItem(SOUND_ENABLED, q);
  },
  getSoundEnabled: () => {
    return storage.getItem(SOUND_ENABLED);
  },
  setControlSchemaId: q => {
    storage.setItem(CONTROL_SCHEMA_ID, q);
  },
  getControlSchemaId: () => {
    return storage.getItem(CONTROL_SCHEMA_ID);
  },
  setMeetingReservationId: id => {
    sessionStorage.setItem(MEETING_RESERVATION_ID_FROM_URL, id);
  },
  getMeetingReservationId: () => {
    return sessionStorage.getItem(MEETING_RESERVATION_ID_FROM_URL);
  },
  setOnboardingDone: () => {
    storage.setItem(ONBOARDING_DONE, true);
  },
  getOnboardingDone: () => {
    return storage.getItem(ONBOARDING_DONE);
  },
};
