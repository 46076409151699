import create from 'utilities/zustand/create';
import { useTheaterStore } from 'services/TheaterService';
import { useNotificationStore } from 'services/NotificationService';
import Toast from 'common/components/Toast';

import { useUserStore } from 'services/UserService';

let socket = null;
export const useTalkStore = create((set, get) => ({
  talks: [],

  async init(managedSocket) {
    socket = managedSocket;
    await get().poll();
  },

  update: talks => {
    const language = useUserStore.getState().user.language || 'en_US';
    const localTalks = talks.filter(talk => !talk.language || talk.language === language);
    const theaterTalks = localTalks.filter(talk => talk.location === 'stage01');
    useTheaterStore.setState({ theaterTalks });
    set({ talks: [...localTalks] });
  },

  poll: () => {
    return new Promise(resolve => {
      socket.emit('talk/list', talks => {
        //console.log('talk/list', talks);
        get().update(talks);
        resolve();
      });
    });
  },

  bookmark: (talkId, value) => {
    return new Promise(resolve => {
      socket.emit('talk/bookmark', talkId, value, (/*result*/) => {
        //console.log('talk/bookmark', result);
        const { talks, update } = get();
        const talk = talks.find(talk => talk.id === talkId);
        if (talk != null) {
          talk.isBookmarked = value;
          update(talks);
        }
        // TODO still necessary to bookmark theaterTalks seperately?
        const { theaterTalks } = useTheaterStore.getState();
        const theaterTalk = theaterTalks.find(talk => talk.id === talkId);
        if (theaterTalk != null) {
          const theaterTalkIndex = theaterTalks.findIndex(talk => talk.id === talkId);
          theaterTalks[theaterTalkIndex] = { ...theaterTalk, isBookmarked: value };
          useTheaterStore.setState({ theaterTalks: [...theaterTalks] });
        }
        resolve();
      });
    });
  },

  create: value => {
    return new Promise(resolve => {
      socket.emit('talk/create', value, result => {
        const { statusCode } = result;
        if (statusCode === 200) {
          const data = result.result;
          const { talks, update } = get();
          update([...talks, data]);
          resolve();
        } else {
          const error = result.message;
          const toast = <Toast level={'error'} position={'bottom'} headline={'Error'} text={error} />;
          useNotificationStore.getState().addNotification(toast);
        }
      });
    });
  },
  cancel: id => {
    return new Promise(resolve => {
      socket.emit('talk/cancel', id, result => {
        const { statusCode } = result;
        if (statusCode === 200) {
          const { talks, update } = get();
          update(talks.filter(t => t.id !== id));
          resolve();
        } else {
          const error = result.message;
          const toast = <Toast level={'error'} position={'bottom'} headline={'Error'} text={error} />;
          useNotificationStore.getState().addNotification(toast);
        }
      });
    });
  },
}));
