import React, { useMemo } from 'react';
import { useAvatarStore } from 'services/AvatarService';
import { BarProgress, OptionIndicatorWrapper, Bar } from './styles';

const OptionIndicator = () => {
  const selectedCategory = useAvatarStore(state => state.getSelectedCategory());
  const selectedOptionIndex = useAvatarStore(state => state.getSelectedOptionIndex());
  const optionCount = useAvatarStore(state => state.getOptionCount());

  const progress = useMemo(() => (selectedOptionIndex + 1) / optionCount, [optionCount, selectedOptionIndex]);

  return (
    <OptionIndicatorWrapper>
      {!!optionCount && (
        <>
          <Bar>
            <BarProgress progress={progress} />
          </Bar>
          {selectedOptionIndex + 1}/{optionCount}
        </>
      )}
    </OptionIndicatorWrapper>
  );
};

export default OptionIndicator;
