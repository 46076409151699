import { usePlayAmbientHowls } from './hooks';
import { useEffect, useRef, useState } from 'react';
import { useWeatherStore } from '../Play/WeatherScene/store';
import { CHARTS_SUN } from '../Play/WeatherScene/WeatherUI/TextDisplay/config';

const sunSounds = {
  morning: 'ambient_weather_sun-1',
  noon: 'ambient_weather_sun-2',
  evening: 'ambient_weather_sun-3',
};

export default function SunHandler() {
  const requestRef = useRef(null);

  const [sunSound, setSunSound] = useState(null);

  const update = () => {
    const sliderProgress = useWeatherStore.getState().sliderProgress;

    let nextSunSound = null;
    for (let i = 0; i < CHARTS_SUN.length; i++) {
      const range = CHARTS_SUN[i].range;
      if ((sliderProgress === 0 && range[0] === 0) || (range[0] < sliderProgress && sliderProgress <= range[1])) {
        nextSunSound = sunSounds[CHARTS_SUN[i].sound];
        break;
      }
    }
    setSunSound(nextSunSound);
    requestRef.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  usePlayAmbientHowls([sunSound]);

  return null;
}
