import { useAvatarStore } from 'services/AvatarService';
import Indicator from '../../Indicator';
import { ButtonContainer, Icon, IconContainer, TextContainer, WhiteBox } from './styles';

export default function CategoryButton({ category, translated }) {
  const selectCustomizationCategory = useAvatarStore(state => state.selectCustomizationCategory);
  return (
    <ButtonContainer
      onClick={() => {
        selectCustomizationCategory(category.id, !category.selected);
      }}
    >
      <WhiteBox selected={category.selected} />
      <IconContainer>
        <Icon src={category.icon} />
        <Indicator showCheckIcon={category.customized} />
      </IconContainer>
      <TextContainer selected={category.selected}>{translated}</TextContainer>
    </ButtonContainer>
  );
}
