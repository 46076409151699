import { usePlayerStore } from 'services/PlayerService';
import { useUserStore } from 'services/UserService';
import create from 'utilities/zustand/create';

let socket = null;

const checkThreshold = (pos, oldPos, threshold) => {
  const x = Math.abs(pos[0]) - Math.abs(oldPos[0]);
  const z = Math.abs(pos[2]) - Math.abs(oldPos[2]);
  return Math.abs(x) < threshold && Math.abs(z) < threshold;
};

export const globalPlayers = {};
const localMic = {
  level: 0,
};

let lastSendMs = 0;

export const useGlobalHubStore = create((set, get) => ({
  userIds: [],
  users: {},
  ivId: 0,
  emoji: null,
  dirty: false,
  appearance: null,
  init: managedSocket => {
    socket = managedSocket;

    socket.on('globalHub/receiveEmoji', emoji => {
      set({ emoji });
    });

    socket.on('globalHub/receive', data => {
      const globalPlayerIsNew = globalPlayers[data.userId] === undefined;

      let equalExistingAppearance = null;
      if (!globalPlayerIsNew) {
        const currentAppearance = globalPlayers[data.userId].appearance;
        const newAppearance = data.appearance;
        if (JSON.stringify(currentAppearance) === JSON.stringify(newAppearance)) {
          equalExistingAppearance = { appearance: currentAppearance };
        }
      }
      globalPlayers[data.userId] = { ...data, ...equalExistingAppearance, lastSeenMs: Date.now() };

      if (globalPlayerIsNew) {
        set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
      }

      set({ users: { ...globalPlayers } });
    });

    socket.on('globalHub/disconnect', id => {
      delete globalPlayers[id];
      set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
      set({ users: { ...globalPlayers } });
    });

    const ivId = setInterval(() => {
      if (!useUserStore.getState().user) return;

      // delete timeout global players
      const tenSecondsAgo = Date.now() - 3000;
      Object.values(globalPlayers)
        .filter(p => p.lastSeenMs < tenSecondsAgo)
        .forEach(p => {
          delete globalPlayers[p.userId];
          set({ userIds: Object.keys(globalPlayers).map(a => Number(a)) });
        });

      // mark some warning
      const userId = useUserStore.getState().user.id;
      const isExpert = useUserStore.getState().user.role.type === 'expert';
      if (isExpert && !globalPlayers[userId]) {
        // eslint-disable-next-line no-console
        console.warn('Not receiving from global hub');
      }

      set({ dirty: true });
      set({ users: globalPlayers });
      get().transmitPlayerState();
    }, 1000);

    set({ ivId });
  },
  exit: () => {
    clearInterval(get().ivId);
  },
  setMicLevel: (userId, micLevel) => {
    const { user } = useUserStore.getState();
    const isSelf = String(user?.id) === String(userId);
    if (isSelf) {
      if (localMic.level !== micLevel) {
        localMic.level = micLevel;
        set({ dirty: true });
        get().transmitPlayerState();
      }
    }
  },
  getMicLevel: userId => {
    if (!globalPlayers[userId]) return null;

    const { micLevel } = globalPlayers[userId];
    return micLevel;
  },
  updatePosition: () => {
    const user = useUserStore.getState().user;
    if (user.role.type !== 'expert') return;

    const { position, rotation, velocity } = usePlayerStore.getState();

    const host = get().users[user.id];
    if (host) {
      const belowThreshold = checkThreshold(position, host.position, 0.0001);
      if (belowThreshold) return;

      host.position = position;
      host.rotation = rotation;
      host.velocity = velocity;
      if (host.reaction !== 0) host.reaction = 0;

      set({ dirty: true });
      get().transmitPlayerState();
    }
  },
  refreshGlobalPlayer: () => {
    set({ dirty: true });
    lastSendMs = 0;
    get().transmitPlayerState();
  },
  transmitPlayerState: () => {
    const { user, player } = useUserStore.getState();
    const { id: userId, role, forename, surname } = user;
    const appearance = player.user.appearance;
    const isGlobalPlayer = role.type === 'expert';
    if (!isGlobalPlayer) {
      return;
    }
    const { position, rotation, velocity, reaction, input } = usePlayerStore.getState();
    const { dirty } = get();
    const data = {
      forename,
      surname,
      userId,
      appearance,
      position,
      rotation,
      velocity,
      reaction,
      input,
    };

    if (Date.now() - lastSendMs > 50) {
      lastSendMs = Date.now();
      if (dirty) {
        set({ dirty: false });
        socket.emit('globalHub/transmit', data);
      }
    }
  },
}));
