import styled, { css } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useWeatherStore } from '../../store';
import { CHARTS_SUN, CHARTS_WEATHER } from './config';
import { mediaMax } from '../../../../../common/layout';
import { hexToRgba } from '../../../../../utilities/color';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 140px;
  height: 120px;
  padding: 0 24px;

  ${mediaMax(
    'medium',
    css`
      align-items: flex-start;
      justify-content: flex-start;
      width: 50%;
    `
  )};

  ${mediaMax(
    'small',
    css`
      top: 115px;
      align-items: flex-start;
      justify-content: flex-start;
      width: 50%;
    `
  )};
`;

const Chart = styled.div`
  position: absolute;
  font-family: 'AzoSansLight', serif;
  font-size: 20px;
  line-height: 25px;
  max-width: 500px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 0 4px ${hexToRgba('#ffffff', 0.6)}, 0 0 4px ${hexToRgba('#00676B', 1)};
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  transition-delay: ${props => (props.visible ? 0.2 : 0)}s;

  ${mediaMax(
    'medium',
    css`
      text-align: left;
    `
  )};

  ${mediaMax(
    'small',
    css`
      font-size: 14px;
      line-height: 18px;
      max-width: 200px;
    `
  )};
`;

export default function TextDisplay() {
  const requestRef = useRef(null);
  const [chartIndex_weather, setChartIndex_weather] = useState(0);
  const [chartIndex_sun, setChartIndex_sun] = useState(0);
  const isWeather = useWeatherStore(state => state.isWeather);

  const update = () => {
    const sliderProgress = useWeatherStore.getState().sliderProgress;

    let index_weather = 0;
    for (let i = 0; i < CHARTS_WEATHER.length; i++) {
      const range = CHARTS_WEATHER[i].range;
      if (sliderProgress > range[0] && sliderProgress <= range[1]) {
        index_weather = i;
        break;
      }
    }
    setChartIndex_weather(index_weather);

    let index_sun = 0;
    for (let i = 0; i < CHARTS_SUN.length; i++) {
      const range = CHARTS_SUN[i].range;
      if (sliderProgress > range[0] && sliderProgress <= range[1]) {
        index_sun = i;
        break;
      }
    }
    setChartIndex_sun(index_sun);

    requestRef.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <Wrapper>
      {CHARTS_WEATHER.map((chart, index) => {
        return (
          <Chart key={index} visible={index === chartIndex_weather && isWeather}>
            {chart.copy}
          </Chart>
        );
      })}
      {CHARTS_SUN.map((chart, index) => {
        return (
          <Chart key={index} visible={index === chartIndex_sun && !isWeather}>
            {chart.copy}
          </Chart>
        );
      })}
    </Wrapper>
  );
}
