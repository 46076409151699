import { useDatGuiStackStore } from 'components/DevContent/DatGuiStack/store';
import { Item } from '../styles';

export default function DatGuiPanel() {
  const { stack, selectedIndex } = useDatGuiStackStore(state => state);
  return (
    <>
      {stack.map((o, i) => {
        return (
          <Item
            key={i}
            selected={i === selectedIndex}
            onClick={() => {
              useDatGuiStackStore.getState().setSelectedIndex(i);
            }}
          >
            {o.label}
          </Item>
        );
      })}
    </>
  );
}
