import { useChatStore } from 'services/ChatService';
import styled, { css } from 'styled-components';
import { lgBaseColors, zIndex } from 'uiLivi/styles';
import { useUserStore } from 'services/UserService';
import Person from 'common/components/Person';
import { smallTextStyle, textOverflowEllipsis } from 'common/text';
import { mediaMin } from 'common/layout';

const chatBarPadding = '15px';

const StyledToast = styled.div`
  ${mediaMin(
    'medium',
    css`
      width: calc(320px - ${chatBarPadding});
    `
  )};
  padding: 24px;
  background: ${lgBaseColors.grey.dark};
  color: white;
  z-index: ${zIndex.chat + 2};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  ${smallTextStyle};
`;

const StyledHeadline = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  ${smallTextStyle};
  ${textOverflowEllipsis};
  max-width: 200px;
`;

const StyledUserDescription = styled.div`
  color: ${lgBaseColors.grey.light};
  ${smallTextStyle}
`;

const StyledToastText = styled.div`
  margin-top: 16px;
  line-height: 22px;
`;

const ChatToast = ({ message }) => {
  const { user, text, chat, id } = message || {};
  const sender = useUserStore(state => state.users.find(u => u.id === user));
  const { forename, surname, role } = sender || {};
  const senderUserName = forename ? forename : 'unknown' + surname ? surname : '';
  const hasProfilePic = sender && sender.profilePicture;

  const openChat = async () => {
    const chatToEnter = useChatStore.getState().findById(chat);
    useChatStore.getState().setIsActive(true);
    useChatStore.getState().enter(chatToEnter);
  };

  return (
    <StyledToast key={id} onClick={openChat}>
      <Person
        image={{ src: hasProfilePic, size: 'm' }}
        infoComponents={[
          <StyledHeadline key={'headline'}>New message from {senderUserName}</StyledHeadline>,
          <StyledUserDescription key={'userjob'}>{role && role.name}</StyledUserDescription>,
        ]}
      />
      <StyledToastText>{text}</StyledToastText>
    </StyledToast>
  );
};

export default ChatToast;
