import { useEffect, useRef, useState } from 'react';
import { useWindowStore } from '../../../../services/WindowService';
import { Title, Content, Copy, Header, Headline, Overlay, Wrapper } from './styles';

export default function AssemblyStep({ productData, instruction, show }) {
  const vh = useWindowStore(state => state.vh);
  const contentRef = useRef(null);
  const [hasFade, setHasFade] = useState(false);

  const updateFade = () => {
    const willHaveFade =
      contentRef.current.clientHeight < contentRef.current.scrollHeight &&
      contentRef.current.scrollTop + 2 < contentRef.current.scrollHeight - contentRef.current.clientHeight;

    if (willHaveFade !== hasFade) setHasFade(willHaveFade);
  };

  useEffect(updateFade, [vh, hasFade]);

  return (
    <>
      <Wrapper>
        <Overlay show={(instruction && show && 'true') || undefined} vh={vh}>
          <Header>
            <Title>{productData.name}</Title>
            <Headline>{instruction?.headline}</Headline>
          </Header>
          <Content
            $expanded={true}
            $hasFade={hasFade}
            animate={{ height: 'auto' }}
            ref={contentRef}
            onAnimationComplete={updateFade}
            onScroll={updateFade}
          >
            <Copy>{instruction?.copy}</Copy>
          </Content>
        </Overlay>
      </Wrapper>
    </>
  );
}
