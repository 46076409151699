// @ts-nocheck
import { useGLTF, useTexture } from '@react-three/drei';
import { ClampToEdgeWrapping, RepeatWrapping } from 'three';

import fromCdn from 'utilities/cdn';

export default function useAssets({ assets }) {
  const { nodes } = useGLTF(fromCdn(assets.model));
  const original = nodes[Object.keys(nodes)[1]]; // 0 is always scene
  const [dataTxt, noiseTxt] = useTexture(
    [fromCdn(assets.dataTexture), fromCdn('/shared/districts/intersolar/weather/trees/palm/noise2.jpg')],
    ([data, noise]) => {
      data.flipY = false;
      noise.wrapS = RepeatWrapping;
      noise.wrapT = ClampToEdgeWrapping;
    }
  );

  return {
    dataTxt,
    noiseTxt: noiseTxt,
    originalMaterial: original.material,
    originalGeometry: original.geometry,
  };
}
