import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MathUtils } from 'three';
import throttle from 'lodash-es/throttle';
import { gsap } from 'gsap';
import { useWeatherStore } from '../../store';
import { useDebugStore } from '../../../../../storage/debug';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import Icon_0 from './icons/icon-0.png';
import Icon_1 from './icons/icon-1.png';
import Icon_2 from './icons/icon-2.png';
import Icon_3 from './icons/icon-3.png';
import Icon_4 from './icons/icon-4.png';
import Icon_5 from './icons/icon-5.png';
import Icon_Sun_Half from './icons/icon_sun_half.png';
import IconMarker from './icons/marker.svg';
import IconMarkerDanger from './icons/marker-danger.svg';
import Polygon from './icons/polygon.svg';
import {
  DebugProgress,
  Dot,
  DotScala,
  DragHandle,
  GradientMask,
  GradientMaskSun,
  GradientSun,
  GradientWeather,
  HANDLE_SIZE,
  Icon,
  IconImage,
  IconImageSuntracker,
  Icons,
  IconsSuntracker,
  IconSuntracker,
  Line,
  MarkerPosition,
  Markers,
  PeakIndicator,
  Ping,
  PingContainer,
  SliderWrapper,
  SunIconPath,
  ToggleText,
  ToggleWrapperDesktop,
  ToggleWrapperMobile,
  Track,
  Value,
  Values,
  ValueScale,
  VisibilityWrapper,
  WrapperSuntrack,
  WrapperToggleText,
} from './styles';
import { mapLinear } from 'three/src/math/MathUtils';
import { useDeviceStore } from '../../../../../services/DeviceService';
import { useOnboardingStore } from '../../../../../services/OnboardingService';
import { useWindowStore } from '../../../../../services/WindowService';
import ToggleWeather from './ToggleWeather';
import { useHowlerStore } from '../../../../../services/HowlerService';

gsap.registerPlugin(MotionPathPlugin);

function Marker({ type = 'default', xp }) {
  return <MarkerPosition xp={xp}>{type === 'default' ? <IconMarker /> : <IconMarkerDanger />}</MarkerPosition>;
}

function IconsWind({ visible }) {
  return (
    <VisibilityWrapper visible={visible}>
      <Icon xp={4}>
        <IconImage src={Icon_0} />
      </Icon>
      <Icon xp={21}>
        <IconImage src={Icon_1} />
      </Icon>
      <Icon xp={40}>
        <IconImage src={Icon_2} />
      </Icon>
      <Icon xp={64}>
        <IconImage src={Icon_3} />
      </Icon>
      <Icon xp={80}>
        <IconImage src={Icon_4} />
      </Icon>
      <Icon xp={95}>
        <IconImage src={Icon_5} />
      </Icon>
    </VisibilityWrapper>
  );
}

function IconsSun({ visible }) {
  return (
    <VisibilityWrapper visible={visible}>
      <IconSuntracker xp={1.75}>
        <IconImageSuntracker src={Icon_Sun_Half} />
      </IconSuntracker>
      <IconSuntracker xp={98.5}>
        <IconImageSuntracker src={Icon_Sun_Half} />
      </IconSuntracker>
    </VisibilityWrapper>
  );
}

export default function WeatherSlider() {
  const setStoreProgress = useWeatherStore(state => state.setSliderProgress);
  const setSliderActive = useWeatherStore(state => state.setSliderActive);
  const isMobile = useDeviceStore(state => state.device.isMobile);
  const isPhone = useDeviceStore(state => state.device.isMobileOnly);
  const isWeather = useWeatherStore(state => state.isWeather);
  const setIsWeather = useWeatherStore(state => state.setIsWeather);
  const playClickUiSound = useHowlerStore(state => state.playClickUiSound);

  const windowWidth = useWindowStore(state => state.width);
  const [sliderWidth, setSliderWidth] = useState(680);

  const [isDragging, setIsDragging] = useState(false);
  const [pingActive, setPingActive] = useState(true);

  const slider = useRef(null);
  const gradientRef = useRef(null);
  const dragHandle = useRef(null);
  const dragHandleRect = useRef(null);
  const sunIcon = useRef(null);
  const tl = useRef(null);

  const dragPosition = useRef(0);
  const startPos = useRef(0);
  const startEventPos = useRef(0);

  const weatherProgressEnabled = useDebugStore(state => state.getWeatherProgressEnabled());
  const debugProgress = useRef(null);

  const requestRef = useRef(null);
  const indicator = useRef(null);

  const updateState = useCallback(
    throttle(progress => {
      setStoreProgress(progress);
    }, 200), // Adjust the throttle delay (in milliseconds) as per your needs
    []
  );

  const handleDrag = useCallback(e => {
    const sliderRange = slider.current.offsetWidth + HANDLE_SIZE - dragHandleRect.current.width;
    const event = e.targetTouches ? e.targetTouches[0] : e;

    dragPosition.current = startPos.current + (event.clientX - startEventPos.current);
    dragPosition.current = MathUtils.clamp(dragPosition.current, 0, sliderRange);

    const progress = dragPosition.current / sliderRange;
    if (weatherProgressEnabled) debugProgress.current.innerText = progress.toFixed(3);

    // THROTTLE THE STORE UPDATE
    updateState(progress);

    // MOVE THINGS
    gsap.set(dragHandle.current, { x: dragPosition.current });
    gsap.set(gradientRef.current, { width: progress * 100 + '%' });
    tl.current.progress(progress);
  }, []);

  const startDrag = e => {
    if (pingActive) setPingActive(false);
    setIsDragging(true);

    startPos.current = dragPosition.current;
    startEventPos.current = (e.targetTouches ? e.targetTouches[0] : e).clientX;

    dragHandleRect.current = dragHandle.current.getBoundingClientRect();
    useOnboardingStore.getState().startInactivityTimer();
    playClickUiSound();
  };

  const stopDrag = () => {
    setIsDragging(false);
    if (isDragging) playClickUiSound();
  };

  useEffect(() => {
    if (isWeather) {
      const sliderProgress = useWeatherStore.getState().sliderProgress;
      gsap.set(gradientRef.current, { width: sliderProgress * 100 + '%' });
    }
  }, [isWeather]);

  useEffect(() => {
    setSliderActive(isDragging);

    if (isDragging) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('touchmove', handleDrag);
      window.addEventListener('mouseup', stopDrag);
    } else {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('touchmove', handleDrag);
      window.removeEventListener('mouseup', stopDrag);
    }

    return () => {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('touchmove', handleDrag);
      window.removeEventListener('mouseup', stopDrag);
    };
  }, [isDragging]);

  const updateIndicator = () => {
    if (isPhone) return;

    const smoothedValues = useWeatherStore.getState().smoothedValues;
    const v =
      (smoothedValues.wind.kmSpeed +
        smoothedValues.noisyIntensity * 30 * (smoothedValues.sliderProgress * 0.8 + 0.2) +
        0.5) |
      0;
    const offset = Math.min(mapLinear(v, 0, 257, 0, 680), 680);
    indicator.current.style.transform = `translateX(${offset - 6}px)`;

    requestRef.current = requestAnimationFrame(updateIndicator);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(updateIndicator);

    return () => {
      setStoreProgress(0);
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  const createSuntrackTL = () => {
    if (tl.current) tl.current.kill();
    tl.current = gsap.timeline({ paused: true }).to(sunIcon.current, {
      duration: 1,
      ease: 'none',
      motionPath: {
        path: document.getElementById('suntrack'),
        align: document.getElementById('suntrack'),
        autoRotate: false,
        alignOrigin: [0.5, 0.5],
      },
    });
    const sliderProgress = useWeatherStore.getState().sliderProgress;
    tl.current.progress(sliderProgress + 0.01);
  };

  useEffect(() => {
    if (slider.current) {
      setSliderWidth(slider.current.clientWidth);
    }
    createSuntrackTL();
  }, [windowWidth, slider.current, tl.current]);

  return (
    <>
      {!isMobile && (
        <>
          <ToggleWrapperDesktop>
            <WrapperToggleText>
              <ToggleText>{isWeather ? 'Hurricane Simulation' : 'Sun Adaptation'}</ToggleText>
            </WrapperToggleText>
            <ToggleWeather
              active={isWeather}
              onToggle={value => {
                setIsWeather(value);
              }}
            />
          </ToggleWrapperDesktop>
        </>
      )}

      <SliderWrapper ref={slider} isMobile={isMobile}>
        {isMobile && (
          <ToggleWrapperMobile>
            <ToggleWeather
              active={isWeather}
              onToggle={value => {
                setIsWeather(value);
              }}
            />
          </ToggleWrapperMobile>
        )}
        <WrapperSuntrack width={sliderWidth} show={!isWeather}>
          <svg viewBox="0 0 609 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              id="suntrack"
              opacity="0.75"
              d="M1 60.8749C203.5 -22.5003 450 -14.0003 608 60.8749"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeDasharray="1 7"
            />
          </svg>
          <SunIconPath ref={sunIcon} url={Icon_0}></SunIconPath>
        </WrapperSuntrack>
        <Track>
          {weatherProgressEnabled && <DebugProgress ref={debugProgress}>{0}</DebugProgress>}
          <Icons>
            <IconsWind visible={isWeather} />
          </Icons>
          <IconsSuntracker>
            <IconsSun visible={!isWeather} />
          </IconsSuntracker>
          <Values style={{ opacity: isWeather && !isMobile ? 1 : 0, transition: 'opacity 0.5s ease-in-out' }}>
            <Value xp={0} color={'#ff0000'}>
              {'0'}
            </Value>
            <ValueScale xp={17} color={'#ff0000'}>
              {'beaufort scale'}
            </ValueScale>
            <Value xp={50} color={'#ff0000'}>
              {'12'}
            </Value>
            <ValueScale last xp={69} color={'#5302BC'}>
              {'hurricane scale'}
            </ValueScale>
          </Values>
          {/*<GradientMask ref={gradientRef}>{isWeather ? <GradientWeather /> : <GradientSun />}</GradientMask>*/}
          {isWeather ? (
            <GradientMask ref={gradientRef}>
              <GradientWeather width={sliderWidth} />
            </GradientMask>
          ) : (
            <GradientMaskSun>
              <GradientSun width={sliderWidth} />
            </GradientMaskSun>
          )}
          <Line />
          <Markers style={{ opacity: isWeather ? 1 : 0, transition: 'opacity 0.5s ease-in-out' }}>
            <Marker xp={16} />
            <Marker xp={20} />
            <Marker xp={25} />
            <Marker xp={27} />
            <Marker xp={36} />
            <Marker type={'danger'} xp={50} />
            <Marker xp={62} />
            <Marker xp={72} />
          </Markers>
          {isWeather && (
            <>
              <DotScala />
              <DotScala last={true} />
            </>
          )}
          {pingActive && (
            <PingContainer>
              <Ping />
            </PingContainer>
          )}
        </Track>
        <DragHandle
          ref={dragHandle}
          dragging={isDragging}
          onPointerDown={startDrag}
          onPointerUp={stopDrag}
          onMouseUp={stopDrag}
        >
          <Dot />
        </DragHandle>
        {!isPhone && (
          <PeakIndicator ref={indicator}>
            <Polygon style={{ opacity: isWeather ? 1 : 0, transition: 'opacity 0.5s ease-in-out' }} />
          </PeakIndicator>
        )}
      </SliderWrapper>
    </>
  );
}
