import styled from 'styled-components';
import { Padder, ButtonContainer, TriangleWrapper, CarouselDescription } from '../styles';
import useVariationStyles, { activeWidth } from './useVariationStyles';
import ButtonNext from '../icons/button-next.inline.svg';
import descriptionBg from '../icons/description-bg.png';
import Triangle from '../icons/triangle.svg';
import { VIEWPORT_M_MIN } from '../config';
import { SvgButtonHoverEffect } from '../../../../../uiLivi/styles';

const CarouselContainer = styled.div`
  overflow: hidden;

  ${Padder} {
    position: relative;
    z-index: 1;
  }
`;

const ButtonRow = styled.div`
  width: ${activeWidth * 100}%;
  margin: 0 auto;
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    width: 100%;
  }

  position: relative;
`;

const Description = styled.div`
  background: url('${descriptionBg}');
  background-size: 100% calc(100%);
  background-position: center bottom;
  background-repeat: no-repeat;


  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    background-image: none;
  }
  
  padding-top: ${props => props.containerHeight};
  margin-top: -${props => props.containerHeight};
`;

export const VariationImage = styled.div`
  background: url('${props => props.src}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  
  transition: all 0.3s;
  
  margin: 0;
  flex-shrink: 0;
  
  cursor: pointer;
  pointer-events: ${props => (props.enabled ? 'all' : 'none')};
`;
export const PaginationContainer = styled.div`
  position: relative;
  z-index: 1;

  margin: auto;
  margin-top: 12px;
  margin-bottom: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  svg {
    display: block;
  }
`;

export default function Carousel({ children, variations, index, selectVariation }) {
  const [variationStyles, containerHeight, containerRef, renderVariations, containerWidth] = useVariationStyles(
    variations,
    index
  );

  return (
    <>
      <CarouselContainer>
        <Padder>
          <div style={{ position: 'relative', width: '100%', height: containerHeight }} ref={containerRef}>
            {renderVariations &&
              variations.map((variation, i) => (
                <VariationImage
                  key={i}
                  src={variation.rendering}
                  {...variationStyles[i]}
                  onClick={() => selectVariation(i)}
                  data-sound-action="click"
                  enabled={i !== index}
                />
              ))}
          </div>
        </Padder>
      </CarouselContainer>
      <CarouselDescription>
        <Padder>
          <PaginationContainer>
            {renderVariations &&
              variations.map((variation, i) => (
                <TriangleWrapper
                  key={i}
                  onClick={() => selectVariation(i)}
                  data-sound-action="click"
                  $active={i === index}
                >
                  <Triangle />
                </TriangleWrapper>
              ))}
          </PaginationContainer>
          <ButtonRow>
            <Description containerHeight={containerHeight} containerWidth={containerWidth}>
              {children}
            </Description>
            <ButtonContainer
              onClick={() => selectVariation(index - 1)}
              disabled={index === 0}
              data-sound-action="click"
              flipped
              containerHeight={containerHeight}
            >
              <SvgButtonHoverEffect>
                <ButtonNext />
              </SvgButtonHoverEffect>
            </ButtonContainer>
            <ButtonContainer
              onClick={() => selectVariation(index + 1)}
              disabled={index === variations.length - 1}
              data-sound-action="click"
              containerHeight={containerHeight}
            >
              <SvgButtonHoverEffect>
                <ButtonNext />
              </SvgButtonHoverEffect>
            </ButtonContainer>
          </ButtonRow>
        </Padder>
      </CarouselDescription>
    </>
  );
}
