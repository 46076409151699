import {
  AboutBg,
  AboutDescriptionContainer,
  AboutHeader,
  Container,
  Description,
  Fg,
  GetInTouchPanel,
  Headline26_900,
  Headline_64_900,
  Padder,
  RoundedOverlayMargin,
  SmallHeadline,
} from '../styles';
import CTAs from '../CTAs';
import Banners from '../Banners';
import Footer from '../Footer';
import useContainerWidth from '../useContainerWidth';
import aboutBgDesktop from '../images/about-bg-desktop.png';
import aboutBgMobile from '../images/about-bg-mobile.png';
import getInTouchBg from '../images/get-in-touch-bg.png';
import ContactUsButton from '../icons/contact-us-button-green.svg';
import { SvgButtonHoverEffect } from '../../../../../uiLivi/styles';

export default function About({ ctas }) {
  const [containerRef, containerWidth] = useContainerWidth();

  return (
    <Container>
      <RoundedOverlayMargin>
        <AboutBg mobileSrc={aboutBgMobile} desktopSrc={aboutBgDesktop} />
        <Fg ref={containerRef}>
          <Padder>
            <AboutHeader>
              <SmallHeadline glow>Welcome to the</SmallHeadline>
              <Headline_64_900>
                House of
                <br />
                Schletter
              </Headline_64_900>
              <Description glow maxWidth="433px" opacity={1}>
                We couldn’t find a stage that did our systems justice—so we built one. One digital home for our vision,
                products, and support. Join us as we showcase the future of solar.
              </Description>
            </AboutHeader>
            <Headline26_900>Have a wander.</Headline26_900>
            <AboutDescriptionContainer>
              <Description maxWidth="540px">
                You’re not confined to one room—we have more for you to explore. And the best part? Each experience is
                completely unique.
              </Description>
            </AboutDescriptionContainer>
          </Padder>
          <Banners containerWidth={containerWidth} />
          <GetInTouchPanel src={getInTouchBg}>
            <Headline26_900>Let’s talk.</Headline26_900>
            <Description maxWidth="500px" opacity={0.65}>
              When solar wins, we all win. That’s why we prioritize the success of all solar companies—including yours.
              We’re always here to help, and on hand to support your projects with the right products. Just drop us a
              line when you’re good to go.
            </Description>
            <SvgButtonHoverEffect>
              <ContactUsButton
                onClick={() => {
                  window.open('https://www.schletter-group.com/metaverse/');
                }}
                className="button"
              />
            </SvgButtonHoverEffect>
          </GetInTouchPanel>
          <CTAs {...ctas} />
          <Footer {...ctas} />
        </Fg>
      </RoundedOverlayMargin>
    </Container>
  );
}
