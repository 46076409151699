import styled from 'styled-components';
import { smallTextStyle } from 'common/text';

export const Container = styled.div`
  text-align: ${props => props.align};
  display: inline-block;
`;

export const Date = styled.div`
  font-weight: bold;
  ${smallTextStyle};
  line-height: 14px;
`;

export const Time = styled.div`
  ${smallTextStyle};
  line-height: 14px;
`;
