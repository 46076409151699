import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;
export const Video = styled.video`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-height: 100%;
`;
