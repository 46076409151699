import { InstructionHeadline, InstructionIconContainer, InstructionText, InstructionWrapper } from '../../../../styles';
import IconJourney from '../../../assets/journey.png';
import React from 'react';
import styled from 'styled-components';

const InstructionIcon = styled.div`
  width: 53px;
  height: 55px;
  background-image: url(${IconJourney});
  background-size: cover;
`;

export default function OnboardingJourney() {
  return (
    <InstructionWrapper>
      <InstructionIconContainer>
        <InstructionIcon />
      </InstructionIconContainer>
      <InstructionHeadline>Start the conversation.</InstructionHeadline>
      <InstructionText>
        If you have feedback for us or know which system's right for you, head over to our Support Desk when you're
        ready. You'll find it in The Hub.
      </InstructionText>
    </InstructionWrapper>
  );
}
