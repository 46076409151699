import { InstructionHeadline, InstructionIconContainer, InstructionText, InstructionWrapper } from '../../../../styles';
import IconControl from '../../../assets/control.png';
import React from 'react';
import styled from 'styled-components';

const InstructionIcon = styled.div`
  width: 183px;
  height: 65px;
  background-image: url(${IconControl});
  background-size: cover;
`;

export default function OnboardingToggle() {
  return (
    <InstructionWrapper>
      <InstructionIconContainer>
        <InstructionIcon />
      </InstructionIconContainer>
      <InstructionHeadline>Switch focus.</InstructionHeadline>
      <InstructionText>
        Flick between scenes to follow the Tracking System's movements or witness its stability.
      </InstructionText>
    </InstructionWrapper>
  );
}
