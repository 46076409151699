import React, { useEffect, useMemo, useState } from 'react';
import {
  ColonDigit,
  CountdownContainer,
  IntroText,
  SingleDigit,
  TimeColumn,
  TimeContainer,
  TimeDigits,
  TimeUnit,
} from './styles';
import moment from 'moment';
import { useInterval } from 'utilities/hooks';
import { useEventStore } from 'services/EventService';
import { useTranslate } from 'services/TranslationService/hooks';

export default function Countdown() {
  const [timeValues, setTimeValues] = useState([]);
  const eventStartDateString = useEventStore(state => state.event.startDate);
  const translate = useTranslate();

  const startTime = useMemo(() => moment(new Date(eventStartDateString)), []);

  const twoDigits = value => (value < 10 ? '0' + value : value.toString());
  const formatDigits = str => twoDigits(str).split('');
  const updateCountdown = () => {
    const nowTime = moment();
    const timeOver = nowTime.isAfter(startTime);
    const days = timeOver ? 0 : startTime.diff(nowTime, 'days');
    const hours = timeOver ? 0 : startTime.diff(nowTime, 'hours') % 24;
    const minutes = timeOver ? 0 : startTime.diff(nowTime, 'minutes') % 60;
    const seconds = timeOver ? 0 : startTime.diff(nowTime, 'seconds') % 60;

    const timeValues = [
      { unit: translate('confirmation.countdown.days') || 'Days', digits: formatDigits(days) },
      { unit: translate('confirmation.countdown.hours') || 'Hours', digits: formatDigits(hours) },
      { unit: translate('confirmation.countdown.minutes') || 'Minutes', digits: formatDigits(minutes) },
      { unit: translate('confirmation.countdown.seconds') || 'Seconds', digits: formatDigits(seconds) },
    ];

    setTimeValues(timeValues);
  };

  useEffect(updateCountdown, []);
  useInterval(updateCountdown, 1000);

  return (
    <CountdownContainer>
      <IntroText>{translate('confirmation.headline') || 'The main event for partners and professionals.'}</IntroText>
      <TimeContainer>
        {timeValues.map((t, index) => (
          <span key={index}>
            <TimeColumn>
              <TimeDigits>
                {t.digits.map((d, index) => (
                  <SingleDigit key={index}>{d}</SingleDigit>
                ))}
              </TimeDigits>
              {t.unit && <TimeUnit>{t.unit}</TimeUnit>}
            </TimeColumn>
            {index < timeValues.length - 1 && <ColonDigit>:</ColonDigit>}
          </span>
        ))}
      </TimeContainer>
    </CountdownContainer>
  );
}
