import styled from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { smallTextStyle, textOverflowEllipsis } from 'common/text';

export const ChatBubbleContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.floatRight ? 'row-reverse' : 'row')};
  width: 100%;
  margin: 5px 0;
  justify-content: flex-start;
`;

export const Bubble = styled.div`
  position: relative;
  display: flex;
  max-width: 70%;
  flex-direction: column;
  padding: 8px 14px;
  text-align: left;
  border-radius: 10px 10px ${props => (props.isSelf ? '0 10px' : '10px 0')};
  background-color: ${props => (props.isSelf ? colors.accent.base : props.isContentChat ? 'black' : 'black')};
  color: ${props => (props.isSelf || props.isContentChat ? 'white' : colors.font.strong)};
  line-height: 22px;
  cursor: ${props => (props.interactive ? 'pointer' : 'auto')};
  opacity: ${props => (props.hide ? 0.3 : 1)};
`;

export const StyledMessageTime = styled.span`
  align-self: flex-end;
  ${smallTextStyle};
  margin: 2px -6px -6px 0;
  opacity: 0.8;
  letter-spacing: 0.1em;
`;

export const Sender = styled.div`
  ${textOverflowEllipsis};
  ${smallTextStyle};
  line-height: 12px;
  color: ${props => (props.isSelf ? lgBaseColors.red.light : colors.accent.base)};
  font-weight: bold;
`;

export const ChatBubbleVisibilityToggle = styled.div`
  align-self: center;
  display: flex;
  justify-content: ${props => (props.isSelf ? 'flex-start' : 'flex-end')};
  margin: ${props => (props.isSelf ? '0 15px 0 0' : '0 0 0 15px')};
  ${smallTextStyle};
  cursor: pointer;
  color: ${lgBaseColors.grey.light};
`;
