import { useDebugStore } from '../../../../storage/debug';
import { useEnvironmentStore } from '../../Environment/store';
import React from 'react';

export default function DebugEnvironmentObjects() {
  const debugEnvironmentEnabled = useDebugStore(state => state.getDebugEnvironmentEnabled());
  const environmentConfiguration = useEnvironmentStore(state => state.environmentConfiguration);

  return (
    debugEnvironmentEnabled && (
      <>
        <mesh position={[2, 2, 0]}>
          <boxGeometry args={[2, 2, 2]} />
          <meshStandardMaterial
            envMapIntensity={environmentConfiguration.envMap.intensity}
            color={'white'}
            roughness={0}
            metalness={1}
          />
        </mesh>
        <mesh position={[-2, 2, 0]}>
          <sphereGeometry args={[1, 32, 32]} />
          <meshStandardMaterial
            envMapIntensity={environmentConfiguration.envMap.intensity}
            color={'white'}
            roughness={0}
            metalness={1}
          />
        </mesh>
        <mesh position={[2, 2, -4]}>
          <boxGeometry args={[2, 2, 2]} />
          <meshStandardMaterial
            envMapIntensity={environmentConfiguration.envMap.intensity}
            color={'white'}
            roughness={0.9}
            metalness={0.0}
          />
        </mesh>
        <mesh position={[-2, 2, -4]}>
          <sphereGeometry args={[1, 32, 32]} />
          <meshStandardMaterial
            envMapIntensity={environmentConfiguration.envMap.intensity}
            color={'white'}
            roughness={0.9}
            metalness={0.0}
          />
        </mesh>
      </>
    )
  );
}
