import styled from 'styled-components';
import { lgBaseColors } from 'uiLivi/styles';

export const OuterContainer = styled.div`
  overflow: auto;
  min-height: 300px;
  background-color: ${lgBaseColors.grey.dark};
`;

export const CardsContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
`;

export const GroupContainer = styled.div`
  margin-bottom: 24px;
`;

export const GroupTitle = styled.div`
  font-weight: bold;
  margin-left: 8px;
  margin-bottom: 3px;
`;
