const hue_shift = `
vec3 hueShift( vec3 color, float hueAdjust ){

  const vec3 k = vec3(0.57735, 0.57735, 0.57735);
  float cosAngle = cos(hueAdjust);
  return vec3(color * cosAngle + cross(k, color) * sin(hueAdjust) + k * dot(k, color) * (1.0 - cosAngle));

}
`;

const saturation = `
vec3 czm_saturation(vec3 rgb, float adjustment)
{
  // Algorithm from Chapter 16 of OpenGL Shading Language
  const vec3 W = vec3(0.2125, 0.7154, 0.0721);
  vec3 intensity = vec3(dot(rgb, W));
  return mix(intensity, rgb, adjustment);
}
`;

const get_color = `
vec3 getColor(vec3 reflectVec, vec3 worldPosition, sampler2D color, float h) {
  vec3 resultColor = vec3(0.0);
  float c = acos(reflectVec.y + worldPosition.z * 0.05 + h) / PI;
  resultColor = texture2D( color, vec2(0.0,c) ).xyz;
  return resultColor;
}
`;

export { hue_shift, saturation, get_color };
