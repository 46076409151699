import styled, { css, keyframes } from 'styled-components';
import { zIndex } from '../../../../uiLivi/styles';
import { mediaMaxPx } from '../../../../common/layout';
import { motion } from 'framer-motion';
import {
  aboutHeightDesktop,
  aboutHeightMobile,
  headerPaddingTopDesktop,
  headerPaddingTopMobile,
  VIEWPORT_L_MIN,
  VIEWPORT_M_MIN,
} from './config';

const glow = size => css`
  ${props =>
    props.glow &&
    css`
      color: #ffffff;
      text-shadow: 0px 0px ${size}px rgba(255, 255, 255, 0.6);
    `}
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: ${zIndex.menu + 2};
  right: 0;
  top: 0;
  width: 45.8%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translate3d(${props => (props.show ? '0%,0,0' : '100%,0,0')});
  transition: all ${props => (props.show ? '.35s ease-out' : '.2s ease-in')};

  padding: 0;

  overflow: hidden;

  max-width: calc(880px + 2 * 16px);
  ${mediaMaxPx(
    VIEWPORT_L_MIN,
    css`
      width: 100%;
      max-width: initial;
    `
  )};
`;
export const Fg = styled.div`
  width: 100%;
  font-family: AzoSans;

  padding-top: ${headerPaddingTopDesktop}px;
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    padding-top: ${headerPaddingTopMobile}px;
  }

  height: fit-content;
  overflow: hidden;

  color: #00676b;
  grid-area: center;
`;
export const Bg1 = styled.div`
  background-color: #e5f1e9;
  grid-area: center;
  height: calc(360px + ${headerPaddingTopDesktop}px);
`;

export const Bg2 = styled.div`
  background-color: #fff;
  background-image: ${props =>
    props.src
      ? `url('${props.src}'), linear-gradient(180deg, #e4f0e8 56.25%, rgba(228, 240, 232, 0) 100%)`
      : 'linear-gradient(180deg, #e4f0e8 56.25%, rgba(228, 240, 232, 0) 100%)'};
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;

  grid-area: center;

  width: 100%;
  aspect-ratio: 1760 / 1355;

  transform: translateY(calc(450px + ${props => props.$containerWidth * 0.05}px - 50%));
`;

export const AboutBg = styled.div`
  grid-area: center;
  
  height: ${aboutHeightDesktop}px;
  background-image: url('${props => props.desktopSrc}');
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    height: ${aboutHeightMobile}px;
    background-image: url('${props => props.mobileSrc}');
  }
  
  background-size: cover;
  background-position: center bottom;
`;

export const Padder = styled.div`
  padding: 0 38px;

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    padding: 0 16px;
  }

  position: relative;
`;

export const Close = styled.div`
  position: absolute;
  z-index: 100;
  right: 0;
  top: 0;
  padding: 28px calc(36px + 12px);
  font-size: 30px;

  svg {
    width: 48px;
    display: block;
  }
  @media only screen and (max-width: ${VIEWPORT_L_MIN}px) {
    padding: 19px calc(24px + 12px);
    svg {
      width: 42px;
    }
  }

  ${props =>
    props.bright &&
    css`
      svg {
        .bg {
          fill: #6db6a3;
        }
      }
    `}
`;
export const VariationDivider = styled.div`
  height: 1px;
  background: linear-gradient(90deg, #0b585a00, #0b585a, #0b585a00);
  margin: 32px 0;
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    margin: 72px 0;
  }
  opacity: 0.12;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow-x: hidden;
  overflow-y: auto;
`;
export const Header = styled.div`
  min-height: 330px;
`;
export const VariationContainer = styled(motion.div)`
  grid-area: center;
  width: 100%;
`;

export const SmallHeadline = styled.div`
  color: #00676b;
  text-align: center;
  font-family: 'AzoSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  letter-spacing: 0.91px;
  text-transform: uppercase;

  ${glow(4)}
`;
export const Headline = styled.div`
  font-family: 'AzoSansBlack', serif;
  max-width: 358px;
  margin: 0 auto;
  margin-top: 8px;

  color: #00676b;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: -0.03em;

  ${glow(10)}
`;
export const Headline_34_700 = styled.div`
  text-transform: uppercase;

  max-width: 67%;
  margin: 0 auto;

  color: #00676b;
  text-align: center;
  text-shadow: 0px 0px 13.098088264465332px rgba(255, 255, 255, 0.6);
  font-family: 'AzoSansBold';
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  min-height: 2em;

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    font-family: AzoSansBlack;
    font-size: 26px;
    font-weight: 900;
    letter-spacing: -0.5px;
  }
`;
export const Headline26_900 = styled.div`
  color: #00676b;
  text-align: center;
  font-family: AzoSansBlack;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.03em;
  text-transform: uppercase;
`;
export const Headline_22_900 = styled.div`
  color: #00676b;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6);
  font-family: AzoSansBlack;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: -0.03em;
`;

export const Headline_18_700 = styled.div`
  color: #00676b;
  text-shadow: 0px 0px 13.098088264465332px rgba(255, 255, 255, 0.6);
  font-family: 'AzoSansBold';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    font-size: 22px;
    font-weight: 900;
    font-family: AzoSansBlack;
    letter-spacing: -0.03em;
  }
`;

export const Headline_18_900 = styled.div`
  color: #00676b;
  text-shadow: 0px 0px 13.098088264465332px rgba(255, 255, 255, 0.6);
  font-family: AzoSansBlack;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
`;

export const Headline_64_900 = styled.div`
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6);
  font-family: AzoSansBlack;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 103%; /* 65.92px */
  text-transform: uppercase;

  margin-top: 8px;

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    font-size: 50px;
  }
`;

export const Description = styled.div`
  color: #00676b;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6);
  font-family: 'AzoSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  white-space: pre-line;

  opacity: ${props => props.opacity || 0.8};
  margin: 0 auto;
  margin-top: 16px;

  ${glow(4)}

  max-width: ${props => props.maxWidth};
`;
export const AboutDescriptionContainer = styled.div`
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    display: none;
  }
`;

export const SystemsBrochureButtonContainer = styled.div`
  margin-top: 16px;
  text-align: center;

  svg {
    cursor: pointer;
  }
`;

export const Sides = styled.div`
  display: flex;
  align-items: start;
  justify-content: stretch;
  gap: 32px;

  flex-direction: ${props => (props.flipped ? 'row' : 'row-reverse')};

  ${props =>
    props.$isNarrow &&
    css`
      flex-direction: column;
    `}
`;

export const Side = styled.div`
  width: ${props => (props.width || 0.5) * 100}%;

  ${props =>
    props.$isNarrow &&
    css`
      width: 100%;
      text-align: center;
    `}

  img {
    width: 100%;
    pointer-events: none;
  }
`;

export const A = styled.a`
  color: #3a87887f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 0;
  margin-top: 12px;
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    margin-top: 16px;
  }

  svg {
    margin-left: 0.5em;
  }

  display: block;
  ${props =>
    props.$isCarousel &&
    css`
      margin-left: auto;
      margin-right: auto;
      max-width: fit-content;
      margin-bottom: 36px;
    `}
`;

export const YoutubeVideoContainer = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;

  background: #000;

  overflow: hidden;

  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
`;

const fadeIn = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

export const YoutubeVideo = styled.iframe.attrs(props => ({
  src: `${props.src.replace('watch?v=', 'embed/')}?rel=0`,
  frameBorder: 0,
  allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
  allowFullScreen: true,
  onLoad: e => {
    e.target.classList.add('loaded');
  },
}))`
  width: 100%;
  height: 100%;

  display: none;
  &.loaded {
    display: block;
    animation: ${fadeIn} 0.5s forwards;
  }

  object-fit: contain;
`;

export const DivideLine = styled.div`
  margin: 28px 0;

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    .buttons {
      margin: 24px 0;
    }
  }

  height: 1px;
  background: #fff;
  opacity: 0.2;
`;

export const BannerContainer = styled.div``;

export const StyledBanner = styled.div`
  background-image: url('${props => props.desktopBg}');
  ${props => props.bgColor && `background-color: ${props.bgColor};`}
  background-size: cover;
  background-position: center;
  
  color: ${props => props.color};
  
  width: 100%;
  aspect-ratio: ${props => props.aspect};

  padding: 47px 39px;
  
  ${props =>
    props.mobileBg &&
    css`  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    background-image: url('${props => props.mobileBg}');
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
  }`}
  

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    aspect-ratio: auto;
    height: ${props => 100 * props.vh}px;
    padding: 47px 24px;
  }
  
  overflow: hidden;
  

  .firstLine {
    max-width: 281px;
    
    color: #00676B;
    font-family: 'AzoSans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 15.4px */
    letter-spacing: 0.91px;
    text-transform: uppercase;
  }

  .headline {
    max-width: 281px;    
    &.hasFirstLine {
      margin-top: 20px;
    }
    
    color: #00676B;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.60);
    font-family: 'AzoSansBlack';
    font-size: 26px;
    font-style: normal;
    font-weight: 900;
    line-height: 113%; /* 29.38px */
    letter-spacing: -1px;
    text-transform: uppercase;
  }
  
  .enterRoomButton {
    margin-top: 20px;
    cursor: pointer;
  }
  
  .description {
    margin-top: 16px;
    color: #00676B;
    text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.60), 0px 0px 4px rgba(255, 255, 255, 0.60);
    font-family: AzoSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    
    max-width: 50%;
    @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
      max-width: 100%;
    }
    
    svg {
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }
`;

export const ButtonContainer = styled.div`
  transform: translateX(50%);
  cursor: pointer;

  position: absolute;
  top: calc(${props => props.containerHeight} - 12px);
  right: 0;
  ${props =>
    props.flipped &&
    css`
      left: 0;
      right: auto;
      transform: translateX(-50%);
      svg {
        transform: scaleX(-1);
      }
    `}
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      .disabled-pagination-arrow {
        fill: #dfefeb;
      }
    `}

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    top: calc(${props => props.containerHeight} - 82px);
    transform: translateX(0);
  }
`;

export const Footer = styled.div`
  background: #0b585a;

  .button {
    cursor: pointer;
  }
`;

export const Row1 = styled.div`
  min-height: 42px;

  margin: 0 -8px;
  padding-top: 24px;

  .button {
    display: inline-block;
    margin: 4px 8px;
  }

  ${props =>
    !props.$buttonsVisible &&
    css`
      .button {
        display: none;
      }
    `}
`;
export const Row2 = styled.div`
  display: flex;
  gap: 16px;
  justify-content: end;
  align-items: center;

  padding: 0 18px;
  padding-bottom: 38px;

  .logotext {
    align-self: start;
    margin-right: auto;
  }

  svg {
    display: block;
  }

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    padding-left: 0;
    padding-right: 0;
  }

  ${props =>
    !props.$buttonsVisible &&
    css`
      .button {
        display: none;
      }
    `}
`;

export const VariationsList = styled.div`
  margin-top: 96px;
  margin-bottom: 120px;
`;
export const StyledVariationWithVideo = styled.div`
  display: flex;
  align-items: start;
  gap: 16px;

  flex-direction: row;

  ${props =>
    props.$isNarrow &&
    css`
      flex-direction: column;
      gap: 24px;
    `}
`;

export const RoomBannersScrollContainer = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  gap: 20px;

  ${props =>
    props.$isScrollable &&
    css`
      overflow-x: auto;
    `}

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
export const RoomBanners = styled.div`
  display: grid;
  gap: 0 20px;

  margin-top: 32px;
  margin-bottom: 12px;

  grid-template-areas: 'tl tr' 'bl br';

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
`;

export const minContainerWidth = 650;

export const BgImage = styled.div`
  width: 100%;
  height: 100%;
  background: url('${props => props.src}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const RoomBannerBg = styled(motion.div)`
  ${props =>
    props.$containerWidth < minContainerWidth
      ? css`
          width: calc(${props.$containerWidth * 0.9}px - 48px);
        `
      : css`
          width: 100%;
          min-height: 100%;
          aspect-ratio: 393 / 249;
        `};

  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);

  cursor: pointer;

  overflow: hidden;

  border: 1px solid rgba(0, 103, 107, 0.15);
  border-bottom: 0;

  .image {
    transition: transform 0.4s;
  }
  ${props =>
    props.$hasHover &&
    css`
      .icon {
        transform: translate(-50%, 50%) scale(1.2);
      }
      .image {
        transform: scale(1.1);
      }
    `}
`;
export const RoomBannerText = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;

  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
  background: #fff;

  cursor: pointer;
  position: relative;

  padding: 0 8px;
  padding-top: calc(24px + 100px / 2);
  padding-bottom: 24px;
  border: 1px solid rgba(0, 103, 107, 0.15);
  border-top: 0;

  .icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.2s;
  }
  ${props =>
    props.$hasHover &&
    css`
      .icon {
        transform: translate(-50%, -50%) scale(1.2);
      }
    `}
`;
export const RoomBannerDescription = styled.div`
  width: 80%;
  margin: 0 auto;

  color: #00676b;
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6);
  font-family: AzoSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  opacity: 0.8;
  min-height: calc(3em * 1.705);

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    font-size: 14px;
    width: 100%;
    padding: 0 12px;
  }
`;

export const OtherVariationsHeadline = styled.div`
  margin-top: 150px;
  margin-bottom: 74px;
`;

export const TriangleWrapper = styled(motion.div)`
  cursor: pointer;

  svg {
    opacity: 0.5;
    transition: opacity 0.2s;
  }

  ${props =>
    props.$active &&
    css`
      svg {
        cursor: auto;
        opacity: 1;
      }
    `}
`;

export const AboutHeader = styled.div`
  min-height: ${aboutHeightDesktop - headerPaddingTopDesktop}px;
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    min-height: ${aboutHeightMobile - headerPaddingTopMobile}px;
  }
  text-align: center;
`;

export const GetInTouchPanel = styled.div`
  background: url('${props => props.src}');
  background-size: cover;
  background-position: center;
  
  margin-top: 90px;
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    margin-top: 42px;
  }

  padding: 38px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.04) inset;
  .button {
    display: block;
    margin: 0 auto;
    margin-top: 16px;
    cursor: pointer;
  }
`;

export const CarouselDescription = styled.div`
  padding-bottom: 130px;
  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    padding-bottom: 87px;
  }

  @media only screen and (max-width: ${VIEWPORT_M_MIN}px) {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 11.46%, #f7fff5 49.48%);
  }
  &:after {
    content: '';
    display: block;
  }
`;

export const RoundedOverlayMargin = styled.div`
  background: #fff;
  display: grid;
  grid-template-areas: 'center';

  margin: 16px;
  overflow: hidden;
  @media only screen and (max-width: ${VIEWPORT_L_MIN}px) {
    border-radius: 0;
    margin: 0;
  }
`;
