import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { Container, Video } from './styles';
import { useTheaterContent } from 'utilities/hooks';
import { useSoundStore } from 'services/SoundService';
import { Vector3 } from 'three';

export const configureVideo = (video, videoSrc) => {
  video.setAttribute('playsinline', '');
  video.setAttribute('autoplay', '');
  video.setAttribute('loop', '');
  video.setAttribute('muted', '');
  video.autoPlay = true;
  video.loop = true;
  video.playsinline = true;
  video.muted = true;
  video.controls = true;
  video.crossOrigin = 'anonymous';

  if (!videoSrc) return video;

  let hls = null;
  if (videoSrc.endsWith('m3u8')) {
    if (Hls.isSupported()) {
      const config = {
        //reduce initial loading cpu load
        maxMaxBufferLength: 15,
      };
      hls = new Hls(config);
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.muted = false;
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
    }
  } else {
    video.src = videoSrc;
    video.addEventListener('loadedmetadata', () => {
      video.muted = false;
    });
  }
  return hls;
};

export default function LiveStream(props) {
  const { content: defaultContent } = props;
  const { content } = useTheaterContent(defaultContent);
  const videoRef = useRef();

  useEffect(() => {
    console.log('LiveStream createVideo', content);
    const hls = configureVideo(videoRef.current, content);
    const video = videoRef.current;
    return () => {
      if (hls) {
        hls.detachMedia();
        hls.destroy();
      }
      video.pause();
    };
  }, [content, videoRef.current]);

  return (
    <Container>
      <video width="100%" height="100%" ref={videoRef} />
    </Container>
  );
}
