export const CHARTS_WEATHER = [
  {
    range: [0, 0.02],
    copy: "You're in charge. Move the slider to change the weather.",
    sound: 'strength1',
  },
  {
    range: [0.02, 0.08],
    // copy: 'Our product moves with the sun, but what happens when the wind blows?',
    copy: "Our Tracking System's built to follow the Sun. But what happens when the wind picks up?",
    sound: 'strength1',
  },
  {
    range: [0.08, 0.15],
    copy:
      // 'Wind force 5: The Tracking System continues to hold its position. But from here on out, caution is advised for cars.',
      "At Wind Force 5, the Tracking System remains unphased. But, from here on out, it's likely unsafe to drive.",
    sound: 'strength1',
  },
  {
    range: [0.15, 0.19],
    copy:
      // 'No more trying to hold on to your umbrella. Even the Tracking System with these large modules has to adjust its position in 15-degree increments.',
      "It's time to batten down the hatches of your emergency shelter. To decrease wind resistance, the Tracking System adjusts its position in 15° increments.",
    sound: 'strength2',
  },
  {
    range: [0.19, 0.24],
    copy:
      // 'You are advised to head inside as it is becoming increasingly dangerous. The Tracking System remains stable.',
      "At this point, you're advised to head inside. It's just too dangerous to stay outside—except for the Tracking System, which stays perfectly stable.",
    sound: 'strength2',
  },
  {
    range: [0.24, 0.35],
    copy:
      // 'It is almost impossible to put one foot in front of the other. The Tracking System now goes into a stow position.',
      'The storm sirens are blaring and the water rising. Nothing outside is safe. The Tracking System moves automatically into a stow position.',
    sound: 'strength3',
  },
  {
    range: [0.35, 0.47],
    copy:
      // 'We have customized solutions for harsh conditions in high intensity areas that use a larger number of posts.',
      "More posts mean greater stability. That's why we have customized solutions for areas prone to harsh and intense conditions.",
    sound: 'strength3',
  },
  {
    range: [0.47, 0.51],
    // copy: 'The end of the official Beaufort scale: it "just" says heaviest devastations.',
    copy:
      "We've reached the end of the official Beaufort scale. This is hurricane territory. Devastation is unavoidable. Are you sure you wish to continue?",
    sound: 'strength3',
  },
  {
    range: [0.51, 0.61],
    // copy: 'No one can stay on their feet in such extreme gale force winds, but our Tracking System still works.',
    copy:
      'Vehicles, trees, and unsecured property are now either water- or air-borne. The Tracking System, however, owing to its patented Geneva drive, stands strong.',
    sound: 'strength4',
  },
  {
    range: [0.61, 0.71],
    copy:
      // 'In 1999, "Lothar", the storm of the century, swept across southern Europe. With our Tracking System, you are ready for the storms of this century.',
      'Stormy days lie ahead. Due to climate change, extreme weather events that were once generational are now becoming annual in some areas. Wherever you are in the world, you can expect severe weather more frequently—and you need to be prepared.',
    sound: 'strength4',
  },
  {
    range: [0.71, 1.0],
    copy:
      // 'From here on, the damage will be catastrophic. Most of the area will be uninhabitable for weeks or months, but at least our products can contribute to the rebuilding effort.',
      'The damage from now on will be widespread and catastrophic. Most areas will be uninhabitable for weeks or months, but at least the Tracking System can help power the rebuilding efforts.',
    sound: 'strength4',
  },
];

export const CHARTS_SUN = [
  {
    range: [0, 0.2],
    copy: 'Maximize solar power generation with our durable Tracking Systems.',
    sound: 'morning',
  },
  {
    range: [0.2, 0.4],
    copy: 'Benefit from a 120° rotation range that captures sunlight all day long.',
    sound: 'morning',
  },
  {
    range: [0.4, 0.6],
    copy: 'Experience a significant 25% increase in power output over fixed-tilt systems.',
    sound: 'noon',
  },
  {
    range: [0.6, 0.8],
    copy: 'Achieve remarkable efficiency with highly stable ±60° self-locking rows.',
    sound: 'noon',
  },
  {
    range: [0.8, 1.0],
    copy: 'Our Tracking Systems are highly adaptable and can be installed anywhere in the world.',
    sound: 'evening',
  },
];
