import axios from 'axios';
import { useTalkStore } from 'services/TalkService';
import create from 'utilities/zustand/create';

const getTimeStamps = t => {
  const startTime = Date.parse(t.startTime);
  const endTime = startTime + t.duration * 60000;
  const isPast = Date.now() > endTime;
  return { startTime, endTime, isPast };
};

const needsUpdate = (talk, oldTalk) => {
  const talkId = talk && talk.id;
  const oldTalkId = oldTalk && oldTalk.id;
  const talkUrl = talk && talk.providerPayload.liveStream.url;
  const oldTalkUrl = oldTalk && oldTalk.providerPayload.liveStream.url;
  return talkId != oldTalkId || talkUrl != oldTalkUrl;
};

export const useTheaterStore = create((set, get) => ({
  currentTheaterTalk: null,
  upcomingTheaterTalk: null,
  talkIsLive: false,
  theaterTalks: [],
  updater: null,
  poller: null,

  init: () => {
    const { pollTalks, updateTalks } = get();
    clearInterval(get().updater);
    const updater = setInterval(() => {
      updateTalks();
    }, 1000);
    updateTalks();
    set({ updater });

    clearInterval(get().poller);
    const poller = setInterval(() => {
      pollTalks();
    }, 60000);
    pollTalks();
    set({ poller });
  },

  pollTalks: () => {
    useTalkStore.getState().poll();
  },

  updateTalks: () => {
    const { theaterTalks, currentTheaterTalk, upcomingTheaterTalk, checkStreamAvailability } = get();

    const runningTalk = theaterTalks.find(t => {
      const { startTime, isPast } = getTimeStamps(t);
      const isRunning = Date.now() > startTime && !isPast;
      return isRunning;
    });
    const nextTalk = theaterTalks.find(t => {
      const { isPast } = getTimeStamps(t);
      const isNext = t !== currentTheaterTalk && !isPast;
      return isNext;
    });

    needsUpdate(runningTalk, currentTheaterTalk) && set({ currentTheaterTalk: runningTalk });
    needsUpdate(nextTalk, upcomingTheaterTalk) && set({ upcomingTheaterTalk: nextTalk });

    runningTalk && checkStreamAvailability(runningTalk.providerPayload.liveStream.url);
  },

  checkStreamAvailability: async url => {
    const { talkIsLive } = get();
    try {
      await axios.get(url).then(res => {
        if (!talkIsLive) {
          set({ talkIsLive: true });
        }
      });
    } catch (e) {
      if (talkIsLive) {
        set({ talkIsLive: false });
      }
    }
  },
}));
