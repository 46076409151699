import styled from 'styled-components';
import { colors } from 'uiLivi/styles';
import { iconInactiveStyle, iconStyle } from 'common/icon';

export const TabNavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 63px;
  text-align: center;
`;

export const TabIconContainer = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 12px 0 0;
`;

export const TabTextContainer = styled.div`
  font-weight: bold;
  text-align: center;
`;

export const Tab = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${props => 100 / props.totalTabs}%;
  height: 100%;
  cursor: pointer;
  opacity: ${props => (props.isHidden ? 0.3 : 1)};
  color: ${props => (props.active ? colors.font.strong : colors.font.light)};
  border-top: 2px solid ${props => (props.active ? colors.accent.base : 'black')};
  background-color: ${props => (props.active ? colors.background.base : colors.background.highlight)};
  ${TabIconContainer} {
    ${props => (props.active ? iconStyle : iconInactiveStyle)};
  }
`;
