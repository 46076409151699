import create from 'utilities/zustand/create';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

import { useTheaterStore } from 'services/TheaterService';
import { useEventStore } from 'services/EventService';
import { useActivityStore } from 'services/ActivityService';
import { useContentStore } from 'services/ContentService';
import { useResourceStore } from 'services/ResourceService';
import { CONTENT_TYPE } from '../ContentService/contentTypes';

function headlineToPathElement(headline) {
  return encodeURIComponent(
    headline
      .toLowerCase()
      .split(' ')
      .join('-')
  );
}

function catchErrors(f) {
  try {
    f();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('catched: ', e, f);
  }
}

export const useTrackingStore = create((set, get) => ({
  analytics: null,
  trackPoints: [],

  init: () => {
    get().initGoogleAnalytics();
    get().initRoutePageTracking();
    get().initContentPageTracking();
    get().initResourcePageTracking();

    // track initial page
    get().trackPage();

    // temporary dummy event tracking
    window.addEventListener(
      'click',
      e => {
        let child = e.target;

        let category = null;
        let action = null;
        let label = null;
        while (child) {
          if (!category) category = child.getAttribute('data-track-category');
          if (!action) action = child.getAttribute('data-track-action');
          if (!label) label = child.getAttribute('data-track-label');
          if (category && action && label) break;
          if (child.parentNode && child.parentNode.getAttribute) {
            child = child.parentNode;
          } else {
            break;
          }
        }
        if (category && action) {
          get().trackEvent({ category, action, label });
        }
      },
      true
    );
  },

  initGoogleAnalytics: function() {
    const { trackingId } = useEventStore.getState().event;
    if (trackingId == null || trackingId.length < 10) {
      // eslint-disable-next-line no-console
      console.warn('trackingId not set in event');
    }

    const analytics = Analytics({
      app: 'service-now',
      plugins: [
        googleAnalytics({
          trackingId: trackingId || 'invalid',
        }),
      ],
    });
    set({ analytics });
  },

  initRoutePageTracking: function() {
    // automatic page tracking
    let href = window.location.href;
    setInterval(() => {
      if (href === window.location.href) return;
      href = window.location.href;
      get().trackPage();
    }, 100);
  },

  initContentPageTracking: function() {
    // automatic content tracking
    let activeContent = useContentStore.getState().activeContent;
    useContentStore.subscribe(state => {
      catchErrors(() => {
        if (activeContent === state.activeContent) return;
        activeContent = state.activeContent;

        if (activeContent) {
          get().trackActiveContent(activeContent);
        } else {
          get().trackPage();
        }
      });
    });
  },

  trackActiveContent: function(activeContent) {
    if (activeContent.location === 'stage01' && useTheaterStore.getState().currentTheaterTalk) {
      const talk = useTheaterStore.getState().currentTheaterTalk;
      const pathElement = headlineToPathElement(talk.title);
      const path = 'content/' + pathElement;
      const title = talk.title;
      get().trackPage({ path, title });
      get().trackEvent({ category: 'Talk', action: 'View' });
      get().trackActivity({
        activity: 'content',
        content: activeContent.type.id,
        category: activeContent.headline,
        label: talk.title,
      });
    } else {
      const pathElement = headlineToPathElement(activeContent.headline);
      const path = 'content/' + pathElement;
      const title = activeContent.headline;
      get().trackPage({ path, title });
      get().trackActivity({
        activity: 'content',
        content: activeContent.type.id,
        category: activeContent.headline,
        label: activeContent.subline,
      });
    }
  },

  initResourcePageTracking: function() {
    let activeResource = useResourceStore.getState().activeResource;
    useResourceStore.subscribe(state => {
      catchErrors(() => {
        if (activeResource === state.activeResource) return;
        activeResource = state.activeResource;
        const activeContent = useContentStore.getState().activeContent;
        if (activeResource && activeContent) {
          const contentPathElement = headlineToPathElement(activeContent.headline);
          const category = activeContent.categories.find(c => c.id === activeResource.category);
          const categoryPathElement = headlineToPathElement(category.headline);
          const resourcePathElement = headlineToPathElement(activeResource.headline);
          const path = 'content/' + contentPathElement + '/' + categoryPathElement + '/' + resourcePathElement;
          const title = activeResource.headline;
          get().trackPage({ path, title });
          get().trackActivity({
            activity: 'content',
            content: CONTENT_TYPE.RESOURCE.id,
            category: category.headline,
            label: activeResource.headline,
          });
        }
      });
    });
  },

  trackPage: ({ path, title } = {}) => {
    const { name } = useEventStore.getState().event;
    if (title) {
      title = name + ' | ' + title;
    } else {
      title = name;
    }

    // optional feature, can be safely removed without affecting tracking
    // document.title = title;

    if (!path) {
      path = window.location.pathname;
    }
    const { analytics, trackPoints } = get();
    trackPoints.push({ type: 'page', path, title });
    analytics.page({ path, title });
    set({ trackPoints: [...trackPoints] });
  },

  trackEvent: ({ category, action, label }) => {
    catchErrors(() => {
      if (!label) label = '';
      if (!category) {
        // eslint-disable-next-line no-console
        console.warn('no category');
        return;
      }
      if (!action) {
        // eslint-disable-next-line no-console
        console.warn('no action');
        return;
      }

      const { analytics, trackPoints } = get();
      trackPoints.push({ type: 'event', category, action, label });
      analytics.track(action, {
        category: category,
        label: label,
      });
      set({ trackPoints: [...trackPoints] });
    });
  },

  trackActivity: data => {
    const validTypes = ['boolean', 'string', 'number'];
    const flattened = Object.keys(data).reduce((acc, key) => {
      if (validTypes.includes(typeof data[key])) {
        acc[key] = data[key];
      }
      return acc;
    }, {});
    const { trackPoints } = get();
    trackPoints.push({ type: 'activity', data: flattened });
    useActivityStore.getState().addActivity(flattened);
    set({ trackPoints: [...trackPoints] });
  },

  onUser: user => {
    get().analytics.identify('' + user.id);
  },
}));
