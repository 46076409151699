import styled, { css } from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { iconFillAndStroke } from '../../icon';
import { hexToRgba } from 'utilities/color';

export const disabledStyle = css`
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
  background-color: ${props => (props.accent ? lgBaseColors.red.base : '#6d6d6d')};
`;

const enabledStyle = css`
  opacity: 1;
  cursor: ${props => (props.disableHover ? 'default' : 'pointer')};
  pointer-events: all;
  ${props =>
    !props.disableHover &&
    css`
      &:active {
        background-color: ${colors.secondary.base};
        color: white;
      }
    `}
`;

export const getHoverStyle = accent => css`
  background-color: ${accent ? colors.accent.highlight : 'transparent'};
  color: ${accent ? lgBaseColors.white : colors.accent.highlight};
  box-shadow: 0 0 20px ${hexToRgba(colors.accent.base, 0.5)};
`;

export const buttonTransition = css`
  transition: background-color 0.1s, border 0.1s, box-shadow 0.2s;
`;

export const Button = styled.button.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  padding: ${props => (props.small ? 11 : 12)}px;
  line-height: ${props => (props.small ? '14px' : 1.15)};
  background-color: ${props => (props.accent ? colors.accent.base : 'transparent')};
  outline: none;
  cursor: pointer;
  font-weight: bold;
  color: ${lgBaseColors.white};
  letter-spacing: 0.1em;
  ${props => (props.disabled ? disabledStyle : enabledStyle)};
  border: 2px solid ${colors.accent.base};
  ${buttonTransition}

  ${props =>
    props.veStyle &&
    css`
      --c1: ${hexToRgba(colors.accent.base, 1)};
      --c2: ${hexToRgba(colors.accent.highlight, 1)};
      background: linear-gradient(120deg, var(--c2) 0%, var(--c1) 51%, var(--c2) 100%);
      background-size: 200% auto;
      border: none;
      transition: 0.5s;
      box-shadow: none;
      border-radius: ${props => (props.noRadius ? 0 : 3)}px;
      padding: 1.5em;
      line-height: 1;

      &:hover {
        background-position: 100% center;
        box-shadow: none;
      }
    `}
  
  svg {
    ${iconFillAndStroke(lgBaseColors.white, lgBaseColors.white)};
    vertical-align: middle;
    margin: -4px 5px -4px 0;
  }
`;
