import styled from 'styled-components';
import { colors, lgBaseColors, zIndex } from 'uiLivi/styles';
import { iconFillAndStroke, iconStyle } from 'common/icon';
import { smallTextStyle } from 'common/text';

export const StyledChatButton = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  cursor: pointer;
  z-index: ${zIndex.chat};
  bottom: 0;
  right: 0;
  background: ${props => (props.isContentChat ? 'black' : colors.background.base)};
  opacity: ${props => (props.show ? 1 : 0)};
  ${smallTextStyle};
  font-weight: bold;
  color: ${props => (props.isContentChat ? lgBaseColors.grey.base : colors.font.strong)};
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  svg {
    ${props => (props.isContentChat ? iconFillAndStroke(colors.accent.base, 'white') : iconStyle)};
  }
`;
