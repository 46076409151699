import styled, { css } from 'styled-components';
import { zIndex } from '../../../../uiLivi/styles';
import { mediaMaxPx } from '../../../../common/layout';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  pointer-events: none;

  ${mediaMaxPx(
    1200,
    css`
      display: none;
    `
  )};
`;

export const Header = styled.div`
  padding: 24px;
  padding-top: 29px;

  align-self: stretch;

  background: #00676b;
  position: relative;
`;

export const Overlay = styled(motion.div).attrs(props => ({
  style: {
    maxHeight: `${100 * props.vh - 181}px`,
  },
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(180deg, #fff 0%, #f3f6f6 100%);
  color: #000000;
  white-space: pre-line;
  text-align: center;

  margin-right: 24px;
  margin-bottom: 181px;

  opacity: ${props => (props.show ? 1 : 0)};
  transform: scale(${props => (props.show ? 1 : 0.75)});
  transition: all ${props => (props.show ? '.35s ease-out' : '.1s ease-in')};
  pointer-events: ${props => (props.show ? 'all' : 'none')};

  overflow: auto;
  width: 100%;
  max-width: 461px;

  position: relative;
`;

export const copyPaddingTop = 21;
export const copyPaddingBottom = 24;

const gradientRightPosition = 95;
const gradientBottomPosition = 95;

export const Content = styled(motion.div)`
  overflow: auto;

  font-family: AzoSans;
  font-size: 16px;

  ${props =>
    props.$hasFade &&
    css`
      mask-image: linear-gradient(to bottom, black, black ${gradientBottomPosition}%, rgba(255, 255, 255, 0) 100%),
        linear-gradient(
          to right,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0) ${gradientRightPosition}%,
          black ${gradientRightPosition}%,
          black 100%
        );
    `};
`;

export const Title = styled.div`
  color: #fff;
  font-family: AzoSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;

  opacity: 0.8;
  text-align: left;
`;

export const Headline = styled.div`
  color: #fff;
  font-family: AzoSansBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;

  margin-top: 12px;
  //max-width: 290px;
  text-align: left;
`;

export const Copy = styled(motion.div)`
  color: #00676b;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.6);
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 27.2px */

  padding: ${copyPaddingTop}px;
  padding-top: ${copyPaddingBottom}px;
  text-align: left;
`;
