import { BannerContainer, StyledBanner } from '../styles';
import trackerBannerBg from '../images/PDP_TeaserImg_TrackingSystem.png';
import flatBannerBg from '../images/PDP_TeaserImg_FixGrid.png';
import pitchedBannerBg from '../images/PDP_TeaserImg_RoofHooks.png';
import testCenterBackground from '../images/test-center.png';
import aboutMapBackgroundDesktop from '../images/about-map.png';
import aboutMapBackgroundMobile from '../images/about-map-mobile.png';
import { useCallback, useMemo } from 'react';
import { useDistrictStore } from '../../../../../services/DistrictService';
import { useHistory } from 'react-router-dom';
import { useNavigationStore } from '../../../../../services/NavigationService';
import EnterRoomButton from '../icons/enter-room-button.svg';
import { useWindowStore } from '../../../../../services/WindowService';
import MapIcon from '../icons/map-icon.svg';
import { SvgButtonHoverEffect } from '../../../../../uiLivi/styles';
import { useConstructionAssemblyService } from '../../../../../services/ConstructionAssemblyService';

function Banner({ firstLine, headline, description, color, desktopBg, mobileBg, bgColor, text, onClick, aspect }) {
  const vh = useWindowStore(state => state.vh);

  return (
    <StyledBanner desktopBg={desktopBg} mobileBg={mobileBg} bgColor={bgColor} color={color} aspect={aspect} vh={vh}>
      <div className="firstLine">{firstLine}</div>
      <div className={`headline ${firstLine && 'hasFirstLine'}`}>{headline}</div>
      {description ? (
        <div className="description">{description}</div>
      ) : (
        <SvgButtonHoverEffect className="enterRoomButton">
          <EnterRoomButton onClick={onClick} data-sound-action="click">
            {text}
          </EnterRoomButton>
        </SvgButtonHoverEffect>
      )}
    </StyledBanner>
  );
}

export default function CTAs({
  constructionAndAssembly = false,
  testCenter = false,
  map = false,
  constructionAssemblyId = null,
}) {
  const history = useHistory();
  const setShowProductInfoLayer = useNavigationStore(state => state.setShowProductInfoLayer);
  const setSelectedModelId = useConstructionAssemblyService(state => state.setSelectedModelId);

  const transitionTo = useCallback(
    districtId => () => {
      if (constructionAssemblyId) setSelectedModelId(constructionAssemblyId);
      setShowProductInfoLayer(false);
      useDistrictStore.getState().navigateToDistrict(history, districtId);
    },
    [history, constructionAssemblyId]
  );

  const desktopBg = useMemo(() => {
    switch (constructionAssemblyId) {
      case 'TrackingSystem':
        return trackerBannerBg;
      case 'FixGridPro':
        return flatBannerBg;
      case 'RoofHooks':
        return pitchedBannerBg;
    }
  }, [constructionAssemblyId]);

  return (
    <>
      {(constructionAndAssembly || testCenter || map) && (
        <BannerContainer>
          {testCenter && (
            <Banner
              aspect={881 / 594}
              firstLine="THE WEATHER LAB"
              headline="Put our tracking systems to the ultimate test"
              desktopBg={testCenterBackground}
              onClick={transitionTo('weather')}
            />
          )}
          {constructionAndAssembly && (
            <Banner
              aspect={881 / 594}
              firstLine="TIME FOR A CHANGE OF SCENERY?"
              headline="THE WORKSHOP"
              desktopBg={desktopBg}
              onClick={transitionTo('construction')}
            />
          )}
          {map && (
            <Banner
              aspect={1320 / 953}
              headline="Tap the map."
              description="To switch rooms and locations, just open the menu, and click the map icon."
              desktopBg={aboutMapBackgroundDesktop}
              mobileBg={aboutMapBackgroundMobile}
              bgColor="#F9FFFB"
            />
          )}
        </BannerContainer>
      )}
    </>
  );
}
