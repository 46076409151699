import { useEffect, useRef, useState } from 'react';
import { useWeatherStore } from '../Play/WeatherScene/store';
import { CHARTS_WEATHER } from '../Play/WeatherScene/WeatherUI/TextDisplay/config';
import { usePlayAmbientHowls } from './hooks';

const windSounds = {
  strength1: 'ambient_weather_wind-1',
  strength2: 'ambient_weather_wind-2',
  strength3: 'ambient_weather_wind-3',
  strength4: 'ambient_weather_wind-4',
};

export default function WindHandler() {
  const requestRef = useRef(null);

  const [windSound, setWindSound] = useState(null);

  const update = () => {
    const sliderProgress = useWeatherStore.getState().sliderProgress;

    let nextWindSound = null;
    for (let i = 0; i < CHARTS_WEATHER.length; i++) {
      const range = CHARTS_WEATHER[i].range;
      if ((sliderProgress === 0 && range[0] === 0) || (range[0] < sliderProgress && sliderProgress <= range[1])) {
        nextWindSound = windSounds[CHARTS_WEATHER[i].sound];
        break;
      }
    }
    setWindSound(nextWindSound);
    requestRef.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  usePlayAmbientHowls([windSound]);

  return null;
}
