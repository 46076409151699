import { StyledBouncePage, StyledCopy, StyledLogo } from './styles';
import LG_Logo from 'assets/Lg_Logo.png';

export default function BouncePage() {
  return (
    <StyledBouncePage>
      <StyledLogo src={LG_Logo} />
      <StyledCopy>It seems your browser is not capable to handle the virtual experience</StyledCopy>
      <p>Please use a modern browser like Google Chrome</p>
    </StyledBouncePage>
  );
}
