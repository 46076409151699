import styled, { css, keyframes } from 'styled-components';
import { colors } from 'uiLivi/styles';
import { textOverflowEllipsis } from '../../../../common/text';

export const StyledChatOverView = styled.div`
  padding: 5px 0;
`;

export const rowInAnimation = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const lastItemInRowStyle = css`
  border-bottom: 1px solid rgba(221, 221, 221, 0.5);
  padding: 5px 0 10px 0;
  margin-bottom: 5px;
`;

export const StyledRow = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  padding: 5px 0;
  cursor: pointer;
  ${props => props.lastItem && lastItemInRowStyle};
  transform: translateY(50px);
  opacity: 0;
  animation: ${rowInAnimation} 0.75s ease-out forwards;
  animation-delay: ${props => (props.animationIndex ? (props.animationIndex - 1) * 50 + 'ms' : 0)};
  animation-timing-function: cubic-bezier(0.17, 0.67, 0, 0.98);
  transition: color 0.15s ease;

  &:hover {
    color: ${colors.accent.base};
  }
`;

export const StyledUserName = styled.div`
  ${textOverflowEllipsis}
  max-width: 200px;
  padding: 5px 0;
  color: white;
`;
