import styled, { css, keyframes } from 'styled-components';
import { center, colors, easings } from 'uiLivi/styles';
import { hexToRgba } from 'utilities/color';

const backgroundStyle = css`
  background-color: ${hexToRgba(colors.background.base, 0.85)};
  backdrop-filter: blur(4px);
`;

const appear = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0) scale(1);
  }
`;

export const SlackWrapper = styled.div`
  ${center.absolute()};
  ${backgroundStyle};
  width: 300px;
  animation: ${appear} 0.5s ${easings.easeOutStrong} forwards;
`;

export const ContentWrapper = styled.div`
  padding: 28px 38px;
`;

export const Topline = styled.h3`
  font-size: 10px;
  font-weight: normal;
  margin-bottom: 3px;
`;

export const Headline = styled.h2`
  margin-top: 3px;
  font-size: 14px;
  font-weight: bold;
`;

export const Subline = styled.h3`
  font-size: 10px;
  font-weight: normal;
  margin-top: -10px;
`;

export const Paragraph = styled.p`
  font-size: 12px;
  line-height: 14.4px;
`;
