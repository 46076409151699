import { useNotificationStore } from 'services/NotificationService';
import { useWindowStore } from 'services/WindowService';
import { useChatStore } from 'services/ChatService';
import styled, { css } from 'styled-components';
import CloseButton from 'common/components/CloseButton';
import { animations, center, colors, zIndex } from 'uiLivi/styles';

const chatWidth = 320;

const defaultMobilePosition = css`
  bottom: 0;
  left: 0;
  //right: 0;
  //margin: 0 auto;
  width: calc(100% - 56px);
  max-width: 320px;
`;

const defaultPosition = css`
  top: 15px;
  right: 15px;
  transition: transform 0.15s ease;
  transform: translateX(${props => (props.offsetRight ? -chatWidth : 0)}px);
`;

const bottomPositionStyle = css`
  ${center.horizontal()};
  bottom: 32px;
`;

const StyledNotifications = styled.div`
  position: absolute;
  z-index: ${zIndex.modalAndNotifications};
  ${props => {
    switch (props.notificationPosition) {
      case 'default':
        return props.isMobile ? defaultMobilePosition : defaultPosition;
      case 'bottom':
        return bottomPositionStyle;
      default:
        return;
    }
  }}
`;

const positionDefault = {
  top: 0,
  right: 0,
};

const positionMobile = {
  top: 0,
  left: 0,
};

const Notifications = () => {
  const isMobile = useWindowStore(state => state.isMobile);
  const notifications = useNotificationStore(state => state.notifications);
  const notificationLevel = useNotificationStore.getState().level;
  const notificationPosition = useNotificationStore.getState().position;
  const isVisible = useNotificationStore(state => state.notifications.length > 0);
  const isChatOpen = useChatStore(state => state.isActive);
  const isFullHeight = useChatStore(state => state.isFullHeight);
  const buttonColor = colors[notificationLevel];
  const defaultPosition = notificationPosition === 'default';

  const close = () => {
    useNotificationStore.getState().closeNotification();
  };

  return (
    <StyledNotifications
      onClick={close}
      offsetRight={isChatOpen && isFullHeight && defaultPosition}
      notificationPosition={notificationPosition}
      isMobile={isMobile}
    >
      {isVisible && (
        <>
          {notifications[notifications.length - 1]}
          <CloseButton
            xColor={buttonColor}
            handler={close}
            position={!isMobile ? positionDefault : positionMobile}
            noBackground
            withAnimation={animations.showFromBottom}
          />
        </>
      )}
    </StyledNotifications>
  );
};

export default Notifications;
