import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useScrollStore } from '../../../../services/ScrollService';

const Wrapper = styled.div`
  position: absolute;
  width: 50%;
  bottom: -35px;
`;
const ProgressBar = styled.div`
  position: absolute;
  width: 100%;
  height: 5px;
  border: 1px solid black;
  overflow: hidden;
  border-radius: 3px;
`;

const Bar = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000000;
  transition: transform 0.2s;
`;

export default function DebugProgressBar() {
  const requestRef = useRef(null);
  const bar = useRef(null);
  const display = useRef(null);

  const update = () => {
    const progress = useScrollStore.getState().normalizedProgress;
    bar.current.style.transform = `translateX(${-100 + progress * 100}%)`;
    display.current.textContent = progress.toFixed(3);
    requestRef.current = requestAnimationFrame(update);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <Wrapper>
      <div ref={display} style={{ color: '#000000' }} />
      <ProgressBar>
        <Bar ref={bar} />
      </ProgressBar>
    </Wrapper>
  );
}
