import TextLink from './TextLink';

export default function textLink(rawText) {
  const urlExp = /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]))/gim;
  const split = rawText.split(urlExp);
  return split.map((t, index) => {
    if (t)
      if (t.match(urlExp)) {
        if (t.indexOf('http://') === -1 && t.indexOf('https://') === -1) t = 'https://' + t;
        return (
          <TextLink key={index} to={t}>
            {t}
          </TextLink>
        );
      } else return <span key={index}>{t}</span>;
    else return null;
  });
}
