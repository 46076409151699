import { Button } from 'common/components/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { useChatStore } from 'services/ChatService';
import { useSocketStore } from 'services/SocketService';
import { connect, createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import Participant from './Participant';
import { Countdown, Participants, Spinner, UiContainer } from './styles';
import useLocalTracks from './useLocalTracks';
import MicOnIcon from 'assets/icons/Icon_MicOn.svg';
import MicOffIcon from 'assets/icons/Icon_MicOff.svg';
import CameraOnIcon from 'assets/icons/Icon_CameraOn.svg';
import CameraOffIcon from 'assets/icons/Icon_CameraOff.svg';
import { breakPoints } from 'common/layout';
import { usePlayerStore } from 'services/PlayerService';
import { usePovStore } from 'services/PovService';
import { useUserStore } from 'services/UserService';
import { useDistrictStore } from 'services/DistrictService';
import { useEventStore } from 'services/EventService';
import { useHowlerStore } from 'services/HowlerService';
import { MODE_FITTING } from 'services/PlayerService/constants';
import { useSlackStore } from 'services/SlackService';

export default function Twilio({ userInitials, mapId }) {
  const [token, setToken] = useState(null);
  const [connectedArea, setConnectedArea] = useState(null);
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [shouldReconnect, setShouldReconnect] = useState(false);
  const [participantInitials, setParticipantInitials] = useState({});
  const chatOpen = useChatStore(state => state.isActive);
  const slackUiopen = useSlackStore(state => state.open);

  const avatarArea = usePlayerStore(state => state.area);
  const district = useDistrictStore(state => state.district);
  const pov = usePovStore(state => state.pov);
  const useAvatars = useEventStore(state => state.event.useAvatars);
  const isExpert = useUserStore(state => state.user && state.user.role.type === 'expert');
  const selfIsFitting = usePlayerStore(state => state.mode === MODE_FITTING);

  const [orbitArea, setOrbitArea] = useState(null);
  useEffect(() => {
    if (!useAvatars && pov && pov.id === 'meeting') {
      setOrbitArea('meeting');
    } else {
      setOrbitArea(null);
    }
  }, [pov, useAvatars]);
  const isGlobalVoice = district?.isGlobalVoiceArea;
  const area = isGlobalVoice ? district.room : avatarArea || orbitArea;
  const showUiInGlobalVoice = !isGlobalVoice || district?.isGlobalVoicePublic || isExpert;

  const countdownDuration = 5000;
  const isMobile = window.innerWidth < breakPoints.small.max;

  const socket = useSocketStore.getState().getSocket();

  const onToken = useCallback(
    ({ roomName, token, participantInitials }) => {
      if (!roomName.includes(area)) {
        // eslint-disable-next-line no-console
        console.warn('onToken - wrong room', roomName, area);
        return;
      }
      setParticipantInitials(participantInitials);
      setToken(token);
    },
    [area]
  );

  const onParticipantInitials = useCallback(
    ({ identity, participantInitials }) => {
      setParticipantInitials(p => ({ ...p, [identity]: participantInitials }));
    },
    [setParticipantInitials]
  );

  useEffect(() => {
    socket.on('twilio/token', onToken);
    socket.on('twilio/participantInitials', onParticipantInitials);
    return () => {
      socket.off('twilio/token', onToken);
      socket.off('twilio/participantInitials', onParticipantInitials);
    };
  }, [socket, onToken]);

  // starts connection and marks by setting connectedArea
  const requestToken = useCallback(() => {
    if (!area) {
      // eslint-disable-next-line no-console
      console.warn('no area while requesting token');
      return;
    }
    socket.emit('twilio/enterRoom', { roomName: area });
    setConnectedArea(area);
    if (!isMobile && !isGlobalVoice) {
      useChatStore.getState().shiftForVideoChat();
    }
  }, [socket, setConnectedArea, area, isGlobalVoice]);

  // new area, either connect or handle room change
  useEffect(() => {
    if (area) {
      setShowCountdown(false);
      if (area === connectedArea) return;
      if (room) {
        setShouldReconnect(true);
        setTimeout(() => {
          disconnect();
        });
        return;
      }
      requestToken();
    }
  }, [area]);

  useEffect(() => {
    if (!area) {
      setShowCountdown(true);
      const timer = setTimeout(() => {
        setShowCountdown(false);
        setConnectedArea(null);
        disconnect();
      }, countdownDuration);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [area]);

  const { localTracks } = useLocalTracks();

  useEffect(() => {
    if (!token) return;
    connect(token, {
      name: area,
      audio: false,
      video: false,
      tracks: localTracks,
      bandwidthProfile: {
        video: {
          mode: isExpert ? 'presentation' : 'collaboration',
        },
      },
    }).then(
      room => {
        if (!connectedArea) {
          disconnect();
          return;
        }
        setIsAudioOn(false);
        setIsVideoOn(false);
        setParticipants(p => p.concat(Array.from(room.participants.values())));
        setRoom(room);
        setShouldReconnect(false);
        if (!isGlobalVoice) useHowlerStore.getState().fadeOutSound();
      },
      error => {
        // eslint-disable-next-line no-console
        console.error(`Unable to connect to Room: ${error.message}`);
      }
    );
  }, [setIsAudioOn, setIsVideoOn, setRoom, setParticipants, token]);

  const onParticipantConnected = useCallback(
    participant => {
      setParticipants(participants => participants.concat(participant));
    },
    [setParticipants]
  );

  const onParticipantDisconnected = useCallback(
    participant => {
      setParticipants(participants => participants.filter(p => p.identity !== participant.identity));
    },
    [setParticipants]
  );

  const onDisconnected = useCallback(
    room => {
      // Detach the local media elements
      room.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
      });
      setRoom(null);
      setParticipants([]);
      if (shouldReconnect) requestToken();
    },
    [shouldReconnect, requestToken, setRoom, setParticipants]
  );

  useEffect(() => {
    if (!room) return;
    room.on('participantConnected', onParticipantConnected);
    room.on('participantDisconnected', onParticipantDisconnected);
    room.on('disconnected', onDisconnected);

    return () => {
      if (!room) return;
      room.off('participantConnected', onParticipantConnected);
      room.off('participantDisconnected', onParticipantDisconnected);
      room.off('disconnected', onDisconnected);
    };
  }, [room, onParticipantConnected, onParticipantDisconnected, onDisconnected]);

  useEffect(() => {
    if (!room) return;
    return () => {
      disconnect();
    };
  }, [room]);

  const toggleAudio = useCallback(async () => {
    const localParticipant = room.localParticipant;
    if (isAudioOn) {
      localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });
      setIsAudioOn(false);
    } else {
      if (localParticipant.audioTracks.size === 0) {
        try {
          const localAudioTrack = await createLocalAudioTrack();
          localParticipant.publishTrack(localAudioTrack);
          localParticipant.emit('trackSubscribed', localAudioTrack);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } else {
        localParticipant.audioTracks.forEach(publication => {
          publication.track.enable();
        });
      }
      setIsAudioOn(true);
    }
  }, [room, isAudioOn, createLocalAudioTrack]);

  const toggleVideo = useCallback(async () => {
    const localParticipant = room.localParticipant;
    if (isVideoOn) {
      resetLocalParticipantVideoTracks();
      setIsVideoOn(false);
    } else {
      if (localParticipant.videoTracks.size === 0) {
        try {
          const localVideoTrack = await createLocalVideoTrack({
            width: isMobile ? 48 * devicePixelRatio : 300,
            height: isMobile ? 48 * devicePixelRatio : 200,
          });
          localParticipant.publishTrack(localVideoTrack);
          localParticipant.emit('trackSubscribed', localVideoTrack);
          setIsVideoOn(true);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }
  }, [room, isVideoOn, createLocalVideoTrack]);

  const resetLocalParticipantAudioTracks = useCallback(() => {
    room.localParticipant.audioTracks.forEach(publication => {
      publication.track.stop();
      publication.unpublish();
      room.localParticipant.emit('trackUnsubscribed', publication.track, publication, room.localParticipant);
    });
  }, [room]);

  const resetLocalParticipantVideoTracks = useCallback(() => {
    room.localParticipant.videoTracks.forEach(publication => {
      publication.track.stop();
      publication.unpublish();
      room.localParticipant.emit('trackUnsubscribed', publication.track, publication, room.localParticipant);
    });
  }, [room]);

  const resetLocalParticipantTracks = useCallback(() => {
    resetLocalParticipantAudioTracks();
    resetLocalParticipantVideoTracks();
  }, [resetLocalParticipantAudioTracks, resetLocalParticipantVideoTracks]);

  const disconnect = useCallback(() => {
    setIsAudioOn(false);
    setIsVideoOn(false);
    setParticipants([]);
    setShowCountdown(false);
    useChatStore.getState().unshiftForVideoChat();
    if (room) {
      resetLocalParticipantTracks();
      setRoom(null);
      room.disconnect();
    }
    if (!isGlobalVoice) useHowlerStore.getState().fadeInSound();
  }, [room]);

  return (
    <>
      <Participants visible={!isGlobalVoice && !!connectedArea} chatOpen={chatOpen}>
        {room ? (
          <>
            <Participant participant={room?.localParticipant} initials={userInitials}></Participant>
            {participants.map(p => (
              <Participant key={p.identity} participant={p} initials={participantInitials[p.identity]}></Participant>
            ))}
          </>
        ) : connectedArea ? (
          // only show spinner when entering an area
          <Spinner />
        ) : null}
      </Participants>
      <UiContainer visible={connectedArea && room && showUiInGlobalVoice && !slackUiopen}>
        {!selfIsFitting && (
          <Button accent veStyle onClick={toggleAudio}>
            {isAudioOn ? <MicOnIcon /> : <MicOffIcon />}
          </Button>
        )}
        {!isGlobalVoice && (
          <Button accent veStyle onClick={toggleVideo}>
            {isVideoOn ? <CameraOnIcon /> : <CameraOffIcon />}
          </Button>
        )}
      </UiContainer>
      {showCountdown && connectedArea ? (
        <Countdown>You are leaving the {connectedArea.split('_')[0]} zone.</Countdown>
      ) : null}
    </>
  );
}
