import styled, { css } from 'styled-components';
import { zIndex, center } from '../../uiLivi/styles';
import { mediaMax } from '../../common/layout';

export const NavigationContainer = styled.div`
  position: fixed;
  z-index: ${props => (props.show ? zIndex.menu : 0)};
  top: 0;
  left: 0;
  height: 90px;
  width: 100vw;
  user-select: none;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease-in;
  display: ${props => (props.disabled ? 'none' : 'block')};
`;

export const NavigationBar = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.div`
  display: flex;
  cursor: pointer;
`;

export const LogoText = styled.div`
  font-family: 'AzoSansBlack', serif;
  font-size: 14px;
  font-weight: 900;
  line-height: 13.5px;
  margin-left: 10px;
  letter-spacing: -0.02em;
`;

export const LocationSelector = styled.div`
  ${center.horizontal()};
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  border: 0.5px solid rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(1px);
  border-radius: 27.5px;
  padding: 10px 35px 10px 55px;
  cursor: pointer;

  pointer-events: ${props => (props.show ? 'all' : 'none')};
  opacity: ${props => (props.show ? 1 : 0)};

  transition: opacity 0.3s ease-in-out, background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const LocationIcon = styled.div`
  position: absolute;
  left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.12);
`;

export const LocationText = styled.div`
  font-family: 'AzoSans', serif;
  font-size: 12px;
  font-weight: 700;
  text-shadow: 0 0 4px #ffffff;
  text-transform: uppercase;
`;

export const ButtonsMobile = styled.div`
  position: absolute;
  z-index: 2;
  right: 24px;
  bottom: 32px;
`;

export const MobileMenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);

  pointer-events: ${props => (props.show ? 'all' : 'none')};
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

export const ImprintButtons = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  right: 0;
  bottom: 32px;
  padding-right: 32px;

  ${mediaMax(
    'small',
    css`
      left: 0;
      padding-left: 32px;
    `
  )};
`;

export const StyledAnchor = styled.a`
  font-family: 'AzoSans', sans-serif;
  text-decoration: underline;
  letter-spacing: 0.09em;
  color: #ffffff;
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.8;
  text-shadow: 0 0 4px #ffffff;

  &:visited,
  &:link {
    text-decoration: underline;
  }
`;
