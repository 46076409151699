import React, { useMemo } from 'react';
import SkyMesh from './SkyMesh';
import { useFrame } from '@react-three/fiber';

export default function Sky({ size, fadeY, config }) {
  const sky = useMemo(() => {
    return new SkyMesh();
  }, []);

  useFrame(() => {
    const x = Math.sin(config.inclination) * Math.cos(config.azimuth);
    const y = Math.cos(config.inclination);
    const z = Math.sin(config.inclination) * Math.sin(config.azimuth);

    var uniforms = sky.material.uniforms;
    uniforms['fadeY'].value = fadeY;
    uniforms['sunPosition'].value = [x, y, z];
    uniforms['mixValue'].value = config.mixValue;
  });
  return <primitive name="Sky" object={sky} position={[0, 0, 0]} scale={[size, size, size]} renderOrder={-1100} />;
}
