import React, { Suspense, useRef } from 'react';
import AnimationController from './AnimationController';
import ParticleFX from '../ParticleFX';
import PlayerIndicator from '../../PlayerIndicator';
import TeleportFX from '../TeleportFX';
import PlayerEmojiFX from '../PlayerEmojiFX';

export default function Character({
  playerData = {
    position: [0, 0, 0],
    rotation: 0,
    velocity: [0, 0, 0, 0],
  },
  reactionId = 0,
  isSelf = false,
  indicatorVisible = false,
  appearance,
  children,
  ...props
}) {
  const group = useRef();
  const scale = 1.0;
  const hasUserData = playerData.user !== undefined;
  return (
    <group ref={group} {...props}>
      <Suspense fallback={null}>
        <ParticleFX reactionId={reactionId} />
        {hasUserData && <PlayerEmojiFX userId={playerData.user.id} />}
        <PlayerIndicator visible={indicatorVisible} />
      </Suspense>
      <TeleportFX reactionId={reactionId} />
      <AnimationController
        group={group}
        isSelf={isSelf}
        getPlayerData={() => playerData}
        scale={[scale, scale, scale]}
        reactionId={reactionId}
        appearance={appearance}
      />
      {children}
    </group>
  );
}
