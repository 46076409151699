import create from 'utilities/zustand/create';
import { useTheaterStore } from '../TheaterService';
import { CONTENT_TYPE } from '../ContentService/contentTypes';

let socket = null;

export const useActivityStore = create((set, get) => ({
  activities: [],
  requestedUserId: -1,

  init: managedSocket => {
    socket = managedSocket;
  },

  addActivity: data => {
    socket.emit('activity/add', data);
  },

  enterContent(content) {
    let talk = content.talk || null;
    if (!talk && content.location === 'stage01' && useTheaterStore.getState().currentTheaterTalk)
      talk = useTheaterStore.getState().currentTheaterTalk;
    const data = {
      type: content.type ? content.type.id : CONTENT_TYPE.DEMO.id,
      label: (talk && talk.title) || content.title || content.headline,
      location: content.location,
    };
    socket.emit('activity/set', data);
  },

  leaveContent() {
    socket.emit('activity/set', null);
  },
}));
