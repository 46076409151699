import create from 'utilities/zustand/create';

export const WarmupPhase = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  ENTERING: 'ENTERING',
  INIT: 'INIT',
  DONE: 'DONE',
};

export const useDistrictWarmupStore = create(set => ({
  phase: WarmupPhase.IDLE,
  enteringProgress: 0,
  loadingProgress: 0,
  setPhase: phase => {
    set({ phase });
  },
  setLoadingProgress: loadingProgress => {
    set({ loadingProgress });
  },
}));
