export const Details = {
  // Tracking-System

  TrackingSystem_Girders: {
    headline: 'THE GIRDERS',
    copy:
      'For ease, durability, and taking the strain.\n' +
      '\n' +
      'Our fully patented gear arches transfer all occurring loads into the ground at each post, and their 120° rotational range ensures maximum power output.\n' +
      '\n' +
      'Plus, with wind resistance of up to 257 km/h, they’ll carry on supporting your system through catastrophic conditions.\n' +
      '\n' +
      'You can order them pre-assembled or unassembled.',
  },
  TrackingSystem_Posts: {
    headline: 'THE POSTS',
    copy:
      'For strength, stability, and material efficiency.\n' +
      '\n' +
      'Designed to be fixed 100 cm into the ground, our high-strength, galvanized steel ramming posts make installing your Tracking System 1P/2P even easier.\n' +
      '\n' +
      'Perfect for flat ground, or terrain with a <10° slope.',
  },
  TrackingSystem_Purlins: {
    headline: 'THE PURLINS',
    copy:
      'For year-round protection and easy installations.\n' +
      '\n' +
      'Our lightweight, high-strength steel purlins come with pre-drilled holes to make attaching panels and components as straightforward as possible.\n' +
      '\n' +
      'The galvanized zinc coating protects them from rust and erosion, and the steel itself is more than capable of withstanding the loads imposed by your panels, even in high winds and heavy snow.\n' +
      '\n' +
      'And, importantly, they can be fixed from below, so you can leave your ladder in the van.',
  },
  'TrackingSystem_Drive units': {
    headline: 'THE DRIVE UNITS AND DRIVE SHAFTS',
    copy:
      'For stability, security, and optimal performance.\n' +
      '\n' +
      'Each table has a unique and patented self-locking method at every post, which makes them as stable as a fixed tilt in every position.\n' +
      '\n' +
      'To eliminate the galloping effect and achieve a secure, positive fit at every angle, we’ve fitted the gear arch with a Geneva drive, giving you a much higher degree of investment security.',
  },
  'TrackingSystem_PV modules': {
    headline: 'MOUNT YOUR MODULES',
    copy:
      'For safety, simplicity, and compatibility.\n' +
      '\n' +
      'The Tracking System 1P is designed to fully support your modules and make it easy for you to screw them in from below without gaps forming above the posts.\n' +
      '\n' +
      'It’s also fully optimized for the latest generation of large bifacial modules, and compatible with First Solar modules.',
  },
  'TrackingSystem_Tracking Control Unit': {
    headline: 'THE TRACKING CONTROL UNIT (TCU)',
    copy:
      'For live tracking and real-time communication.\n' +
      '\n' +
      'Using a combination of GPS, GLONASS, and BeiDou satellite signals, our highly sophisticated TCU tracks the sun as accurately as possible, optimizing your system`s efficiency and performance.\n' +
      '\n' +
      'For wireless systems, it uses ZigBee for instant communication. For wired systems, it uses RS485.\n' +
      '\n' +
      'It`s straightforward to mount. All you need are a handful of M8 or M6 fasteners, depending on the fasteners used for the PV modules themselves.\n' +
      '\n' +
      'Available in grid-powered (GP) and self-powered (SP) versions.\n' +
      '\n' +
      '(Include AI predictive maintenance)',
  },

  // Roof-Hooks

  'RoofHooks_Roof hooks': {
    headline: 'THE ROOF HOOKS',
    copy:
      'For security, simplicity, and compatibility.\n' +
      '\n' +
      'Available in aluminum, steel, and high-strength steel, our Rapid A Pro roof hooks make installing your ProLine system quick and easy.\n' +
      '\n' +
      'They`re compatible with almost all pitched roofs—including tile, slate, and metal—and come in sizes varied enough to suit every batten and pitch.\n' +
      '\n' +
      'Just click them into place, and connect them with a Torx T40 screwdriver bit.',
  },
  'RoofHooks_Module support': {
    headline: 'THE MODULE SUPPORT RAILS',
    copy:
      'For strength, performance, and efficiency.\n' +
      '\n' +
      'With our new and improved Module Support Rail Pro, your ProLine system will benefit from a higher load capacity, fully optimized rail geometries, and significantly less material usage.\n' +
      '\n' +
      'Connect them easily to all your ProLine components by simply tightening the pre-assembled screws.',
  },
  RoofHooks_Clamps: {
    headline: 'THE CLAMPS',
    copy:
      'For easy installations and material efficiency.\n' +
      '\n' +
      'To make installing your ProLine system even easier, we’ve designed the Rapid Pro module clamp: a one-size-fits-all clamp to fit all module frames between 30 and 47 mm.\n' +
      '\n' +
      'They come pre-assembled, so all you have to do is click them in and fasten them tightly with just one M8×40 screw each.',
  },

  // Fix-Grid

  'FixGridPro_Protection mats': {
    headline: 'THE PROTECTIVE MATS',
    copy:
      'For material safety and preservation.\n' +
      '\n' +
      'Aluminum lamination beneath each mat protects your roofing membrane from potentially harmful softeners, so you’ll never have to worry about replacing your plasticizer.',
  },
  'FixGridPro_Floor profile': {
    headline: 'THE BASE RAILS',
    copy:
      'For durability, versatility, and reliability.\n' +
      '\n' +
      'Made of high-quality aluminum and designed to withstand the harshest wind, snow, and rain, our FixGrid Pro base rails can be used on a variety of flat roof surfaces, including concrete, bitumen, and EPDM.\n' +
      '\n' +
      "They're compatible with a wide range of solar modules—including First Solar's—and require no special tools to install.\n" +
      '\n' +
      'The ballast blocks are to be placed lengthwise on the base rails. If the required ballasts are higher than the space under the modules, additional ballast rails may be required. Alternatively, the use of a gravel bed is possible.',
  },
  'FixGridPro_Module support': {
    headline: 'THE MODULE SUPPORT RAILS',
    copy:
      'For easy and efficient installations.\n' +
      '\n' +
      'To make your installations even easier, we’ve swapped screwing connections for screw-in aids, which connect your base profile to your module support.\n' +
      '\n' +
      'Then it’s just a case of clicking in the Pro heightening elements until you reach the height and angle you want.\n' +
      '\n' +
      "For quarter-point clamping, you'll need two rails per row instead of one between each row. Either way, you can mount your modules in portrait or landscape mode.",
  },
  'FixGridPro_Heightening elements': {
    headline: 'THE HEIGHTENING ELEMENTS',
    copy:
      'For security, longevity, and resourcefulness.\n' +
      '\n' +
      'Our FixGrid Pro heightening elements are made of high-quality aluminum, and are available in multiple heights to help clear obstacles on your roof or to create a more level surface for your panels.\n' +
      '\n' +
      'They’re easy to install, resistant to corrosion, and compatible with various roof materials, including asphalt shingles, metal, and concrete tile.',
  },
  'FixGridPro_Module clamps': {
    headline: 'THE CLAMPS',
    copy:
      'For compatibility and material minimalism.\n' +
      '\n' +
      'We’ve designed our RapidPro module clamps to fit all solar systems—including First Solar’s—making them the only clamps you’ll need.\n' +
      '\n' +
      'To fit one, just click it in and secure it tightly with a single M8x40 screw.',
  },
};
