import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'common/components/Input';
import { usePeopleStore } from 'services/PeopleService';
import { lgBaseColors } from 'uiLivi/styles';
import { useTranslate } from 'services/TranslationService/hooks';

const StyledChatFilter = styled.div`
  position: relative;
  z-index: 2;
  padding: 10px;
  box-shadow: 0 1px 1px rgba(102, 104, 146, 0.11), 0 2px 2px rgba(102, 104, 146, 0.11);
  input {
    border: 1px solid ${lgBaseColors.grey.medium};
    background: black;
    color: white;
  }
`;

const ChatFilter = () => {
  const search = usePeopleStore(state => state.search);
  const translate = useTranslate();

  const [input, setInput] = useState('');
  useEffect(() => {}, [input]);
  const onFormSubmit = e => {
    e.preventDefault();
    setInput('');
  };

  return (
    <StyledChatFilter>
      <form onSubmit={onFormSubmit}>
        <Input
          margin={'none'}
          onChange={e => {
            usePeopleStore.getState().setSearchValue(e.target.value);
          }}
          value={search}
          placeholder={translate('chat.search.placeholder') || 'Filter ...'}
        />
      </form>
    </StyledChatFilter>
  );
};

export default ChatFilter;
