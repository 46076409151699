let debugDomElem = null;

const createDebug = () => {
  const body = document.querySelector('body');
  debugDomElem = document.createElement('div');
  debugDomElem.id = 'debug_user_session';
  debugDomElem.style.position = 'absolute';
  debugDomElem.style.right = 0;
  debugDomElem.style.padding = '5px';
  debugDomElem.style.display = 'block';
  debugDomElem.style.zIndex = 1000000;
  debugDomElem.style.backgroundColor = 'black';
  debugDomElem.style.fontSize = '10px';
  debugDomElem.style.color = 'white';
  body.appendChild(debugDomElem);
};

const deleteDebug = () => {
  const body = document.querySelector('body');
  body.removeChild(debugDomElem);
};

const updateDebug = (focus, idle) => {
  if (!debugDomElem) return;
  debugDomElem.innerHTML = `focus ${focus}, idle: ${idle}`;
};

export { createDebug, deleteDebug, updateDebug };
