import create from 'utilities/zustand/create';
import { useGpuDetect } from '../GpuDetectService';
import userStorage from '../../storage/user';

export const useAssetDetails = create((set, get) => ({
  isDetailMobile: false,
  isDetailMobileRecommendation: false,
  init: () => {
    const { isMobile, tier } = useGpuDetect.getState();
    const useMobileAssetDetail = isMobile || tier <= 2;
    userStorage.initAssetDetailIsMobile(useMobileAssetDetail);
    set({ isDetailMobile: userStorage.getAssetDetailIsMobile(), isDetailMobileRecommendation: useMobileAssetDetail });
  },
  changeAssetDetail: isMobile => {
    userStorage.setAssetDetailIsMobile(isMobile);
    set({ isDetailMobile: isMobile });
  },
}));
