/**
 * @see https://threejs.org/examples/#webgl_shaders_sky
 */

import { BackSide, Color, Mesh, ShaderMaterial, SphereGeometry, UniformsLib, UniformsUtils, Vector3 } from 'three';
import skyFrag from './sky.frag';
import skyVert from './sky.vert';

class SkyMesh extends Mesh {
  constructor() {
    super();
    this.geometry = new SphereGeometry(1, 50, 9);
    this.material = new ShaderMaterial({
      name: 'Sky',
      vertexShader: skyVert,
      fragmentShader: skyFrag,
      uniforms: UniformsUtils.merge([
        UniformsLib['fog'],
        {
          fadeY: { value: 0 },
          // color1: { value: new Color(0xf9eee1) },
          // color2: { value: new Color(0xcfdbf3) },
          // color3: { value: new Color(0xcfdbf3) },
          color1: { value: new Color('#c7dbed') },
          color2: { value: new Color('#a5c3e6') },
          color3: { value: new Color('#c7dbed') },
          sunPosition: { value: new Vector3() },
          up: { value: new Vector3(0, 1, 0) },
          mixValue: { value: 0 },
        },
      ]),
      side: BackSide,
      depthTest: false,
      depthWrite: false,
      transparent: false,
      fog: false,
    });
  }
}

export default SkyMesh;
