import React, { useEffect, useState } from 'react';
import PasswordInput from 'common/components/PasswordInput';
import { InputLabel } from 'components/Login/styles';
import { useDeviceStore } from 'services/DeviceService';

function PasswordInputWithCheck({
  passwordLabel = null,
  placeholder,
  password,
  setPassword,
  checkPasswordLabel = null,
  checkPlaceholder,
  checkPassword,
  checkSetPassword,
}) {
  const [passwordsIdentical, setPasswordsIdentical] = useState(password === checkPassword);
  const { setKeyboardActive } = useDeviceStore.getState();

  const handleOnFocus = () => {
    setKeyboardActive(true);
  };
  const handleOnBlur = () => {
    setKeyboardActive(false);
  };

  useEffect(() => {
    setPasswordsIdentical(password === checkPassword);
  }, [password, checkPassword]);

  return (
    <>
      {passwordLabel && <InputLabel>{passwordLabel}</InputLabel>}
      <PasswordInput
        placeholder={placeholder}
        password={password}
        setPassword={setPassword}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {checkPasswordLabel && <InputLabel>{checkPasswordLabel}</InputLabel>}
      <PasswordInput
        placeholder={checkPlaceholder}
        password={checkPassword}
        setPassword={checkSetPassword}
        invalid={!passwordsIdentical}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
    </>
  );
}

export default PasswordInputWithCheck;
