import Pin from 'components/Contents/Pin';
import React, { useEffect } from 'react';
import { useDistrictStore } from 'services/DistrictService';
import { usePlayerStore } from 'services/PlayerService';
import { useSceneStore } from 'services/SceneService';
import { useDebugStore } from 'storage/debug';
import { Vector3 } from 'three';

export default function Portals({ onEnterPortal = () => {} }) {
  const playerZone = usePlayerStore(state => state.zone);
  useEffect(() => {
    const zoneType = playerZone && playerZone.split('_')[0];
    if (zoneType === 'portal') {
      const roomId = playerZone.split('_')[1];
      onEnterPortal(roomId);
      usePlayerStore.setState({ zone: null });
    }
  }, [playerZone]);
  const portals = useDistrictStore(state => state.district && state.district.portals);
  const zoneGroup = useSceneStore(state => state.scene && state.scene.zoneGroup);
  const portalZones = zoneGroup && zoneGroup.children.filter(a => a.name.split('_')[0] === 'portal');
  const debugStore = useDebugStore();
  const disabled = debugStore.getUiDisabled();

  const portalComponents = () => {
    if (portalZones) {
      return portalZones.map((a, i) => {
        const roomId = a.name.split('_')[1];
        const portal = portals && portals.find(a => a.room === roomId);
        const v = new Vector3();
        v.copy(a.position);
        return (
          <group name={roomId} key={i} position={v}>
            {portal && !disabled && (
              <Pin
                onClick={() => onEnterPortal(roomId)}
                content={{
                  ...portal,
                }}
              />
            )}
          </group>
        );
      });
    } else return null;
  };

  //TODO should be state not render function
  const comps = portalComponents();
  if (!comps || comps.length === 0) return null;

  return <group name={'Portals'}>{comps}</group>;
}
