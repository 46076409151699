import styled, { css } from 'styled-components';
import { animations, colors, lgBaseColors } from 'uiLivi/styles';
import CloseButton from 'common/components/CloseButton';
import { useWindowStore } from 'services/WindowService';
import { fontSize, H3, smallFontSize } from 'common/text';
import { iconStyle } from 'common/icon';
import { hexToRgba } from 'utilities/color';

const StyledNotification = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  background: ${lgBaseColors.grey.base};
  backdrop-filter: blur(4px);
  ${props => !props.isLarge && `max-width: 100%`};
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  padding: 0 28px;
  svg {
    ${iconStyle}
  }
`;

const StyledContent = styled.div`
  padding: ${props => (props.isMobile ? '24px 6%' : '24px 40px')};
  background-color: ${hexToRgba(colors.background.base, 0.85)};
  color: white;
  display: flex;
  flex-direction: column;
  max-width: ${props => (props.isLarge ? '320px' : '375px')};
  ${props => props.isMobile && `width: 100%; margin: 0 auto`};
`;

const StyledHeadline = styled(H3)`
  margin: 0;
`;

const StyledText = styled.div`
  font-weight: normal;
  font-size: ${props => (props.small ? smallFontSize : fontSize)}px;
  line-height: ${props => (props.small ? 16 : 22)}px;
  margin: 8px 0;
  ${props => !props.isMobile && `min-width: 220px`};
`;

const StyledButtonWrapper = styled(StyledText)`
  ${props =>
    props.isMobile
      ? css`
          button {
            width: 100%;
            padding: 10px 0;
            margin: 15px 0 0 0;
          }
        `
      : css`
          display: flex;
          button {
            margin: 0 10px 0 0;
          }
        `}
`;

const Notification = ({ icon, headline, text, date = null, buttons = null, closeHandler = false }) => {
  const isMobile = useWindowStore(state => state.isMobile);

  return (
    <StyledNotification isLarge={!isMobile}>
      {!isMobile && <StyledIconContainer>{icon}</StyledIconContainer>}
      <StyledContent isMobile={isMobile}>
        <StyledHeadline>{headline}</StyledHeadline>
        <StyledText isMobile={isMobile}>{text}</StyledText>
        {date && (
          <StyledText isMobile={isMobile} small>
            {date}
          </StyledText>
        )}
        {buttons && <StyledButtonWrapper isMobile={isMobile}>{buttons}</StyledButtonWrapper>}
      </StyledContent>

      {closeHandler && (
        <CloseButton
          black
          size={40}
          position={{ top: 0, right: 0 }}
          handler={closeHandler}
          withAnimation={animations.showFromBottom}
        />
      )}
    </StyledNotification>
  );
};
export default Notification;
