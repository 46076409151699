import { InstructionHeadline, InstructionIconContainer, InstructionText, InstructionWrapper } from '../../../../styles';
import IconControl from '../../../assets/control.png';
import React from 'react';
import styled from 'styled-components';

// const InstructionIcon = styled.img`
//   width: 183px;
//   animation: ${props => (props.needsPulse ? css`1.6s ease-in-out 0s infinite ${pulse}` : 'none')};
//   margin-top: ${props => (props.needsPulse ? '1rem' : 0)};
// `;

const InstructionIcon = styled.div`
  width: 183px;
  height: 65px;
  background-image: url(${IconControl});
  background-size: cover;
`;

export default function OnboardingControl() {
  // const queryParams = qs.parse(window.location.search);

  return (
    <InstructionWrapper>
      <InstructionIconContainer>
        <InstructionIcon />
        {/*<InstructionIcon src={IconControl} needsPulse={queryParams.minimal != null} />*/}
      </InstructionIconContainer>
      <InstructionHeadline>Control the weather.</InstructionHeadline>
      <InstructionText>Slowly move the slider to start changing the conditions.</InstructionText>
    </InstructionWrapper>
  );
}
