import styled, { css } from 'styled-components';
import { panelStyle } from '../styles';
import { lgBaseColors } from 'uiLivi/styles';
import { H1, smallTextStyle, textOverflowEllipsis } from 'common/text';

const padding = '20px';

export const Container = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  padding: ${padding};
`;

export const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MiddleContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TopContainer = styled.div`
  margin-bottom: 70px;
`;

export const Topline = styled.div`
  max-width: 170px;
  ${textOverflowEllipsis};
  ${smallTextStyle};
  color: ${lgBaseColors.grey.light};
`;

export const Headline = styled(H1)`
  margin-top: 4px;
  color: #fff;
`;
