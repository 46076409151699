export default {
  palm: {
    assets: {
      model: `/shared/districts/intersolar/common/trees/palm/1.glb`,
      dataTexture: `/shared/districts/intersolar/common/trees/palm/data.jpg`,
    },
    leavesPivot: [-14.32, 125.0, 4.75],
    data: [
      {
        position: [-5.12, -2.54, 11.29],
        rotation: [0.14, -0.04, 0.2],
        scale: 0.098,
        timeShift: 1.3,
        overAllFactor: 1.0,
      },
      {
        position: [8.01, -0.97, 31.34],
        rotation: [0.23, 0.3, 0.04],
        scale: 0.056,
        timeShift: 0.6,
        overAllFactor: 0.9,
      },
      {
        position: [-12.55, -0.9, 31.79],
        rotation: [0.08, 0.46, -0.18],
        scale: 0.059,
        timeShift: 0.9,
        overAllFactor: 0.97,
      },
      {
        position: [-12.24, -0.17, 30.22],
        rotation: [2.66, 1.13, -2.83],
        scale: 0.043,
        timeShift: 0.9,
        overAllFactor: 0.97,
      },
      {
        position: [-4.57, -0.37, 11.32],
        rotation: [2.4, 1.43, -2.33],
        scale: 0.058,
        timeShift: 1.9,
        overAllFactor: 1.1,
      },
      {
        position: [-9.04, -0.39, 33.69],
        rotation: [0, -1.3, 0.0],
        scale: 0.046,
        timeShift: 1.2,
        overAllFactor: 1.3,
      },
    ],
  },

  thiccPalm: {
    assets: {
      model: `/shared/districts/intersolar/common/trees/thiccPalm/1.glb`,
      dataTexture: `/shared/districts/intersolar/common/trees/thiccPalm/data.jpg`,
    },
    leavesPivot: [1.57, 62.0, -1.3],
    data: [
      {
        position: [-5.7, 0.65, 12.29],
        rotation: [0, 1.32, 0],
        scale: 0.068,
        timeShift: 1.6,
        overAllFactor: 1.4,
      },
      {
        position: [9.42, 0.66, 32.68],
        rotation: [0, -0.66, 0],
        scale: 0.05,
        timeShift: 0.7,
        overAllFactor: 1.3,
      },
    ],
  },
};
