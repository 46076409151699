import { DoubleSide } from 'three';
import { useTraverse } from '../hooks';

export default function usePatchFoliage() {
  useTraverse(() => {
    return o => {
      if (o.material?.name === 'foliage_atlas_mat') {
        o.castShadow = false;
        o.material.side = DoubleSide;
      }
    };
  });
}
