import styled, { css } from 'styled-components';
import ButtonBg from './Button.png';
import { lgBaseColors } from 'uiLivi/styles';

export const Button = styled.button.attrs(props => ({
  'data-sound-action': props.soundAction || 'click',
}))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 17px 17px 15px 17px;
  min-width: 220px;
  font-size: 12px;
  line-height: 1;
  color: ${lgBaseColors.white};
  text-transform: uppercase;
  font-family: 'AzoSans', serif;
  font-weight: 700;
  cursor: pointer;
  text-shadow: 0px 0px 4px ${lgBaseColors.white};
  border-image-source: url(${ButtonBg});
  border-image-slice: 48;
  border-image-width: 48;
`;
