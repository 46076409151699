import styled, { css } from 'styled-components';

import { colors } from 'uiLivi/styles';
import { hexToRgba } from 'utilities/color';

const StyledTextLink = styled.span.attrs(props => ({
  'data-sound-action': 'click',
}))`
  position: relative;
  font-weight: 700;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: ${props => (props.disabled ? '#a1a1a1' : '#fff')};
  line-height: 1;
  transition: 0.5s;

  ${props =>
    props.size &&
    css`
      font-size: ${props.size}px;
    `}

  &:after {
    content: '';
    position: absolute;
    bottom: -7px;
    left: 0;
    width: 100%;
    height: 3px;
    --c1: ${hexToRgba(colors.accent.base, 1)};
    --c2: ${hexToRgba(colors.accent.highlight, 1)};
    background: ${props =>
      props.disabled ? '#a1a1a1' : `linear-gradient(120deg, var(--c2) 0%, var(--c1) 51%, var(--c2) 100%)`};
    background-size: 200% auto;
    transition: 0.5s;
  }

  &:hover {
    color: ${props => (props.disabled ? '#a1a1a1' : '#e7e7e7')};

    &:after {
      background-position: 100% center;
      box-shadow: none;
    }
  }

  ${props => {
    if (props.as && props.as === 'button') {
      return css`
        background: none;
        border: none;
        outline: none;
        padding: unset;
      `;
    }
  }}
`;

const VeTextLink = ({ onClick, children, disabled = false, as = null, size = null }) => {
  const handleClick = () => {
    if (!disabled && onClick) onClick();
  };

  return (
    <StyledTextLink as={as} disabled={disabled} onClick={handleClick} size={size}>
      {children}
    </StyledTextLink>
  );
};

export default VeTextLink;
