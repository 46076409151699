import {
  Box,
  // CloseButtonContainer,
  Headline_26_900,
  ProductData,
  ProductImage,
  ProductName,
  ProductOverview,
  ProductSelectionContainer,
  ProductType,
  Subline,
  Bottom,
  Content,
  Padder,
  PaginationContainer,
  TriangleWrapper,
} from './styles';
// import CloseButton from '../../ScrollScene/ProductInfoLayer/images/close-button.inline.svg';
import { productsData, useConstructionAssemblyService } from '../../../../services/ConstructionAssemblyService';
import { useEffect, useRef, useState } from 'react';
import Triangle from '../../ScrollScene/ProductInfoLayer/icons/triangle.svg';
import { useWindowStore } from '../../../../services/WindowService';
import { useHowlerStore } from '../../../../services/HowlerService';
import debounce from 'utilities/debounce';
// import { SvgButtonHoverEffect } from '../../../../uiLivi/styles';

function Product({ name, type, id, index, image, isSelected, isHighlighted, onMouseOver, onMouseOut, onSelect }) {
  return (
    <Box
      $isHighlighted={isHighlighted || isSelected}
      onMouseOver={() => onMouseOver(index)}
      onMouseOut={onMouseOut}
      onClick={event => onSelect(event, id)}
    >
      <Content>
        <ProductImage src={image} />
        <Bottom>
          <ProductData>
            <ProductType>{type}</ProductType>
            <ProductName>{name}</ProductName>
          </ProductData>
        </Bottom>
      </Content>
    </Box>
  );
}

export default function ProductSelection({ visible, onClose }) {
  const selectedModelId = useConstructionAssemblyService(state => state.selectedModelId);
  const setSelectedModelId = useConstructionAssemblyService(state => state.setSelectedModelId);
  const [initialModelIndex, setInitialModelIndex] = useState(productsData.findIndex(p => p.id === selectedModelId));
  const [highlightedModelIndex, setHighlightedModelIndex] = useState(initialModelIndex);
  const [paginationVisible, setPaginationVisible] = useState(false);
  const width = useWindowStore(state => state.width);
  const scrollContainer = useRef(null);
  const playPrimaryClickUiSound = useHowlerStore(state => state.playPrimaryClickUiSound);

  useEffect(() => {
    setInitialModelIndex(productsData.findIndex(p => p.id === selectedModelId));
  }, [selectedModelId]);

  const handleSelect = (evt, id) => {
    evt.stopPropagation();
    setSelectedModelId(id);
    onClose();
    playPrimaryClickUiSound();
  };

  // const handleClose = () => {
  //   setSelectedModelId(products[highlightedModelIndex].id);
  //   onClose();
  // };

  const commitScroll = debounce(index => {
    setHighlightedModelIndex(index);
  }, 200);

  const handleScroll = event => {
    const pos = (productsData.length * event.target.scrollLeft) / (event.target.scrollWidth - event.target.clientWidth);
    const index = Math.floor(pos);
    const highlightedProduct = productsData[index];
    if (!highlightedProduct || index === highlightedModelIndex) return;
    commitScroll(index);
  };

  const handleSelectFromPagination = index => {
    return evt => {
      evt.stopPropagation();
      setHighlightedModelIndex(index);
      setInitialModelIndex(index);
      const left =
        ((scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth) * index) /
        (productsData.length - 1);
      scrollContainer.current.scrollTo({ left: left, behavior: 'smooth' });
    };
  };

  useEffect(() => {
    const contentIsWiderThanContainer = scrollContainer.current.scrollWidth > scrollContainer.current.clientWidth;
    if (contentIsWiderThanContainer !== paginationVisible) {
      setPaginationVisible(contentIsWiderThanContainer);
    }
  }, [width, paginationVisible]);

  useEffect(() => {
    if (!visible) return;

    const left =
      ((scrollContainer.current.scrollWidth - scrollContainer.current.clientWidth) * highlightedModelIndex) /
      (productsData.length - 1);
    scrollContainer.current.scrollTo({ left });
  }, [visible]);

  return (
    <ProductSelectionContainer onClick={onClose} data-sound-action="primaryClick">
      <Padder>
        <Headline_26_900>{'Three unique systems. One defining purpose.'}</Headline_26_900>
        <Subline>
          {
            'When it comes to solar mounting systems, one size doesn’t always fit all. So we’ve developed a range of specialized products to maximize the return on your solar PV investment. Take a look.'
          }
        </Subline>
      </Padder>
      <ProductOverview onScroll={handleScroll} ref={scrollContainer}>
        <Padder>
          {productsData.map((product, i) => (
            <Product
              key={i}
              index={i}
              initialModelIndex={initialModelIndex}
              {...product}
              onMouseOver={setHighlightedModelIndex}
              onMouseOut={() => setHighlightedModelIndex(initialModelIndex)}
              onSelect={handleSelect}
              isSelected={i === initialModelIndex}
              isHighlighted={i === highlightedModelIndex}
            />
          ))}
        </Padder>
      </ProductOverview>
      <PaginationContainer visible={false}>
        {productsData.map((product, i) => (
          <TriangleWrapper
            key={i}
            onClick={handleSelectFromPagination(i)}
            data-sound-action="click"
            $active={i === highlightedModelIndex}
          >
            <Triangle />
          </TriangleWrapper>
        ))}
      </PaginationContainer>
      {/*<CloseButtonContainer>*/}
      {/*  <SvgButtonHoverEffect>*/}
      {/*    <CloseButton onClick={handleClose} />*/}
      {/*  </SvgButtonHoverEffect>*/}
      {/*</CloseButtonContainer>*/}
    </ProductSelectionContainer>
  );
}
