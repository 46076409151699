import styled, { keyframes } from 'styled-components';
import { colors, easings } from 'uiLivi/styles';
import { menuPaddingPx } from 'uiLivi/components/Menu/SubMenu/styles';
import { cardMarginPx } from '../../styles';

const appear = keyframes`
  to {
    opacity: 1;
    transform: scale(1);
`;

export const Container = styled.div`
  margin: ${menuPaddingPx} ${cardMarginPx};
  position: relative;
  padding: 40px;
  background: ${colors.background.highlight};
  opacity: 0;
  transform: scale(0.9);
  animation: ${appear} 0.5s ${easings.easeOutStrong} forwards;
`;
