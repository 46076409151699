import { Headline, Paragraph } from '../../styles';
import React, { useMemo } from 'react';

const Notified = ({ open, isBusy }) => {
  const { name } = open;
  const headline = useMemo(() => (isBusy ? `${name} is not available at the moment` : `We have notified ${name}`), [
    isBusy,
  ]);
  const firstParagraph = useMemo(
    () =>
      isBusy
        ? `Please try again later.`
        : `If available, ${name} will come directly to you. This may take a few minutes.`,
    [isBusy]
  );
  return (
    <>
      <Headline>{headline}</Headline>
      <Paragraph>{firstParagraph}</Paragraph>
      <Paragraph>In the meantime, you can leave a message or continue exploring the virtual space.</Paragraph>
    </>
  );
};

export default Notified;
