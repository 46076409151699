import styled, { css } from 'styled-components';
import { zIndex } from '../../../../uiLivi/styles';
import { breakPoints, mediaMax, mediaMaxPx } from '../../../../common/layout';

export const breakPoint = 1000;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

export const ModelSelection = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(180deg, rgba(214, 234, 222, 0.8) 0%, rgba(0, 94, 97, 0.8) 100%);
  backdrop-filter: blur(4px);

  opacity: ${props => (props.visible ? 1 : 0)};
  transition: all 0.25s ease-in-out;

  z-index: ${zIndex.menu + 1};

  overflow-y: auto;
  max-height: 100%;

  padding: 32px 0;
`;

export const ToggleSelection = styled.div`
  position: absolute;
  left: 22px;
  bottom: 22px;
  opacity: ${props => (props.visible ? 1 : 0)};
  cursor: pointer;
  pointer-events: all;
  transition: all 0.25s ease-in-out;

  .mobile {
    display: none;
  }

  ${mediaMaxPx(
    1230,
    css`
      bottom: 120px;
    `
  )};

  ${mediaMax(
    'small',
    css`
      left: 5px;
    `
  )};

  ${mediaMaxPx(
    breakPoint,
    css`
      .mobile {
        display: block;
      }
      .desktop {
        display: none;
      }
    `
  )};
`;

export const GradientBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 220px;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 3.95%, rgba(0, 0, 0, 0.3) 120%);
`;
