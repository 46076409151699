import styled, { css } from 'styled-components';
import { iconFillAndStroke, iconHideFill } from 'common/icon';

const inactiveStyle = css`
  ${iconFillAndStroke('white', 'white')}
  ${iconHideFill};
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  fill: black;
  svg{  ${props => (props.active ? iconFillAndStroke('white', 'white') : inactiveStyle)}
`;
