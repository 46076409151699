import { InstructionHeadline, InstructionText, InstructionWrapper, InstructionIconContainer } from '../../../../styles';
import IconScroll from '../../../assets/scroll.png';
import IconSwipe from '../../../assets/swipe.png';
import styled, { css } from 'styled-components';
import React from 'react';
import { mediaTouch } from 'common/layout';

const InstructionIcon = styled.div`
  width: 36px;
  height: 67px;
  background-image: url(${IconScroll});
  background-size: cover;
  ${mediaTouch(
    css`
      width: 52px;
      height: 41px;
      background-image: url(${IconSwipe});
    `
  )}
`;

export default function OnboardingScroll() {
  return (
    <InstructionWrapper>
      <InstructionIconContainer>
        <InstructionIcon />
      </InstructionIconContainer>
      <InstructionHeadline>Scroll and learn.</InstructionHeadline>
      <InstructionText>
        Get up close and personal with our most popular products by simply scrolling. To learn more about what you're
        seeing, try clicking on the icons and screens too.
      </InstructionText>
    </InstructionWrapper>
  );
}
