import { lgBaseColors } from 'uiLivi/styles';
import styled from 'styled-components';
import { panelStyle } from '../styles';
import IconArrow from './icons/icon_arrow.png';

export const Container = styled.div`
  padding: 20px 20px 0;
  :not(:first-of-type) {
    margin-top: 8px;
  }
  ${panelStyle}
`;

export const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const OptionTabs = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: -8px;
`;

export const OptionTab = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  color: ${props => (props.active ? lgBaseColors.white : lgBaseColors.grey.light)};
  cursor: pointer;
  width: 100px;
  height: 40px;
  text-align: center;
  padding-top: 9px;
  pointer-events: ${props => (props.active ? 'none' : 'all')};
  background-color: ${props => (props.active ? lgBaseColors.grey.dark : '#000')};
  border-top: 2px solid ${props => (props.active ? lgBaseColors.red.base : '#000')};
  transition: color 0.15s ease-in;
`;

export const Pager = styled.div`
  height: 20px;
  padding-bottom: 10px;
  text-align: center;
  line-height: 0;
`;

export const PagerButton = styled.div`
  width: 12px;
  height: 12px;
  opacity: ${props => (props.active ? 1 : 0.5)};
  background-image: url(${IconArrow});
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: center ${props => (props.left ? 'bottom' : 'top')};
  transform: rotate(${props => (props.left ? 180 : 0)}deg);
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
  pointer-events: ${props => (props.active ? 'all' : 'none')};
`;
