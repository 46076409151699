import { useEffect, useState } from 'react';
import { DefaultLoadingManager } from 'three';
import { useDistrictWarmupStore } from 'services/DistrictWarmupService';

// uses ThreeJS DefaultLoadingManager to send progress to DistrictWarmupStore
export default function ProgressMonitor() {
  const [lastProgress, setLastProgress] = useState(null);
  const [startItemCount, setStartItemCount] = useState(-1);

  useEffect(() => {
    useDistrictWarmupStore.getState().setLoadingProgress(0);
  }, []);

  useEffect(() => {
    DefaultLoadingManager.onProgress = (url, loaded, total) => {
      setLastProgress({ loaded, total });
    };
  }, [setLastProgress]);

  useEffect(() => {
    if (lastProgress === null) return;

    if (startItemCount === -1) {
      setStartItemCount(lastProgress.loaded);
    } else {
      const loaded = lastProgress.loaded - startItemCount;
      const total = lastProgress.total - startItemCount;
      if (total === 0) {
        useDistrictWarmupStore.getState().setLoadingProgress(1);
      } else {
        useDistrictWarmupStore.getState().setLoadingProgress(loaded / total);
      }
    }
  }, [lastProgress, startItemCount]);

  return null;
}
