// @ts-nocheck
import React, { useMemo } from 'react';

import vertex from './shaders/index.vert';
import fragment from './shaders/index.frag';

export default React.memo(({ camera, fbo }) => {
  const shaderArgs = useMemo(() => {
    return {
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        uTxt: { value: fbo.texture },
      },
      transparent: true,
      side: 0,
    };
  }, []);

  return (
    <mesh
      scale={[camera.right * 2, camera.top * 2, 1]}
      rotation-x={-1.57}
      position-y={0.7}
      position-z={camera.position.z}
    >
      <planeGeometry args={[1, 1, 1, 1]} />
      <shaderMaterial args={[shaderArgs]} />
    </mesh>
  );
});
