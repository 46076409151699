import styled from 'styled-components';
import { lgBaseColors } from 'uiLivi/styles';

export default styled.div`
  display: ${props => (props.active ? 'inline-block' : 'none')};
  margin-left: 10px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${lgBaseColors.green.medium};
`;
