import {
  IconContainer,
  InstructionHeadline,
  InstructionText,
  InstructionTopline,
  InstructionVisualBox,
  InstructionVisualColumn,
  InstructionVisualContainer,
  InstructionWrapper,
} from '../../../styles';

import React from 'react';
import JoystickIcon from '../../assets/joystick.svg';
import IconKeys from '../../assets/keys.png';

export default function OnboardingControls({ isMobile }) {
  return isMobile ? (
    <InstructionWrapper isMobile={isMobile}>
      <InstructionTopline>Get Started</InstructionTopline>
      <InstructionHeadline>Move around</InstructionHeadline>
      <InstructionText>Move the joystick to move your character and drag to look around.</InstructionText>
      <InstructionVisualContainer>
        <InstructionVisualColumn grow>
          <InstructionVisualBox>
            <JoystickIcon />
          </InstructionVisualBox>
        </InstructionVisualColumn>
      </InstructionVisualContainer>
    </InstructionWrapper>
  ) : (
    <InstructionWrapper isMobile={isMobile}>
      <InstructionTopline>Get Started</InstructionTopline>
      <InstructionHeadline>Move around</InstructionHeadline>
      <InstructionText>Use WASD or arrow keys to move your character and your mouse to look around.</InstructionText>
      <InstructionVisualContainer>
        <InstructionVisualColumn grow>
          <InstructionVisualBox>
            <IconContainer icon={IconKeys} />
          </InstructionVisualBox>
        </InstructionVisualColumn>
      </InstructionVisualContainer>
    </InstructionWrapper>
  );
}
