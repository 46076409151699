import Person from 'common/components/Person';
import { PersonContainer, SpeakerCompany, SpeakerName, SpeakerRole } from './styles';

export default function TimetablePerson({ role, name, company, image, onClick }) {
  return (
    <PersonContainer
      onClick={() => {
        onClick && onClick();
      }}
    >
      <Person
        image={{ size: 'm', src: image }}
        infoComponents={[
          <SpeakerRole key="role">{role}</SpeakerRole>,
          <SpeakerName key="name">{name}</SpeakerName>,
          <SpeakerCompany key="company">{company}</SpeakerCompany>,
        ]}
      />
    </PersonContainer>
  );
}
