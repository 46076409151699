import { Suspense } from 'react';
import { useDatGuiStackStore } from './store';
import './index.css';
import { zIndex } from 'uiLivi/styles';

function Loader() {
  return <div style={{ backgroundColor: '#333', color: '#fff', width: '100%', padding: 10 }}>Loading...</div>;
}

function CloseButton({ selectedGui }) {
  return (
    <div
      style={{
        fontFamily: 'sans-serif',
        display: 'flex',
        justifyContent: 'space-between',
        color: '#fff',
        textAlign: 'right',
        fontSize: 12,
        height: 27,
      }}
    >
      <span style={{ padding: 4, fontWeight: 'bold' }}>{selectedGui.label}</span>
      <span
        style={{ padding: 4, backgroundColor: 'rgba(255,255,255,0.1)', cursor: 'pointer' }}
        onClick={() => {
          useDatGuiStackStore.getState().setSelectedIndex(0);
        }}
      >
        Close
      </span>
    </div>
  );
}
export default function DatGuiStack() {
  const selectedGui = useDatGuiStackStore(state => state.stack.find((_, i) => i === state.selectedIndex));

  if (!selectedGui?.gui) return null;

  return (
    <div
      style={{
        position: 'fixed',
        width: '360px',
        height: '100%',
        top: 0,
        right: 0,
        zIndex: zIndex.datGui,
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.90)',
        backdropFilter: 'blur(5px)',
      }}
    >
      <CloseButton selectedGui={selectedGui} />
      <div style={{ height: 'calc(100% - 27px)' }}>
        <Suspense fallback={<Loader />}>{selectedGui.gui}</Suspense>
      </div>
    </div>
  );
}
