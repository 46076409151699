import styled from 'styled-components';
import React, { useRef } from 'react';

export const PinContainerRef = { current: null };

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: ${props => (!props.isFitting ? 'block' : 'none')};
`;

export function PinContainer({ isFitting }) {
  const pinContainer = useRef();
  PinContainerRef.current = pinContainer;

  return <Container isFitting={isFitting} ref={pinContainer} />;
}
