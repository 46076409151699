import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ToggleShape from './assets/toggleShape.svg';
import BackgroundShape from './assets/backgroundShape.svg';
import iconWeather from './assets/iconWeather.png';
import iconSun from './assets/iconSun.png';

const Container = styled.div`
  position: relative;
  width: 202px;
  height: 105px;
  opacity: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Toggle = styled.div`
  position: absolute;
  width: 117px;
  height: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(${props => (props.isActive ? -15 : 100)}px, 0, 0);
  transition: transform 0.3s ease-in-out;
`;

const ToggleIcon = styled.div`
  position: absolute;
  width: 84px;
  height: 84px;
  background-image: url(${props => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const BackgroundIcons = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const BackgroundIcon = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${props => props.url});
  background-size: contain;
  background-repeat: no-repeat;
`;

const WrapperHalf = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function ToggleWeather({ active, onToggle = () => {} }) {
  const [isActive, setIsActive] = useState(active);

  const handleOnToggle = () => {
    setIsActive(!isActive);
    onToggle(!isActive);
  };

  useEffect(() => {
    if (isActive !== active) setIsActive(active);
  }, [active]);

  return (
    <Container onClick={handleOnToggle} data-sound-action="click">
      <BackgroundShape style={{ position: 'absolute' }} />
      <BackgroundIcons>
        <WrapperHalf>
          <BackgroundIcon url={iconWeather} />
        </WrapperHalf>
        <WrapperHalf>
          <BackgroundIcon url={iconSun} />
        </WrapperHalf>
      </BackgroundIcons>
      <Toggle isActive={isActive}>
        <ToggleShape />
        <ToggleIcon show={active} url={iconWeather} />
        <ToggleIcon show={!active} url={iconSun} />
      </Toggle>
    </Container>
  );
}
