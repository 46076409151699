import { useLightmaps, useTraverse } from '../hooks';
import { LinearFilter, LinearMipmapLinearFilter, sRGBEncoding } from 'three';
import { useEnvironmentStore } from '../../../components/Play/Environment/store';

const configureLightMap = lightMap => {
  lightMap.flipY = false;
  lightMap.generateMipmaps = true;
  lightMap.anisotropy = 16;
  lightMap.minFilter = LinearMipmapLinearFilter;
  lightMap.magFilter = LinearFilter;
  lightMap.encoding = sRGBEncoding;
};

export default function useConfigureLightmaps() {
  const lightmaps = useLightmaps();
  const environmentConfiguration = useEnvironmentStore(state => state.environmentConfiguration);

  useTraverse(() => {
    return o => {
      const lightmap = lightmaps.find(lm => {
        return o.name.includes(lm.tag);
      });

      if (lightmap) {
        configureLightMap(lightmap.texture);
        o.material.lightMap = lightmap.texture;
        o.material.lightMapIntensity = environmentConfiguration.lightmap.intensity;
      }
    };
  }, [environmentConfiguration]);
}
