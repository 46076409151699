import styled from 'styled-components';
import { styledScrollbar } from 'common/layout';
import { H2 } from 'common/text';
import { FullscreenContentPadding } from '../../styles';

export const CategoryTitle = styled(H2)`
  font-size: 18px;
`;

export const Container = styled.div`
  padding: 0 0 40px;
  max-height: 100%;
  ${FullscreenContentPadding};
  ${styledScrollbar};
`;
