import React, { useEffect, useState } from 'react';
import { Form, InputLabel, IntroSubline, PanelCopy, PanelHeadline, StyledButton } from '../styles';
import { useHistory } from 'react-router-dom';
import { Input } from 'common/components/Input';
import simpleEmailValidation from 'utilities/validation/simpleEmailValidation';
import { useSocketStore } from 'services/SocketService';
import { useNotificationStore } from 'services/NotificationService';
import Toast from 'common/components/Toast';
import { useUserStore } from 'services/UserService';
import { useTranslate } from 'services/TranslationService/hooks';
import VeTextLink from '../../../common/components/VeTextLink';

const qs = require('query-string');

function ForgotPasswordForm() {
  const [email, setEmail] = useState('');
  const [sendEnabled, setSendEnabled] = useState(false);
  const history = useHistory();
  const translate = useTranslate();

  useEffect(() => {
    return () => {
      useUserStore.getState().resetMessage();
    };
  }, []);

  const handleEmailChange = e => {
    const value = e.nativeEvent.target.value;
    setEmail(value);
  };

  useEffect(() => {
    setSendEnabled(simpleEmailValidation(email));
  }, [email]);

  const submit = e => {
    e.preventDefault();

    const socket = useSocketStore.getState().connect();
    socket.once('reconnect_failed', () => {
      // console.error('no connect');
    });
    socket.emit('user/forgotPassword', email, () => {
      // console.log('request success', data);
      const toast = (
        <Toast
          headline={
            translate('toast.password.resetlink') || 'Check your email and click on the link to reset your password.'
          }
          level={'success'}
          position={'bottom'}
          large
        />
      );
      useNotificationStore.getState().addNotification(toast);
      socket.disconnect();
    });

    const queryParams = qs.parse(window.location.search);
    const useSimpleRegistrationForm = 'useSimpleRegisterForm' in queryParams;
    const params = useSimpleRegistrationForm ? '?useSimpleRegisterForm' : '';
    history.push('/join' + params);
  };

  return (
    <Form onSubmit={submit}>
      <PanelHeadline>{translate('forgotpw.headline') || 'Forgot password'}</PanelHeadline>
      <PanelCopy>
        {translate('forgotpw.copy') ||
          'This happens to the best of us. We will send you a link via email to reset your password.'}
      </PanelCopy>
      <InputLabel>{translate('forgotpw.email.headline') || 'Please enter your email address'}</InputLabel>
      <Input
        placeholder={translate('forgotpw.placeholder.email') || 'Email'}
        value={email}
        border
        onChange={handleEmailChange}
      />
      <div style={{ width: '100%', display: 'flex' }}>
        <StyledButton veStyle accent disabled={!sendEnabled} style={{ marginRight: '15px' }} type="submit">
          {translate('forgotpw.cta.submit') || 'Send'}
        </StyledButton>
      </div>
    </Form>
  );
}

function ForgotPasswordIntro() {
  const history = useHistory();

  const loginClick = () => {
    const queryParams = qs.parse(window.location.search);
    const useSimpleRegistrationForm = 'useSimpleRegisterForm' in queryParams;
    const params = useSimpleRegistrationForm ? '?useSimpleRegisterForm' : '';
    history.push('/join' + params);
  };
  const translate = useTranslate();
  return (
    <>
      <IntroSubline>{translate('join.cta.headline') || 'Already a member?'}</IntroSubline>
      <VeTextLink as={'button'} type="submit" onClick={loginClick}>
        {translate('join.login.cta') || 'Login'}
      </VeTextLink>
    </>
  );
}

export { ForgotPasswordForm, ForgotPasswordIntro };
