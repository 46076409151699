import styled from 'styled-components';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { smallTextStyle } from 'common/text';

export const StyledCheckbox = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  display: flex;
  margin: 10px 0;
  cursor: pointer;
`;

const CircleContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const StyledCircle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: black;
  border: 1px solid ${colors.accent.base};
`;

export const StyledCheckmark = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  transform-origin: 50%;
  transform: scale(${props => (props.active ? 1 : 0)});
  background-color: ${colors.accent.base};
  transition: transform 0.15s ease-in;
`;

export const StyledLabel = styled.div`
  color: ${lgBaseColors.grey.light};
  ${smallTextStyle};
  font-weight: bold;
  line-height: 20px;
`;

export default function Checkbox({ value, label, handleAccept }) {
  return (
    <StyledCheckbox onClick={handleAccept}>
      <CircleContainer>
        <StyledCircle />
        <StyledCheckmark active={value} />
      </CircleContainer>
      <StyledLabel>{label}</StyledLabel>
    </StyledCheckbox>
  );
}
