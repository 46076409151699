import create from 'utilities/zustand/create';
import { subscribeWithSelector } from 'zustand/middleware';
import throttle from 'lodash-es/throttle';

const useReflectorMaterialStore = create(
  subscribeWithSelector((set, get) => {
    const debouncedSet = throttle(v => {
      set(v);
    }, 200);

    return {
      params: {
        resolution: 1024,
        blur: [0, 200],
        mixBlur: 1.1,
        mirror: 0,
        mixStrength: 0.7,
        depthScale: 1.0,
        minDepthThreshold: 0.8,
        maxDepthThreshold: 1.0,
        depthToBlurRatioBias: 0.25,
        reflectorOffset: 0,
      },
      set: v => {
        debouncedSet({ params: v });
      },
    };
  })
);

export { useReflectorMaterialStore };
