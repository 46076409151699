import { Headline, Paragraph, Topline } from '../../styles';
import React from 'react';

const Start = ({ open }) => {
  const { name, introduction } = open;
  return (
    <>
      <Topline>Let’s talk</Topline>
      <Headline>Hey, I am {name}! Nice to meet you!</Headline>
      <Paragraph>{introduction}</Paragraph>
      <Paragraph>You can ask me anything! Notify me, and I'll come directly to you if I'm available.</Paragraph>
      <Paragraph>Feel free to explore the space and meet the other team members.</Paragraph>
    </>
  );
};

export default Start;
