import Hotspot from '../assets/hotspot.svg';
import HotspotSelected from '../assets/hotspot-selected.svg';
import styled, { css, keyframes } from 'styled-components';
import { useDistrictStore } from '../../../../services/DistrictService';
import { useHistory } from 'react-router-dom';
import { useNavigationStore } from '../../../../services/NavigationService';
import { easings } from '../../../../uiLivi/styles';
import { mediaMax } from '../../../../common/layout';
import { useState } from 'react';

const appear = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const Pin = styled.div`
  position: absolute;
  z-index: ${props => (props.enabled ? (props.selected ? 2 : 1) : 0)};
  left: ${props => props.position.left}%;
  top: ${props => props.position.top}%;
  display: flex;
  pointer-events: ${props => (props.selected || !props.enabled ? 'none' : 'all')};
  cursor: ${props => (props.selected ? 'default' : 'pointer')};
  transform: translate3d(-50%, -100%, 0) scale(1);
  opacity: ${props => (props.enabled ? 1 : 0.5)};

  ${mediaMax(
    'small',
    css`
      transform: translate3d(-50%, -100%, 0) scale(0.75);
    `
  )};
`;

const PinContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(-25px);
  animation: ${appear} 0.5s ${easings.easeOutStrong} forwards;
  animation-delay: ${props => props.index * 0.1 + 0.25}s;
`;

const Bubble = styled.div`
  position: relative;
  font-family: 'AzoSans', serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  text-shadow: 0 0 4px rgba(255, 255, 255, 0.6);
  padding: 14px 32px 12px 32px;
  border-radius: 27.5px;
  background: ${props =>
    props.selected ? 'rgba(161, 118, 2, 0.3)' : props.hover ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.3)'};
  border: ${props => (props.selected ? '1.5px solid #F9B102' : '1px solid rgba(255, 255, 255, 0.35)')};
  backdrop-filter: blur(5px);
  box-shadow: 0 0 ${props => (props.selected ? 8 : 0)}px #6db6a3;
  margin-bottom: 5px;
  transition: background-color 0.2s ease-in-out;
`;

export default function LocationPin({ id, title, selected, position, enabled = true, index = 0 }) {
  const history = useHistory();
  const setShowNavigationOverlay = useNavigationStore(state => state.setShowNavigationOverlay);
  const [hover, setHover] = useState(false);

  const handleNavigation = () => {
    setShowNavigationOverlay(false);
    useDistrictStore.getState().navigateToDistrict(history, id);
  };
  return (
    <Pin
      position={position}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
      onClick={handleNavigation}
      selected={selected}
      enabled={enabled}
      data-sound-action="primaryClick"
    >
      <PinContent index={index}>
        <Bubble selected={selected} hover={hover}>
          {title}
        </Bubble>
        {enabled ? selected ? <HotspotSelected /> : <Hotspot /> : null}
      </PinContent>
    </Pin>
  );
}
