import React, { useMemo } from 'react';
import { useUserStore } from 'services/UserService';
import {
  Card,
  CardContainer,
  CardTimeOverOverlay,
  Footer,
  Headline,
  LiveContainer,
  LowerContainer,
  Topline,
} from './styles';
import BookmarkButton from 'common/components/Timetable/common/BookmarkButton';
import LiveIndicator from 'common/components/LiveIndicator';
import DateAndTime from '../../common/DateAndTime';
import { Button } from 'common/components/Button';
import moment from 'moment/moment';

export default function TimetableCard({ index, columns, row, column, entry }) {
  const isLive = useMemo(
    () => moment().isAfter(moment(entry.date.start)) && moment().isBefore(moment(entry.date.end)),
    [entry]
  );
  const userCanClaim = useUserStore(state => {
    const type = state.user.role.type;
    return type === 'expert' || type === 'supporter';
  });

  const isOver = useMemo(() => {
    return moment(entry.date.end).isBefore(new Date());
  }, [entry]);

  const user = useUserStore(state => state.user);

  const { expert, date, clickHandler, headline, topline, button, bookmark } = entry;

  let footerComponent = null;

  if (userCanClaim) {
    const claimedByOtherExpert = expert && expert.id !== user.id;

    if (claimedByOtherExpert) {
      footerComponent = <Footer>Claimed by {expert.searchName}</Footer>;
    } else if (entry.button) {
      const { text, clickHandler, Icon, disabled } = button;
      footerComponent = (
        <Button data-id={entry.id} accent key={text} onClick={clickHandler} disabled={disabled}>
          {Icon && <Icon />}
          {text}
        </Button>
      );
    }
  }

  return (
    <CardContainer index={index} columns={columns} row={row} column={column}>
      <Card onClick={clickHandler}>
        {isLive && (
          <LiveContainer>
            <LiveIndicator startDate={date.start} endDate={date.end} />
          </LiveContainer>
        )}
        <Topline>{topline}</Topline>
        <Headline>{headline}</Headline>
        <LowerContainer>
          <DateAndTime startDate={date.start} endDate={date.end} />
          {bookmark.show && (
            <BookmarkButton
              small
              active={bookmark.active}
              talkId={entry.id}
              style={{
                position: `relative`,
                float: 'right',
                bottom: '-10px',
              }}
            />
          )}
        </LowerContainer>
      </Card>
      {footerComponent}
      {isOver && <CardTimeOverOverlay />}
    </CardContainer>
  );
}
