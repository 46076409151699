import { FrontSide } from 'three';
import { useTraverse } from '../hooks';
import { useEnvironmentStore } from '../../../components/Play/Environment/store';

export default function usePatchPortal() {
  const environmentConfiguration = useEnvironmentStore(state => state.environmentConfiguration);

  useTraverse(() => {
    return o => {
      if (o.material.name === 'portal_emissive_mat') {
        o.material.emissiveIntensity = 2.2;
        o.material.name = o.orgMaterial.name;
        o.material.side = FrontSide;
        o.material.transparent = true;
        o.material.needsUpdate = true;
        o.material.envMapIntensity = 0;
        o.material.onBeforeCompile = shader => {
          shader.numDirLights = 0;
        };
      }
    };
  }, [environmentConfiguration]);
}
