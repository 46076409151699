const kmVariation = 30;
const getKmValue = (v, p) => v - p * kmVariation;
export default {
  kmVariation: kmVariation,
  weather: {
    stages: {
      start: {
        position: 0.0,
        kmSpeed: getKmValue(1, 0.0),
        windStrength: 1,
        baseWind: 0.1,
        speedFactor: 3.55,
        heightFactor: 0.6,
        blueFactor: 0.45,
        redFactor: 0.75,
        greenFactor: 0.9,
        noiseScale: 2.0,
      },
      slow: {
        position: 0.08,
        kmSpeed: getKmValue(19, 0.08),
        windStrength: 1,
        baseWind: 0.1,
        speedFactor: 3.55,
        heightFactor: 0.6,
        blueFactor: 0.45,
        redFactor: 0.75,
        greenFactor: 0.9,
        noiseScale: 2.0,
      },
      windy: {
        position: 0.26,
        kmSpeed: getKmValue(65, 0.26),
        windStrength: 1.8,
        baseWind: 0.5,
        speedFactor: 7.55,
        heightFactor: 1.7,
        blueFactor: 0.85,
        redFactor: 0.3,
        greenFactor: 1.0,
        noiseScale: 2.0,
      },
      medium: {
        position: 0.47,
        kmSpeed: getKmValue(117, 0.47),
        windStrength: 2.5,
        baseWind: 0.5,
        speedFactor: 8.5,
        heightFactor: 2.0,
        blueFactor: 1.35,
        redFactor: 1.9,
        greenFactor: 0.9,
        noiseScale: 2.0,
      },
      stormy: {
        position: 0.71,
        kmSpeed: getKmValue(177, 0.71),
        windStrength: 3.4,
        baseWind: 0.5,
        speedFactor: 8.5,
        heightFactor: 4.25,
        blueFactor: 1.35,
        redFactor: 1.9,
        greenFactor: 0.9,
        noiseScale: 2.0,
      },
      hurricane: {
        position: 1,
        kmSpeed: getKmValue(257, 1),
        windStrength: 4.6,
        baseWind: 0.5,
        speedFactor: 9,
        heightFactor: 6.35,
        blueFactor: 1.7,
        redFactor: 1.9,
        greenFactor: 0.9,
        noiseScale: 2.0,
      },
    },
  },
  sunTracker: {
    stages: {
      start: {
        position: 0.0,
        kmSpeed: getKmValue(1, 0.0),
        windStrength: 1,
        baseWind: 0.1,
        speedFactor: 3.55,
        heightFactor: 0.6,
        blueFactor: 0.45,
        redFactor: 0.75,
        greenFactor: 0.9,
        noiseScale: 2.0,
      },
      slow: {
        position: 0.5,
        kmSpeed: getKmValue(19, 0.08),
        windStrength: 0.9,
        baseWind: 0.1,
        speedFactor: 2.55,
        heightFactor: 0.6,
        blueFactor: 0.35,
        redFactor: 0.75,
        greenFactor: 0.9,
        noiseScale: 2.0,
      },
      windy: {
        position: 1.0,
        kmSpeed: getKmValue(13, 0.26),
        windStrength: 1.2,
        baseWind: 0.1,
        speedFactor: 3.15,
        heightFactor: 0.6,
        blueFactor: 0.45,
        redFactor: 0.85,
        greenFactor: 0.9,
        noiseScale: 2.0,
      },
    },
  },
};
