import ButtonHelp from './ButtonHelp';
import ButtonSound from './ButtonSound';
import ButtonContact from './ButtonContact';
import styled, { css } from 'styled-components';
import { mediaMax } from '../../../common/layout';

const WrapperButtons = styled.div`
  display: flex;
  font-size: 12px;
  font-family: 'AzoSans', serif;
  font-weight: 700;
  flex-direction: row;

  ${mediaMax(
    'small',
    css`
      align-items: flex-start;
      flex-direction: column;
    `
  )};
`;

export const IconShadow = styled.div`
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.6));
`;

export default function MenuButtons() {
  return (
    <WrapperButtons>
      <ButtonContact />
      <ButtonHelp />
      <ButtonSound />
    </WrapperButtons>
  );
}
