import React, { useEffect, useState } from 'react';
import { Form, PanelHeadline, StyledButton } from '../styles';
import { useHistory } from 'react-router-dom';
import { useSocketStore } from 'services/SocketService';
import PasswordInputWithCheck from 'common/components/PasswordInputWithCheck';
import { useUserStore } from 'services/UserService';
import Toast from 'common/components/Toast';
import { useNotificationStore } from 'services/NotificationService';
import { useTranslate } from 'services/TranslationService/hooks';

const qs = require('query-string');

function ChangePasswordForm() {
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const history = useHistory();
  const translate = useTranslate();

  useEffect(() => {
    return () => {
      useUserStore.getState().resetMessage();
    };
  }, []);

  useEffect(() => {
    setButtonEnabled(password.length > 5 && password === passwordCheck);
  }, [password, passwordCheck]);

  const submit = e => {
    e.preventDefault();
    const queryParams = qs.parse(window.location.search);
    const token = queryParams.token;

    const socket = useSocketStore.getState().connect();
    socket.once('reconnect_failed', () => {
      // eslint-disable-next-line no-console
      console.error('no connect');
    });
    socket.emit('user/resetPassword', { token, password }, data => {
      // eslint-disable-next-line no-console
      console.log(data);
      const toast = (
        <Toast
          headline={translate('toast.password.changesuccess') || 'Your password has been succesfully changed.'}
          level={'success'}
          position={'bottom'}
          large
        />
      );
      useNotificationStore.getState().addNotification(toast);
      socket.disconnect();
    });

    history.push('/join');
  };

  return (
    <Form onSubmit={submit}>
      <PanelHeadline>{translate('changepw.headline') || 'Change your password'}</PanelHeadline>

      <PasswordInputWithCheck
        passwordLabel={translate('changepw.newpassword.headline') || 'Enter your new password'}
        placeholder={translate('register.placeholder.password') || 'Password* (at least 6 characters)'}
        password={password}
        setPassword={setPassword}
        checkPasswordLabel={translate('register.placeholder.newpassword') || 'Re-enter your new password'}
        checkPlaceholder={translate('register.placeholder.reenterpassword') || 'Re-enter password'}
        checkPassword={passwordCheck}
        checkSetPassword={setPasswordCheck}
      />

      <div style={{ width: '100%', display: 'flex' }}>
        <StyledButton accent disabled={!buttonEnabled} style={{ marginRight: '15px' }} type="submit">
          {translate('changepw.cta.submit') || 'Change password'}
        </StyledButton>
      </div>
    </Form>
  );
}

function ChangePasswordIntro() {
  const loginClick = () => {
    history.push('/join');
  };
  const translate = useTranslate();
  return (
    <>
      <h5 style={{ color: 'white', marginBottom: 0 }}>{translate('join.cta.headline') || 'Already a member?'}</h5>
      <StyledButton accent type="submit" onClick={loginClick}>
        {translate('join.login.cta') || 'Login'}
      </StyledButton>
    </>
  );
}

export { ChangePasswordForm, ChangePasswordIntro };
