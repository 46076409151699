import React, { useEffect, useMemo, useRef, useState } from 'react';
import userStorage from 'storage/user';
import { BackSide, BoxGeometry, Color, Mesh, MeshBasicMaterial, Object3D, PlaneGeometry, Texture } from 'three';
import { usePlayerStore } from 'services/PlayerService';

export default function FittingRoom({ visible }) {
  const group = useRef();
  const [fittingRoom, setFittingRoom] = useState();

  const createTexture = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;

    const context = canvas.getContext('2d');
    context.fillStyle = 'rgba(0,0,0,0)';
    context.fillRect(0, 0, canvas.width, canvas.height);

    const gradient = context.createRadialGradient(256, 256, 5, 256, 256, 256);
    gradient.addColorStop(0, 'rgba(14,12,21,1)');
    gradient.addColorStop(1, 'rgba(0,0,0,1)');
    context.fillStyle = gradient;
    context.fillRect(0, 0, 512, 512);

    const texture = new Texture(canvas);
    texture.anisotropy = 16;
    texture.needsUpdate = true;

    return texture;
  };

  useEffect(() => {
    const groundGeom = new PlaneGeometry(10, 10);
    const planeGeom = new PlaneGeometry(2, 2);
    const cubeGeom = new BoxGeometry(10, 5, 10);

    const floor = new Mesh(
      planeGeom,
      new MeshBasicMaterial({
        map: createTexture(),
        side: BackSide,
      })
    );
    floor.name = 'Floor';
    floor.position.set(0, 0.1, 0);
    floor.rotation.set(Math.PI / 2, 0, 0);

    const ground = new Mesh(
      groundGeom,
      new MeshBasicMaterial({
        color: 0x000000,
        side: BackSide,
      })
    );

    ground.name = 'Ground';
    ground.position.set(0, 0.0, 0);
    ground.rotation.set(Math.PI / 2, 0, 0);

    const bg = new Mesh(
      cubeGeom,
      new MeshBasicMaterial({
        color: new Color(0x000),
        side: BackSide,
      })
    );
    bg.name = 'BG';
    bg.scale.set(1, 1, 1);
    bg.position.set(0, 1, 0);

    const group = new Object3D();
    group.add(ground);
    group.add(bg);
    group.add(floor);

    const playerPosition = usePlayerStore.getState().position;
    group.position.x = playerPosition[0];
    group.position.y = playerPosition[1] - 0.1;
    group.position.z = playerPosition[2];

    setFittingRoom(group);
  }, []);

  useEffect(() => {
    const playerPosition = usePlayerStore.getState().position;
    if (!group.current) return;
    group.current.position.x = playerPosition[0];
    group.current.position.y = playerPosition[1] - 0.1;
    group.current.position.z = playerPosition[2];
  }, [visible]);

  if (!fittingRoom) return;
  return <primitive name="Fitting Room" ref={group} object={fittingRoom} visible={visible} />;
}
