import { useEffect } from 'react';
import { useTrackingStore } from './index';

export default function useOpenCloseTracking(category) {
  useEffect(() => {
    useTrackingStore.getState().trackEvent({ category, action: 'Open' });
    return () => {
      useTrackingStore.getState().trackEvent({ category, action: 'Close' });
    };
  }, []);
}
