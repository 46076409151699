import styled from 'styled-components';
import { useStatsStore } from './store';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  z-index: 10050;
  font-family: monospace;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
`;

export default function Stats() {
  const stats = useStatsStore(state => state);
  return (
    <Container>
      {JSON.stringify(stats)
        .replace(/({|"|})/g, '')
        .replace(/,/g, ', ')}
    </Container>
  );
}
