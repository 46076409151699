import styled, { css } from 'styled-components';
import { colors, lgBaseColors, zIndex } from 'uiLivi/styles';
import { Button } from 'common/components/Button';
import { mediaMax } from 'common/layout';

export const ParticipantView = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  background-color: ${lgBaseColors.grey.dark};
  margin-bottom: 2px;

  ${mediaMax(
    'small',
    css`
      display: inline-block;
      width: 48px;
      height: 48px;
      margin: 0 2px 0 0;
    `
  )}

  video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Participants = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  overflow-y: auto;
  background-color: #000;
  z-index: ${zIndex.videoChat};
  transform: translateX(${props => (props.visible ? 0 : 100)}%);
  transition: transform 0.75s 0.15s ease;

  ${mediaMax(
    'small',
    css`
      bottom: auto;
      left: 0;
      width: 100%;
      transition: transform 0.2s ease;
      transform: translateX(${({ chatOpen, visible }) => (!visible || chatOpen ? '-100%' : '0')});
      overflow-x: auto;
      padding: 14px;
      padding-left: 74px;
      height: 76px;
      background-color: ${lgBaseColors.grey.dark};
    `
  )}
`;

export const ParticipantInitials = styled.aside`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  z-index: 1;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(94.46deg, #e877ae -21.03%, #6d62fe 77.63%);

  ${mediaMax(
    'small',
    css`
      width: 48px;
      height: 48px;
      font-size: 12px;
    `
  )}
`;

export const VoiceIndicator = styled.div`
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 75px);
  width: 150px;
  height: 150px;
  z-index: 0;
  border-radius: 50%;
  background-color: ${colors.accent.base};
  opacity: 0.25;
  transform: scale(0);
  will-change: transform;

  ${mediaMax(
    'small',
    css`
      top: calc(50% - 45px);
      left: calc(50% - 45px);
      width: 90px;
      height: 90px;
    `
  )}
`;

export const UiContainer = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
  position: fixed;
  bottom: 32px;
  right: 50%;

  ${mediaMax(
    'small',
    css`
      right: auto;
      left: 32px;
      bottom: 50px;
    `
  )}

  ${Button} {
    display: inline-flex;
    width: 56px;
    height: 56px;
    border-radius: 56px;
    white-space: nowrap;
    margin-right: 16px;
    padding: 0;

    align-items: center;
    justify-content: center;

    transform: translateY(${props => (props.visible ? 0 : 200)}%);
    transition: transform
      ${props =>
        props.visible
          ? '.5s .3s cubic-bezier(0.495, 1.435, 0.185, 1)'
          : '.4s 0s cubic-bezier(0.745, 0.000, 0.770, 0.530)'};

    + ${Button} {
      transition-delay: ${props => (props.visible ? 0.35 : 0.05)}s;
    }

    svg {
      margin: 0;
    }
  }
`;

export const Countdown = styled.div`
  position: fixed;
  top: 0;
  right: 50%;
  background-color: ${lgBaseColors.grey.dark};
  color: white;
  padding: 16px;

  ${mediaMax(
    'small',
    css`
      top: 76px;
      left: 0;
      right: 0;
    `
  )}
`;

export const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid ${colors.accent.base};
  border-radius: 50%;
  border-bottom-color: transparent;
  margin-left: -20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(1turn);
    }
  }

  ${mediaMax(
    'small',
    css`
      width: 20px;
      height: 20px;
      margin: -10px 0 -10px 0;
    `
  )}
`;
