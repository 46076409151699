import React, { useMemo } from 'react';
import { BrandingLogo, Content, IntroContainer, IntroHeadline, OuterContainer } from './styles';
import { useWindowStore } from 'services/WindowService';
import { useRouteMatch } from 'react-router-dom';
import { ForgotPasswordForm, ForgotPasswordIntro } from './ForgotPassword';
import { ChangePasswordForm, ChangePasswordIntro } from './ChangePassword';
import { ReducedRegisterForm } from './ReducedRegister';
import VideoBackground from './VideoBackground';
import { useEventStore } from 'services/EventService';
import fromCdn from 'utilities/cdn';
import { Welcome } from './Welcome';

export const loginPathsAndComponents = [
  { path: '/host', intro: null, form: ReducedRegisterForm, props: { showHostCodeInput: true } },
  { path: '/join', intro: null, form: ReducedRegisterForm },
  { path: '/welcome', intro: null, form: Welcome },
  { path: '/forgotPassword', intro: ForgotPasswordIntro, form: ForgotPasswordForm },
  { path: '/resetPassword', intro: ChangePasswordIntro, form: ChangePasswordForm },
];

export default function Login() {
  const vh = useWindowStore(state => state.vh);
  const { event } = useEventStore.getState();
  const { path } = useRouteMatch();
  const miniLogo = fromCdn(event.branding?.logoMini);

  const lpac = useMemo(() => {
    return loginPathsAndComponents.find(lpac => lpac.path === path);
  }, [path]);

  const showWelcome = path === '/welcome';
  if (showWelcome) {
    return <Welcome />;
  }

  return (
    <OuterContainer vh={vh}>
      <IntroContainer>
        <VideoBackground />
        <Content>
          {miniLogo ? <BrandingLogo src={miniLogo} /> : <IntroHeadline large>ve.</IntroHeadline>}
          <IntroHeadline regular>
            {event != null && event.description ? event.description : 'Welcome to our corporate metaverse demo'}
          </IntroHeadline>
          {/*<IntroComponent />*/}
        </Content>
      </IntroContainer>
      <IntroContainer>
        <lpac.form {...lpac.props} />
      </IntroContainer>
    </OuterContainer>
  );
}
