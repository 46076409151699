import React, { useEffect, useState } from 'react';
import { useChatStore } from 'services/ChatService';
import { useWindowStore } from 'services/WindowService';
import { StyledChatContent } from './styles';

const SCROLL_THRESHOLD = 10;

const ChatContent = ({ isContent, isFullHeight, children }) => {
  const activeChat = useChatStore(state => state.activeChat);
  const isMobile = useWindowStore(state => state.isMobile);
  const vh = useWindowStore(state => state.vh);
  const userDidScrollUp = useChatStore(state => state.userDidScrollUp);
  const scrollIsTop = useChatStore(state => state.scrollIsTop);
  const setChatSeen = useChatStore.getState().setChatSeen;
  const setUserDidScrollUp = useChatStore.getState().setUserDidScrollUp;
  const setScrollIsTop = useChatStore.getState().setScrollIsTop;
  const chatContent = React.createRef();
  const [lowestMessageId, setLowestMessageId] = useState(-1);
  const [lastContent, setLastContent] = useState(-1);
  const messageCount = activeChat ? activeChat.messages.length : 0;

  const setOldestMessage = () => {
    const messages = activeChat && activeChat.messages;
    if (!messages || messages.length === 0) return 0;
    const oldestMessage = messages.reduce((current, next) => {
      return current.id < next.id ? current : next;
    }, 0);
    if (oldestMessage.id) setLowestMessageId(oldestMessage.id);
  };

  const didLoadMessages = () => {
    if (!activeChat || activeChat.messages.length === 0) return false;
    const hasPreviousMessages = !!activeChat.messages.find(messages => messages.id < lowestMessageId);
    hasPreviousMessages && setUserDidScrollUp(true);
    return hasPreviousMessages;
  };

  const scrollToBottom = () => {
    const doSmoothScroll = messageCount < 20;
    if (chatContent.current)
      chatContent.current.scroll({
        top: chatContent.current.scrollHeight,
        behavior: doSmoothScroll ? 'smooth' : 'auto',
      });
    if (activeChat) setChatSeen();
    setUserDidScrollUp(false);
  };

  const scrollToTop = (to = 0) => {
    if (chatContent.current) chatContent.current.scroll({ top: to, behavior: 'auto' });
  };

  const updateScrollState = e => {
    if (!e.target) return;
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    const didScrollUp = scrollHeight - clientHeight > scrollTop + SCROLL_THRESHOLD;

    if (!didScrollUp) setChatSeen();
    if (scrollTop === 0 && !scrollIsTop) {
      const chat = useChatStore.getState().activeChat;
      chat && useChatStore.getState().loadMessages(chat, true);
    }
    setUserDidScrollUp(didScrollUp);
    setScrollIsTop(scrollTop === 0);
  };

  useEffect(() => {
    if (chatContent.current) chatContent.current.addEventListener('scroll', updateScrollState);
    return () => {
      if (chatContent.current) chatContent.current.removeEventListener('scroll', updateScrollState);
    };
  }, []);

  useEffect(() => {
    const currentContent = children && children.key;
    const isDifferentContent = lastContent !== currentContent;
    const userInteraction = userDidScrollUp || didLoadMessages();
    const doScrollToBottom = isDifferentContent || !userInteraction;
    if (doScrollToBottom && activeChat) scrollToBottom();
    else {
      if (userInteraction && scrollIsTop) scrollToTop(1);
      else if (!userInteraction && !scrollIsTop) scrollToTop();
    }
    scrollIsTop && setScrollIsTop(false);
    setOldestMessage();
    setLastContent(currentContent);
  }, [lastContent, messageCount]);

  return (
    <StyledChatContent
      activeChat={activeChat}
      isMobile={isMobile}
      vh={vh}
      isFullHeight={isMobile || isFullHeight || isContent}
      ref={chatContent}
    >
      {children}
    </StyledChatContent>
  );
};

export default ChatContent;
