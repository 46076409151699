import create from 'utilities/zustand/create';

export const useProductAudioStore = create((set, get) => ({
  howls: [],

  registerProductAudio: howl => {
    const { howls } = get();
    const existing = howls.find(h => h.name === howl.name);
    if (existing) {
      console.error('A howl named', howl.name, 'already exists');
      return;
    }
    set({ howls: [...howls, howl] });
  },

  unregisterProductAudio: howl => {
    const { howls } = get();
    set({ howls: howls.filter(h => h.name !== howl.name) });
  },
}));
