import { Vector3 } from 'three';
import { useCameraStore } from 'services/CameraService';
import { useDebugStore } from 'storage/debug';

const SPEED_ROTATION_PER_SECOND = 3.0;
const FORWARD_SPEED_MOVEMENT_PER_SECOND = 5.328;
const BACKWARD_SPEED_MOVEMENT_PER_SECOND = 1.332;

const moveInCamDirection = moveParams => {
  const speedHack = useDebugStore.getState().getSpeedHack();
  const { xAxis, yAxis, rotation, isJoystick } = moveParams;
  const delta = Math.min(moveParams.delta, 1.0);
  const targetYAngle = normalizeRad(useCameraStore.getState().euler.y + Math.PI);
  const playerYAngle = rotation;
  const rotationDelta = targetYAngle - playerYAngle;
  const { oldOrbit: orbit } = useCameraStore.getState();
  orbit.startFollowing();

  const movement = new Vector3(xAxis, 0, yAxis);
  if (movement.lengthSq() > 1.0) {
    movement.normalize();
  }
  if (movement.z < 0) {
    movement.x *= -0.5;
  }
  if (isJoystick) {
    movement.x = Math.sign(xAxis) * xAxis * xAxis;
  }

  return [
    Math.sin(rotation) * delta * movement.z * FORWARD_SPEED_MOVEMENT_PER_SECOND * (speedHack ? 10 : 1),
    0,
    Math.cos(rotation) * delta * movement.z * FORWARD_SPEED_MOVEMENT_PER_SECOND * (speedHack ? 10 : 1),
    rotationDelta,
  ];
};

const moveFromKeys = moveParams => {
  const speedHack = useDebugStore.getState().getSpeedHack();
  const { xAxis, yAxis, rotation, isJoystick } = moveParams;
  const delta = Math.min(moveParams.delta, 1.0);

  const movement = new Vector3(xAxis, 0, yAxis);
  if (movement.lengthSq() > 1.0) {
    movement.normalize();
  }
  if (movement.z < 0) {
    movement.x *= -0.5;
  }
  if (isJoystick) {
    movement.x = Math.sign(xAxis) * xAxis * xAxis;
  }
  const aboveThreshold = Math.abs(movement.x) > 0.05 || Math.abs(movement.z) > 0.05;
  if (!aboveThreshold) {
    return [0, 0, 0, 0];
  }
  return [
    Math.sin(rotation) *
      movement.z *
      delta *
      (movement.z > 0 ? FORWARD_SPEED_MOVEMENT_PER_SECOND : BACKWARD_SPEED_MOVEMENT_PER_SECOND) *
      (speedHack ? 10 : 1),
    0,
    Math.cos(rotation) *
      movement.z *
      delta *
      (movement.z > 0 ? FORWARD_SPEED_MOVEMENT_PER_SECOND : BACKWARD_SPEED_MOVEMENT_PER_SECOND) *
      (speedHack ? 10 : 1),

    movement.x * delta * SPEED_ROTATION_PER_SECOND * 1.5,
  ];
};

const move = moveParams => {
  const { oldOrbit: orbit } = useCameraStore.getState();
  if (orbit.following) {
    return moveFromKeys(moveParams);
  } else {
    return moveInCamDirection(moveParams);
  }
};

const normalizeRad = rad => {
  return Math.atan2(Math.sin(rad), Math.cos(rad));
};

const init = () => {};
export default { init, move, label: 'RefinedRelativeCam' };
