// @ts-nocheck
import React, { useRef, useEffect, useMemo } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Vector3, MathUtils, RepeatWrapping, InstancedBufferAttribute, Object3D } from 'three';
import { useTexture } from '@react-three/drei';
import { damp } from 'maath/easing';
import sortBy from 'lodash-es/sortBy';
import renderOrder from '../../../config/renderOrder';

import fromCdn from 'utilities/cdn';

import { useWeatherStore } from '../../../store';

import vertex from './shaders/index.vert';
import fragment from './shaders/index.frag';

const V3 = new Vector3();
const OBJ = new Object3D();
const Z_SHIFT = 100;

const VIEWPORT_RANGES = [-500, 500];

const calculateCullingDistance = (distance, camera) => {
  const vFOV = MathUtils.degToRad(camera.fov);
  const height = 2 * Math.tan(vFOV / 2) * (distance + camera.position.z);
  const width = height * camera.aspect;
  return { width, height };
};

// INSTANCING? MOVE ANIMATION INTO VERTEX?
export default function NewClouds({ data, id }) {
  const ref = useRef(null);
  const geometry = useRef(null);
  const internalData = useRef(null);
  const { amount, asset, spreadY, spreadZ, sizeRange, speedRange, opacityRange } = data;

  const { camera } = useThree();
  const smoothedValues = useWeatherStore(state => state.smoothedValues);
  const { env } = smoothedValues;
  const envOpacityRef = useRef({
    default: 0,
    damp1: 0,
    damp2: 0,
  });

  const noiseTexture = useTexture(fromCdn(`/shared/districts/intersolar/weather/dom/noise.png`), t => {
    t.wrapS = t.wrapT = RepeatWrapping;
  });
  const perlinTexture = useTexture(fromCdn(`/shared/districts/intersolar/weather/dom/perlin.jpg`), t => {
    t.wrapS = t.wrapT = RepeatWrapping;
  });

  const cloudTxt = useTexture(fromCdn(asset), t => {
    t.wrapS = t.wrapT = RepeatWrapping;
  });

  const shaderArgs = useMemo(() => {
    return {
      vertexShader: vertex,
      fragmentShader: fragment,
      uniforms: {
        uTxt: { value: cloudTxt },
        uNoiseTxt: { value: noiseTexture },
        uPerlinTxt: { value: perlinTexture },
        uResolution: { value: [window.innerWidth, window.innerHeight] }, // make it dynamic, not priority tho
        uTime: { value: 0 },
        uEnvOpacity: { value: [1, 1, 1] },
      },
      transparent: true,
      depthWrite: false,
    };
  }, []);

  const createDataArray = () => {
    internalData.current = Array.from({ length: amount }).map((e, i) => {
      const position = V3.clone().set(
        MathUtils.randFloat(VIEWPORT_RANGES[0], VIEWPORT_RANGES[1]),
        MathUtils.randFloat(spreadY[0], spreadY[1]),
        MathUtils.randFloat(spreadZ[0], spreadZ[1]) + Z_SHIFT
      );

      const ratio = cloudTxt.source.data.width / cloudTxt.source.data.height;
      const scaleX = MathUtils.randFloat(sizeRange[0], sizeRange[1]);
      const scale = V3.clone().set(scaleX, (scaleX / ratio) * MathUtils.randFloat(0.9, 1.1), 1);

      const rotation = V3.clone().set(Math.PI * 0.2, MathUtils.randFloatSpread(0.1), Math.PI * 0);

      const speed = MathUtils.randFloat(speedRange[0], speedRange[1]);

      const envOpacityType = ['default', 'damp1', 'damp2'][MathUtils.randInt(0, 2)];

      let cullingX = calculateCullingDistance(Math.abs(position.z), camera).width;
      cullingX *= -0.5;
      cullingX -= scale.x * 0.5;

      return { position, scale, speed, rotation, envOpacityType, cullingX };
    });

    internalData.current = sortBy(internalData.current, el => el.position.z);
  };

  const updateGeometry = () => {
    const opacityArr = [];
    const envOpacityArr = [];

    internalData.current.forEach(el => {
      opacityArr.push(MathUtils.randFloat(opacityRange[0], opacityRange[1]));
      envOpacityArr.push(MathUtils.randInt(0, 2));
    });

    const iba1 = new InstancedBufferAttribute(new Float32Array(opacityArr), 1);
    geometry.current.setAttribute('aOpacity', iba1);

    const iba2 = new InstancedBufferAttribute(new Float32Array(envOpacityArr), 1);
    geometry.current.setAttribute('aEnvOpacityType', iba2);
  };

  const setInitial = () => {
    internalData.current.forEach((el, i) => {
      OBJ.position.copy(el.position);
      OBJ.rotation.set(el.rotation.x, el.rotation.y, el.rotation.z);
      OBJ.scale.set(el.scale.x, el.scale.y, el.scale.z);
      OBJ.updateMatrix();

      ref.current.setMatrixAt(i, OBJ.matrix);
    });

    ref.current.instanceMatrix.needsUpdate = true;
  };

  useEffect(() => {
    createDataArray();
    updateGeometry();
    setInitial();
  }, []);

  useFrame((state, delta) => {
    if (!internalData.current) return;
    envOpacityRef.current.default = env[id].opacity;
    damp(envOpacityRef.current, 'damp1', env[id].opacity, 0.8, delta * 0.9);
    damp(envOpacityRef.current, 'damp2', env[id].opacity, 0.8, delta * 0.8);
    shaderArgs.uniforms.uTime.value += delta;

    shaderArgs.uniforms.uEnvOpacity.value[0] = envOpacityRef.current.default;
    shaderArgs.uniforms.uEnvOpacity.value[1] = envOpacityRef.current.damp1;
    shaderArgs.uniforms.uEnvOpacity.value[2] = envOpacityRef.current.damp2;

    internalData.current.forEach((el, i) => {
      // RESET IF OUT OF VIEWPORT >> move to vertex > micro perf opt?
      if (el.position.x < el.cullingX) {
        // use higher scale to give little more range due depth
        el.position.x = VIEWPORT_RANGES[1] + el.scale.x * 0.5;
        el.position.y = MathUtils.randFloat(spreadY[0], spreadY[1]);
      }

      // MOVE
      el.position.x -= 10 * delta * el.speed * env[id].speed;

      OBJ.position.copy(el.position);
      OBJ.rotation.set(el.rotation.x, el.rotation.y, el.rotation.z);
      OBJ.scale.set(el.scale.x, el.scale.y, el.scale.z);
      OBJ.updateMatrix();

      ref.current.setMatrixAt(i, OBJ.matrix);
    });

    ref.current.instanceMatrix.needsUpdate = true;

    // SAVE SOME CALLS
    ref.current.visible = !(
      envOpacityRef.current.default < 0.02 &&
      envOpacityRef.current.damp1 < 0.02 &&
      envOpacityRef.current.damp2 < 0.02
    );
  });

  return (
    <>
      <instancedMesh
        ref={ref}
        args={[null, null, amount]}
        matrixAutoUpdate={false}
        position-z={-Z_SHIFT}
        name={`Instanced clouds`}
        renderOrder={renderOrder.CLOUDS}
      >
        <planeGeometry args={[1, 1, 1, 1]} ref={geometry} />
        <shaderMaterial args={[shaderArgs]} />
      </instancedMesh>
    </>
  );
}
