import BookmarkButtonRegular from './BookmarkButtonRegular';
import BookmarkButtonSmall from './BookmarkButtonSmall';

import { useTrackingStore } from 'services/TrackingService';
import { useTalkStore } from 'services/TalkService';

export default function BookmarkButton({ talkId, active, small, style = {} }) {
  const bookmark = e => {
    e.stopPropagation();
    const action = active ? 'Unbookmark' : 'Bookmark';
    useTrackingStore.getState().trackEvent({ category: 'Talk', action: action });
    useTalkStore.getState().bookmark(talkId, !active);
  };

  return small ? (
    <BookmarkButtonSmall clickHandler={bookmark} active={active} style={style} />
  ) : (
    <BookmarkButtonRegular clickHandler={bookmark} active={active} style={style} />
  );
}
