import DevMenu from '../../../uiLivi/components/Menu/SubMenu/DevMenu';
import styled from 'styled-components';
import IconDev from './icons/icon-dev.svg';
import { useState } from 'react';

const DevMenuWrapper = styled.div`
  position: fixed;
  left: 24px;
  top: 80px;
  z-index: 1;
  width: 300px;
  height: calc(100vh - 80px);
  background-color: #191919;
  border-radius: 5px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 80px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 0 4px #ffffff;
  cursor: pointer;
  pointer-events: all;
  background-color: #191919;
  padding: 10px;
  border-radius: 5px;
`;

const Close = styled.div`
  display: flex;
  padding: 10px;
  font-size: 20px;
  justify-content: right;
  cursor: pointer;
`;

export default function DevContent() {
  const [show, setShow] = useState(false);
  const open = () => {
    setShow(true);
  };
  const close = () => {
    setShow(false);
  };

  return (
    <>
      <ButtonWrapper onClick={open}>
        <IconDev style={{ width: '20px', marginRight: '8px', stroke: '#ffffff' }} />
        {'DEV'}
      </ButtonWrapper>
      {show && (
        <DevMenuWrapper>
          <Close onClick={close}>x</Close>
          <DevMenu />
        </DevMenuWrapper>
      )}
    </>
  );
}
