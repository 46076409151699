import ChatSelector from 'uiLivi/components/Chat/ChatSelector';
import Person from 'common/components/Person';
import { useUserStore } from 'services/UserService';
import { useChatStore } from 'services/ChatService';
import { StyledChatOverView, StyledRow, StyledUserName } from './styles';
import OnlineIndicator from 'common/components/OnlineIndicator';

export default function ChatOverview({ chats }) {
  const user = useUserStore(state => state.user);
  const selfId = user.id;
  const isSupportUser = user && user.role.type === 'supporter';
  const contentChats = chats.filter(c => c.type === 'content');
  const districtChats = chats.filter(c => c.type === 'district');
  const supporterChats = chats.filter(c => c.type === 'support');
  const privateOneOnOneChats = chats.filter(c => c.type === 'private').filter(c => c.users.length === 2);
  const privateGroupChats = chats.filter(c => c.type === 'private').filter(c => c.users.length > 2);

  const openChat = c => {
    useChatStore.getState().enter(c);
  };

  let animationIndex = 0;

  return (
    <StyledChatOverView>
      {contentChats.map((c, i) => {
        const lastItem = i === contentChats.length - 1;
        animationIndex++;
        return (
          <StyledRow key={i} lastItem={lastItem} animationIndex={animationIndex}>
            <ChatSelector key={i} chat={c} />
          </StyledRow>
        );
      })}
      {districtChats.map((c, i) => {
        const lastItem = i === districtChats.length - 1;
        animationIndex++;
        return (
          <StyledRow key={i} lastItem={lastItem} animationIndex={animationIndex}>
            <ChatSelector key={i} chat={c} />
          </StyledRow>
        );
      })}
      {isSupportUser &&
        supporterChats.map((c, i) => {
          if (c.users.length < 2) return null;
          const otherUser = c.users.find(u => u.id !== selfId);
          const otherImage = otherUser.profilePicture;
          animationIndex++;
          return (
            <StyledRow key={i} onClick={() => openChat(c)} animationIndex={animationIndex}>
              <Person
                verticalCenter
                chatNotice={!c.seen}
                image={{ src: otherImage, size: 'm' }}
                infoComponents={[<StyledUserName key={i}>Support - {c.name}</StyledUserName>]}
              />
            </StyledRow>
          );
        })}
      {privateGroupChats.map((c, i) => {
        animationIndex++;
        return (
          <StyledRow key={i} animationIndex={animationIndex}>
            <ChatSelector key={i} chat={c} isGroupChat />
          </StyledRow>
        );
      })}
      {privateOneOnOneChats.map((c, i) => {
        const otherUser = c.users.find(u => u.id !== selfId);
        const otherImage = otherUser.profilePicture;
        const isExpert = otherUser.role.type === 'expert';
        const isOnline = otherUser.isOnline;
        animationIndex++;
        return (
          <StyledRow key={i} onClick={() => openChat(c)} animationIndex={animationIndex}>
            <Person
              verticalCenter
              isExpert={isExpert}
              chatNotice={!c.seen}
              image={{ src: otherImage, size: 'm' }}
              infoComponents={[
                <StyledUserName key={'chatUserName'}>{c.name}</StyledUserName>,
                <OnlineIndicator key={'onlineIndicatorKey'} active={isOnline} />,
              ]}
            />
          </StyledRow>
        );
      })}
    </StyledChatOverView>
  );
}
