import { Billboard, Text } from '@react-three/drei';
import React, { Suspense, useRef } from 'react';
import fromCdn from 'utilities/cdn';
import { limitTextLength } from 'components/Play/Players';
import { useGlobalHubStore } from 'services/GlobalHubService';
import { useFrameAfterMount } from 'utilities/hooks';
import { Color } from 'three';
import { useDistrictStore } from 'services/DistrictService';

export default function GlobalPlayerNamePlate({ internalRef, userId }) {
  const district = useDistrictStore(state => state.district);

  const textRef = useRef();
  const impulse = useRef(0);
  const colors = useRef({
    c1: new Color(0xe877ae),
    c2: new Color(0x7c73f3),
    c3: new Color(0),
  });

  useFrameAfterMount((state, dt) => {
    if (!textRef.current) return;

    internalRef.current.visible = true;

    let micLevel = useGlobalHubStore.getState().getMicLevel(userId);
    if (!district.isGlobalVoiceArea) micLevel = 0;

    const micLevelAmplified = Math.pow(micLevel, 0.5) * 1.5;
    if (impulse.current < micLevelAmplified) {
      impulse.current = micLevelAmplified;
    } else {
      impulse.current -= dt * 3.0;
    }
    const impulseMicLevel = impulse.current;

    textRef.current.outlineWidth = 0.02 - (1 - impulseMicLevel) * 0.01;
    textRef.current.outlineBlur = 0.01 + 0.5 * impulseMicLevel;
    textRef.current.outlineOpacity = 0.9 - (1 - impulseMicLevel) * 0.9;
    textRef.current.color = 0x7fe8fc;

    const { c1, c2, c3 } = colors.current;
    c3.lerpColors(c1, c2, impulseMicLevel);
    textRef.current.outlineColor = c3.getHex(); //0x7c73f3 + mxicLevel * 255;
  });

  return (
    <Billboard ref={internalRef} name={'NamePlate'} visible={false}>
      <Suspense fallback={null}>
        <Text
          ref={textRef}
          outlineWidth={0.02}
          outlineColor={'#ff00ff'}
          outlineBlur={0.03}
          outlineOpacity={0.9}
          position={[0, 0, 0]}
          color={'#ffffff'}
          fontSize={0.1}
          maxWidth={200}
          lineHeight={1}
          letterSpacing={0.02}
          textAlign={'left'}
          font={fromCdn('/shared/fonts/demodern-medium-webfont.woff')}
          anchorX="center"
          anchorY="middle"
        >
          {limitTextLength(useGlobalHubStore.getState().users[userId].forename)}
        </Text>
      </Suspense>
    </Billboard>
  );
}
