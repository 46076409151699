import { useAudioEnabledState, usePlayAmbientHowls } from '../../AudioController/hooks';
import { useProductAudioStore } from '../../../services/ProductAudioService';
import { useState, useEffect, useRef } from 'react';

export default function useSfx() {
  const [panelMovementSoundActive, setPanelMovementSoundActive] = useState(false);
  const timerRef = useRef(null);

  usePlayAmbientHowls([...(panelMovementSoundActive ? ['ambient_weather_sun_panel-movement'] : [])], {
    fadeInDuration: 0.5,
    fadeOutDuration: 0.5,
  });

  const enabled = useAudioEnabledState();

  const playSupportOutSound = () => {
    if (!enabled) return;

    const name = 'ambient_weather_sfx-solar-panel-support-out';
    const delay = 1;
    const howl = useProductAudioStore.getState().howls.find(howl => howl.name === name);
    if (!howl) {
      console.warn(name, 'not found');
      return;
    }
    howl.loop(false);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      howl.play();
    }, delay * 1000);
  };
  const playSupportInSound = () => {
    if (!enabled) return;

    const name = 'ambient_weather_sfx-solar-panel-support-in';
    const howl = useProductAudioStore.getState().howls.find(howl => howl.name === name);
    if (!howl) {
      console.warn(name, 'not found');
      return;
    }
    howl.loop(false);
    howl.play();
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  return { playSupportOutSound, playSupportInSound, setPanelMovementSoundActive };
}
