import styled from 'styled-components';
import { menuPadding } from 'uiLivi/components/Menu/SubMenu/styles';
import { colors } from 'uiLivi/styles';

export const cardMargin = 8;
export const cardMarginPx = `${cardMargin}px`;
export const menuPaddingMinusCardMarginPx = `${menuPadding - cardMargin}px`;

export const NoCards = styled.div`
  height: 200px;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  margin: 20px;
`;

export const BodyContainer = styled.div`
  padding: 0 ${menuPaddingMinusCardMarginPx};
  background-color: ${colors.background.base};
  overflow: auto;
`;
