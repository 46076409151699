import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import Curve from './Curve';
import { AdditiveBlending, MathUtils, Object3D } from 'three';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';
import glow from './assets/lensflare/15140473-sun_l.png';
import hexangle from './assets/lensflare/hexangle.png';
import gsap from 'gsap';
import { Lensflare, LensflareElement } from 'three/addons/objects/Lensflare';
import { useWeatherStore } from '../store';

export default function Sun() {
  const { gl } = useThree();
  const isTrackerScene = useWeatherStore(state => !state.isWeather);

  // const { progress, dirLightTarget_pos } = useControls({
  //   progress: { value: 0.5, min: 0, max: 1, step: 0.001 },
  //   dirLight: folder({
  //     dirLightTarget_pos: {
  //       value: [0, 0, -44],
  //       step: 0.1,
  //     },
  //   }),
  // });

  const curve = useRef(new Curve());
  // const line = useRef(
  //   new Line(
  //     new BufferGeometry().setFromPoints(curve.current.getCurvePoints()),
  //     new LineBasicMaterial({ color: '#b50000' })
  //   )
  // );
  const target = useRef(new Object3D());
  const pointLight = useRef(null);
  const sprite = useRef(null);
  const spriteMat = useRef(null);
  const dirLight = useRef(null);
  const smoothProgress = useRef(0);
  const tl = useRef(null);
  const values = useMemo(() => {
    return { color: 'rgb(244,94,44)', intensity: 0.1 };
  }, []);

  const [t_glow, t_hexangle] = useLoader(TextureLoader, [glow, hexangle]);

  useEffect(() => {
    if (isTrackerScene) {
      gl.shadowMap.autoUpdate = true;
      gsap.to(spriteMat.current, 0.5, { opacity: 1 });
      pointLight.current.children[2].visible = true;
    } else {
      gsap.to(spriteMat.current, 0.5, { opacity: 0 });
      pointLight.current.children[2].visible = false;
    }
    // console.log('shadowMap:', gl.shadowMap);
  }, [isTrackerScene]);

  useLayoutEffect(() => {
    if (pointLight.current && pointLight.current.children.length === 2) {
      const lensflare = new Lensflare();
      lensflare.addElement(new LensflareElement(t_hexangle, 60, 0.6));
      lensflare.addElement(new LensflareElement(t_hexangle, 70, 0.7));
      lensflare.addElement(new LensflareElement(t_hexangle, 120, 0.9));
      lensflare.addElement(new LensflareElement(t_hexangle, 70, 1));
      pointLight.current.add(lensflare);
    }

    if (dirLight.current) {
      const shadow = { size: 2048, bias: 0.06 };
      const sizeWidth = 45;
      const sizeHeight = 26;
      dirLight.current.shadow.camera.near = 0;
      dirLight.current.shadow.camera.far = 2000;
      dirLight.current.shadow.camera.left = -sizeWidth;
      dirLight.current.shadow.camera.right = sizeWidth;
      dirLight.current.shadow.camera.bottom = -sizeHeight;
      dirLight.current.shadow.camera.top = sizeHeight;
      dirLight.current.shadow.camera.updateProjectionMatrix();
      dirLight.current.shadow.bias = shadow.bias / 100000;

      dirLight.current.shadow.mapSize.width = shadow.size;
      dirLight.current.shadow.mapSize.height = shadow.size;
    }

    tl.current = gsap.timeline();
    tl.current.to(values, { intensity: 1.2, color: 'rgb(255,250,229)', duration: 0.5 });
    tl.current.to(values, { intensity: 0.01, color: 'rgb(244,94,44)', duration: 0.5 });

    smoothProgress.current = useWeatherStore.getState().sliderProgress;
  }, []);

  // useEffect(() => {
  //   const helper = new CameraHelper(dirLight.current.shadow.camera);
  //   scene.add(helper);
  // }, []);

  useFrame(() => {
    // const sliderProgress = progress;
    const sliderProgress = useWeatherStore.getState().sliderProgress;
    smoothProgress.current = MathUtils.lerp(smoothProgress.current, sliderProgress, 0.05);
    pointLight.current.position.copy(curve.current.getPointAt(smoothProgress.current));

    tl.current.progress(sliderProgress);
    spriteMat.current.color.set(values.color);
    dirLight.current.color.set(values.color);
    dirLight.current.desiredintensity = values.intensity;
    dirLight.current.intensity = MathUtils.lerp(
      dirLight.current.intensity,
      isTrackerScene ? dirLight.current.desiredintensity : 0,
      0.1
    );
  });

  return (
    <>
      {/*<axesHelper position={dirLightTarget_pos} args={[3]} />*/}
      {/*<primitive position={dirLightTarget_pos} object={target.current} />*/}
      {/*<primitive object={line.current} />*/}
      <primitive position={[0, 0, 0]} object={target.current} />
      <pointLight ref={pointLight} intensity={0}>
        <sprite ref={sprite} scale={[0.15, 0.15]}>
          <spriteMaterial
            ref={spriteMat}
            map={t_glow}
            alphaMap={t_glow}
            blending={AdditiveBlending}
            sizeAttenuation={false}
            depthTest={true}
            depthWrite={false}
            transparent={true}
          />
        </sprite>
        <directionalLight target={target.current} ref={dirLight} castShadow={true} />
      </pointLight>
    </>
  );
}
