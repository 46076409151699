import { useFrame, useThree } from '@react-three/fiber';
import CameraController from 'components/Play/CameraController';
import Environment from 'components/Play/Environment';
import GlobalPlayers from 'components/Play/GlobalPlayers';
import PointerControls from 'components/Play/PointerControls';
import React, { Suspense, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useCameraStore } from 'services/CameraService';
import { useContentStore } from 'services/ContentService';
import { useDistrictStore } from 'services/DistrictService';
import { useEventStore } from 'services/EventService';
import { useMaterialStore } from 'services/MaterialService';
import { usePlayerStore } from 'services/PlayerService';
import { MODE_DISTRICT, MODE_FITTING, MODE_PRELOAD } from 'services/PlayerService/constants';
import { usePovStore } from 'services/PovService';
import { useSceneStore } from 'services/SceneService';
import { useDebugStore } from 'storage/debug';
import ThreeCanvas from 'three/ThreeCanvas';
import Measurement from '../../Stats/Measurement';
import { useEnvironmentStore } from '../Environment/store';
import FittingRoom from '../FittingRoom';
import HowlerPlayer from '../HowlerPlayer';
import PlayerControls from '../PlayerControls';
import Players from '../Players';
import Portals from '../Portals';
import Products from '../Products';
import Scene from 'components/Play/Scene';
import ErrorFallbackUI from 'components/ErrorFallbackUI';
import { usePostStore } from 'three/Renderer/store';
import { useControlsStore } from 'services/ControlsService';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';
import Character from 'components/Play/Character';
import axios from 'axios';
import AvatarPerformance from '../../DevContent/AvatarPerformance';

// Mounted if Suspense is completed
// Upload/warm up GPU, enter district, hide loadder
function Done() {
  const debugMinimal3d = useDebugStore(state => state.getMinimal3d());

  const { gl, scene } = useThree();
  const [frame, setFrame] = useState(-1);

  useEffect(() => {
    if (!useSceneStore.getState().scene) {
      // eslint-disable-next-line no-console
      console.warn('no scene:', useSceneStore.getState().scene);
      return;
    }
    // flo disable metrics
    // axios.get('https://metrics-collector.virtexp.virtual-events.dmdr.io/metric/incr_stage_passed?stage=enter');

    useDistrictWarmupStore.setState({ loadingProgress: 1 });

    // set player position to spawn
    usePlayerStore.getState().resetAndSpawn();

    let id2, id3;
    const id1 = setTimeout(() => {
      // update loader
      useDistrictWarmupStore.setState({ enteringProgress: 0 });
      useDistrictWarmupStore.getState().setPhase(WarmupPhase.ENTERING);

      // start this components useFrame, wait until prev states have synced
      id2 = setTimeout(() => {
        const players = scene.getObjectByName('Players');
        const products = scene.getObjectByName('Products');
        players && (players.visible = false);
        products && (products.visible = false);

        // start renderer
        usePostStore.setState({ isPaused: false });

        // handle first major glitch (aka compile shaders)
        id3 = setTimeout(() => {
          setFrame(0);
        }, 100);
      }, 50);
    }, 50);

    return () => {
      clearTimeout(id1);
      clearTimeout(id2);
      clearTimeout(id3);
    };
  }, []);

  useFrame(() => {
    if (frame >= 0) {
      setFrame(frame + 1);

      // rotate camera
      const rot = frame * (debugMinimal3d ? 0.5 : 0.15);
      // camera.rotation.set(0, rot, 0);

      useDistrictWarmupStore.setState({ enteringProgress: Math.min(rot / (Math.PI * 2), 1) });

      // render shadowmap only first quarter of rotation
      if (rot < Math.PI / 4) {
        gl.shadowMap.autoUpdate = true;
      } else if (rot < Math.PI / 3) {
        // disable shadowmap update if not dynamic
        if (
          !usePostStore.getState().renderConfiguration.shadow.enabled &&
          useDistrictStore.getState().district.room !== 'weather'
        ) {
          gl.shadowMap.autoUpdate = false;
        }
      } else {
        const players = scene.getObjectByName('Players');
        const products = scene.getObjectByName('Products');
        players && (players.visible = true);
        products && (products.visible = true);
      }

      // one rotation is over
      if (rot > Math.PI * 2) {
        // make sure we stop the local frame loop
        setFrame(-1);

        //wait a bit
        requestAnimationFrame(() => {
          //enter district
          // flo disable metrics
          // axios.get('https://metrics-collector.virtexp.virtual-events.dmdr.io/metric/incr_stage_passed?stage=done');
          useDistrictStore.getState().setDistrictLoaded();
          useDistrictWarmupStore.getState().setPhase(WarmupPhase.INIT);
          //wait a bit
          requestAnimationFrame(() => {
            setTimeout(() => {
              //hide loader
              usePlayerStore.getState().enterDistrict();
              useDistrictWarmupStore.getState().setPhase(WarmupPhase.DONE);
            }, 100);
          });
        });
      }
    }
  });

  return null;
}

function ErrorFallback({ error }) {
  useEffect(() => {
    useCameraStore.setState({ mode: 'free' });
    useSceneStore.getState().setEmptyScene();
    useDistrictStore.getState().setDistrictLoaded();
    useDistrictWarmupStore.getState().setPhase(WarmupPhase.DONE);
    return () => {
      useDistrictWarmupStore.getState().setPhase(WarmupPhase.IDLE);
    };
  }, []);
  return <ErrorFallbackUI message={error.message} />;
}

// mounted per district
export default function DistrictCanvas({ district, children }) {
  const { mapId } = useParams();

  const debugMinimal3d = useDebugStore(state => state.getMinimal3d());
  const debugStatsEnabled = useDebugStore(state => state.getStatsEnabled());
  const debugShowManyAvatars = useDebugStore(state => state.getShowManyAvatars());

  const materials = useMaterialStore(state => state.materials.materials);
  const mode = usePlayerStore(state => state.mode);
  const isFitting = mode === MODE_FITTING;
  const isOnDistrict = mode === MODE_DISTRICT;
  const isOnDistrictOrPreloading = mode === MODE_DISTRICT || mode === MODE_PRELOAD;
  const environmentPreset = isFitting ? 'fittingRoom' : district.environmentPreset;
  const content = useContentStore(state => state.activeContent);
  const fullscreenContentInFocus = content && (content.type.isFullscreen || content.contentType.isFullscreen);
  const history = useHistory();
  const useAvatars = useEventStore(state => state.event.useAvatars);
  const pov = usePovStore(state => state.pov);
  const warmupPhase = useDistrictWarmupStore(state => state.phase);

  const isShown = warmupPhase === WarmupPhase.DONE;
  const isInitOrShown = warmupPhase === WarmupPhase.INIT || warmupPhase === WarmupPhase.DONE;
  const [startLoading, setStartLoading] = useState(false);

  useEffect(() => {
    // select materials
    const materials = district.materials ? district.materials : 'LobbyMaterials';
    const materialsOption = useMaterialStore.getState().materialsOptions.find(m => m.name === materials);
    useMaterialStore.getState().setMaterialsOption(materialsOption);

    // camera, lights, fog, sky...
    useEnvironmentStore.getState().setPresetByName(environmentPreset);

    usePostStore.setState({ isPaused: true });

    // delay loading to give trans out some time
    const ivId = setTimeout(() => {
      // flo disable metrics
      // axios.get('https://metrics-collector.virtexp.virtual-events.dmdr.io/metric/incr_stage_passed?stage=load');
      useDistrictWarmupStore.getState().setPhase(WarmupPhase.LOADING);
      setStartLoading(true);
    }, 400);
    return () => {
      clearTimeout(ivId);
    };
  }, []);

  useEffect(() => {
    useEnvironmentStore.getState().setPresetByName(environmentPreset);
  }, [environmentPreset]);

  useEffect(() => {
    if (!pov) return;
    const povPosition = pov.location;
    const orbitEuler = pov.euler;
    usePlayerStore.getState().playerActions.goToPov(povPosition);
    const oldOrbit = useCameraStore.getState().oldOrbit;
    oldOrbit.euler.set(orbitEuler[0], orbitEuler[1], orbitEuler[2], 'YXZ');
    oldOrbit.following = false;
  }, [pov, mapId]);

  if (!startLoading) return;

  return (
    <ThreeCanvas DoneComponent={Done} ErrorComponent={ErrorFallback}>
      {debugStatsEnabled && <Measurement />}

      <Suspense fallback={null}>{<HowlerPlayer />}</Suspense>
      <FittingRoom visible={isFitting} />

      <Scene url={district.mesh} visible={isOnDistrictOrPreloading} />
      <Products district={district} visible={!isFitting} />
      <Portals
        onEnterPortal={nextMapId => {
          useDistrictStore.getState().navigateToDistrict(history, nextMapId);
          usePlayerStore.setState({ previousRoom: useDistrictStore.getState().district.room });
        }}
      />

      {useAvatars && (warmupPhase === WarmupPhase.LOADING || warmupPhase === WarmupPhase.ENTERING) && !debugMinimal3d && (
        <Character
          name={'Character-Preload'}
          visible={true}
          frustumCulled={false}
          playerData={{
            reaction: 10,
            position: [
              usePlayerStore.getState().position[0],
              usePlayerStore.getState().position[1],
              usePlayerStore.getState().position[2],
            ],
            rotation: usePlayerStore.getState().rotation,
            velocity: [0, 0, 0, 0],
          }}
          reactionId={10}
          isSelf={true}
          indicatorVisible={true}
          appearance={null}
        />
      )}

      {debugShowManyAvatars && <AvatarPerformance />}

      {useAvatars && <GlobalPlayers isFitting={isFitting} />}
      {useAvatars ? <Players /> : null}

      <Environment environmentPreset={environmentPreset} />
      {!debugMinimal3d && <materials.type {...materials.props} />}

      {isShown && (isOnDistrict || isFitting) && <PointerControls />}
      {isShown && isOnDistrict && !fullscreenContentInFocus && useAvatars && <PlayerControls />}
      {isInitOrShown && useAvatars && <CameraController />}
      {children}
    </ThreeCanvas>
  );
}
