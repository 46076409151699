import create from 'utilities/zustand/create';

const NOTIFICATION_LIFESPAN = 7500;
let notificationTimeout = null;

export const useNotificationStore = create((set, get) => ({
  notifications: [],
  level: 'default',
  position: 'default',

  clearNotificationTimeout: stayOpen => {
    if (notificationTimeout) clearTimeout(notificationTimeout);
    if (!stayOpen) {
      notificationTimeout = setTimeout(() => {
        useNotificationStore.getState().closeNotification();
      }, NOTIFICATION_LIFESPAN);
    }
  },
  addNotification: notification => {
    const { props } = notification;
    const level = props && props.level ? props.level : 'default';
    const position = props && props.position ? props.position : 'default';
    const stayOpen = props && props.stayOpen;
    get().clearNotificationTimeout(stayOpen);
    const stateNotifications = get().notifications;
    set({ notifications: [...stateNotifications, notification], level, position });
  },
  closeNotification: () => {
    get().clearNotificationTimeout();
    const { notifications } = get();
    notifications.shift();
    set({ notifications: [...notifications] });
  },
}));
