import React, { useEffect } from 'react';
import Person from 'common/components/Person';
import OnlineIndicator from 'common/components/OnlineIndicator';
import { FullName, GreySendIcon, ListContainer, ListHeadline, LiveSessionIndicator, NameContainer } from './styles';
import { useChatStore } from 'services/ChatService';
import { useUserStore } from 'services/UserService';
import { useTrackingStore } from 'services/TrackingService';
import { usePeopleStore } from 'services/PeopleService';
import styled from 'styled-components';
import { lgBaseColors } from 'uiLivi/styles';
import { smallTextStyle } from 'common/text';
import { useTranslate } from 'services/TranslationService/hooks';

const StyledLoadMore = styled.div`
  ${smallTextStyle};
  font-weight: bold;
  cursor: pointer;
  color: ${lgBaseColors.grey.light};
  text-align: center;
  padding-top: 10px;
`;

export default function People({ updateSize, closeMenu }) {
  const user = useUserStore(state => state.user);
  const isSupporter = useUserStore(state => state.user && state.user.role.type === 'supporter');
  const people = usePeopleStore(state => state.people).filter(p => p.id !== user.id);
  const onlinePeople = people.filter(p => p.isOnline).filter(p => p.id !== user.id);
  const offlinePeople = people.filter(p => !p.isOnline);
  const hasMore = usePeopleStore(state => state.hasMore);
  const translate = useTranslate();

  useEffect(() => {
    usePeopleStore.getState().requestPeople();
  }, []);

  useEffect(updateSize, [people]);

  const renderList = people => {
    return people.map((user, index) => {
      const livesession = user.activity && (user.activity.type === 'DEMO' || user.activity.type === 'LIVESTREAM');
      if (user.role.type === 'expert') return null;
      return (
        <Person
          margin={'15px 0 0 0 '}
          key={index}
          image={{ src: user.profilePicture, size: 'm' }}
          infoComponents={[
            <NameContainer key={'NameContainer'}>
              <FullName
                onClick={() => {
                  useUserStore.getState().selectUserById(user.id);
                  useTrackingStore
                    .getState()
                    .trackEvent({ category: 'People', action: 'ViewUserProfile', label: '' + user.id });
                }}
              >
                {user.forename} {user.surname}
              </FullName>
              <OnlineIndicator active={user.isOnline} />
            </NameContainer>,

            livesession && <LiveSessionIndicator key={'liveSessionIndicator'}>@Live Session</LiveSessionIndicator>,
          ]}
          actionComponents={[
            <GreySendIcon
              key="context"
              onClick={() => {
                closeMenu();
                useChatStore.getState().start([user.id], isSupporter ? 'support' : 'private');
                useTrackingStore
                  .getState()
                  .trackEvent({ category: 'People', action: 'StartChat', label: '' + user.id });
              }}
            />,
          ]}
        />
      );
    });
  };

  return (
    <ListContainer>
      {onlinePeople.length > 0 && <ListHeadline>{translate('people.category.online') || 'Online'}</ListHeadline>}
      {renderList(onlinePeople)}
      {offlinePeople.length > 0 && <ListHeadline>{translate('people.category.offline') || 'Offline'}</ListHeadline>}
      {renderList(offlinePeople)}
      {hasMore && (
        <StyledLoadMore
          onClick={() => {
            usePeopleStore.getState().loadMore();
          }}
        >
          {translate('people.category.loadmore') || 'Load More'}
        </StyledLoadMore>
      )}
    </ListContainer>
  );
}
